import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { CardBody, CardHeader, CardImg, CardTitle } from 'reactstrap'

import { PATHS } from '../../../../utils/constants/routes/RoutePaths'

import { Article } from '../../../../types/Article'

import { BASE_PATH } from '../../../../api/api'
import { articleService } from '../../../../services/articlesService'
import Card from '../../../atoms/card/Card'
import ArticleDateAuthor from '../../articles/article-date-author/ArticleDateAuthor'
import './CardArticle.scss'

interface CardArticleProps extends Article {
  className?: string
}

// FIXME Max : Trouver comment utiliser le htmlParser de ReactMarkdown pour ne laisser parser que les <u>
//  et pas tout le html
const CardArticle: FC<CardArticleProps> = (props) => {
  const { title, slug, image, image_link, published_at, className = '' } = props
  const { t } = useTranslation()
  const date = articleService.formatDate(published_at)

  const route = generatePath(PATHS.NEWS.PAGE, { slug })

  let PATH_TO_IMAGE = ''
  if (image) {
    PATH_TO_IMAGE = BASE_PATH + image.url
  }
  // S'il y a une image, on affiche la date & auteur et avant le titre.
  // S'il n'y en a pas, on affiche le titre avant.
  return (
    <Card className={`CardArticle ${className}`} clickable sm={12} md={6} xl={4}>
      <NavLink to={route}>
        {image ? (
          <CardHeader
            className={`CustomCardHeader`}
            style={{
              backgroundImage: `url(${PATH_TO_IMAGE})`,
            }}
          >
            <div className='image-container'>
              <CardImg
                className={`card-image`}
                top
                src={PATH_TO_IMAGE}
                alt={image.alternativeText || t('kiosk.article.imageAlt')}
              />
            </div>
          </CardHeader>
        ) : (
          <></>
        )}
        {!image && image_link && (
          <CardHeader
            className={`CustomCardHeader`}
            style={{
              backgroundImage: `url(${image_link})`,
            }}
          >
            <div className='image-container'>
              <CardImg className={`card-image`} top src={image_link} alt={image_link || t('kiosk.article.imageAlt')} />
            </div>
          </CardHeader>
        )}
        {!image && !image_link && (
          <CardHeader
            className={`CustomCardHeader`}
            style={{
              backgroundImage: `url(https://palmares.lemondeduchiffre.fr/images/joomlart/demo/default.jpg)`,
            }}
          >
            <div className='image-container'>
              <CardImg
                top
                className={`card-image`}
                src={'https://palmares.lemondeduchiffre.fr/images/joomlart/demo/default.jpg'}
                alt={image_link || t('kiosk.article.imageAlt')}
              />
            </div>
          </CardHeader>
        )}
        <CardBody>
          <ArticleDateAuthor date={date} />
          {!image_link && (
            <CardTitle tag='h2' className='card-article-title'>
              {title}
            </CardTitle>
          )}

          {image_link && (
            <CardTitle tag='h2' className='card-article-title'>
              {title}
            </CardTitle>
          )}
        </CardBody>
      </NavLink>
    </Card>
  )
}

export default CardArticle
