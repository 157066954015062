import React, { FunctionComponent } from 'react'

import './Overlay.scss'

type OverlayProps = {
  children?: JSX.Element
  className?: string
  color?: string
}

const Overlay: FunctionComponent<OverlayProps> = ({ children, className = '' }) => {
  return <div className={`Overlay ${className}`}>{children}</div>
}

export default Overlay
