import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const LoginTextSection: FC = () => {
  const { t } = useTranslation()
  return (
    <div className='LoginText'>
      <img alt='OPCO 2i' src='/news_logo-512x512.png' title={t('nav.home')} className='LoginTextLogo' />
      <p className='font-weight-bold'>Bienvenue sur Instances 2i</p>
      <p>
        Afin de vous accompagner dans vos missions, OPCO 2i met à votre disposition un espace privé spécialement conçu
        pour vous, membres des instances.
      </p>
      <p>Cette interface dédiée vous permet d’accéder, de façon personnalisée, à un ensemble de services.</p>
      <p>
        Calendrier des instances, gestion de vos réunions ou encore accès à diverses informations et documentations,
        Instances 2i a été développé dans le but de simplifier l’ensemble de vos démarches.
      </p>
      <p>
        Nous travaillons à l’amélioration continue de votre espace Instances 2i. A ce titre, nous vous invitons à nous
        faire part de toute proposition d’évolution.
      </p>
      <p>Bonne navigation.</p>
    </div>
  )
}

export default LoginTextSection
