import MeetingInstanceAssociationName from 'components/molecules/meeting/MeetingInstanceAssociationName'
import React, { ChangeEvent, FC, Ref } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { Delegate } from 'types/Delegate'

import MandateDateCell from '../tables-cells/MandateDateCell'

interface RenderDelegateProps {
  delegate: Delegate
  index: number
  errors: DeepMap<
    {
      data: Delegate[]
    },
    FieldError
  >
  register: Ref<HTMLInputElement>
  canEdit: boolean
  submit: (delegateId: number, data: Partial<Delegate>) => void
  current: boolean
}

const RenderDelegate: FC<RenderDelegateProps> = ({
  delegate: { meeting, ...delegate },
  index,
  errors,
  register,
  canEdit,
  submit,
  current,
}) => {
  const submitDate = (e: ChangeEvent<HTMLInputElement>, dateType: 'start_date' | 'end_date') => {
    submit(delegate.id, { [dateType]: e.target.value })
  }

  return (
    <tr key={delegate.id} className={current ? '' : 'member-row-archived'}>
      <td>
        <MeetingInstanceAssociationName meeting={meeting} nameType='long_name' />
      </td>
      <MandateDateCell
        error={errors.data?.[index]?.start_date?.message}
        id={`data[${index}].start_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={delegate.id}
        dateType='start_date'
      />
      <MandateDateCell
        error={errors.data?.[index]?.end_date?.message}
        id={`data[${index}].end_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={delegate.id}
        dateType='end_date'
      />
    </tr>
  )
}

export default RenderDelegate
