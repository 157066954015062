import { format, parse } from 'date-fns'
import fr from 'date-fns/locale/fr'

import { Meeting } from '../../types/Meeting'

import { meetingApiTimeFormat } from '../../services/meetingService'
import { getTime, parseAsDate, VERBAL_DATE_FORMAT } from '../dateUtils'

type InterpolationType = {
  interpolation: string
  explanation: string
}
//interpolation begin key, interpolation end key
export const [ibk, iek] = ['[', ']']

export const EMAIL_INTERPOLATION = (meeting: Meeting): Record<string, InterpolationType> => {
  const options = {
    locale: fr,
  }
  return {
    HEURE_DEBUT: {
      interpolation: getTime(parse(meeting.start_time, meetingApiTimeFormat, new Date())),
      explanation: 'interpolation.meeting.startTime',
    },
    HEURE_FIN: {
      interpolation: getTime(parse(meeting.end_time, meetingApiTimeFormat, new Date())),
      explanation: 'interpolation.meeting.endTime',
    },

    DATE_REUNION: {
      interpolation: format(parseAsDate(meeting.start_date), VERBAL_DATE_FORMAT, options),
      explanation: 'interpolation.meeting.startDate',
    },
    NOM_INSTANCE: {
      interpolation: meeting.instance.long_name,
      explanation: '',
    },
    NOM_OFFICIEL_INSTANCE: {
      interpolation: meeting.instance.name,
      explanation: '',
    },
    NOM_ASSOCIATION: {
      interpolation: meeting.instance.association?.name || '',
      explanation: '',
    },
  }
}
