import AutoCompleteInput from 'components/atoms/input/AutoCompleteInput'
import CustomInput from 'components/atoms/input/CustomInput'
import Row from 'components/atoms/layout/Row'
import React, { FC } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Button, Col, FormGroup, InputGroup, Label } from 'reactstrap'
import { organisationService } from 'services/organisationService'
import { MemberCollegeEnum } from 'types/Member'
import { notifyError } from 'utils/alertUtils'
import { usePermissions } from 'utils/hooks/usePermissions'

import { UserCollegeType, USER_COLLEGE } from '../../../utils/constants/College'

import { User } from '../../../types/User'

import InputWithLabel from '../../atoms/input/InputWithLabel'

export interface UserFormPropsInterface {
  toggleModal: () => void
  register?: React.Ref<HTMLInputElement>
  isInEditMode?: boolean
  errors?: DeepMap<User, FieldError>
  isMember?: boolean
  isDelegate?: boolean
  canEditUser?: boolean
  selectedCollege?: UserCollegeType | null
  defaultOrganizationGroup?: string
  defaultOrganization?: string
  isOnProfileView?: boolean
  hideTelephoneInContacts?: boolean
  hideProfilePictureInContacts?: boolean
}

/**
 * Composant du formulaire des informations d'un utilisateur
 */
const UserForm: FC<UserFormPropsInterface> = (props) => {
  const {
    toggleModal,
    register,
    isInEditMode = false,
    errors,
    selectedCollege,
    isMember,
    isDelegate,
    canEditUser,
    defaultOrganizationGroup,
    defaultOrganization,
    isOnProfileView = false,
    hideTelephoneInContacts,
  } = props
  const { t } = useTranslation()
  const { CONTACTS_CAN_MANAGE, IS_SUPER_ADMIN } = usePermissions()

  const mappingCollege = {
    PATRONAL: {
      enum: MemberCollegeEnum.PATRONAL,
      functionName: 'getOrganisationPatronal',
      function: organisationService.getOrganisationPatronal,
    },
    SALARIE: {
      enum: MemberCollegeEnum.SALARIE,
      functionName: 'getOrganisationSalarie',
      function: organisationService.getOrganisationSalarie,
    },
  }

  const { data: organisation } = useQuery<{ value: string }[]>({
    queryKey: ['organisation', mappingCollege[selectedCollege || 'PATRONAL'].functionName],
    queryFn: async (): Promise<{ value: string }[]> => {
      const res = await mappingCollege[selectedCollege ? selectedCollege : MemberCollegeEnum.PATRONAL].function()
      return res.map((organisation) => {
        return {
          value: organisation.label,
        }
      })
    },
    onError: () => notifyError(t('toastify.errors.organisation.list')),
  })

  const { data: organisationGroup } = useQuery<{ value: string }[]>({
    queryKey: ['organisation', `getGroupOrganisationWithFilter(${selectedCollege})`],
    queryFn: async (): Promise<{ value: string }[]> => {
      const res = await organisationService.getGroupOrganisationWithFilter(
        mappingCollege[selectedCollege || 'PATRONAL'].enum,
      )
      return res.map((organisationGroup) => {
        return {
          value: organisationGroup.label,
        }
      })
    },
    onError: () => notifyError(t('toastify.errors.organisation.group')),
  })

  return (
    <>
      <Row grid>
        <Col xs={12}>
          {isOnProfileView && (
            <CustomInput
              disabled={!isInEditMode}
              type='checkbox'
              id='hide_profile_picture'
              name='hide_profile_picture'
              innerRef={register}
              label={t('forms.hideProfilePicture')}
            />
          )}
        </Col>
        <Col sm={12} md={6}>
          <InputWithLabel
            type='text'
            name='lastname'
            innerRef={register}
            placeholder={t('forms.lastname')}
            labelText={t('forms.lastname')}
            readOnly={!isInEditMode}
            error={errors?.lastname?.message && t(errors.lastname.message)}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputWithLabel
            type='text'
            name='firstname'
            innerRef={register}
            placeholder={t('forms.firstname')}
            labelText={t('forms.firstname')}
            readOnly={!isInEditMode}
            error={errors?.firstname?.message && t(errors.firstname.message)}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputWithLabel
            type='email'
            name='email'
            innerRef={register}
            placeholder={t('forms.email')}
            labelText={t('forms.email')}
            readOnly={true}
            error={errors?.email?.message && t(errors.email.message)}
          />
          <>
            {isInEditMode && (isOnProfileView || IS_SUPER_ADMIN()) ? (
              <Button
                disabled={!isInEditMode}
                style={{ width: '250px' }}
                outline
                color='primary'
                onClick={() => {
                  toggleModal()
                }}
              >
                {t('emailUpdate.title')}
              </Button>
            ) : (
              <></>
            )}
          </>
        </Col>
        <Col sm={12} md={6}>
          {(!hideTelephoneInContacts || isOnProfileView || canEditUser) && (
            <InputWithLabel
              type='text'
              name='telephone'
              innerRef={register}
              placeholder={t('forms.telephone')}
              labelText={t('forms.telephone')}
              readOnly={!isInEditMode}
              error={errors?.telephone?.message && t(errors.telephone.message)}
            />
          )}
          {isOnProfileView && (
            <CustomInput
              type='checkbox'
              disabled={!isInEditMode}
              id='hide_telephone'
              name='hide_telephone'
              innerRef={register}
              label={t('forms.hideTelephone')}
            />
          )}
        </Col>
        {(isMember || isDelegate || IS_SUPER_ADMIN() || CONTACTS_CAN_MANAGE()) && (
          <>
            <Col sm={12} md={6}>
              <FormGroup>
                <InputGroup>
                  <InputWithLabel
                    labelText='college'
                    type='select'
                    name='college'
                    innerRef={register}
                    invalid={!!errors?.college}
                    disabled={true}
                    readOnly={!isInEditMode}
                    error={errors?.college?.message && t(errors.college.message)}
                  >
                    <option value={undefined} disabled>
                      {t('forms.selectCollege')}
                    </option>
                    {USER_COLLEGE.map(([key, college]) => (
                      <option key={key} value={key}>
                        {t(college.label)}
                      </option>
                    ))}
                  </InputWithLabel>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <Label className='font-weight-bold' for='organization'>
                {t('forms.organization')}
              </Label>
              <AutoCompleteInput
                className='mb-3'
                searchOptions={organisation}
                name='organization'
                innerRef={register}
                placeholder={isInEditMode && t('forms.organization')}
                disabled={!isInEditMode}
                defaultValue={defaultOrganization}
              />
            </Col>
            {canEditUser && (
              <Col sm={12} md={6}>
                <Label className='font-weight-bold' for='organization_group'>
                  {t('forms.organizationGroup')}
                </Label>
                <AutoCompleteInput
                  className='mb-3'
                  searchOptions={organisationGroup}
                  name='organization_group'
                  innerRef={register}
                  placeholder={isInEditMode && t('forms.organizationGroup')}
                  disabled={!(!isOnProfileView && isInEditMode && (CONTACTS_CAN_MANAGE() || IS_SUPER_ADMIN()))}
                  defaultValue={defaultOrganizationGroup}
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  )
}

export default UserForm
