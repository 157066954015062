import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, ModalBody, ModalHeader } from 'reactstrap'

import Dropzone from '../../atoms/dropzone/Dropzone'
import Row from '../../atoms/layout/Row'
import Modal from '../../atoms/modal/Modal'
import Spinner from '../../atoms/spinner/Spinner'

interface ModalAddActivityProps {
  show: boolean
  toggle: () => void
  onChange: (newFileList: File[]) => void
}

const ModalAddActivity: FC<ModalAddActivityProps> = ({ show, toggle, onChange }) => {
  const { t } = useTranslation()

  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [backdrop, setBackdrop] = useState<boolean | 'static'>(true)

  const addNewFile = async (newFileList: File[]) => {
    setIsUploading(true)
    setBackdrop('static')
    await onChange(newFileList)
    setIsUploading(false)
    setBackdrop(true)
    toggle()
  }

  const onClickClose = () => {
    if (isUploading) {
      return
    }
    toggle()
  }

  const closeBtn = (
    <button className='close' onClick={onClickClose} type='button'>
      &times;
    </button>
  )

  return (
    <>
      <Modal className='FileSelectionModal' isOpen={show} toggle={toggle} backdrop={backdrop} centered size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {t('kiosk.addActivityFile')}
        </ModalHeader>
        <ModalBody>
          <Row grid fluid className='p-3'>
            <Col>
              {isUploading ? (
                <>
                  <p>{t('meeting.uploading')}</p>
                  <Spinner />
                </>
              ) : (
                <Dropzone label={t('meeting.dropzonePlaceholder')} addNewFiles={addNewFile} />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalAddActivity
