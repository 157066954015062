import React, { FunctionComponent } from 'react'
// Reactstrap
import { ContainerProps } from 'reactstrap'

// Components
import Container from './Container'
// Styles
import './Section.scss'

type SectionProps = ContainerProps & {
  bgAlt?: boolean
  bgColor?: string
  oblique?: 'down' | 'up'
  obliquePosition?: 'default' | 'down' | 'up'
  id?: string
  containerFluid?: boolean
  sectionClassName?: string
}

const Section: FunctionComponent<SectionProps> = ({
  bgAlt,
  bgColor,
  oblique,
  obliquePosition,
  id,
  containerFluid,
  className = '',
  children,
  ...rest
}) => {
  const SectionClassName =
    (className ? className : '') +
    (bgAlt ? ' bg-alt' : '') +
    (bgColor ? ' bg-' + bgColor : '') +
    (oblique ? ' oblique-direction-' + oblique : '') +
    (oblique && obliquePosition ? ' oblique-position-' + obliquePosition : '')

  return (
    <section className={`Section ${SectionClassName}`} id={id}>
      <Container fluid={containerFluid} {...rest}>
        {children}
      </Container>
    </section>
  )
}
export default Section
