import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Spinner from 'components/atoms/spinner/Spinner'
import Table from 'components/atoms/table/Table'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import collaboratorService from 'services/collaboratorService'
import { instanceService } from 'services/instanceService'
import { Collaborator, CollaboratorRelationEnum } from 'types/Collaborator'
import { UserManagerRights } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { updateCollaboratorValidationSchema } from 'utils/constants/Collaborator'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { buildCollaboratorsForManager } from 'utils/mandateUtils'

import Card from '../../atoms/card/Card'
import RenderCollaborator from '../user-collaborators/RenderCollaborator'
import AddCollaboratorFromInstanceModal from './AddCollaboratorFromInstanceModal'

const fetchCollaboratorsWithManagerRightsAndIsCurrent = async (instanceId: number, managerRights: UserManagerRights) =>
  await instanceService
    .getCollaborators(instanceId)
    .then(async (res) => await buildCollaboratorsForManager(res, managerRights))

interface InstanceCollaboratorsTableProps {
  instanceId: number
  instanceName: string
}

const InstanceCollaboratorsTable: FC<InstanceCollaboratorsTableProps> = ({ instanceId, instanceName }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { register, errors, reset } = useForm<{ data: Collaborator[] }>({
    mode: 'onChange',
    resolver: yupResolver(updateCollaboratorValidationSchema),
  })

  const managerRights = useManagerRights()

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['instance', 'getCollaboratorsForManager', instanceId],
    queryFn: () => fetchCollaboratorsWithManagerRightsAndIsCurrent(instanceId, managerRights),
    onSuccess: (data) => reset({ data }),
    onError: () => notifyError(t('toastify.errors.get.collaborateur')),
  })

  const { mutate } = useMutation(
    ({ collaboratorId, data }: { collaboratorId: number; data: Partial<Collaborator> }) =>
      collaboratorService.updateOne(collaboratorId, data),
    {
      mutationKey: ['user', 'updateMe'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMe'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submit = (collaboratorId: number, data: Partial<Collaborator>) => {
    mutate({ collaboratorId, data })
  }

  const { mutate: mutateDelete } = useMutation(
    ({ collaboratorId }: { collaboratorId: number }) => collaboratorService.deleteOne(collaboratorId),
    {
      mutationKey: ['collaborator', 'deleteOne'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.deleteMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitDelete = (collaboratorId: number) => {
    mutateDelete({ collaboratorId })
  }

  if (isLoading) {
    return <Spinner />
  }

  const headers = [
    { label: t('forms.lastname') },
    { label: t('forms.firstname') },
    { label: t('forms.email') },
    { label: t('forms.organization') },
    { label: t('common.fields.mandate.startDate') },
    { label: t('common.fields.mandate.endDate') },
    { label: t('common.fields.mandate.state') },
  ]

  return (
    <>
      <Card>
        <Table>
          <thead>
            <tr>
              {headers.map(({ label }, index) => (
                <th key={`${label}${index}`}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((collaborator, index) => {
                return (
                  <RenderCollaborator
                    current={!!collaborator.is_current}
                    canEdit={!!collaborator.has_manager_right}
                    collaborator={collaborator}
                    key={collaborator.id}
                    index={index}
                    errors={errors}
                    register={register}
                    submit={submit}
                    submitDelete={submitDelete}
                    relation={CollaboratorRelationEnum.INSTANCE}
                  />
                )
              })
            ) : (
              <tr>
                <td colSpan={headers.length}>
                  <p className='text-center mb-0'>{t('mandates.noMandatesFound')} </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Button
        color='primary'
        label={t('mandates.addMandate.collaborator.modalButton')}
        onClick={openModal}
        className='d-block mx-auto mt-3'
      />
      {modalOpen && (
        <AddCollaboratorFromInstanceModal
          instanceId={instanceId}
          instanceName={instanceName}
          toggle={closeModal}
          isOpen={modalOpen}
          refetchCollaborators={refetch}
        />
      )}
    </>
  )
}

export default InstanceCollaboratorsTable
