import { isValid } from 'date-fns'
import qs from 'qs'
import { generatePath } from 'react-router'

import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { DOCUMENT_FRENCH_DATE_OPTIONS } from '../utils/constants/Date'
import { Language } from '../utils/constants/Languages'
import {
  convertDateObjectToStringWithLocale,
  convertDateToStringFromFormat,
  DATABASE_DATE_FORMAT,
} from '../utils/dateUtils'

import { DocumentListType, DocumentType, DocumentTypeEnum } from '../types/Document'
import { DocumentFilterType } from '../types/Filters'
import { QsQueryParamsType } from '../types/Params'
import { User } from '../types/User'

import { execute, executeFormData } from '../api/api'

const getMyDocuments = (
  documentFilters: DocumentFilterType,
  defaultInstance: (string | number | undefined)[],
  user?: User,
): Promise<DocumentListType> => {
  const { instanceList, startDate, endDate, search: fullText, page, limit, favorite } = documentFilters
  const query: QsQueryParamsType = {
    page: page,
    limit: limit,
  }
  query.onlyFavorite = favorite
  if (instanceList && instanceList.length > 0) {
    query.instanceList = instanceList.join('-')
  } else {
    query.instanceList = defaultInstance.join('-')
  }
  if (startDate && isValid(startDate)) {
    query.startDate = convertDateToStringFromFormat(startDate, DATABASE_DATE_FORMAT)
  }
  if (endDate && isValid(endDate)) {
    query.endDate = convertDateToStringFromFormat(endDate, DATABASE_DATE_FORMAT)
  }
  if (fullText) {
    query.fullText = fullText
  }
  const qsQuery = `?${qs.stringify(query)}`
  return execute<DocumentListType>(`${generatePath(ApiRoutes.USERS.DOCUMENTS, { id: user?.id })}${qsQuery}`, 'GET')
}

const getDocumentDisplayDate = (date: Date): string | undefined => {
  return convertDateObjectToStringWithLocale(date, Language.FR, DOCUMENT_FRENCH_DATE_OPTIONS)
}

/**
 * Uploads one or several documents to Strapi and links them to an entity if needed
 * @param files the files to upload
 * @param ref the DB collection on which the link will be created
 * @param refId the id of the collection entry on which the link will be created
 * @param field the field of the collection on which the link will be created
 * @param type the type of document
 * @param source the plugin of the collection if there is any (optional)
 */
const uploadDocuments = (
  files: File[],
  ref: string,
  refId: number,
  field: string,
  type: DocumentTypeEnum = DocumentTypeEnum.MEETING,
  source?: string,
): Promise<DocumentType[]> => {
  const formData = new FormData()

  for (const file of files) {
    formData.append('files', file)
  }
  formData.append('ref', ref)
  formData.append('refId', refId.toString())
  formData.append('field', field)
  formData.append('type', type)
  if (source) {
    formData.append('source', source)
  }

  return executeFormData<DocumentType[]>(ApiRoutes.DOCUMENTS.UPLOAD, 'POST', formData)
}

export const documentService = {
  getMyDocuments,
  getDocumentDisplayDate,
  uploadDocuments,
}
