import classnames from 'classnames'
import React, { FC, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import './TabbedContainer.scss'

export type Tab = {
  id: string
  label: string
  content: JSX.Element
  show?: boolean
  idDefault?: boolean
}

interface TabbedContainerProps {
  tabs: Tab[]
}

const TabbedContainer: FC<TabbedContainerProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(
    tabs.find((tab) => tab.idDefault === true)?.id || tabs.find((tab) => tab.show === true)?.id || tabs[0].id,
  )

  const toggleTab = (tabId: string) => {
    if (activeTab !== tabId) setActiveTab(tabId)
  }

  return (
    <div className='TabbedContainer'>
      <Nav tabs={true}>
        {tabs.map(({ id, label, show = true }) => (
          <NavItem key={`nav${id}`} className={`${show ? '' : 'd-none'}`}>
            <NavLink
              className={classnames({ active: activeTab === id })}
              onClick={() => {
                toggleTab(id)
              }}
            >
              {label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.map(({ id, content }) => (
          <TabPane key={`tab${id}`} tabId={id}>
            {content}
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default TabbedContainer
