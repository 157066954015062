import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import history from '../utils/history'

import { Collaborator } from '../types/Collaborator'
import { Delegate } from '../types/Delegate'
import { Member } from '../types/Member'
import { User } from '../types/User'

import { execute } from '../api/api'
import { filterAR2iCollaborator, removeUser, setUser, UserStore } from '../store/UserStore'

const getConnectedUser = (): User | null => {
  return UserStore.getState()
}
const setConnectedUser = async (user: User): Promise<void> => {
  setUser(user)
  await filterAR2iCollaborator()
}

const login = (identifier: string, password: string): Promise<User | undefined> => {
  return execute<{ user: User }>(ApiRoutes.AUTH.LOGIN, 'POST', {
    identifier,
    password,
  }).then(({ user }) => {
    let activeMembers: Member[] = []
    if (user?.members) {
      activeMembers = user.members.filter((member) => !member.end_date || new Date(member.end_date) > new Date())
    }

    let activeCollaborators: Collaborator[] = []
    if (user?.collaborators) {
      activeCollaborators = user.collaborators.filter(
        (collaborator) => !collaborator.end_date || new Date(collaborator.end_date) > new Date(),
      )
    }

    let activeDelegate: Delegate[] = []
    if (user?.delegates) {
      activeDelegate = user.delegates.filter(
        (delegate) => !delegate.end_date || new Date(delegate.end_date) > new Date(),
      )
    }
    if (!!user && activeMembers.length === 0 && activeCollaborators.length === 0 && activeDelegate.length === 0) {
      return undefined
    }
    setConnectedUser(user)
    return user
  })
}

const logout = (): Promise<unknown> => {
  return execute(ApiRoutes.AUTH.LOGOUT, 'POST').finally(() => {
    removeUser()
    history.push(PATHS.LOGOUT)
  })
}

const forgotPassword = (email: string): Promise<never> => {
  return execute(ApiRoutes.AUTH.FORGOT_PASSWORD, 'POST', {
    email,
  })
}

const resetPassword = (password: string, passwordConfirmation: string, code: string | null): Promise<never> => {
  return execute(ApiRoutes.AUTH.RESET_PASSWORD, 'POST', {
    code,
    password,
    passwordConfirmation,
  })
}

export const loginService = {
  getConnectedUser,
  setConnectedUser,
  login,
  logout,
  forgotPassword,
  resetPassword,
}
