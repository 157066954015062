import { yupResolver } from '@hookform/resolvers/yup'
import TextInput from 'components/atoms/input/TextInput'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { CardBody, CardHeader, Col, Form } from 'reactstrap'

import { notifySuccess } from '../../../../utils/alertUtils'
import { forgotPasswordValidationSchema } from '../../../../utils/constants/Auth'
import { PATHS } from '../../../../utils/constants/routes/RoutePaths'

import { ForgotPasswordValuesType } from '../../../../types/Auth'

import { loginService } from '../../../../services/loginService'
import Button from '../../../atoms/button/Button'
import Card from '../../../atoms/card/Card'

const ForgotPasswordFormSection: FC = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm<ForgotPasswordValuesType>({
    mode: 'onChange',
    resolver: yupResolver(forgotPasswordValidationSchema),
  })
  const history = useHistory()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const { mutate } = useMutation(({ email }: ForgotPasswordValuesType) => loginService.forgotPassword(email), {
    mutationKey: ['auth', 'forgotPassword'],
    onSettled: () => {
      notifySuccess(t('toastify.success.forgotPassword'), {
        autoClose: 6000,
      })
      history.push(PATHS.LOGIN)
    },
  })

  const onSubmitResetPassword = (values: ForgotPasswordValuesType) => {
    setIsSubmitted(true)
    return mutate(values)
  }

  return (
    <Card className='LoginCard' boxShadow='none' banner bannerColor='orange' borderRadius='xl'>
      <CardHeader>
        <h2 className='BannerContent'> {t('auth.forgotPassword.header')}</h2>
      </CardHeader>
      <CardBody>
        <Col
          sm={12}
          lg={{ size: 6, offset: 3 }}
          className='mb-5 mb-lg-0 py-5 px-lg-5 d-flex justify-content-center align-items-center'
        >
          <Form className='w-100' onSubmit={handleSubmit(onSubmitResetPassword)}>
            <h2>{t('auth.login.identifier')}</h2>
            <TextInput
              name='email'
              type='email'
              innerRef={register}
              className='my-4'
              placeholder={t('auth.login.identifierPlaceholder')}
              error={errors.email?.message && t(errors.email.message)}
            />

            <Button type='submit' label={t('common.submit')} className='d-block mt-5 mx-auto' disabled={isSubmitted} />
            <Link to={PATHS.LOGIN} className='d-block text-center mt-4'>
              {t('auth.redirectLogin')}
            </Link>
          </Form>
        </Col>
      </CardBody>
    </Card>
  )
}

export default ForgotPasswordFormSection
