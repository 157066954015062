import React, { FC, useMemo } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { notifyError } from '../../../utils/alertUtils'
import { formatBytes } from '../../../utils/fileUtils'

import Container from '../layout/Container'
import './Dropzone.scss'

interface DropzoneProps extends DropzoneOptions {
  className?: string
  label: string
  addNewFiles: (newFileList: File[]) => void
  multiple?: boolean
}

const Dropzone: FC<DropzoneProps> = (props) => {
  // maxFiles = 0 signifie pas de limitation sur le nombre de fichiers, cf https://react-dropzone.js.org/
  const {
    className = '',
    label,
    addNewFiles,
    accept,
    multiple = true,
    maxFiles = 0,
    maxSize = Infinity,
    ...rest
  } = props

  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    maxSize,
    accept,
    noDragEventsBubbling: true,
    onDropAccepted: (files) => {
      addNewFiles && addNewFiles(files)
    },
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          switch (err.code) {
            case 'too-many-files':
              //On throw pour limiter le nombre de notification
              throw notifyError(t('toastify.errors.dropzone.maxFiles', { maxFiles }))
            case 'file-too-large':
              return notifyError(t('toastify.errors.dropzone.maxSize', { maxSize: formatBytes(maxSize) }))
            default:
              return notifyError(t('meeting.errorOnFileType'))
          }
        })
      })
    },
    maxFiles,
    multiple,
    ...rest,
  })
  const dropzoneStyle = useMemo(() => {
    return (
      'BaseStyle' +
      (isDragActive ? ' ActiveStyle' : '') +
      (isDragAccept ? ' AcceptStyle' : '') +
      (isDragReject ? ' RejectStyle' : '')
    )
  }, [isDragActive, isDragReject, isDragAccept])
  return (
    <Container className={`DropzoneContainer ${className}`} id='dropz'>
      <div {...getRootProps({ className: dropzoneStyle })}>
        <span className='text-center my-auto'>{label}</span>
        <input {...getInputProps()} />
      </div>
    </Container>
  )
}

export default Dropzone
