import Badge from 'components/atoms/badge/Badge'
import Button from 'components/atoms/button/Button'
import Card from 'components/atoms/card/Card'
import Dropdown from 'components/atoms/dropdown/Dropdown'
import Figure from 'components/atoms/figure/Figure'
import Icon from 'components/atoms/icon/Icon'
import IconSvg from 'components/atoms/icon/IconSvg'
import CustomInput from 'components/atoms/input/CustomInput'
import TextInput from 'components/atoms/input/TextInput'
import TextareaInput from 'components/atoms/input/TextareaInput'
import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import Modal from 'components/atoms/modal/Modal'
import Nav from 'components/atoms/nav/Nav'
import Overlay from 'components/atoms/overlay/Overlay'
import DateCard from 'components/molecules/calendar-grid/meetings/DateCard'
import MeetingPointsOverview from 'components/molecules/calendar-grid/meetings/MeetingPointsOverview'
import DocumentCard from 'components/molecules/documents/DocumentCard'
import HeadingListGroup from 'components/molecules/heading/HeadingListGroup'
import VideosViewer from 'components/molecules/videos/VideosViewer'
import { FC, useState } from 'react'
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  DropdownItem,
  FormGroup,
  Label,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
} from 'reactstrap'
import { DocumentType } from 'types/Document'
import { FileTypeEnum } from 'utils/constants/File'

import SelectSearchInput from '../components/atoms/input/select/search/SelectSearchInput'
import Option from '../components/atoms/input/select/search/options/Option'
import View from '../components/atoms/layout/View'

import './ComponentsExample.scss'

const dropdownItems = [
  { label: 'Titre', header: true },
  { divider: true },
  { label: 'Item1' },
  { label: 'Item2' },
  { label: 'Item désactivé', disabled: true },
]

const file: DocumentType = {
  id: 1,
  date: '2 avril 2021',
  mime: 'application/png',
  name: 'example-file.png',
  extension: FileTypeEnum.PNG,
  size: '1,0 Mo',
  url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZUX7zo1yYFaBeOYIcOfcgwnULvpM7YqzXxA&usqp=CAU',
}

const video: DocumentType = {
  id: 1,
  date: '2 avril 2021',
  mime: 'application/mp4',
  name: 'example-video.mp4',
  extension: FileTypeEnum.MP4,
  size: '1,0 Mo',
  url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
}

const ComponentsExemple: FC = () => {
  const [modalOpenSm, setModalOpenSm] = useState<boolean>(false)
  const [modalOpenMd, setModalOpenMd] = useState<boolean>(false)
  const [modalOpenLg, setModalOpenLg] = useState<boolean>(false)
  const [modalOpenXl, setModalOpenXl] = useState<boolean>(false)
  const [modalOpenFull, setModalOpenFull] = useState<boolean>(false)
  const [modalOpenAuto, setModalOpenAuto] = useState<boolean>(false)
  const openModalSm = () => setModalOpenSm(true)
  const openModalMd = () => setModalOpenMd(true)
  const openModalLg = () => setModalOpenLg(true)
  const openModalXl = () => setModalOpenXl(true)
  const openModalFull = () => setModalOpenFull(true)
  const openModalAuto = () => setModalOpenAuto(true)
  const closeModalSm = () => setModalOpenSm(false)
  const closeModalMd = () => setModalOpenMd(false)
  const closeModalLg = () => setModalOpenLg(false)
  const closeModalXl = () => setModalOpenXl(false)
  const closeModalFull = () => setModalOpenFull(false)
  const closeModalAuto = () => setModalOpenAuto(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFile, setSelectedFile] = useState<DocumentType>()
  const onFileChange = (file?: DocumentType) => {
    setSelectedFile(file)
  }

  return (
    <View>
      <ViewHead>
        <Section>
          <h1>Components</h1>
          <p>
            Ce framework graphique est basé sur{' '}
            <a href='https://getbootstrap.com/docs/4.6/getting-started/introduction/' title='Bootstrap 4.6'>
              Bootstrap 4.6
            </a>{' '}
            et{' '}
            <a href='https://reactstrap.github.io/' title='Reacstrap'>
              Reactstrap
            </a>
          </p>

          <Nav>
            <NavItem>
              <NavLink href='#Intro'>Intro</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Utilities'>Utilities</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Section'>Section</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Grid'>Grid</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Types'>Types</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Icon'>Icon</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Nav'>Nav</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Button'>Button</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Badge'>Badge</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Figure'>Figure</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Card'>Card</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Forms'>Forms</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Modal'>Modal</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#Business-component'>Composants métier</NavLink>
            </NavItem>
          </Nav>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section id='Intro'>
          <h2 className='h2 ExampleTitle'>Intro</h2>
          <h3 className='h3 ExampleSubtitle'>Bootstrap</h3>
          <p>
            Les styles sont packagé, nous n&apos;appelons pas tout bootstrap, chaque composant importe si nescessaire
            les éléments de{' '}
            <a href='https://getbootstrap.com/docs/4.6/getting-started/introduction/' title='Bootstrap 4.6'>
              Bootstrap
            </a>{' '}
            puis surcharge
          </p>
          <h3 className='h3 ExampleSubtitle'>Reactstrap</h3>
          <p>
            Les composants reactstrap sont appelés/wrappés par un composant éponyme, se référer à la doc{' '}
            <a href='https://reactstrap.github.io/' title='Reacstrap'>
              Reactstrap
            </a>{' '}
            pour connaitre toutes les propriétés.
          </p>
          <h3 className='h3 ExampleSubtitle'>Atoms</h3>
          <p>
            Seuls les composants nesceccitant des props complémentaires ou l&apos;appel d&apos;un noeud de style majeur
            de Bootrstrap nescecitent un atom.
            <br />
            Exemple : Card réponds aux deux besoins et a donc un atom Card.tsx mais pas des sous éléments dans les
            styles sont déjà inclus dans card.scss de bootstrap comme CardHeader ou CardBody, ils n&apos;ont donc pas
            besoin d&apos;un atom et sont appelés directement dans depuis Reactstrap.
          </p>
          <h3 className='h3 ExampleSubtitle'>Molecules</h3>
          <p>
            Tous les composants métiers avec des pérticularité de contenus sont des molecules (assemblages
            d&apos;atoms), ou d&apos;autres molécules plus petites. Il s&apos;agit là d&apos;une adaptation de la
            méthode Atomic Deisgn simplifiée.
          </p>
        </Section>
        <Section id='MediaQueries'>
          <h2 className='h2 ExampleTitle'>Media Queries</h2>
          <h3 className='h3 ExampleSubtitle'>Col</h3>
          <Row>
            <Col xs='12' sm='10' md='8' lg='6' xl='4'>
              <div className='ExampleBlock bg-light'>
                Exemple : xs=&apos;12&apos; sm=&apos;10&apos; md=&apos;8&apos; lg=&apos;6&apos; xl=&apos;4&apos;
              </div>
            </Col>
          </Row>
        </Section>
        <hr />
        <Section id='Utilities'>
          <h2 className='h2 ExampleTitle'>Utilities</h2>
          <p>
            Les Utilities sont des className avec des effets graphiques simples. Ils peuvent être utilisés à travers
            tous le projet.
          </p>
          <h3 className='h3 ExampleSubtitle'>Bootstrap</h3>
          <p>D&apos;sabord vous avez accès à toutes les utilities de Bootstrap</p>
          <p>
            <Button tag='a' href='https://getbootstrap.com/docs/4.1/utilities/' target='_blank' rel='noreferrer'>
              https://getbootstrap.com/docs/4.1/utilities/
            </Button>
          </p>
          <h3 className='h3 ExampleSubtitle'>Background</h3>
          <Row grid className='text-center' gutters='xs'>
            <Col xs='6' md='3'>
              <div className='ExampleBlock bg-primary'>.bg-primary</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock bg-secondary'>.bg-secondary</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock bg-light'>.bg-light</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock bg-dark'>.bg-dark</div>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>Color</h3>
          <Row grid className='text-center' gutters='xs'>
            <Col xs='6' md='3'>
              <div className='ExampleBlock text-primary'>.text-primary</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock text-secondary'>.bg-secondary</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock text-light'>.text-light</div>
            </Col>
            <Col xs='6' md='3'>
              <div className='ExampleBlock text-dark'>.text-dark</div>
            </Col>
          </Row>
        </Section>

        <hr />

        <Section id='Section'>
          <h2 className='h2 ExampleTitle'>Section</h2>
          <p>Section reçoit un Containeur par défaut</p>
        </Section>
        <Section bgAlt>
          <p>Section avec un arrière-plan alternatif (par defaut équivalent à l&apos;utilitie bg-light)</p>
        </Section>
        <Section bgColor='primary'>
          <p>Section avec un arrière-plan spécifique (utilise l&apos;utilitie bg-primary)</p>
        </Section>
        <Section bgColor='secondary'>
          <p>Section avec un arrière-plan spécifique (utilise l&apos;utilitie bg-tertiary)</p>
        </Section>
        <hr />
        <Section bgColor='medium'>
          <div className='ExampleBlock bg-light'>Section avec un container non fluid</div>
        </Section>
        <hr />
        <Section fluid bgColor='medium'>
          <div className='ExampleBlock bg-light'>Section avec un container fluid</div>
        </Section>

        <hr />

        <Section id='Grid'>
          <h2 className='h2  ExampleTitle'>Grid</h2>
          <p>
            Les Grid peuvent être complétées par les Utilities responsvive flex de Bootstrap :{' '}
            <a href='https://getbootstrap.com/docs/4.1/utilities/flex/'>
              https://getbootstrap.com/docs/4.1/utilities/flex/
            </a>
            .
          </p>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Grille classique pour les structures</h3>
          <Row>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='8'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Grille multiligne pour les listes de vignettes</h3>
          <Row grid>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='4'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Gutters</h3>
          <h4 className='h4'>no</h4>
          <Row grid gutters='no' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <h4 className='h4'>xs</h4>
          <Row grid gutters='xs' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <h4 className='h4'>sm</h4>
          <Row grid gutters='sm' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <h4 className='h4'>md</h4>
          <Row grid gutters='md' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <h4 className='h4'>lg</h4>
          <Row grid gutters='lg' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <h4 className='h4'>xl</h4>
          <Row grid gutters='xl' className='mb-5'>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
            <Col xs='12' md='6'>
              <div className='ExampleBlock bg-light'>Col</div>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Raccourcis flex (globaux : non responsive)</h3>
          <h4>flexFill: boolean</h4>
          <Row grid flexFill className='mb-5' gutters='sm'>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>col-2</div>
            </Col>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>col-2</div>
            </Col>
          </Row>
          <h4>flexWrap : &apos;wrap&apos; | &apos;nowrap&apos;</h4>
          <Row grid flexWrap='nowrap' className='mb-5' gutters='sm'>
            <Col xs='8'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='8'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <Row grid flexWrap='wrap' className='mb-5' gutters='sm'>
            <Col xs='8'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='8'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <h4>
            flexDirection : &apos;row&apos; | &apos;row-reverse&apos; | &apos;column&apos; | &apos;column-reverse&apos;
          </h4>
          <Row grid flexDirection='row-reverse' className='mb-5' gutters='sm'>
            <Col xs='6'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='6'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <Row grid flexDirection='column' className='mb-5' gutters='sm'>
            <Col xs='6'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='6'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <h4>
            justifyContent: &apos;start&apos; | &apos;end&apos; | &apos;center&apos; | &apos;around&apos; |
            &apos;between&apos;
          </h4>
          <Row justifyContent='start' className='mb-5' gutters='sm'>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <Row justifyContent='center' className='mb-5' gutters='sm'>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <Row justifyContent='end' className='mb-5' gutters='sm'>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <Row justifyContent='around' className='mb-5' gutters='sm'>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='2'>
              <div className='ExampleBlock bg-light'>B</div>
            </Col>
          </Row>
          <h4>
            alignItems: &apos;start&apos; | &apos;end&apos; | &apos;center&apos; | &apos;stretch&apos; |
            &apos;baseline&apos;
          </h4>
          <Row alignItems='start' className='mb-5' gutters='sm'>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B
              </div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B<br />C
              </div>
            </Col>
          </Row>
          <Row alignItems='center' className='mb-5' gutters='sm'>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B
              </div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B<br />C
              </div>
            </Col>
          </Row>
          <Row alignItems='end' className='mb-5' gutters='sm'>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B
              </div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B<br />C
              </div>
            </Col>
          </Row>
          <Row alignItems='stretch' className='mb-5' gutters='sm'>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>A</div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B
              </div>
            </Col>
            <Col xs='4'>
              <div className='ExampleBlock bg-light'>
                A<br />B<br />C
              </div>
            </Col>
          </Row>
        </Section>

        <hr className='Hr' />

        <Section id='Types'>
          <h2 className='h2 ExampleTitle'>Types</h2>
          <Row grid>
            <Col xs='12' md='6'>
              <h1 className='h1'>
                Title of page / h1 <br />
                <small>(tag modifiable pour SEO)</small>
              </h1>
              <hr />
              <h2 className='h2'>
                Titre de section / h2 <br />
                <small>(tag modifiable pour SEO)</small>
              </h2>
              <hr />
              <h3 className='h3'>
                Titre de card / h3 <br />
                <small>(tag modifiable pour SEO)</small>
              </h3>
              <hr />
              <h4 className='h4'>
                Titre de contenu / h4 <br />
                <small>(tag modifiable pour SEO)</small>
              </h4>
              <hr />
              <h5 className='h5'>
                Soustitre de contenu / h5 <br />
                <small>(tag modifiable pour SEO)</small>
              </h5>
              <hr />
              <p>
                Paragraphe de texte dont éléments standards :<br />
                <a href='' title=''>
                  ancre
                </a>{' '}
                <strong>strong</strong> <em>emphase</em>
              </p>
            </Col>
          </Row>
        </Section>

        <hr />

        <Section id='Icon'>
          <h2 className='h2 ExampleTitle'>Icon</h2>
          <h3 className='h3 ExampleSubtitle'>Avec Fontello</h3>
          <Row grid className='text-center'>
            <Col xs='2' sm='1'>
              <Icon name='dashboard' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='logout' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='left-open' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='profile' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='meetings' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='contacts' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='help' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='cancel' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='mail' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='right-open' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='down-open' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='up-open' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='star' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='download' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='menu' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='documents' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='euro' />
            </Col>
            <Col xs='2' sm='1'>
              <Icon name='newspaper' />
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>Avec des assets SVG</h3>
          <Row grid className='text-center'>
            <Col xs='2' sm='1'>
              <IconSvg name='ChevronRight' />
            </Col>
            <Col xs='2' sm='1'>
              <IconSvg name='ChevronLeft' />
            </Col>
            <Col xs='2' sm='1'>
              <IconSvg name='ChevronUp' />
            </Col>
            <Col xs='2' sm='1'>
              <IconSvg name='ChevronDown' />
            </Col>
          </Row>
        </Section>

        <hr />

        <Section id='Nav'>
          <h2 className='h2 ExampleTitle'>Nav</h2>
          <h3 className='h3 ExampleSubtitle'>Standard</h3>
          <Nav>
            <NavItem>
              <NavLink href='' active>
                Item 1
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href=''>Item 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href=''>Item 3</NavLink>
            </NavItem>
          </Nav>

          <hr />

          <h3 className='h3 ExampleSubtitle'>Vertical</h3>
          <Nav vertical>
            <NavItem>
              <NavLink href='' active>
                Item 1
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href=''>Item 2</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href=''>Item 3</NavLink>
            </NavItem>
          </Nav>
        </Section>

        <hr />

        <Section id='Button'>
          <h2 className='h2 ExampleTitle'>Button</h2>
          <h3 className='h3 ExampleSubtitle'>Contenu</h3>
          <p className='buttons'>
            <Button label={'Avec props label'} color={'light'} /> <Button color={'light'}>Avec children</Button>
          </p>
          <h3 className='h3 ExampleSubtitle'>Thèmes</h3>
          <h4 className='h4'>Normal</h4>
          <p className='buttons'>
            <Button label={'primary'} color={'primary'} /> <Button label={'secondary'} color={'secondary'} />{' '}
            <Button label={'light'} color={'light'} /> <Button label={'medium'} color={'medium'} />{' '}
            <Button label={'dark'} color={'dark'} />
          </p>
          <h4 className='h4'>Outline</h4>
          <p className='buttons'>
            <Button label={'primary'} outline color={'primary'} />{' '}
            <Button label={'secondary'} outline color={'secondary'} />{' '}
            <Button label={'light'} outline color={'light'} /> <Button label={'medium'} outline color={'medium'} />{' '}
            <Button label={'dark'} outline color={'dark'} />
          </p>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Tailles</h3>
          <p className='buttons'>
            <Button label={'Small'} size='sm' /> <Button label={'Normal (default'} />{' '}
            <Button label={'Large'} size='lg' />
          </p>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Border radius</h3>
          <p>Default value = &quot;md&quot;</p>
          <p className='buttons'>
            <Button label={'none'} borderRadius='none' color={'light'} />{' '}
            <Button label={'sm'} borderRadius='sm' color={'light'} />{' '}
            <Button label={'md'} borderRadius='md' color={'light'} />{' '}
            <Button label={'lg'} borderRadius='lg' color={'light'} />{' '}
          </p>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Shadow</h3>
          <p>Default value = &quot;primary-light-sm&quot;</p>
          <p className='Buttons'>
            <Button label={'none'} boxShadow='none' color={'light'} />{' '}
          </p>
          <p className='Buttons'>
            <Button label={'primary-light-sm'} boxShadow='primary-light-sm' color={'light'} />{' '}
            <Button label={'primary-medium-sm'} boxShadow='primary-medium-sm' color={'light'} />{' '}
            <Button label={'primary-strong-sm'} boxShadow='primary-strong-sm' color={'light'} />{' '}
          </p>
          <p className='Buttons'>
            <Button label={'primary-light-md'} boxShadow='primary-light-md' color={'light'} />{' '}
            <Button label={'primary-medium-md'} boxShadow='primary-medium-md' color={'light'} />{' '}
            <Button label={'primary-strong-md'} boxShadow='primary-strong-md' color={'light'} />{' '}
          </p>
          <p className='Buttons'>
            <Button label={'primary-light-lg'} boxShadow='primary-light-lg' color={'light'} />{' '}
            <Button label={'primary-medium-lg'} boxShadow='primary-medium-lg' color={'light'} />{' '}
            <Button label={'primary-strong-lg'} boxShadow='primary-strong-lg' color={'light'} />{' '}
          </p>
          <h3 className='h3 ExampleSubtitle'>Button block</h3>
          <p>
            <Button label={'block'} color={'light'} block />
          </p>
        </Section>

        <hr />

        <Section id='Badge'>
          <h2 className='h2 ExampleTitle'>Badge</h2>
          <h3 className='h3 ExampleSubtitle'>Size</h3>
          <p className='Badges'>
            <Badge color={'primary'}>Default (proportionnel au context)</Badge>{' '}
          </p>
          <p className='Badges'>
            <Badge color={'primary'} size='sm'>
              Small
            </Badge>{' '}
            <Badge color={'primary'} size='md'>
              Medium
            </Badge>{' '}
            <Badge color={'primary'} size='lg'>
              Large
            </Badge>
          </p>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Thèmes</h3>
          <h4 className='h4'>Normal</h4>
          <p className='Badges'>
            <Badge color={'primary'}>primary</Badge> <Badge color={'secondary'}>secondary</Badge>{' '}
            <Badge color={'light'}>light</Badge> <Badge color={'medium'}>medium</Badge>{' '}
            <Badge color={'dark'}>dark</Badge>
          </p>
          <h4 className='h4'>Outline</h4>
          <p className='buttons'>
            <Badge outline color={'primary'}>
              primary
            </Badge>{' '}
            <Badge outline color={'secondary'}>
              secondary
            </Badge>{' '}
            <Badge outline color={'light'}>
              light
            </Badge>{' '}
            <Badge outline color={'medium'}>
              medium
            </Badge>{' '}
            <Badge outline color={'dark'}>
              dark
            </Badge>
          </p>
          <h4 className='h4'>Outline filled</h4>
          <p className='buttons'>
            <Badge outline filled color={'primary'}>
              primary
            </Badge>{' '}
            <Badge outline filled color={'secondary'}>
              secondary
            </Badge>{' '}
            <Badge outline filled color={'light'}>
              light
            </Badge>{' '}
            <Badge outline filled color={'medium'}>
              medium
            </Badge>{' '}
            <Badge outline filled color={'dark'}>
              dark
            </Badge>
          </p>
        </Section>
        <hr />

        <Section id='Figure'>
          <h2 className='h2 ExampleTitle'>Figure</h2>

          <h3 className='h3 ExampleSubtitle'>Figure & Overlay</h3>
          <Row grid>
            <Col xs='12' md='4'>
              <h4 className='h4'>Figure</h4>
              <Figure>
                <img src='http://via.placeholder.com/600x400' alt='' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h4 className='h3'>Figure + Overlay</h4>
              <Figure>
                <>
                  <img src='http://via.placeholder.com/600x400' alt='' />
                  <Overlay>
                    <p>OVERLAY</p>
                  </Overlay>
                </>
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>Figure + Overlay + Icon</h3>
              <Figure>
                <>
                  <img src='http://via.placeholder.com/600x400' alt='' />
                  <Overlay>
                    <Icon name='menu' />
                  </Overlay>
                </>
              </Figure>
            </Col>
          </Row>

          <hr className='Hr' />
          <h2 className='h2'>Figure ratios</h2>
          <Row grid>
            <Col xs='12' md='4'>
              <h3 className='h3'>16:9</h3>

              <Figure ratio='16-9'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>3:2</h3>

              <Figure ratio='3-2'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>4:3</h3>

              <Figure ratio='4-3'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>1:1</h3>

              <Figure ratio='1-1'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>3:4</h3>

              <Figure ratio='3-4'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
            <Col xs='12' md='4'>
              <h3 className='h3'>2:3</h3>

              <Figure ratio='2-3'>
                <img src='http://via.placeholder.com/600x450' alt='test img' />
              </Figure>
            </Col>
          </Row>
        </Section>
        <hr />
        <Section id='Card'>
          <h2 className='h2 ExampleTitle'>Card</h2>
          <p>
            Le composant Card du projet wrap celui de ReactStrap ainsi que les styles de Bootstrap. Les sous composants
            peuvent être importés directement depuis reactsrap{' '}
            <a href='https://reactstrap.github.io/components/card/'>ReactStrap</a>.
          </p>
          <h3 className='h3 ExampleSubtitle'>Thèmes</h3>
          <Row grid className='text-center'>
            <Col xs='6' md='3'>
              <Card>
                <CardHeader>CardHeader</CardHeader>
                <CardBody>CardBody</CardBody>
                <CardFooter>CardFooter</CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card color='primary'>
                <CardHeader>CardHeader</CardHeader>
                <CardBody>CardBody</CardBody>
                <CardFooter>CardFooter</CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card color='secondary'>
                <CardHeader>CardHeader</CardHeader>
                <CardBody>CardBody</CardBody>
                <CardFooter>CardFooter</CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card color='dark'>
                <CardHeader>CardHeader</CardHeader>
                <CardBody>CardBody</CardBody>
                <CardFooter>CardFooter</CardFooter>
              </Card>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>Paddings</h3>
          <Row grid className='text-center' gutters='sm' alignItems='start'>
            <Col xs='6' md='3'>
              <h4>none</h4>
              <Card padding='none'>
                <CardHeader>
                  <div className='ExampleBlock bg-light'>card-header</div>
                </CardHeader>
                <CardBody>
                  <div className='ExampleBlock bg-light'>CardBody</div>
                </CardBody>
                <CardFooter>
                  <div className='ExampleBlock bg-light'>CardFooter</div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <h4>sm</h4>
              <Card padding='sm'>
                <CardHeader>
                  <div className='ExampleBlock bg-light'>card-header</div>
                </CardHeader>
                <CardBody>
                  <div className='ExampleBlock bg-light'>CardBody</div>
                </CardBody>
                <CardFooter>
                  <div className='ExampleBlock bg-light'>CardFooter</div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <h4>md</h4>
              <Card padding='md'>
                <CardHeader>
                  <div className='ExampleBlock bg-light'>card-header</div>
                </CardHeader>
                <CardBody>
                  <div className='ExampleBlock bg-light'>CardBody</div>
                </CardBody>
                <CardFooter>
                  <div className='ExampleBlock bg-light'>CardFooter</div>
                </CardFooter>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <h4>lg</h4>
              <Card padding='lg'>
                <CardHeader>
                  <div className='ExampleBlock bg-light'>card-header</div>
                </CardHeader>
                <CardBody>
                  <div className='ExampleBlock bg-light'>CardBody</div>
                </CardBody>
                <CardFooter>
                  <div className='ExampleBlock bg-light'>CardFooter</div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>BorderRadius</h3>
          <p>Default value = &quot;lg&quot;</p>
          <Row grid className='text-center'>
            <Col xs='6' md='3'>
              <Card borderRadius='none'>
                <CardBody>none</CardBody>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card borderRadius='sm'>
                <CardBody>sm</CardBody>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card borderRadius='md'>
                <CardBody>md</CardBody>
              </Card>
            </Col>
            <Col xs='6' md='3'>
              <Card borderRadius='lg'>
                <CardBody>lg</CardBody>
              </Card>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>BoxShadow</h3>
          <p>Default value = &quot;primary-light-lg&quot;</p>
          <Row grid className='text-center'>
            <Col xs='4'>
              <Card boxShadow='none'>
                <CardBody>none</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-light-sm'>
                <CardBody>primary-light-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-light-md'>
                <CardBody>primary-light-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-light-lg'>
                <CardBody>primary-light-lg (default)</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-medium-sm'>
                <CardBody>primary-medium-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-medium-md'>
                <CardBody>primary-medium-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-medium-lg'>
                <CardBody>primary-medium-lg</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-strong-sm'>
                <CardBody>primary-strong-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-strong-md'>
                <CardBody>primary-strong-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='primary-strong-lg'>
                <CardBody>primary-strong-lg</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-light-sm'>
                <CardBody>dark-light-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-light-md'>
                <CardBody>dark-light-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-light-lg'>
                <CardBody>dark-light-lg</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-medium-sm'>
                <CardBody>dark-medium-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-medium-md'>
                <CardBody>dark-medium-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-medium-lg'>
                <CardBody>dark-medium-lg</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-strong-sm'>
                <CardBody>primary-strong-sm</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-strong-md'>
                <CardBody>dark-strong-md</CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card boxShadow='dark-strong-lg'>
                <CardBody>dark-strong-lg</CardBody>
              </Card>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Disposition</h3>
          <p>Les Cards peuvent avoir une disposition horizontale à l&apos;aide de la grille CSS sans gouttières</p>
          <Row grid className='text-center'>
            <Col xs='12' md='6'>
              <Card>
                <Row gutters='no'>
                  <Col xs='6'>
                    <CardHeader className='bg-primary'>CardHeader</CardHeader>
                  </Col>
                  <Col xs='6'>
                    <CardBody>CardBody</CardBody>
                    <CardFooter>CardFooter</CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs='12' md='6'>
              <Card>
                <Row gutters='no'>
                  <Col xs='6'>
                    <Figure>
                      <CardImg src='http://via.placeholder.com/600x450' />
                    </Figure>
                  </Col>
                  <Col xs='6'>
                    <CardHeader>CardHeader</CardHeader>
                    <CardBody>CardBody</CardBody>
                    <CardFooter>CardFooter</CardFooter>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>Clickable (effet hover)</h3>
          <Row grid className='text-center'>
            <Col xs='4'>
              <Card clickable>
                <CardBody>Carte clickable (effet au survol)</CardBody>
              </Card>
            </Col>
          </Row>
          <h3 className='h3 ExampleSubtitle'>Test overflow</h3>
          <Row grid className='text-center'>
            <Col xs='4'>
              <Card>
                <CardBody>
                  <Dropdown label='Sélection multiple'>
                    {dropdownItems.map((item) => (
                      <DropdownItem key={item.label} {...item}>
                        {item.label}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </CardBody>
              </Card>
            </Col>
            <Col xs='4'>
              <Card borderRadius='md'>
                <CardBody>
                  <Dropdown label='Sélection multiple'>
                    {dropdownItems.map((item) => (
                      <DropdownItem key={item.label} {...item}>
                        {item.label}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Section>
        <hr />
        <Section id='Forms'>
          <h2 className='h2 ExampleTitle'>Forms</h2>
          <h3 className='h3 ExampleSubtitle'>Inputs</h3>
          <Row grid>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input</Label>
                <TextInput />
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input disabled</Label>
                <TextInput value='value' disabled />
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input readonly</Label>
                <TextInput readOnly value='Value' />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row grid>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input select</Label>
                <TextInput type='select'>
                  <option key={'option-1'} value={'option-1-value'}>
                    {'Option 1'}
                  </option>
                  <option key={'option-2'} value={'option-2-value'}>
                    {'Option 2'}
                  </option>
                </TextInput>
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input select disabled</Label>
                {/*Mettre disabled et non readonly pour les select*/}
                <TextInput type='select' disabled>
                  <option key={'option-1'} value={'option-1-value'}>
                    {'Option 1'}
                  </option>
                  <option key={'option-2'} value={'option-2-value'}>
                    {'Option 2'}
                  </option>
                </TextInput>
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input select readonly</Label>
                {/*Mettre disabled et non readonly pour les select*/}
                <TextInput type='select' readOnly disabled>
                  <option key={'option-1'} value={'option-1-value'}>
                    {'Option 1'}
                  </option>
                  <option key={'option-2'} value={'option-2-value'}>
                    {'Option 2'}
                  </option>
                </TextInput>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row grid>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input Select with search input</Label>
                <SelectSearchInput>
                  <Option value={1}>Option 1</Option>
                  <Option value={2}>Option 2</Option>
                </SelectSearchInput>
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Inputs spéciaux</h3>
          <Row grid>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input addon</Label>
                <TextInput addonText={'@'} />
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input date</Label>
                <TextInput type={'date'} />
              </FormGroup>
            </Col>
          </Row>
          <Row grid>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input checkbox</Label>
                <CustomInput type={'checkbox'} id={'example-checkbox'}>
                  Checkbox
                </CustomInput>
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input radio</Label>
                <CustomInput type={'radio'} id={'example-radio-1'} name={'example-radio'}>
                  Radio 1
                </CustomInput>
                <CustomInput type={'radio'} id={'example-radio-2'} name={'example-radio'}>
                  Radio 2
                </CustomInput>
              </FormGroup>
            </Col>
            <Col xs='12' md='4'>
              <FormGroup>
                <Label>Input switch</Label>
                <CustomInput type={'switch'} id={'example-switch'} />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Textarea</h3>
          <TextareaInput rows={6} placeholder='Placeholder...' />
          <hr />
          <h3 className='h3 ExampleSubtitle'>Dropdown</h3>
          <Row grid>
            <Col xs='12' md='4'>
              <Dropdown label='Sélection multiple' color='green'>
                {dropdownItems.map((item) => (
                  <DropdownItem key={item.label} {...item}>
                    {item.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </Col>
            <Col xs='12' md='4'>
              <Dropdown label='Sélection multiple' color='orange'>
                {[
                  { label: 'Titre', header: true },
                  { divider: true },
                  { label: 'Item1' },
                  { label: 'Item2' },
                  { label: 'Item désactivé', disabled: true },
                ].map((item) => (
                  <DropdownItem key={item.label} {...item}>
                    {item.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </Col>
            <Col xs='12' md='4'>
              <Dropdown label='Sélection multiple' color='red'>
                {[
                  { label: 'Titre', header: true },
                  { divider: true },
                  { label: 'Item1' },
                  { label: 'Item2' },
                  { label: 'Item désactivé', disabled: true },
                ].map((item) => (
                  <DropdownItem key={item.label} {...item}>
                    {item.label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </Col>
          </Row>
          <hr />
          <Row grid>
            <Col xs='12' md='4' />
          </Row>
        </Section>

        <hr />

        <Section id='Modal'>
          <h2 className='h2 ExampleTitle'>Modal</h2>
          <h3 className='h3 ExampleSubtitle'>Size</h3>
          <Row grid>
            <Col xs='12' md='2'>
              <Button onClick={openModalSm} color={'primary'}>
                sm
              </Button>
            </Col>
            <Col xs='12' md='2'>
              <Button onClick={openModalMd} color={'primary'}>
                md
              </Button>
            </Col>
            <Col xs='12' md='2'>
              <Button onClick={openModalLg} color={'primary'}>
                lg
              </Button>
            </Col>
            <Col xs='12' md='2'>
              <Button onClick={openModalXl} color={'primary'}>
                xl
              </Button>
            </Col>
            <Col xs='12' md='2'>
              <Button onClick={openModalFull} color={'primary'}>
                full
              </Button>
            </Col>
            <Col xs='12' md='2'>
              <Button onClick={openModalAuto} color={'primary'}>
                auto
              </Button>
            </Col>
          </Row>
          <Modal isOpen={modalOpenSm} toggle={closeModalSm} size='sm'>
            <ModalHeader toggle={closeModalSm}>Modal SM</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
          <Modal isOpen={modalOpenMd} toggle={closeModalMd}>
            <ModalHeader toggle={closeModalMd}>Modal MD</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
          <Modal isOpen={modalOpenLg} toggle={closeModalLg} size='lg'>
            <ModalHeader toggle={closeModalLg}>Modal LG</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
          <Modal isOpen={modalOpenXl} toggle={closeModalXl} size='xl'>
            <ModalHeader toggle={closeModalXl}>Modal XL</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
          <Modal isOpen={modalOpenFull} toggle={closeModalFull} size='full'>
            <ModalHeader toggle={closeModalFull}>Modal FULL</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
          <Modal isOpen={modalOpenAuto} toggle={closeModalAuto} size='auto'>
            <ModalHeader toggle={closeModalAuto}>Auto</ModalHeader>
            <ModalBody>...</ModalBody>
          </Modal>
        </Section>

        <Section color='light' />

        <Section id='Business-component'>
          <h1 className='h1'>Composants métier</h1>
        </Section>

        <hr />

        <Section>
          <h2 className='h2 ExampleTitle'>Headings</h2>
          <HeadingListGroup>Heading list group</HeadingListGroup>
          <p className='text-muted'>Following content...</p>
        </Section>

        <hr />

        <Section>
          <h2 className='h2 ExampleTitle'>Cards spéciaux</h2>
          <h3 className='h3 ExampleSubtitle'>Card event</h3>
          <Row>
            <Col xs='12' md='6'>
              <Card>
                <CardBody>
                  <DateCard date={new Date()} className={'float-right'} />
                  <CardTitle tag='h5'>Card title</CardTitle>
                  <MeetingPointsOverview strs={['Ligne 1', 'Ligne 2', 'Ligne 3', 'Ligne 4']} className={'tasks-list'} />
                  <CardText>
                    Some quick example text to build on the card title and make up the bulk of the card.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Card document</h3>
          <Row grid gutters='md'>
            <Col xs='12'>
              <DocumentCard file={file} openPreviewDocument={onFileChange} />
              {/* <Card className='DocumentCard'>
                <CardBody>
                  <Row className='DocumentCardGrid' flexWrap='nowrap'>
                    <Col xs='auto' className='DocumentCardCheck'>
                      <CustomInput type={'checkbox'} id={'checkbox-DocumentCard'} />
                    </Col>
                    <Col xs='auto' className='DocumentCardIcon'>
                      <IconSvg name='FilePDF' size='4' />
                    </Col>
                    <Col xs='auto' className='DocumentCardName'>
                      FileName
                    </Col>
                    <Col xs='auto' className='DocumentCardDate'>
                      20/03/2021
                    </Col>
                    <Col xs='auto' className='DocumentCardWeight'>
                      50 Ko
                    </Col>
                    <Col xs='auto' className='DocumentCardActions'>
                      <Icon name='star' />
                      <a href=''>
                        <Icon name='download' />
                      </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
            <Col xs='6' md='4'>
              <DocumentCard grid file={file} openPreviewDocument={onFileChange} />
              {/* <Card className='DocumentCard'>
                <CardBody>
                  <Row grid className='DocumentCardGrid' flexWrap='nowrap'>
                    <Col xs='auto' className='DocumentCardCheck'>
                      <CustomInput type={'checkbox'} id={'checkbox-DocumentCard'} />
                    </Col>
                    <Col xs='auto' className='DocumentCardIcon'>
                      <IconSvg name='FilePDF' size='4' />
                    </Col>
                    <Col xs='auto' className='DocumentCardName'>
                      FileName
                    </Col>
                    <Col xs='auto' className='DocumentCardDate'>
                      20/03/2021
                    </Col>
                    <Col xs='auto' className='DocumentCardWeight'>
                      50 Ko
                    </Col>
                    <Col xs='auto' className='DocumentCardActions'>
                      <Icon name='star' />
                      <a href=''>
                        <Icon name='download' />
                      </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          <hr />
          <h3 className='h3 ExampleSubtitle'>Card vidéo</h3>
          <VideosViewer videoList={Array(5).fill(video)} />
        </Section>
      </ViewBody>
    </View>
  )
}

export default ComponentsExemple
