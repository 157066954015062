import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FILE_TYPES } from '../../../utils/constants/File'

import { DocumentType } from '../../../types/Document'

import { BASE_PATH } from '../../../api/api'
import Checkbox from '../../atoms/checkbox/Checkbox'
import Icon from '../../atoms/icon/Icon'
import Table from '../../atoms/table/Table'

interface DocumentTableProps {
  fileList: DocumentType[]
  onFileChange: (file?: DocumentType) => void
  checkedFileList: DocumentType[]
  onCheckDocument: (file: DocumentType) => void
  showChecked: boolean
  favoriteIds: number[]
  changeFavorite: (isFavorite: boolean, id: number) => void
}

const DocumentTable: FC<DocumentTableProps> = ({
  fileList,
  onFileChange,
  checkedFileList,
  onCheckDocument,
  showChecked = true,
  favoriteIds,
  changeFavorite,
}: DocumentTableProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Table className={'table-hover'}>
      <thead>
        <tr>
          <th scope='col'>{t('common.enums.documentTable.selected')}</th>
          <th scope='col'>{t('common.enums.documentTable.name')}</th>
          <th scope='col'>{t('common.enums.documentTable.date')}</th>
          <th scope='col'>{t('common.enums.documentTable.size')}</th>
          <th scope='col'>{t('common.enums.documentTable.favorite')}</th>
          <th scope='col'>{t('common.enums.documentTable.download')}</th>
        </tr>
      </thead>
      <tbody>
        {fileList.map((file: DocumentType) => (
          <tr key={file.id}>
            <td>
              {showChecked && (
                <Checkbox checked={checkedFileList.includes(file)} handleCheck={() => onCheckDocument(file)} />
              )}
            </td>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.name}
            </td>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.date}
            </td>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.size}
            </td>
            <td onClick={() => changeFavorite(favoriteIds.includes(file.id, 0), file.id)}>
              <Icon name={favoriteIds.includes(file.id, 0) ? 'star' : 'star-empty'} />
            </td>
            <td>
              <a
                href={BASE_PATH + file.url}
                download
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
              >
                <Icon name='download' />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default DocumentTable
