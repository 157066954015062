import Section from 'components/atoms/layout/Section'
import UserCreateForm from 'components/molecules/user-create/UserCreateForm'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

import { PATHS } from '../utils/constants/routes/RoutePaths'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'

const UserCreateView: FC = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.CONTACTS.HOME}>{t('contacts.header')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{t('userCreate.viewTitle')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <UserCreateForm />
        </Section>
      </ViewBody>
    </View>
  )
}

export default UserCreateView
