import React, { FC } from 'react'
import { Breadcrumb as RSBreadcrumb, BreadcrumbProps as RSBreadcrumbProps } from 'reactstrap'

import './Breadcrumb.scss'

interface BreadcrumbProps extends RSBreadcrumbProps {
  className?: string
}

const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const { className = '', children, ...rest } = props

  return (
    <RSBreadcrumb className={`Breadcrumb ${className}`} {...rest}>
      {children}
    </RSBreadcrumb>
  )
}

export default Breadcrumb
