import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, FormGroup, Label } from 'reactstrap'
import { isMixedChannelInstance } from 'utils/instances'

import { InvitationStatusType } from '../../../../types/InvitationStatus'

import TextInput from '../../../atoms/input/TextInput'
import Row from '../../../atoms/layout/Row'
import ParticipationStatusSelect from './participation/ParticipationStatusSelect'

interface ParticipantsFiltersProps {
  search: string
  participation?: InvitationStatusType
  instanceId: number
  onFilterChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const ParticipantsFilters: FC<ParticipantsFiltersProps> = (props) => {
  const { search, participation, instanceId, onFilterChange } = props
  const { t } = useTranslation()

  return (
    <Row>
      <Col xs='12' md='3'>
        <FormGroup>
          <Label className='text-uppercase text-nowrap text-darkgrey'>
            {t('meeting.invitations.filters.search.label')}
          </Label>
          <TextInput
            name='search'
            placeholder={t('meeting.invitations.filters.search.placeholder')}
            className='shadow-none'
            value={search}
            onChange={onFilterChange}
          />
        </FormGroup>
      </Col>
      <Col xs='12' md='3'>
        <FormGroup>
          <Label className='text-uppercase text-nowrap text-darkgrey'>
            {t('meeting.invitations.filters.participation.label')}
          </Label>
          <ParticipationStatusSelect
            id='filterParticipation'
            name='participation'
            value={participation || undefined}
            mixedChannel={isMixedChannelInstance(instanceId)}
            onChange={onFilterChange}
          >
            <option value=''>{t('meeting.invitations.filters.participation.placeholder')}</option>
          </ParticipationStatusSelect>
        </FormGroup>
      </Col>
    </Row>
  )
}

export default ParticipantsFilters
