import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { CardBody, Col } from 'reactstrap'

import Card from '../../../atoms/card/Card'
import Row from '../../../atoms/layout/Row'
import './CardArticle.scss'

const ArticleLoader: FC = () => {
  return (
    <Row grid xs={1} md={2} lg={3}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
        <Col key={item}>
          <Card className={`CardArticle`}>
            <CardBody>
              <ContentLoader style={{ width: '100%', height: '370px' }}>
                <rect x='10' y='5' rx='3' ry='3' width='200' height='15' />

                <rect x='10' y='40' rx='3' ry='3' width='90' height='10' />

                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((item) => (
                  <rect key={item} x='10' y={50 + item * 17} rx='3' ry='3' width='450' height='8' />
                ))}
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default ArticleLoader
