import { useStore } from 'effector-react'
import _ from 'lodash'
import { InstanceListStore } from 'store/InstanceListStore'
import { UserStore } from 'store/UserStore'
import { Collaborator, PerimeterTypeEnum } from 'types/Collaborator'
import { Instance } from 'types/Instance'
import { UserManagerRights } from 'types/User'
import { isMandateCurrent } from 'utils/mandateUtils'

import { _getCollaboratorAssociationList, _getCollaboratorInstanceList } from './usePermissions'

/**
 * Permet de calculer les droits manager d'un utilisateur
 * @param userCollaboratorList
 * @returns objet avec: hasManagerOnAll si tous les droits / instancesIds et associationsIds sur lesquels l'utilisateur a des droits gestionnaires
 */
export const computeManagerRightsOnInstancesAndAssociationsForUser = (
  userCollaboratorList: Collaborator[] | undefined,
  instanceList: Instance[],
): UserManagerRights => {
  if (userCollaboratorList) {
    const currentCollaboratorIsManagerList = userCollaboratorList.filter(
      (collaborator) => collaborator.is_manager && isMandateCurrent(collaborator.start_date, collaborator.end_date),
    )

    const hasManagerRightsOnAll = currentCollaboratorIsManagerList.some(
      ({ perimeter_type }) => perimeter_type === PerimeterTypeEnum.ALL,
    )

    if (hasManagerRightsOnAll) {
      return { hasManagerRightsOnAll }
    } else {
      const instancesIds = _.map(_getCollaboratorInstanceList(instanceList, currentCollaboratorIsManagerList), 'id')
      const associationsIds = _.map(
        _getCollaboratorAssociationList(instanceList, currentCollaboratorIsManagerList),
        'id',
      )

      return {
        hasManagerRightsOnAll,
        instancesIds,
        associationsIds,
      }
    }
  }
  return {}
}

const cachedComputeManagerRights = _.memoize(computeManagerRightsOnInstancesAndAssociationsForUser)

const useManagerRights = (): UserManagerRights => {
  const userStore = useStore(UserStore)
  const collaborators = userStore?.collaborators
  const instanceList = useStore(InstanceListStore)

  return cachedComputeManagerRights(collaborators, instanceList)
}

export default useManagerRights
