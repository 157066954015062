import { UserProfileEnum } from 'types/User'
import * as Yup from 'yup'

import { addressValidationSchema } from './Address'
import { phoneRegex } from './RegexConstants'

export const userProfileValidationSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('common.validation.required'),
  lastname: Yup.string().trim().required('common.validation.required').uppercase('required'),
  email: Yup.string().email('common.validation.email').required('common.validation.required'),
  telephone: Yup.string().matches(phoneRegex, {
    message: 'common.validation.phone',
    excludeEmptyString: true,
  }),
  address: addressValidationSchema,
})

export const userCreateValidationSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('common.validation.required'),
  lastname: Yup.string().trim().required('common.validation.required'),
  email: Yup.string().email('common.validation.email').required('common.validation.required'),
  college: Yup.string().when('profile', {
    is: (profile: UserProfileEnum) => profile === UserProfileEnum.MEMBER,
    then: Yup.string().trim().required('common.validation.required'),
  }),
  profile: Yup.string().trim().required('common.validation.required'),
})
