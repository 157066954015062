import { MemberStatusEnum } from '../../types/MemberStatus'

export const MEMBER_STATUS = [
  {
    key: MemberStatusEnum.TITULAIRE,
    label: 'common.enums.memberStatutes.holder',
  },
  {
    key: MemberStatusEnum.SUPPLEANT,
    label: 'common.enums.memberStatutes.substitute',
  },
]
