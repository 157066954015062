import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Label } from 'reactstrap'

import { Instance } from '../../../types/Instance'

import TextInput from '../../atoms/input/TextInput'

interface InstanceSelectProps {
  onInstanceSelect: (instanceId: number) => void
  instanceList: Instance[]
  className?: string
  instanceId?: string
}
const InstanceSelectFilter: FC<InstanceSelectProps> = (props) => {
  const { instanceList, onInstanceSelect, className = '', instanceId } = props
  const { t } = useTranslation()

  const onSelectedValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const instanceId = parseInt(event.target.value)
    onInstanceSelect(instanceId)
  }

  return (
    <FormGroup className={className}>
      <Label>{t('common.instances')}</Label>
      <TextInput
        type='select'
        id='statusSelect'
        onChange={onSelectedValueChange}
        disabled={!!instanceId}
        defaultValue={instanceId || ''}
      >
        <option value=''>{t('forms.selectInstance')}</option>
        {instanceList.map((instance) => (
          <option key={instance.id} value={instance.id}>
            {`${instance.association?.name} - ${instance.long_name}`}
          </option>
        ))}
      </TextInput>
    </FormGroup>
  )
}

export default InstanceSelectFilter
