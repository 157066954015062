import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody } from 'reactstrap'

import { GENERAL_PERMISSION } from '../../../utils/constants/Permissions'

import Table from '../../atoms/table/Table'
import ConfirmationModal from '../modals/ConfirmationModal'

export type MandateRightType = {
  title: string
  permission: string
  checked: boolean
}

interface MandatePermissionsProps {
  rights: MandateRightType[]
  onChange: (checked: boolean, permission: string) => void
}

const MandateRight: FC<MandatePermissionsProps> = ({ rights, onChange }): JSX.Element => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)

  const modalOnChange = () => {
    const right = rights.find((right) => right.permission === 'SUPER_ADMIN')
    right && onChange(!right.checked, right.permission)
    closeModal()
  }

  return (
    <div>
      <Card className={'mt-5'}>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th scope='col'>{t('permissions.header.permission')}</th>
                <th scope='col'>{t('permissions.header.current')}</th>
              </tr>
            </thead>
            <tbody>
              {rights
                .filter((right) => right.permission !== GENERAL_PERMISSION.SUPER_ADMIN)
                .map((right) => (
                  <tr key={right.title}>
                    <td className='col-md-10'>{right.title}</td>
                    <td>
                      <div className='custom-control custom-switch'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          checked={right.checked}
                          id={right.title}
                          onChange={() => {
                            onChange(!right.checked, right.permission)
                          }}
                        />
                        <label className='custom-control-label' htmlFor={right.title} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card className={'mt-5'}>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th scope='col'>{t('permissions.header.admin')}</th>
                <th scope='col'>{t('permissions.header.current')}</th>
              </tr>
            </thead>
            <tbody>
              {rights
                .filter((right) => right.permission === GENERAL_PERMISSION.SUPER_ADMIN)
                .map((right) => (
                  <tr key={right.title}>
                    <td className='col-md-10'>{right.title}</td>
                    <td>
                      <div className='custom-control custom-switch'>
                        <input
                          type='checkbox'
                          className='custom-control-input'
                          checked={right.checked}
                          id={right.title}
                          onChange={() => {
                            openModal()
                          }}
                        />
                        <label className='custom-control-label' htmlFor={right.title} />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <ConfirmationModal onChange={modalOnChange} show={modalOpen} toggle={closeModal} />
    </div>
  )
}

export default MandateRight
