import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import Header from 'components/molecules/heading/Header'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import { DocumentListType } from '../types/Document'
import { NotificationInterface, NotificationType } from '../types/Notification'

import Button from '../components/atoms/button/Button'
import ModalAddSummaryBoardDirectors from '../components/molecules/modals/ModalAddSummaryBoardDirectors'

import { notificationService } from '../services/notificationService'
import { NotificationStore } from '../store/NotificationStore'

const KioskSummaryBoardDirectorsView: FC = () => {
  const queryClient = useQueryClient()
  const getNotificationsKey = ['user', 'getUserNotifications']

  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.summaryBoardDirectors')

  const { isLoading, data: documentList, refetch } = useQuery({
    queryKey: ['kiosk', 'fetchSummaryBoardDirectorsAndBuildDocumentList'],
    queryFn: () => kioskService.fetchSummaryBoardDirectorsAndBuildDocumentList(),
  })

  const { data: summaryBoardDirectors, refetch: refetchSummaryBoardDirectors } = useQuery({
    queryKey: ['summaryBoardDirectors', 'getKioskSummaryBoardDirectors'],
    queryFn: () => kioskService.getKioskSummaryBoardDirectors(),
  })

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddSummaryBoardDirectorOpen, toggle: toggleModalSummaryBoardDirectorRecord } = useToggle()

  const { mutate: deleteDocuments } = useMutation(
    ['deleteSummaryBoardDirectors', 'deleteKioskSummaryBoardDirectors'],
    async (documentList: DocumentListType) => {
      if (summaryBoardDirectors) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateSummaryBoardDirector({
          id: summaryBoardDirectors.id,
          documents: summaryBoardDirectors.documents
            ?.filter((doc) => !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              year: doc.year,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetch()
        refetchSummaryBoardDirectors()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const deleteNotifications = async () => {
    const notifications = NotificationStore.getState()
    const boardNotifications = notifications.filter(
      (notification) => [NotificationType.SUMMARIES_BOARD].includes(notification.type) && !notification.view,
    )

    const now = new Date()
    if (boardNotifications.length > 0) {
      boardNotifications.forEach((notification) => {
        notificationService.update(notification.id, { view: now }).then(() => {
          queryClient.setQueryData(
            getNotificationsKey,
            notifications.map((item: NotificationInterface) => {
              if (item.type === NotificationType.SUMMARIES_BOARD) {
                return {
                  ...item,
                  view: now,
                }
              }
              return item
            }),
          )
        })
      })
    }
  }

  useEffect(() => {
    deleteNotifications()
  }, [])

  const toggleModal = () => {
    toggleModalSummaryBoardDirectorRecord()
    refetch()
    refetchSummaryBoardDirectors()
  }

  const onDeleteDocument = (deletedDocuments: DocumentListType) => {
    deleteDocuments(deletedDocuments)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && (
              <Button onClick={toggleModalSummaryBoardDirectorRecord}>{t('kiosk.addSummaryBoardDirector')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          {documentList && !isLoading && (
            <DocumentsListsWithViewer
              lists={documentList}
              onDeleteDocument={onDeleteDocument}
              deletable={canManageKiosk}
            />
          )}
          {!documentList?.length && !isLoading && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: rubricLabel.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddSummaryBoardDirectors
          toggle={toggleModal}
          isOpen={isAddSummaryBoardDirectorOpen}
          oldDocuments={summaryBoardDirectors?.documents ?? []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskSummaryBoardDirectorsView
