import React, { FC } from 'react'
import { Instance } from 'types/Instance'
import { useInstance } from 'utils/hooks/usePermissions'

interface Props {
  instance: Instance | number
  nameType: keyof Pick<Instance, 'name' | 'short_name' | 'long_name'>
}

const MandateInstanceAssociationName: FC<Props> = ({ instance, nameType }) => {
  const populatedInstance = useInstance(instance)

  if (!populatedInstance) {
    return null
  }

  return (
    <span className='d-flex text-ellipsis'>{`${populatedInstance[nameType as keyof Instance]}${
      populatedInstance.association ? ' - ' + populatedInstance.association.name : ''
    }`}</span>
  )
}

export default MandateInstanceAssociationName
