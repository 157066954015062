import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { SearchType } from '../types/Search'

import { execute } from '../api/api'

const getSearch = (query: string | null, userInstance: string): Promise<SearchType> => {
  return execute(`${ApiRoutes.SEARCH.BASE}?q=${query}&instanceList=${userInstance}`, 'GET')
}

export const searchService = {
  getSearch,
}
