import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Label } from 'reactstrap'

import { INVITATION_STATUS, UNRESPONDED_USER_STATUTES_STATUS_FILTER } from '../../../utils/constants/Status'

import { InvitationStatusType } from '../../../types/InvitationStatus'

import TextInput from '../../atoms/input/TextInput'

interface StatusSelectProps {
  value?: InvitationStatusType | ''
  onStatusChange: (id: InvitationStatusType) => void
}

const StatusSelectFilter: FC<StatusSelectProps> = (props) => {
  const { value, onStatusChange } = props
  const { t } = useTranslation()

  const selectClassName = value ? `text-${INVITATION_STATUS[value].color}` : ''

  return (
    <FormGroup>
      <Label>{t('meeting.filters.status')}</Label>
      <TextInput
        type='select'
        id='statusSelect'
        value={value}
        className={selectClassName}
        onChange={(event) => onStatusChange(event.target.value as InvitationStatusType)}
      >
        <option value=''>{t('meeting.status.none')}</option>
        {UNRESPONDED_USER_STATUTES_STATUS_FILTER.map(([key, value]) => (
          <option key={key} value={key} className={`text-${value.color}`}>
            {t(value.label)}
          </option>
        ))}
      </TextInput>
    </FormGroup>
  )
}

export default StatusSelectFilter
