import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardTitle } from 'reactstrap'

import { DocumentType } from '../../../types/Document'

import Card from '../../atoms/card/Card'
import DocumentsListsWithViewer from '../documents/DocumentsListsWithViewer'
import './Search.scss'

interface SearchDocumentProps {
  documents: DocumentType[]
}

const SearchDocument: FC<SearchDocumentProps> = ({ documents }) => {
  const { t } = useTranslation()

  const documentListType = {
    label: '',
    documents: documents,
    loadMore: true,
  }

  return (
    <Card className='search-card'>
      <CardBody>
        <CardTitle>{t('search.documents.title')}</CardTitle>
        {documents.length > 0 ? (
          <DocumentsListsWithViewer
            lists={[documentListType]}
            xlSize={4}
            deletable={false}
            showHeader={false}
            flexWrap='wrap'
          />
        ) : (
          <div className='no-result d-flex flex-column justify-content-center'>
            <p>{t('search.noResult')}</p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default SearchDocument
