import Button from 'components/atoms/button/Button'
import Modal from 'components/atoms/modal/Modal'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { User } from 'types/User'

import ModalContact from '../modals/contacts/ModalContact'

interface Props {
  userList: User[]
  onModalClose: () => void
  onConfirmCreate: () => void
}

const SimilarUserListModal: FC<Props> = ({ userList, onModalClose, onConfirmCreate }) => {
  const { t } = useTranslation()
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const onCloseContactModal = () => setSelectedUser(undefined)

  return (
    <>
      <Modal isOpen={true} toggle={onModalClose} size='md'>
        <ModalHeader toggle={onModalClose}>
          <b>{t('userCreate.similarNameModal.head')}</b>
        </ModalHeader>
        <ModalBody>
          <span className='m-1'>{t('userCreate.similarNameModal.body')}</span>
          <ul className='mb-5'>
            {userList.map((user) => (
              <li key={user.id}>
                <a className='cursor-pointer m-1' onClick={() => setSelectedUser(user)}>
                  {user.firstname} {user.lastname} - {user.email}
                </a>
              </li>
            ))}
          </ul>
          <Button label={'Confirmer la création'} onClick={onConfirmCreate} />
        </ModalBody>
      </Modal>
      {!!selectedUser && (
        <ModalContact isOpen={!!selectedUser} toggle={onCloseContactModal} contact={selectedUser} editRight={true} />
      )}
    </>
  )
}

export default SimilarUserListModal
