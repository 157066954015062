import { FC } from 'react'

import './NotificationDot.scss'

interface NotificationDotProps {
  show?: 'DotMain' | 'DotOther' | false
}

const NotificationDot: FC<NotificationDotProps> = (props) => {
  const { show } = props

  return <span className={`NotificationDot ${show || ''}`} />
}

export default NotificationDot
