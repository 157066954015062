import React, { FC } from 'react'

import './Header.scss'

interface HeaderProps {
  className?: string
  children?: React.ReactNode
}

const Header: FC<HeaderProps> = (props) => {
  const { className = '', children = null } = props
  return <div className={`Header ${className}`}>{children}</div>
}

export default Header
