import React, { FC, ChangeEvent } from 'react'
import { FormGroup, Input, InputProps } from 'reactstrap'

import './Checkbox.scss'

interface CheckboxProps extends InputProps {
  handleCheck?: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const { className = '', handleCheck, ...rest } = props
  const onChecked = (e: ChangeEvent<HTMLInputElement>) => {
    handleCheck && handleCheck(e)
  }

  return (
    <FormGroup className={'form-check'} check>
      <Input className={`form-check-input Checkbox ${className}`} onChange={onChecked} type={'checkbox'} {...rest} />
    </FormGroup>
  )
}

export default Checkbox
