import { format } from 'date-fns'
import React, { FC } from 'react'
import { Instance } from 'types/Instance'
import { Meeting } from 'types/Meeting'
import { useInstance } from 'utils/hooks/usePermissions'

interface Props {
  meeting: Meeting
  nameType: keyof Pick<Instance, 'name' | 'short_name' | 'long_name'>
}

const MeetingInstanceAssociationName: FC<Props> = ({ meeting, nameType }) => {
  const populatedInstance = useInstance(meeting.instance)

  if (!populatedInstance) {
    return null
  }

  return (
    <span className='d-flex text-ellipsis'>
      {format(new Date(meeting.start_date), 'dd/MM/yyyy')}
      {' - ' + populatedInstance[nameType as keyof Instance]}
      {populatedInstance.association ? ' - ' + populatedInstance.association.name : ''}
    </span>
  )
}

export default MeetingInstanceAssociationName
