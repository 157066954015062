import CustomInput from 'components/atoms/input/CustomInput'
import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MANAGER_STATUTES, MANAGER_STATUTES_MIXED_CHANNEL } from '../../../../../utils/constants/Status'

import { InvitationStatusType } from '../../../../../types/InvitationStatus'

interface ParticipationStatusSelectProps {
  id: string
  name?: string
  value?: InvitationStatusType
  mixedChannel: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const ParticipationStatusSelect: FC<ParticipationStatusSelectProps> = (props) => {
  const { id, name, value, mixedChannel, onChange, children } = props
  const { t } = useTranslation()

  return (
    <CustomInput id={id} name={name} type='select' className='cursor-pointer' value={value} onChange={onChange}>
      {children}
      {mixedChannel &&
        MANAGER_STATUTES_MIXED_CHANNEL.map(([key, value]) => (
          <option key={key} value={key} className='cursor-pointer'>
            {t(value.label)}
          </option>
        ))}
      {!mixedChannel &&
        MANAGER_STATUTES.map(([key, value]) => (
          <option key={key} value={key} className='cursor-pointer'>
            {t(value.label)}
          </option>
        ))}
    </CustomInput>
  )
}

export default ParticipationStatusSelect
