import { FC, ReactNode } from 'react'

import './View.scss'

interface Props {
  children: ReactNode
  className?: string
}

const View: FC<Props> = ({ children, className = '' }) => {
  return <div className={`View ${className}`}>{children}</div>
}

export default View
