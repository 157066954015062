import { FunctionComponent } from 'react'
// Reactstrap
import { Row as LibRow, RowProps as LibRowProps } from 'reactstrap'

import './Row.scss'

type RowProps = LibRowProps & {
  grid?: boolean
  gutters?: 'no' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  flexFill?: boolean
  flexWrap?: 'wrap' | 'nowrap'
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'around' | 'between' | 'evenly'
  alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
  onClick?: () => void
}

const Row: FunctionComponent<RowProps> = ({
  children,
  className = '',
  grid,
  gutters,
  flexFill,
  flexWrap,
  flexDirection,
  justifyContent,
  alignItems,
  onClick,
  ...rest
}) => {
  const RowClassName =
    className +
    (grid ? ' grid' : '') +
    (gutters ? ' gutters-' + gutters : '') +
    (flexFill ? ' flex-fill' : '') +
    (flexWrap ? ' flex-' + flexWrap : '') +
    (flexDirection ? ' flex-' + flexDirection : '') +
    (justifyContent ? ' justify-content-' + justifyContent : '') +
    (alignItems ? ' align-items-' + alignItems : '') +
    (onClick ? ' cursor-pointer' : '')

  return (
    <LibRow className={`Row ${RowClassName}`} onClick={onClick} {...rest}>
      {children}
    </LibRow>
  )
}

export default Row
