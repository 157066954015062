import frenchLocale from 'date-fns/locale/fr'
import { FC } from 'react'

import { MeetingDashboard } from '../../../../types/Meeting'

import './Calendar.scss'
import CalendarCells from './CalendarCells'
import CalendarDays from './CalendarDays'
import CalendarHeader from './CalendarHeader'
import CalendarLegend from './CalendarLegend'

export const locale = frenchLocale

interface CalendarProps {
  meetings: MeetingDashboard[]
  selectedDate: Date
  onSelectDate: (day: Date) => void
  onIncrementMonth: () => void
  onDecrementMonth: () => void
}

const Calendar: FC<CalendarProps> = (props) => {
  const { meetings, selectedDate, onSelectDate, onIncrementMonth, onDecrementMonth } = props

  return (
    <div className='Calendar'>
      <div className='top-content'>
        <CalendarHeader
          selectedDate={selectedDate}
          onDecrementMonth={onDecrementMonth}
          onIncrementMonth={onIncrementMonth}
        />
        <CalendarDays selectedDate={selectedDate} />
        <CalendarCells meetings={meetings} selectedDate={selectedDate} onSelectDate={onSelectDate} />
      </div>
      <div className='bot-content'>
        <CalendarLegend />
      </div>
    </div>
  )
}

export default Calendar
