import { createEvent, createStore } from 'effector'

import { isMandateCurrent } from '../utils/mandateUtils'

import { Association } from '../types/Association'
import { Collaborator } from '../types/Collaborator'
import { Instance } from '../types/Instance'
import { User } from '../types/User'

import { associationService } from '../services/associationService'
import { instanceService } from '../services/instanceService'

const InitialUserStore = null

export const setUser = createEvent<User>('SET_USER')
export const removeUser = createEvent('REMOVE_USER')

const filterMandatAR2i = (instance: Instance): boolean => {
  const currentDate = new Date()
  const instanceComplet = instance
  if (currentDate > new Date('2022-12-31')) {
    return !instanceComplet?.association?.name?.includes('AR2i')
  }
  return true
}

const filterCollaboratorsAR2i = (
  collaborator: Collaborator,
  associations: Association[],
  instances: Instance[],
): boolean => {
  if (new Date() > new Date('2022-12-31')) {
    if (collaborator.perimeter_type == 'ASSOCIATION') {
      return !associations.find((association) => association.id == collaborator.id_perimeter)?.name.includes('AR2i')
    } else if (collaborator.perimeter_type == 'INSTANCE') {
      return !instances.find((instance) => instance.id == collaborator.id_perimeter)?.name.includes('AR2i')
    }
  }
  return true
}

export const UserStore = createStore<User | null>(InitialUserStore)
  .on(setUser, (state, payload) => ({
    ...payload,
    instances: payload.instances?.filter(filterMandatAR2i),
    members: payload.members?.filter(
      (member) => filterMandatAR2i(member.instance) && isMandateCurrent(member.start_date, member.end_date),
    ),
  }))
  .reset(removeUser)

export const filterAR2iCollaborator = async (): Promise<void> => {
  const associations = await associationService.getAll()
  const instances = await instanceService.getAll()
  const user = UserStore.getState()
  if (user) {
    user.collaborators = user.collaborators.filter((collaborator) =>
      filterCollaboratorsAR2i(collaborator, associations, instances),
    )
  }
}
