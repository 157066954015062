import React, { FC } from 'react'
import { Col } from 'reactstrap'

import { User } from '../../../types/User'

import Row from '../../atoms/layout/Row'
import ContactCard from './contact-card/ContactCard'

interface ContactTableProps {
  contactList: User[]
  onContactClicked: (user: User) => void
}

const ContactTable: FC<ContactTableProps> = ({ contactList, onContactClicked }): JSX.Element => {
  return (
    <Row grid xs='1' sm='3' md='4' xl='5'>
      <>
        {contactList.map((user) => (
          <Col
            key={`ContactCard_col_user_${user.id}`}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            onClick={() => onContactClicked(user)}
          >
            <ContactCard {...user} />
          </Col>
        ))}
      </>
    </Row>
  )
}

export default ContactTable
