import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'

import { NotificationInterface } from '../../../types/Notification'

import { notificationService } from '../../../services/notificationService'
import './NotificationItem.scss'

interface NotificationCardProps {
  notification: NotificationInterface
  separator: boolean
}

const getNotificationsKey = ['user', 'getUserNotifications']

const NotificationItem: FC<NotificationCardProps> = (props) => {
  const { notification, separator } = props
  const history = useHistory()

  const queryClient = useQueryClient()

  const onClick = () => {
    if (!notification.view) {
      const notifications: NotificationInterface[] = queryClient.getQueryData(getNotificationsKey) || []
      const now = new Date()
      notificationService.update(notification.id, { view: now }).then(() => {
        queryClient.setQueryData(
          getNotificationsKey,
          notifications.map((item: NotificationInterface) => {
            if (item.id === notification.id) {
              return {
                ...item,
                view: now,
              }
            }
            return item
          }),
        )
      })
    }
    history.push(notificationService.getNotificationPath(notification))
  }

  return (
    <div onClick={onClick} className={`NotificationItem ${!notification.view ? 'Highlight' : ''}`}>
      <div className='NotificationBody'>
        <h5>{notificationService.getNotificationMessage(notification)}</h5>
        <small>{notificationService.getNotificationTime(notification)}</small>
      </div>
      {separator && <hr />}
    </div>
  )
}

export default NotificationItem
