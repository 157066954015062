import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { Cgu } from '../types/Cgu'

import { execute } from '../api/api'

const getCgu = (): Promise<Cgu> => {
  return execute<Cgu>(ApiRoutes.CGU.BASE, 'GET')
}

export const cguService = {
  getCgu,
}
