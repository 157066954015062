import React, { FC } from 'react'

import './HeadingListGroup.scss'

interface Props {
  className?: string
  children?: React.ReactNode
}

const HeadingListGroup: FC<Props> = ({ className = '', children = null }) => {
  return (
    <h3 className={`HeadingListGroup ${className}`}>
      <strong>{children}</strong>
    </h3>
  )
}

export default HeadingListGroup
