import Card from 'components/atoms/card/Card'
import Figure from 'components/atoms/figure/Figure'
import Icon from 'components/atoms/icon/Icon'
import Overlay from 'components/atoms/overlay/Overlay'
import { FC } from 'react'
import { CardTitle } from 'reactstrap'
import { DocumentType } from 'types/Document'

import VideoThumbnail from '../../atoms/video/VideoThumbnail'
import './VideosViewer.scss'

interface Props {
  video: DocumentType
  boxShadow?: 'none'
  onVideoSelect?: (video: DocumentType) => void
  className?: string
}

const VideoCard: FC<Props> = ({ boxShadow, video, onVideoSelect, className = '' }) => {
  const videoCardClassName = className + (onVideoSelect ? ' cursor-pointer' : '')

  return (
    <Card boxShadow={boxShadow} onClick={() => onVideoSelect && onVideoSelect(video)} className={videoCardClassName}>
      <Figure>
        <>
          <VideoThumbnail video={video} />
          <Overlay>
            <>
              <Icon name='right-open' />
              <CardTitle>{video.name}</CardTitle>
            </>
          </Overlay>
        </>
      </Figure>
    </Card>
  )
}

export default VideoCard
