import { BASE_PATH } from '../../api/api'

export const ApiRoutes = {
  AUTH: {
    BASE: '/auth',
    LOGOUT: '/auth/logout',
    LOGIN: '/auth/local',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
  },
  USERS: {
    BASE: '/users',
    ME: '/users/me',
    ID: '/users/:id',
    CONTACTS: '/users/contacts',
    USERS: '/users/users',
    EXPORT: '/users/export/contacts',
    MEMBERS: '/users/:id/members',
    PERIMETER: `/users/:id/perimeter`,
    DOCUMENTS: `/users/:id/documents`,
    COLLABORATORS: '/users/:id/collaborators',
    DELEGATES: '/users/:id/delegates',
    FAVORITES: '/users/documents/favorite',
    UPDATE_EMAIL: '/users/email/update',
    VALIDATE_EMAIL: '/users/email/validate',
    RIGHTS: '/users/:userId/rights',
  },
  ARTICLES: {
    BASE: '/articles',
    SLUG: '/articles/:slug',
  },
  NOTIFICATIONS: {
    BASE: '/notifications',
    ME: '/notifications/me',
    ID: '/notifications/:id',
  },
  MEETINGS: {
    BASE: '/meetings',
    ID: '/meetings/:id',
    ICS: `${BASE_PATH}/meetings/:id/ics`,
    MY_MEETINGS: '/meetings/me',
    INVITATIONS: '/meetings/:id/invitations',
    SIGN_IN_SHEET: '/meetings/:id/sign-in-sheet',
    DOCUMENTS: '/meetings/:id/documents/:documentId',
    DECISIONS_RECORDS: '/meetings/:id/decision-records/:documentId',
    MY_MEETINGS_INSTANCES: '/meetings/instances/me',
    MY_MEETINGS_DELEGATES: '/meetings/delegates/me',
    CONVOCATION_TEMPLATE: '/meetings/:id/convocation-template',
    DOCUMENT_NOTIFICATION: `/meetings/:id/documents/notifications`,
    DOWNLOAD_ZIP: `/meetings/:meetingId/download`,
    UPDATE_MEETING_INVITATIONS: `/meetings/:meetingId/invitations`,
  },
  INSTANCES: {
    BASE: '/instances',
    ID: '/instances/:id',
    MY_INSTANCES: '/instances/me',
    SEND_EMAIL: `/instances/:id/send-email`,
    SEND_MAIL_HISTORY: `/instances/:id/send-mail-history`,
    MEMBERS: '/instances/:id/members',
    COLLABORATORS: '/instances/:id/collaborators',
    MEETINGS_DECISIONS_RECORDS: `/instances/:id/meetings/decisions-record`,
    NOT_IN_INSTANCE_USERS: `/instances/:id/not-in-instance-users`,
    MEETINGS_DELEGATE: '/instances/:id/meetings',
  },
  MAIL_HISTORY: {
    MAILS: '/mail-histories',
    COUNT: '/mail-histories/count',
    MAILS_FORGOT_PWD: '/email-histories/forgotten-password',
  },
  MEMBERS: {
    BASE: '/members',
    ID: '/members/:id',
  },
  INVITATIONS: {
    BASE: '/invitations',
    ID: '/invitations/:id',
    HISTORY: '/invitations/:id/history',
    ID_POWER_TEMPLATE: '/invitations/:id/give-power-template',
    ID_REPLACEMENT_TEMPLATE: '/invitations/:id/replacement-template',
  },
  DECISIONS_RECORDS: {
    BASE: '/decision-records',
  },
  COLLABORATORS: {
    BASE: '/collaborators',
    ID: '/collaborators/:id',
  },
  DOCUMENTS: {
    BASE: '/documents',
    UPLOAD: '/upload',
  },
  CONTACT: {
    BASE: '/contact-info',
  },
  LEGAL_NOTICE: {
    BASE: '/legal-notice',
  },
  CGU: {
    BASE: '/cgu',
  },
  HELP: {
    BASE: '/help',
  },
  KIOSK: {
    ACTIVITY_REPORTS: '/kiosk-activity-reports',
    SUMMARY_BOARD_DIRECTORS: '/kiosk-summary-board-directors',
    EXPENSES_PROCEDURES: '/kiosk-expenses-procedures',
    FOUNDING_TEXTS: '/kiosk-founding-texts',
    OBJECTIVES_CONVENTION: '/kiosk-objectives-convention',
    FINANCIAL_DATA: '/kiosk-financial-data',
    ACTIVITY: '/kiosk-activity',
    FORMATIONS: '/kiosk-formations',
    COMMUNICATION_TOOLS: '/kiosk-communication-tools',
    INSTANCE_LETTER: '/kiosk-instance-letter',
  },
  ASSOCIATIONS: {
    BASE: '/associations',
  },
  SEARCH: {
    BASE: '/search',
  },
  DELEGATES: {
    BASE: '/delegates',
    ID: '/delegates/:id',
  },

  ORGANISATION: {
    GROUP: '/organisations-groupes',
    PATRONAL: '/organisations-patronals?_sort=label:ASC',
    SALARIE: '/organisations-salaries?_sort=label:ASC',
  },
}
