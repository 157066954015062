/**
 * File listing every route of the application
 */
import { ComponentType } from 'react'
import { RouteProps } from 'react-router-dom'
import DashboardView from 'views/DashboardView'
import HelpForManagerView from 'views/HelpForManagerView'
import InstanceMandatesView from 'views/InstanceMandatesView'
import InstancesListView from 'views/InstancesListView'
import KioskActivityReportsView from 'views/KioskActicvityReportsView'
import KioskDecisionsRecordsAllInstancesView from 'views/KioskDecisionsRecordsAllInstancesView'
import KioskDecisionsRecordsInstanceView from 'views/KioskDecisionsRecordsInstanceView'
import KioskExpensesProceduresView from 'views/KioskExpensesProceduresView'
import KioskFinancialDataView from 'views/KioskFinancialDataView'
import KioskFoundingTextsView from 'views/KioskFoundingTextsView'
import KioskObjectivesConventionView from 'views/KioskObjectivesConventionView'
import UserCreateView from 'views/UserCreateView'
import ValidateEmailView from 'views/ValidateEmailView'

import ArticlePageView from '../../../views/ArticlePageView'
import ArticlesView from '../../../views/ArticlesView'
import ComponentsExample from '../../../views/ComponentsExample'
import ContactsView from '../../../views/ContactsView'
import DocumentsView from '../../../views/DocumentsView'
import FavoritesView from '../../../views/FavoritesView'
import ForgotPasswordView from '../../../views/ForgotPassword'
import HelpView from '../../../views/HelpView'
import KioskActivityView from '../../../views/KioskActivityView'
import KioskCommunicationToolsView from '../../../views/KioskCommunicationToolsView'
import KioskFormationsView from '../../../views/KioskFormationsView'
import KioskInstanceLetterView from '../../../views/KioskInstanceLetterView'
import KioskSummaryBoardDirectorsView from '../../../views/KioskSummaryBoardDirectorsView'
import KioskView from '../../../views/KioskView'
import LoginView from '../../../views/LoginView'
import LogoutView from '../../../views/LogoutView'
import MailHistoryForgotPwdView from '../../../views/MailHistoryForgotPwdView'
import MailHistoryView from '../../../views/MailHistoryView'
import MandatesRightView from '../../../views/MandatesRightView'
import MeetingDetailsView from '../../../views/MeetingDetailsView'
import MeetingFormView from '../../../views/MeetingFormView'
import MeetingInvitationsView from '../../../views/MeetingInvitationsView'
import MeetingsDelegateView from '../../../views/MeetingsDelegateView'
import MeetingsView from '../../../views/MeetingsView'
import ResetPasswordView from '../../../views/ResetPassword'
import SearchView from '../../../views/SearchView'
import UserManagementView from '../../../views/UserManagementView'
import UserMandatesView from '../../../views/UserMandatesView'
import UserProfileView from '../../../views/UserProfileView'

import { PATHS } from './RoutePaths'

export interface AppRouteInterface {
  path: string
  component: ComponentType<RouteProps>
}

export const LoggedInRoutes: AppRouteInterface[] = [
  {
    path: PATHS.DASHBOARD,
    component: DashboardView,
  },
  {
    path: PATHS.USER_PROFILE,
    component: UserProfileView,
  },
  {
    path: PATHS.MEETINGS.MEETING,
    component: MeetingsView,
  },
  {
    path: PATHS.DOCUMENTS,
    component: DocumentsView,
  },
  {
    path: PATHS.KIOSK.HOME,
    component: KioskView,
  },
  {
    path: PATHS.NEWS.HOME,
    component: ArticlesView,
  },
  {
    path: PATHS.NEWS.PAGE,
    component: ArticlePageView,
  },
  {
    path: PATHS.CONTACTS.HOME,
    component: ContactsView,
  },
  {
    path: PATHS.HELP.HOME,
    component: HelpView,
  },
  {
    path: PATHS.HELP.MANAGER,
    component: HelpForManagerView,
  },
  {
    path: PATHS.FAVORITES.HOME,
    component: FavoritesView,
  },
  {
    path: PATHS.COMPONENTS_EXAMPLE,
    component: ComponentsExample,
  },
  {
    path: PATHS.MEETINGS.MEETING_CREATE,
    component: MeetingFormView,
  },
  {
    path: PATHS.MEETINGS.MEETING_UPDATE,
    component: MeetingFormView,
  },
  {
    path: PATHS.MEETINGS.MEETING_DETAILS,
    component: MeetingDetailsView,
  },
  {
    path: PATHS.MEETINGS.MEETINGS_PARTICIPANTS,
    component: MeetingInvitationsView,
  },
  {
    path: PATHS.USER_MANAGEMENT.MANDATES,
    component: UserMandatesView,
  },
  {
    path: PATHS.USER_MANAGEMENT.CREATE_USER,
    component: UserCreateView,
  },
  {
    path: PATHS.USER_MANAGEMENT.INSTANCE,
    component: InstanceMandatesView,
  },
  {
    path: PATHS.USER_MANAGEMENT.ALL_INSTANCES,
    component: InstancesListView,
  },
  {
    path: PATHS.KIOSK.FOUNDING_TEXTS,
    component: KioskFoundingTextsView,
  },
  {
    path: PATHS.KIOSK.ACTIVITY_REPORTS,
    component: KioskActivityReportsView,
  },
  {
    path: PATHS.KIOSK.SUMMARY_BOARD_DIRECTORS,
    component: KioskSummaryBoardDirectorsView,
  },
  {
    path: PATHS.KIOSK.ACTIVITY,
    component: KioskActivityView,
  },
  {
    path: PATHS.KIOSK.FORMATIONS,
    component: KioskFormationsView,
  },
  {
    path: PATHS.KIOSK.COMMUNICATION_TOOLS,
    component: KioskCommunicationToolsView,
  },
  {
    path: PATHS.KIOSK.INSTANCE_LETTER,
    component: KioskInstanceLetterView,
  },
  {
    path: PATHS.KIOSK.DECISIONS_RECORDS.ALL_INSTANCES,
    component: KioskDecisionsRecordsAllInstancesView,
  },
  {
    path: PATHS.KIOSK.DECISIONS_RECORDS.INSTANCE,
    component: KioskDecisionsRecordsInstanceView,
  },
  {
    path: PATHS.KIOSK.OBJECTIVES_CONVENTION,
    component: KioskObjectivesConventionView,
  },
  {
    path: PATHS.KIOSK.EXPENSES_PROCEDURES,
    component: KioskExpensesProceduresView,
  },
  {
    path: PATHS.KIOSK.FINANCIAL_DATA,
    component: KioskFinancialDataView,
  },
  {
    path: PATHS.USER_MANAGEMENT.HOME,
    component: UserManagementView,
  },
  {
    path: PATHS.USER_MANAGEMENT.MANDATES_RIGHTS,
    component: MandatesRightView,
  },
  {
    path: PATHS.MAIL_HISTORY.HOME,
    component: MailHistoryView,
  },
  {
    path: PATHS.MAIL_HISTORY_FORGOT_PWD.HOME,
    component: MailHistoryForgotPwdView,
  },
  {
    path: PATHS.SEARCH,
    component: SearchView,
  },
  {
    path: PATHS.VALIDATE_NEW_EMAIL,
    component: ValidateEmailView,
  },
]

export const LoggedOutRoutes: AppRouteInterface[] = [
  {
    path: PATHS.LOGOUT,
    component: LogoutView,
  },
  {
    path: PATHS.FORGOT_PASSWORD,
    component: ForgotPasswordView,
  },
  {
    path: PATHS.RESET_PASSWORD,
    component: ResetPasswordView,
  },
  {
    path: PATHS.LOGIN,
    component: LoginView,
  },
  {
    path: PATHS.VALIDATE_NEW_EMAIL,
    component: ValidateEmailView,
  },
]

export const LoggedInDelegateRoutes: AppRouteInterface[] = [
  {
    path: PATHS.USER_PROFILE,
    component: UserProfileView,
  },
  {
    path: PATHS.MEETINGS.MEETING,
    component: MeetingsDelegateView,
  },
  {
    path: PATHS.HELP.HOME,
    component: HelpView,
  },
  {
    path: PATHS.MEETINGS.MEETING_DETAILS,
    component: MeetingDetailsView,
  },
]
