import { DataNode } from 'antd/lib/tree'
import { FileBrowserProps } from 'chonky'
import { toInteger } from 'lodash'
import * as React from 'react'
import { ReactNode, ReactText } from 'react'
import { MeetingUpdate } from 'types/Meeting'
import { v4 } from 'uuid'

import { InfoDropNode } from '../components/atoms/tree/DraggableTree'

import { ATTACHED_DOCUMENT_KEY, ATTACHED_DOCUMENT_ROOT_INDEX_KEY } from './constants/ChonkyFileBrowser'

export interface InputBaseDataNode extends DataNode {
  children?: InputDataNode[]
  documentKeyList?: string[]
  fileBrowserData?: FileBrowserDataNode
}
export interface InputDataNode extends InputBaseDataNode {
  level?: string
  positionTitle?: string
  fileBrowserData?: FileBrowserDataNode
}

export interface FileBrowserDataNode {
  rootFolderId: string
  fileMap: FileBrowserFileMapNode
}

export interface FileBrowserFileMapNode {
  [id: string]: FileBrowserFileNode
}

export interface FileBrowserFileNode {
  id?: string
  name?: string | React.ReactNode
  isDir?: boolean
  childrenIds?: string[]
  childrenCount?: number
  modDate?: string | Date
  parentId?: string
  isHidden?: boolean
  size?: number
  url?: string
}

export interface FileBrowserComponentProps extends FileBrowserProps {
  getCurrentFolderId?: (currentFolderId: string, currentRootFolderId?: string) => void
  dataFiles: FileBrowserDataNode
  newFileMap?: (currentFolderId: FileBrowserFileMapNode, currentRootFolderId?: string) => void
  readOnly?: boolean
  isAttachedDocument?: boolean
  rootFolderId?: string
  meetingId?: number
  updatedMeeting: MeetingUpdate
}

/**
 *
 * @param data - La liste de node dans le noeud de l'arbre actuel
 * @param key - key du node a rechercher
 * @param parent - parent du noeud actuel
 * @param callback - fonction appelé quand le node avec la key est trouvée
 *
 * Parcours en profondeur de l'arbre en matchant la key du node, une fois trouvée on applique la fonction callback
 */
export const depthSearchByKeyWithCallback = (
  data: InputDataNode[],
  key: ReactText,
  callback: (
    node: InputDataNode,
    index: number,
    data: InputDataNode[],
    parent: InputDataNode | undefined,
  ) => InputDataNode | undefined,
  parent?: InputDataNode,
): InputDataNode | undefined => {
  let result

  for (let i = 0; i < data.length && data[i]; i++) {
    if (data[i].key === key) return callback(data[i], i, data, parent)
    if (hasChildren(data[i])) {
      const node = depthSearchByKeyWithCallback(data[i].children || [], key, callback, data[i])
      result = node !== undefined ? node : result
    }
  }
  return result
}
/**
 *
 * @param info - la data récupérée par le drag & drop
 * @param treeData - l'arbre de base
 *
 * L'algorithme effectue un premier parcours en profondeur pour supprimer le node de sa position initiale
 * Puis un deuxième afin d'ajouter ce node à sa nouvelle place
 */
export const moveNodeInTree = (info: InfoDropNode, treeData: InputDataNode[]): InputDataNode[] => {
  const { node: destinationNode, dragNode: draggedNode } = info
  const dropPosition = info.dropPosition - Number(destinationNode.pos.split('-').pop())
  const currentTreeData = [...treeData]

  //Je supprime le node dragged dans la liste de child actuelle et je le récupère
  const dragObj = depthSearchByKeyWithCallback(
    currentTreeData,
    draggedNode.key,
    (draggedNode, index, childNodes, parent) => {
      childNodes.splice(index, 1)
      updateNodes(childNodes, true, false, false, parent?.positionTitle, draggedNode.level)
      return draggedNode
    },
  )

  if (dragObj) {
    //Je rajoute le node dragged la où je l'ai drop
    depthSearchByKeyWithCallback(currentTreeData, destinationNode.key, (draggedNode, index, childNodes, parent) => {
      // si je met un node dans un niveau inférieur
      if (
        !info.dropToGap ||
        (hasChildren(destinationNode) && // Has children
          destinationNode.expanded && // Is expanded
          dropPosition === 1) // On the bottom gap
      ) {
        draggedNode.children = draggedNode.children || []
        draggedNode.children.unshift(dragObj)
      }
      // si je met un node avant un autre au même niveau
      else {
        childNodes.splice(index + Number(dropPosition !== -1), 0, dragObj)
      }
      updateNodes(
        childNodes,
        true,
        false,
        true,
        parent?.positionTitle,
        computeLevel(parent?.level !== undefined ? parent.level : '0', 1),
      )
      return draggedNode
    })
  }

  return currentTreeData
}

export const computeLevel = (level: string, modificator: number): string => {
  const result = toInteger(level) + modificator
  if (result < 10) {
    return `0${result}`
  } else {
    return result.toString()
  }
}
/**
 *
 * @param data - Le noeud actuel dans l'arbre
 * @param shouldUpdateKey - boolean permettant d'update la key
 * @param shouldUpdateTitle - boolean permettant d'update le titre
 * @param shouldUpdateLevel - boolean permettant d'update le level
 * @param preTitle
 * @param level
 */
export const updateNodes = (
  data: InputDataNode[] | undefined,
  shouldUpdateTitle = false,
  shouldUpdateKey = false,
  shouldUpdateLevel = false,
  preTitle: ReactNode = '',
  level = '0',
): void => {
  let positionTitle = 1
  for (let i = 0; data && i < data.length; i++) {
    if (data[i]) {
      if (shouldUpdateTitle)
        data[i].positionTitle = computeTitle(
          `${preTitle || ''}`,
          positionTitle < 10 ? `0${positionTitle}` : `${positionTitle}`,
        )
      if (shouldUpdateKey && !data[i].key) data[i].key = v4()
      if (shouldUpdateLevel && level !== undefined) data[i].level = level
      if (data[i].key !== ATTACHED_DOCUMENT_KEY) positionTitle++

      if (hasChildren(data[i])) {
        updateNodes(
          data[i].children,
          shouldUpdateTitle,
          shouldUpdateKey,
          shouldUpdateLevel,
          data[i].positionTitle,
          level !== undefined ? computeLevel(level, 1) : level,
        )
      }
    }
  }
  //Ce noeud est obligatoire car tous les dossiers sont stockés dans la "fileMap" de ce noeud
  if (data && level === '0') {
    const attachedDocumentIndex = data.findIndex((d) => d.key === ATTACHED_DOCUMENT_KEY)
    if (attachedDocumentIndex === -1) {
      data.push({
        title: 'Annexe',
        key: ATTACHED_DOCUMENT_KEY,
        fileBrowserData: {
          rootFolderId: ATTACHED_DOCUMENT_ROOT_INDEX_KEY,
          fileMap: {},
        },
      })
    }
  }
}

/**
 *
 * @param key - key de l'élément à supprimer
 * @param tree - arbre dans lequel on veut ajouter l'élément
 */
export const deleteNodeWithKeyFromTree = (key: ReactText, tree: InputDataNode[]): InputDataNode[] => {
  const newTree = [...tree]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  depthSearchByKeyWithCallback(newTree, key, (node, index, data, parent) => {
    data.splice(index, 1)
    updateNodes(data, true, false, false, parent?.positionTitle, node.level)

    return node
  })
  return newTree
}

/**
 *
 * @param elt - l'élément à ajouter
 * @param tree - arbre dans lequel ajouter l'élément
 * @param parentKey - la potentielle clé du parent dans lequel on veut ajouter l'élément
 */
export const addNodeFromTree = (elt: InputDataNode, tree: InputDataNode[], parentKey?: ReactText): InputDataNode[] => {
  if (!parentKey) {
    return [...tree, { ...elt, positionTitle: computeTitle(`${elt.title}`, computeLevel(`${tree.length}`, 0)) }]
  } else {
    const newTree = [...tree]

    depthSearchByKeyWithCallback(newTree, parentKey, (node) => {
      node.children = node.children || []
      node.children.push(elt)

      updateNodes(newTree, true, false, true, '')
      return node
    })

    return newTree
  }
}

/**
 *
 * @param newElt
 * @param tree - arbre dans lequel ajouter l'élément
 */
export const updateNode = (newElt: InputDataNode, tree: InputDataNode[]): InputDataNode[] => {
  const newTree = [...tree]

  depthSearchByKeyWithCallback(newTree, newElt.key, (node, index, data, parent) => {
    const newFileBrowserData = newElt.fileBrowserData?.rootFolderId
      ? {
          ...newElt.fileBrowserData,
          fileMap: {
            ...newElt.fileBrowserData?.fileMap,
            [newElt.fileBrowserData?.rootFolderId]: {
              ...newElt.fileBrowserData?.fileMap[newElt.fileBrowserData?.rootFolderId],
              name: newElt.title,
            },
          },
        }
      : newElt.fileBrowserData
    const updatedElt: InputDataNode = {
      ...newElt,
      fileBrowserData: newFileBrowserData?.rootFolderId ? newFileBrowserData : undefined,
    }
    const currentTree = parent && parent.children ? parent.children : data

    const eltIndex = currentTree.findIndex((elt) => newElt.key === elt.key)
    currentTree[eltIndex] = updatedElt

    return updatedElt
  })

  return newTree
}

const hasChildren = (tree: InputDataNode) => {
  return (tree.children || []).length > 0
}

const getDataFiles = (data: InputDataNode, rootFolderId: string, rootChildrens: string[]) => {
  let allFolder = {}
  if (data.fileBrowserData) {
    allFolder = {
      ...(data.fileBrowserData.fileMap ?? {}),
      [data.fileBrowserData.rootFolderId]: {
        ...data.fileBrowserData.fileMap[data.fileBrowserData.rootFolderId],
        parentId: rootFolderId,
        name:
          data.key === ATTACHED_DOCUMENT_KEY
            ? data.fileBrowserData.fileMap[data.fileBrowserData.rootFolderId]?.name
            : `${data.positionTitle} ${data.fileBrowserData.fileMap[data.fileBrowserData.rootFolderId]?.name}`,
      },
    }
    if (data.children) {
      for (const children of data.children) {
        allFolder = {
          ...allFolder,
          ...getDataFiles(children, rootFolderId, rootChildrens),
        }
      }
    }
    if (data.fileBrowserData.fileMap[rootFolderId]) {
      const childrenIds = data.fileBrowserData.fileMap[rootFolderId].childrenIds
      if (childrenIds) {
        for (const id of childrenIds) {
          rootChildrens.push(id)
        }
      }
    } else if (Object.keys(data.fileBrowserData.fileMap).length > 1 || data.key === ATTACHED_DOCUMENT_KEY) {
      rootChildrens.push(data.fileBrowserData.rootFolderId)
    }
  }
  return allFolder
}

export const getGlobalDataFiles = (treeData: InputDataNode[], rootFolderId: string): FileBrowserDataNode => {
  let allFolder = {}
  const rootChildrens: string[] = []
  for (const data of treeData) {
    if (data.fileBrowserData) {
      allFolder = {
        ...allFolder,
        ...getDataFiles(data, rootFolderId, rootChildrens),
      }
    }
  }

  const rootFolder = {
    id: rootFolderId,
    name: 'Tous les dossiers',
    isDir: true,
    childrenIds: rootChildrens,
  }

  return {
    rootFolderId,
    fileMap: {
      ...allFolder,
      [rootFolderId]: rootFolder,
    },
  }
}

const separator = '.'
/**
 *
 * @param preTitle
 * @param title - texte du titre
 * @param withSeparator
 */
export const computeTitle = (preTitle: string, title: string | number, withSeparator = true): string =>
  `${preTitle}${title}${withSeparator ? separator : ''}`
