import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import './NavbarHeader.scss'

const NavbarHeader: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <div className='NavbarHeader'>
      <img
        alt='OPCO 2i'
        src='/logo-512x512.png'
        title={t('nav.home')}
        className='NavbarProfileImage'
        onClick={() => history.push('/')}
      />
    </div>
  )
}

export default NavbarHeader
