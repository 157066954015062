import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import InputWithLabel from 'components/atoms/input/InputWithLabel'
import { capitalize } from 'lodash'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import memberService, { MemberCreateValues } from 'services/memberService'
import { Member } from 'types/Member'
import { User } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { CONTACT_FUNCTIONS } from 'utils/constants/Contacts'
import { createMemberValidationSchema } from 'utils/constants/Member'
import { MEMBER_STATUS } from 'utils/constants/MemberStatus'

interface MemberFormProps {
  users: User[]
  instanceId: number
  refetchMembers: () => void
  toggle: () => void
}

const MemberForm: FC<MemberFormProps> = ({ users, instanceId, refetchMembers, toggle }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, errors } = useForm<Member>({
    mode: 'onSubmit',
    resolver: yupResolver(createMemberValidationSchema),
  })

  const { mutate } = useMutation((data: MemberCreateValues) => memberService.create(data), {
    mutationKey: ['member', 'create', instanceId],
    onSuccess: () => {
      notifySuccess(t('toastify.success.createMandate'))
      toggle()
      refetchMembers()
    },
    onError: () => notifyError(t('toastify.errors.create.member')),
  })

  const submit = (values: MemberCreateValues) => {
    const member: MemberCreateValues = {
      instance: instanceId,
      users_permissions_user: values.users_permissions_user,
      start_date: values.start_date || null,
      end_date: values.end_date || null,
      status: values.status || null,
      function: values.function || null,
    }
    mutate(member)
  }

  return (
    <div>
      {/* TODO Nico recherche */}
      <InputWithLabel
        labelText={t('forms.selectUser')}
        type='select'
        id='users_permissions_user'
        name='users_permissions_user'
        innerRef={register}
        invalid={!!errors.users_permissions_user}
      >
        {users.map(({ id, firstname, lastname }) => (
          <option key={id} value={id}>
            {`${capitalize(firstname)} ${lastname?.toUpperCase()}`}
          </option>
        ))}
      </InputWithLabel>
      <InputWithLabel
        labelText={t('common.fields.mandate.startDate')}
        type='date'
        id='start_date'
        name='start_date'
        innerRef={register}
        invalid={!!errors.start_date}
      />
      <InputWithLabel
        labelText={t('common.fields.mandate.endDate')}
        type='date'
        id='end_date'
        name='end_date'
        innerRef={register}
        invalid={!!errors.end_date}
      />
      <InputWithLabel
        labelText={t('common.fields.mandate.function')}
        type='select'
        id='function'
        name='function'
        innerRef={register}
      >
        {CONTACT_FUNCTIONS.map(({ key, label }) => (
          <option key={key} value={key}>
            {key ? t(label) : t('common.enums.functions.any')}
          </option>
        ))}
      </InputWithLabel>
      <InputWithLabel
        labelText={t('common.fields.mandate.status')}
        type='select'
        id='status'
        name='status'
        innerRef={register}
      >
        <option value={''}>{t('common.enums.memberStatutes.unspecified')}</option>
        {MEMBER_STATUS.map(({ key, label }) => (
          <option key={key} value={key}>
            {label ? t(label) : t('common.enums.memberStatutes.unspecified')}
          </option>
        ))}
      </InputWithLabel>
      <Button label={t('mandates.addMandate.addButton')} onClick={handleSubmit(submit)} />
    </div>
  )
}

export default MemberForm
