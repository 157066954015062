import { createNumberRange } from '../numberUtils'

export const DOCUMENT_FRENCH_DATE_OPTIONS = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
}

export const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

const MINIMUM_DATEPICKER_YEAR = 1990
const MAXIMUM_DATEPICKER_YEAR = 2030

export const YEARS = createNumberRange(MINIMUM_DATEPICKER_YEAR, MAXIMUM_DATEPICKER_YEAR)

export enum CalendarSelectionMode {
  SINGLE_DATE = 'single',
  RANGE_DATE = 'range',
}
