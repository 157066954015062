import { execute } from 'api/api'
import { DocumentType } from 'types/Document'
import { HelpTutorialComponentType, HelpType } from 'types/Help'
import { ApiRoutes } from 'utils/constants/ApiRoutes'
import { HelpTutorialTypeEnum } from 'utils/constants/Help'

const _getHelp = (): Promise<HelpType> => execute<HelpType>(ApiRoutes.HELP.BASE, 'GET')

/**
 * On fetch les tutoriels d'aide puis on regroupe les documents par type: slides ou vidéos
 */
const fetchHelpTutorialsAndGroupByType = async (
  documentField: keyof HelpType,
): Promise<{
  slidesList: DocumentType[]
  videoList: DocumentType[]
}> => {
  const help = await _getHelp()

  const helpDocuments = (help?.[documentField] || []) as HelpTutorialComponentType[]

  return helpDocuments.reduce(
    (acc, cur) => ({
      slidesList: [...acc.slidesList].concat(cur.type === HelpTutorialTypeEnum.SLIDES ? [cur.media] : []),
      videoList: [...acc.videoList].concat(cur.type === HelpTutorialTypeEnum.VIDEO ? [cur.media] : []),
    }),
    { slidesList: [] as DocumentType[], videoList: [] as DocumentType[] },
  )
}

export const helpService = {
  fetchHelpTutorialsAndGroupByType,
}
