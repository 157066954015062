import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Card from 'components/atoms/card/Card'
import Spinner from 'components/atoms/spinner/Spinner'
import Table from 'components/atoms/table/Table'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { CardBody } from 'reactstrap'
import delegateService from 'services/delegateService'
import { userService } from 'services/userService'
import { Delegate, DelegateForManagerType } from 'types/Delegate'
import { User } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { updateDelegateValidationSchema } from 'utils/constants/Delegate'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'

import AddDelegateModal from '../add-mandate/AddDelegateModal'
import RenderDelegate from './RenderDelegate'

interface UserDelegatesTableProps {
  user: User
  canAddMandate: boolean
}

const UserDelegatesTable: FC<UserDelegatesTableProps> = ({ user, canAddMandate }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { register, errors, reset } = useForm<{ data: Delegate[] }>({
    mode: 'onChange',
    resolver: yupResolver(updateDelegateValidationSchema),
  })

  const managerRights = useManagerRights()

  const { isLoading, data: userDelegatesWithManagerRights, refetch } = useQuery<DelegateForManagerType[]>({
    queryKey: ['user', 'getDelegates', user.id],
    queryFn: () => userService.fetchDelegatesWithManagerRightsAndIsCurrent(user.id, managerRights),
    onSuccess: (data) => reset({ data }),
    onError: () => notifyError(t('toastify.errors.get.delegate')),
    cacheTime: 0,
  })

  const { mutate } = useMutation(
    ({ delegateId, data }: { delegateId: number; data: Partial<Delegate> }) =>
      delegateService.updateOne(delegateId, data),
    {
      mutationKey: ['delegate', 'updateOne'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submit = (delegateId: number, data: Partial<Delegate>) => {
    mutate({ delegateId, data })
  }

  const headers = [
    { label: t('common.fields.mandate.meeting') },
    { label: t('common.fields.mandate.startDate') },
    { label: t('common.fields.mandate.endDate') },
  ]

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                {headers.map(({ label }, index) => (
                  <th key={`${label}${index}`}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userDelegatesWithManagerRights && userDelegatesWithManagerRights.length > 0 ? (
                userDelegatesWithManagerRights.map((delegate, index) => (
                  <RenderDelegate
                    current={!!delegate.is_current}
                    canEdit={!!delegate.has_manager_right}
                    delegate={delegate}
                    key={delegate.id}
                    index={index}
                    errors={errors}
                    register={register}
                    submit={submit}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length}>
                    <p className='text-center mb-0'>{t('mandates.noMeetingsFound')} </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {canAddMandate && (
        <Button
          className='mt-3 mx-auto d-block'
          color='primary'
          label={t('mandates.addMandate.delegate.modalButton')}
          onClick={openModal}
        />
      )}
      {modalOpen && <AddDelegateModal user={user} toggle={closeModal} isOpen={modalOpen} refetchDelegates={refetch} />}
    </>
  )
}

export default UserDelegatesTable
