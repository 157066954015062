import Button from 'components/atoms/button/Button'
import { FC, Key, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import delegateService, { DelegatePerimeterType } from 'services/delegateService'
import { DelegateMeetingsWithPerimeterAndRights, DelegateSubmit } from 'types/Delegate'
import { notify, notifyError, notifySuccess } from 'utils/alertUtils'
import { getInstanceMeetingAsDataNodeTypeForManager } from 'utils/instances'

import InstancesTreeInput from './InstancesTreeInput'

// On filtre les associations du périmètre sélectionné
export const buildPerimeterData = (perimeterKeys: Key[]): Key[] => {
  return perimeterKeys.filter((perimeter) => !perimeter.toString().startsWith('asso'))
}

interface DelegatesFormProps {
  data: DelegateMeetingsWithPerimeterAndRights
  userId: number
  refetchDelegates: () => void
  toggle: () => void
}

const DelegateForm: FC<DelegatesFormProps> = ({ data, userId, refetchDelegates, toggle }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, setValue } = useForm<DelegatePerimeterType>({
    mode: 'onSubmit',
  })

  const { mutate } = useMutation((data: Partial<DelegateSubmit>[]) => delegateService.createMany(data), {
    mutationKey: ['delegate', 'createMany'],
    onSuccess: (newDelegateList) => {
      if (newDelegateList.length) {
        notifySuccess(t('toastify.success.createMandate'))
      } else {
        notify(t('toastify.info.noNewMandateCreated'))
      }
      toggle()
      refetchDelegates()
    },
    onError: () => notifyError(t('toastify.errors.create.delegate')),
  })

  const submit = (values: DelegatePerimeterType) => {
    const date = new Date()
    const delegates: Partial<DelegateSubmit>[] = values.perimeter_list.map((meetingId) => ({
      meeting: meetingId,
      users_permissions_user: userId,
      start_date: date.toISOString(),
    }))
    mutate(delegates)
  }

  const [checkedPerimeterList, setCheckedPerimeterList] = useState<Key[]>([])

  const instancesTreeData = getInstanceMeetingAsDataNodeTypeForManager(
    data.allMeetings,
    data.managerRights,
    data.userPerimeter,
  )

  useEffect(() => {
    register('perimeter_list')
    const perimeterData = buildPerimeterData(checkedPerimeterList)
    setValue('perimeter_list', perimeterData)
  }, [register, setValue, checkedPerimeterList])

  const onChangePerimeterList = (checkedPerimeter: Key[]) => setCheckedPerimeterList(checkedPerimeter)

  return (
    <>
      {!!instancesTreeData.length && (
        <div>
          <InstancesTreeInput
            checkedPerimeterList={checkedPerimeterList}
            onChangePerimeterList={onChangePerimeterList}
            instancesTreeData={instancesTreeData}
          />
          <Button label={t('mandates.addMandate.addButton')} onClick={handleSubmit(submit)} />
        </div>
      )}
      {!instancesTreeData.length && (
        <div className='InstancePerimeter'>{t('common.fields.mandate.noMeetingsAddMandates')}</div>
      )}
    </>
  )
}

export default DelegateForm
