import { FC } from 'react'
// Reactstrap
import { Container as LibContainer, ContainerProps } from 'reactstrap'

// Styles
import './Container.scss'

const Container: FC<ContainerProps> = (props) => {
  const className = 'Container' + (props.className ? ' ' + props.className : '')
  return (
    <LibContainer className={className} {...props}>
      {props.children}
    </LibContainer>
  )
}

export default Container
