import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useQuery } from '../../../utils/hooks/useQuery'

import { cguService } from '../../../services/cguService'
import { legalNoticeService } from '../../../services/legalNoticeService'
import Button from '../../atoms/button/Button'
import ReactMarkdown from '../../atoms/markdown/ReactMarkdown'
import Modal from '../../atoms/modal/Modal'
import CguCard from '../cgu/CguCard'

interface ModalLegalNoticeProps {
  isOpen: boolean
  toggle: () => void
}
export const ModalLegalNotice: FC<ModalLegalNoticeProps> = (props) => {
  const { isOpen, toggle } = props
  const { t } = useTranslation()

  const { data } = useQuery({
    queryKey: 'legal-notice',
    queryFn: () => legalNoticeService.getLegalNotice(),
  })

  const { data: cguData } = useQuery({
    queryKey: 'cgu',
    queryFn: () => cguService.getCgu(),
  })

  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('common.legalNotice')}</ModalHeader>
      <ModalBody>
        <ReactMarkdown>{data?.content || t('modal.noLegalNotice')}</ReactMarkdown>
        <h3 className='mt-5'>{t('modal.cgu')} :</h3>
        <CguCard cgu={cguData?.file} />
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={toggle}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalLegalNotice
