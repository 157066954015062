import { BASE_PATH } from 'api/api'
import Figure from 'components/atoms/figure/Figure'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Article } from '../../../types/Article'

import ReactMarkdown from '../../atoms/markdown/ReactMarkdown'
import './ArticleContent.scss'

interface ArticleContentProps {
  article: Article
}
const ArticleContent: FC<ArticleContentProps> = (props) => {
  const { article } = props
  const { t } = useTranslation()
  const { image_link } = article

  let PATH_TO_IMAGE = ''
  if (article.image) {
    PATH_TO_IMAGE = BASE_PATH + article.image.url
  }

  return (
    <article className='ArticleContent'>
      <div className='ArticleContentHeader'>
        {image_link && (
          <Figure ratio='16-9'>
            <img src={image_link} alt={image_link || t('kiosk.article.imageAlt')} />
          </Figure>
        )}
        {article.image?.url && (
          <Figure ratio='16-9'>
            <img src={PATH_TO_IMAGE} alt={image_link || t('kiosk.article.imageAlt')} />
          </Figure>
        )}
        <h1 className='h1'>{article.title}</h1>
        {article.chapeau && <p className='lead'>{article.chapeau}</p>}
      </div>
      <div className='ArticleContentBody'>
        <ReactMarkdown className={article.fromSmartAgence ? 'reactMarkDown container' : 'customArticle'}>
          {article.content}
        </ReactMarkdown>
        <div />
      </div>
    </article>
  )
}

export default ArticleContent
