import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'reactstrap'

import TextInput from '../../atoms/input/TextInput'

interface YearPickerProps {
  yearList: string[]
  onSelectYear: (year: string) => void
}

const YearPicker: FC<YearPickerProps> = (props) => {
  const { yearList, onSelectYear } = props
  const { t } = useTranslation()
  return (
    <div>
      <Label>{t('kiosk.rubrics.filterByYear')}</Label>
      <TextInput id='yearSelect' type='select' onChange={(e) => onSelectYear(e.target.value)} defaultValue=''>
        <option value=''>{t('kiosk.rubrics.yearInputDefault')}</option>
        {yearList.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </TextInput>
    </div>
  )
}

export default YearPicker
