import Button from 'components/atoms/button/Button'
import DropdownItem from 'components/atoms/dropdown/DropdownItem'
import DropdownToggle from 'components/atoms/dropdown/DropdownToggle'
import Icon from 'components/atoms/icon/Icon'
import IconSvg from 'components/atoms/icon/IconSvg'
import Section from 'components/atoms/layout/Section'
import { useStore } from 'effector-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Dropdown, DropdownMenu, Row } from 'reactstrap'
import { loginService } from 'services/loginService'

import { PATHS } from '../../../utils/constants/routes/RoutePaths'
import { usePermissions } from '../../../utils/hooks/usePermissions'

import { UserStore } from '../../../store/UserStore'
import TextInput from '../../atoms/input/TextInput'
import InputGroup from '../../atoms/input/input-group/InputGroup'
import ModalContactInfo from '../modals/ModalContactInfo'
import NotificationContainer from '../notification/NotificationContainer'
import './Topbar.scss'

const Topbar: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const me = useStore(UserStore)
  const { USER_IS_DELEGATE } = usePermissions()

  const [showContactModal, setContactModal] = useState(false)
  const [isTopBarCompact, setIsTopBarCompact] = useState(
    USER_IS_DELEGATE()
      ? history.location.pathname !== PATHS.MEETINGS.MEETING
      : history.location.pathname !== PATHS.DASHBOARD,
  )
  const [searchValue, setSearchValue] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleContactModal = () => setContactModal((state: boolean) => !state)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const onSubmitSearch = () => {
    history.push({
      pathname: PATHS.SEARCH,
      search: `?q=${searchValue}`,
    })
  }

  history.listen(() => {
    setIsTopBarCompact(
      USER_IS_DELEGATE()
        ? history.location.pathname !== PATHS.MEETINGS.MEETING
        : history.location.pathname !== PATHS.DASHBOARD,
    )
  })

  const [isDelegate] = [USER_IS_DELEGATE()]

  return (
    <header className={`Topbar ${isTopBarCompact ? 'compact' : ''}`}>
      <Section bgColor='primary' fluid>
        <Row className='d-none d-lg-flex' style={{ width: '30%' }}>
          <p className='mt-3 baseline'>
            <span>{t('common.WelcomeToYourSpace')}</span>
            <span className='text-orange font-weight-bold'>{` ${t('common.instance2i')}`}</span>
          </p>
        </Row>
        <Row style={{ flexWrap: 'nowrap', width: '44%' }}>
          {!USER_IS_DELEGATE() && (
            <form
              className='search-form'
              onSubmit={(event) => {
                event.preventDefault()
                onSubmitSearch()
              }}
            >
              <InputGroup className='search-input-group'>
                <TextInput
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={t('common.search')}
                  required={true}
                />
                <button className='search-submit'>
                  <Icon name='search' />
                </button>
              </InputGroup>
            </form>
          )}
        </Row>
        <Row className='d-md-grid' style={{ flexWrap: 'nowrap', justifyContent: 'center', width: '16%' }}>
          <Button>
            <a href='https://app.expensya.com/Portal/#/Login' target='_blank' rel='noopener noreferrer'>
              <IconSvg name='IconExpenseReport' size='3' />
            </a>
          </Button>
          <Button onClick={toggleContactModal}>
            <IconSvg name='IconQuestionMark' size='3' />
          </Button>
          {!isDelegate ? <NotificationContainer /> : null}
        </Row>
        <Row style={{ width: '10%' }}>
          {me && (
            <Dropdown label='' isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle color='primary' size='sm' className='m-3' compact={isTopBarCompact} user={me} />
              <DropdownMenu right className='p-0 mt-4'>
                <NavLink to={PATHS.USER_PROFILE} className='TopbarUserInfo pointer-event'>
                  <DropdownItem
                    iconName='profile'
                    text={t(`nav.espacePerso`)}
                    className='d-flex align-items-center px-4 py-3'
                  />
                </NavLink>
                <DropdownItem
                  onClick={() => loginService.logout()}
                  className='d-flex align-items-center text-danger px-4 py-3'
                  iconName='logout'
                  text={t(`nav.logout`)}
                />
              </DropdownMenu>
            </Dropdown>
          )}
        </Row>
      </Section>
      <ModalContactInfo toggle={toggleContactModal} isOpen={showContactModal} />
    </header>
  )
}

export default Topbar
