import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd'
import React, { FC } from 'react'

interface SelectSearchInputProps extends Omit<AntdSelectProps<string | number>, 'value' | 'defaultValue'> {
  className?: string
  mode?: 'multiple' | 'tags'
  value?: string | number
  width?: number
}

const SelectSearchInput: FC<SelectSearchInputProps> = (props) => {
  const { className = '', mode, width = 300, value, children, ...rest } = props

  return (
    <AntdSelect
      mode={mode}
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => {
        try {
          const children: string[] =
            option?.children && Array.isArray(option?.children) ? option?.children : [option?.children]

          return children.some((labelSegment: string) =>
            labelSegment.toLowerCase().includes(input.toLowerCase().trim()),
          )
        } catch (e) {
          return false
        }
      }}
      style={{ width }}
      className={`${value !== undefined ? 'text-capitalize' : ''} ${className}`}
      value={value}
      {...rest}
    >
      {children}
    </AntdSelect>
  )
}

export default SelectSearchInput
