import CustomInput from 'components/atoms/input/CustomInput'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ABSENCE_OPTIONS } from '../../../../utils/constants/AbsenceOption'
import { USER_COLLEGE_ENUM } from '../../../../utils/constants/College'
import { isMandateCurrent } from '../../../../utils/mandateUtils'

import { Invitation } from '../../../../types/Invitation'
import { getMandateStatusLabel } from '../../../../types/MemberStatus'

import UserName from '../../../atoms/custom/UserName'
import Table from '../../../atoms/table/Table'
import ModalInvitationHistory from '../../modals/invitationHistory/ModalInvitationHistory'
import ParticipationCell from './participation/ParticipationCell'

interface ParticipantsTableProps {
  meetingId: string
  invitationsQueryKey: (string | number)[]
  invitations: Invitation[]
  filteredInvitations: Invitation[]
  selectedInvitations: string[]
  onAllCheckboxClick: (event: ChangeEvent<HTMLInputElement>) => void
  onInvitationCheckboxClick: (event: ChangeEvent<HTMLInputElement>) => void
  absence_option: string | null
  selectedInvitationId?: number
  setSelectedInvitationId: (id: number) => void
  historyQueryKey: (string | number | undefined)[]
  disabled: boolean
}

const ParticipantsTable: FC<ParticipantsTableProps> = (props) => {
  const {
    meetingId,
    invitationsQueryKey,
    invitations,
    filteredInvitations,
    selectedInvitations,
    onAllCheckboxClick,
    onInvitationCheckboxClick,
    absence_option,
    selectedInvitationId,
    setSelectedInvitationId,
    historyQueryKey,
    disabled,
  } = props
  const { t } = useTranslation()

  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false)

  const isAllChecked = !filteredInvitations.some(
    (invitation) => !selectedInvitations.includes(invitation.id.toString()),
  )

  const onRowClick = (id: number) => {
    setSelectedInvitationId(id)
    toggleHistoryModal()
  }

  const toggleHistoryModal = () => setHistoryModalOpen((state: boolean) => !state)

  return (
    <>
      {filteredInvitations.length === 0 && (
        <div>
          <p className='text-center'>{t('meeting.invitations.table.noEntry')}</p>
        </div>
      )}
      {filteredInvitations.length > 0 && (
        <>
          <Table>
            <thead>
              <tr>
                <th className='align-middle'>
                  <CustomInput id='all' type='checkbox' checked={isAllChecked} onChange={onAllCheckboxClick} />
                </th>
                <th className='align-middle'>{t('meeting.invitations.table.name')}</th>
                <th className='align-middle'>{t('meeting.invitations.table.status')}</th>
                <th className='align-middle'>{t('meeting.invitations.table.college')}</th>
                <th className='align-middle'>{t('meeting.invitations.table.participations')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvitations.map((invitation) => {
                const userMandate = invitation.user.members.find(
                  (member) =>
                    isMandateCurrent(member.start_date, member.end_date) &&
                    member.instance === invitation.meeting.instance,
                )

                // On passe par une fonction car il faut récupérer le bon label
                //  si la value est null ou undefined (pas possible via un enum)
                const statusLabel = getMandateStatusLabel(userMandate?.status)
                const userCollege = invitation.user.college
                const collegeLabel = userCollege ? USER_COLLEGE_ENUM[userCollege].label : ''
                const instanceId = (invitation.meeting.instance as unknown) as number

                return (
                  <>
                    <tr
                      key={`row-${invitation.id}`}
                      className='cursor-pointer'
                      onClick={() => onRowClick(invitation.id)}
                    >
                      <td>
                        <div className='cursor-pointer'>
                          <CustomInput
                            id={invitation.id}
                            type='checkbox'
                            checked={selectedInvitations.includes(invitation.id.toString())}
                            onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                            onChange={onInvitationCheckboxClick}
                          />
                        </div>
                      </td>
                      <td>
                        <UserName user={invitation.user} />
                      </td>
                      <td width={150}>{t(statusLabel)}</td>
                      <td width={150}>{t(collegeLabel)}</td>
                      <td>
                        <ParticipationCell
                          id={invitation.id.toString()}
                          meetingId={meetingId}
                          userMandate={userMandate}
                          invitation={invitation}
                          instanceId={instanceId}
                          absence_option={absence_option}
                          invitationsQueryKey={invitationsQueryKey}
                          historyQueryKey={historyQueryKey}
                          disabled={disabled}
                        />
                      </td>
                    </tr>
                    {invitations.map((invit) => {
                      const absenceChildrenLabel =
                        invit.absence_option && ABSENCE_OPTIONS[invit.absence_option] === ABSENCE_OPTIONS.POWER
                          ? 'meeting.invitations.table.powerFrom'
                          : invit.absence_option &&
                            ABSENCE_OPTIONS[invit.absence_option] === ABSENCE_OPTIONS.REPLACEMENT
                          ? 'meeting.invitations.table.replacementFrom'
                          : undefined

                      return (
                        invit.absence_children?.id === invitation.id &&
                        absenceChildrenLabel && (
                          <tr>
                            <td className='border-top-0' />
                            <td className='border-top-0 pt-0'>
                              <p className='m-0'>
                                {t(absenceChildrenLabel, {
                                  user: `${invit.user.firstname} ${invit.user.lastname}`,
                                })}
                              </p>
                            </td>
                          </tr>
                        )
                      )
                    })}
                  </>
                )
              })}
            </tbody>
          </Table>
          <ModalInvitationHistory
            isOpen={historyModalOpen}
            invitationId={selectedInvitationId}
            toggle={toggleHistoryModal}
            queryKey={historyQueryKey}
          />
        </>
      )}
    </>
  )
}

export default ParticipantsTable
