import { execute } from 'api/api'
import { MemberCollegeEnum } from 'types/Member'
import { OrganisationGroup, OrganisationPatronal, OrganisationSalarie } from 'types/Organisation'
import { ApiRoutes } from 'utils/constants/ApiRoutes'

const getOrganisationPatronal = (): Promise<OrganisationPatronal[]> => {
  return execute<OrganisationPatronal[]>(ApiRoutes.ORGANISATION.PATRONAL, 'GET')
}

const getOrganisationSalarie = (): Promise<OrganisationSalarie[]> => {
  return execute<OrganisationSalarie[]>(ApiRoutes.ORGANISATION.SALARIE, 'GET')
}

const getGroupOrganisationWithFilter = (filters: MemberCollegeEnum): Promise<OrganisationGroup[]> => {
  return execute<OrganisationGroup[]>(`${ApiRoutes.ORGANISATION.GROUP}?_sort=label:asc&college_type=${filters}`, 'GET')
}

export const organisationService = {
  getOrganisationPatronal,
  getOrganisationSalarie,
  getGroupOrganisationWithFilter,
}
