import Section from 'components/atoms/layout/Section'
import DownloadMultipleDocumentLink from 'components/molecules/documents/DownloadMultipleDocumentLink'
import { useStore } from 'effector-react'
import _ from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { BreadcrumbItem, Col } from 'reactstrap'

import { notifyError } from '../utils/alertUtils'
import { CalendarSelectionMode } from '../utils/constants/Date'
import { initialFilter } from '../utils/constants/Document'
import { SEARCH_DEBOUNCE_DELAY } from '../utils/constants/Filters'
import { PERMISSIONS } from '../utils/constants/Permissions'
import { useInfiniteScroll } from '../utils/hooks/useInfiniteScroll'
import { useQuery } from '../utils/hooks/useQuery'
import { filterInstances, getInstancesAsTreeDataType, getInstancesIdFromTreeDataType } from '../utils/instances'

import { DocumentType } from '../types/Document'
import { DocumentFilterType } from '../types/Filters'
import { User } from '../types/User'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import Checkbox from '../components/atoms/checkbox/Checkbox'
import InputWithLabel from '../components/atoms/input/InputWithLabel'
import Row from '../components/atoms/layout/Row'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import { TreeDataType } from '../components/atoms/tree/TreeSelect'
import ButtonDisplayType from '../components/molecules/buttons/ButtonDisplayType'
import BasicDatePicker from '../components/molecules/datepicker/BasicDatePicker'
import DocumentCardList from '../components/molecules/documents/DocumentCardList'
import DocumentLoader from '../components/molecules/documents/DocumentLoader'
import DocumentTable from '../components/molecules/documents/DocumentTable'
import DocumentViewer from '../components/molecules/documents/DocumentViewer'
import InstanceFilter from '../components/molecules/filters/InstanceFilter'
import Header from '../components/molecules/heading/Header'
import Reducer from '../components/molecules/reducer/Reducer'

import { documentService } from '../services/documentService'
import { instanceService } from '../services/instanceService'
import { userService } from '../services/userService'
import { UserStore } from '../store/UserStore'

const viewBodyId = 'documentList'
const DocumentsView: FC = () => {
  const { t } = useTranslation()
  const user = useStore(UserStore) || undefined
  const [selectedFile, setSelectedFile] = useState<DocumentType>()
  const [typeDisplay, setTypeDisplay] = useState<boolean>(true)
  const [documentFilters, setDocumentFilters] = useState<DocumentFilterType>(initialFilter)
  const [loadMore, setLoadMore] = useState<boolean>(false)
  const [checkedFileList, setCheckedFileList] = useState<DocumentType[]>([])

  const { isLoading: areMyInstanceLoading, data: instanceList } = useQuery<TreeDataType[]>({
    queryKey: ['instance', 'getMine'],
    queryFn: () => fetchUserInstances(),
    onError: () => notifyError(t('toastify.errors.get.instance')),
  })
  const { mutate } = useMutation((data: User) => userService.updateMe(data), {
    mutationKey: ['user', 'me', 'update', 'favorite'],
    onError: () => notifyError(t('toastify.errors.favorite')),
  })
  const { isLoading: areDocumentsLoading, data: documentList = [] } = useQuery<DocumentType[]>({
    queryKey: ['document', 'getMine', documentFilters, instanceList],
    queryFn: async (): Promise<DocumentType[]> => {
      if (!instanceList) {
        return []
      }
      const freshDocumentList = await documentService.getMyDocuments(
        documentFilters,
        getInstancesIdFromTreeDataType(instanceList ?? []),
        user,
      )
      setLoadMore(freshDocumentList.loadMore || false)
      return documentFilters.page === 1 ? freshDocumentList.documents : documentList.concat(freshDocumentList.documents)
    },
    onError: () => notifyError(t('toastify.errors.get.document')),
    cacheTime: 0,
    keepPreviousData: loadMore,
  })

  const { data } = useQuery<User>({
    queryKey: ['user', 'getMe', 'fav'],
    queryFn: () => userService.getMe(),
    onError: () => notifyError(t('toastify.errors.unknown')),
  })

  const changeFavoriteElement = (isFavorite: boolean, id: number): void => {
    if (data) {
      if (isFavorite) {
        const indexOf = data.favorite.indexOf(id)
        data.favorite.splice(indexOf, 1)
      } else {
        data.favorite = [...(data.favorite || []), id]
      }
      mutate(data)
    }
  }

  const [isAllChecked, setIsAllChecked] = useState(
    documentList.length === checkedFileList.length && documentList.length > 0,
  )

  const onSearchInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onFullTextChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )

  const onInstanceChange = (selectedInstances: string[]) => {
    setDocumentFilters((prevState) => ({
      ...prevState,
      instanceList: selectedInstances,
      page: 1,
    }))
  }
  const onDateChange = (mode: CalendarSelectionMode, startDate?: Date, endDate?: Date) => {
    if (
      (!startDate && !endDate) ||
      (CalendarSelectionMode.SINGLE_DATE === mode && startDate) ||
      (CalendarSelectionMode.RANGE_DATE === mode && startDate && endDate)
    ) {
      setDocumentFilters((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate || undefined,
        page: 1,
      }))
    }
  }
  const onFullTextChange = (fullText: string) => {
    setDocumentFilters((prevState) => ({
      ...prevState,
      search: fullText,
      page: 1,
    }))
  }
  const fetchUserInstances = async () => {
    return instanceService
      .getMyInstancesForPermission(PERMISSIONS.READ_DOC_ORDER_OF_THE_DAY)
      .then((serverInstances) => getInstancesAsTreeDataType(serverInstances))
  }
  const loadMoreHandle = () => {
    !areDocumentsLoading &&
      loadMore &&
      setDocumentFilters((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        }
      })
  }
  useInfiniteScroll(viewBodyId, [documentList], () => loadMoreHandle())

  const onFileChange = (file?: DocumentType) => {
    setSelectedFile(file)
  }

  const onCheckDocument = (file: DocumentType) => {
    const checked = !checkedFileList.includes(file)
    setCheckedFileList((prevState) =>
      checked ? [...prevState, file] : prevState.filter((prevFile) => prevFile.id !== file.id),
    )
  }

  const onAllHandleCheck = () => {
    isAllChecked ? setCheckedFileList([]) : setCheckedFileList(documentList)
    setIsAllChecked((prevState) => !prevState)
  }
  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>{t('nav.myDocuments')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
          <Reducer label={t('meeting.filters.filter')}>
            <Row grid>
              <Col xs='12' md='3'>
                <InstanceFilter
                  defaultExpandAll
                  label={t('contacts.filters.instance.label')}
                  placeholder={t('contacts.filters.instance.disabled')}
                  filterTreeNode={filterInstances}
                  data={instanceList || []}
                  value={documentFilters.instanceList}
                  onChange={onInstanceChange}
                />
              </Col>
              <Col xs='12' md='3'>
                <BasicDatePicker
                  inputLabelText={t('documents.filters.date.label')}
                  inputName='documents-filters-date'
                  updateDateFilters={onDateChange}
                />
              </Col>
              <Col xs='12' md='3'>
                <InputWithLabel
                  name='document-filter-search'
                  type='text'
                  placeholder={t('documents.filters.search.placeholder')}
                  labelText={t('documents.filters.search.label')}
                  onChange={onSearchInputChange}
                />
              </Col>
              <Col>
                <ButtonDisplayType typeDisplay={typeDisplay} setTypeDisplay={setTypeDisplay} />
              </Col>
            </Row>
          </Reducer>
          <DownloadMultipleDocumentLink
            documentList={checkedFileList}
            className={`my-auto mr-0 ml-auto d-block ${
              checkedFileList && checkedFileList.length > 0 ? 'visible' : 'invisible'
            }`}
          />
        </Section>
      </ViewHead>
      <ViewBody id={viewBodyId}>
        <Section fluid className={areDocumentsLoading || areMyInstanceLoading ? 'h-100' : ''}>
          {documentList && documentList.length > 0 ? (
            <>
              <div onClick={onAllHandleCheck} className='cursor-pointer'>
                <Checkbox checked={isAllChecked} className='cursor-pointer' />
                <p className='ml-5'>{isAllChecked ? t('common.unselectAll') : t('common.selectAll')}</p>
              </div>
              {data && typeDisplay ? (
                <DocumentTable
                  fileList={documentList}
                  onFileChange={onFileChange}
                  checkedFileList={checkedFileList}
                  onCheckDocument={onCheckDocument}
                  showChecked={true}
                  favoriteIds={data.favorite || []}
                  changeFavorite={changeFavoriteElement}
                />
              ) : (
                <DocumentCardList
                  fileList={documentList}
                  onFileChange={onFileChange}
                  checkedFileList={checkedFileList}
                  onCheckDocument={onCheckDocument}
                  showChecked={true}
                  favoriteIds={data?.favorite ?? []}
                  changeFavorite={changeFavoriteElement}
                />
              )}
              {loadMore && (
                <Button
                  className='d-block mx-auto mt-3'
                  onClick={loadMoreHandle}
                  label={t('documents.filters.seeMoreButton.label')}
                />
              )}
              {selectedFile && (
                <DocumentViewer file={selectedFile} isOpen={Boolean(selectedFile)} onClose={() => onFileChange()} />
              )}
            </>
          ) : areDocumentsLoading || areMyInstanceLoading ? (
            <DocumentLoader />
          ) : (
            <h3 className='mt-3 text-center'>{t('common.noResult')}</h3>
          )}
        </Section>
      </ViewBody>
    </View>
  )
}

export default DocumentsView
