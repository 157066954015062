import React, { FunctionComponent } from 'react'

import './Icon.scss'

type IconProps = {
  className?: string
  name:
    | 'dashboard'
    | 'logout'
    | 'left-open'
    | 'profile'
    | 'meetings'
    | 'contacts'
    | 'help'
    | 'cancel'
    | 'mail'
    | 'right-open'
    | 'down-open'
    | 'up-open'
    | 'star'
    | 'download'
    | 'menu'
    | 'documents'
    | 'euro'
    | 'newspaper'
    | 'edit'
    | 'camera-1'
    | 'nav_kiosk'
    | string
  [key: string]: unknown
}

const Icon: FunctionComponent<IconProps> = ({ className = '', name, ...rest }) => {
  const IconClassName = (name ? ' icon-' + name : '') + ` ${className}`
  return <span className={`Icon ${IconClassName}`} {...rest} />
}

export default Icon
