// The "icons" are the fontello classname which renders the icon.
import { NavbarPropsInterface } from '../../components/molecules/navbars/navbar-item/NavbarItem'

import { PATHS } from './routes/RoutePaths'

interface NavbarPropsWithRightInterface {
  interface: NavbarPropsInterface
  needRight: boolean
  needNoDelegate: boolean
}

export const NAVBAR_TOP_ITEMS_LIST: NavbarPropsWithRightInterface[] = [
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'dashboard',
      i18nKey: 'dashboard',
      icon: 'dashboard',
      path: PATHS.DASHBOARD,
    },
  },
  {
    needNoDelegate: false,
    needRight: false,
    interface: {
      key: 'myMeetings',
      i18nKey: 'myMeetings',
      icon: 'meetings',
      path: PATHS.MEETINGS.MEETING,
    },
  },
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'myDocuments',
      i18nKey: 'myDocuments',
      icon: 'documents',
      path: PATHS.DOCUMENTS,
    },
  },
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'favorites',
      i18nKey: 'favorites',
      icon: 'star',
      path: PATHS.FAVORITES.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'kiosk',
      i18nKey: 'kiosk',
      icon: 'nav_kiosk',
      path: PATHS.KIOSK.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'news',
      i18nKey: 'news',
      icon: 'newspaper',
      path: PATHS.NEWS.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: false,
    interface: {
      key: 'contacts',
      i18nKey: 'contacts',
      icon: 'contacts',
      path: PATHS.CONTACTS.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: true,
    interface: {
      key: 'userManagement',
      i18nKey: 'userManagement',
      icon: 'users',
      path: PATHS.USER_MANAGEMENT.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: true,
    interface: {
      key: 'mailHistory',
      i18nKey: 'mailHistory',
      icon: 'mail',
      path: PATHS.MAIL_HISTORY.HOME,
    },
  },
  {
    needNoDelegate: true,
    needRight: true,
    interface: {
      key: 'forgotPassword',
      i18nKey: 'forgotPassword',
      icon: 'mail',
      path: PATHS.MAIL_HISTORY_FORGOT_PWD.HOME,
    },
  },
  {
    needNoDelegate: false,
    needRight: false,
    interface: {
      key: 'help',
      i18nKey: 'help',
      icon: 'help',
      path: PATHS.HELP.HOME,
    },
  },
]

export const NAVBAR_BOTTOM_ITEMS_LIST: NavbarPropsInterface[] = [
  // Masqué pour le moment
  // {
  //   key: 'refund',
  //   i18nKey: 'refund',
  //   icon: 'euro',
  //   path: 'https://app.expensya.com/Portal/#/Login',
  //   type: 'externalLink',
  // },
]
