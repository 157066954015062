import * as Yup from 'yup'

import { postalCodeRegex } from './RegexConstants'

export const addressValidationSchema = Yup.object().shape({
  postal_code: Yup.string().matches(postalCodeRegex, {
    message: 'common.validation.postalCode',
    excludeEmptyString: true,
  }),
})
