import React, { FC } from 'react'

import { DocumentType } from '../../../types/Document'

import DocumentCard from './DocumentCard'

interface DocumentCardListProps {
  fileList: DocumentType[]
  onFileChange: (file?: DocumentType) => void
  checkedFileList: DocumentType[]
  onCheckDocument: (file: DocumentType) => void
  showChecked: boolean
  favoriteIds: number[]
  changeFavorite: (isFavorite: boolean, id: number) => void
}

const DocumentCardList: FC<DocumentCardListProps> = ({
  fileList,
  onFileChange,
  checkedFileList,
  onCheckDocument,
  showChecked = true,
  favoriteIds,
  changeFavorite,
}: DocumentCardListProps): JSX.Element => {
  return (
    <div>
      {fileList.map((file: DocumentType) => (
        <DocumentCard
          key={file.id}
          file={file}
          openPreviewDocument={onFileChange}
          onCheckDocument={onCheckDocument}
          isChecked={checkedFileList.includes(file)}
          showChecked={showChecked}
          showFavorite={true}
          favoriteIds={favoriteIds}
          changeFavorite={changeFavorite}
        />
      ))}
    </div>
  )
}

export default DocumentCardList
