import { InputBaseDataNode } from '../utils/treeUtils'

import { Address } from './Address'
import { DocumentType } from './Document'
import { Email } from './Email'
import { Instance } from './Instance'
import { Invitation } from './Invitation'

type DecisionsRecordComponentType = {
  id: number
  documents: DocumentType[]
}

export type DecisionsRecordType = {
  decisions_record: DecisionsRecordComponentType | undefined
  meetingDate: string
}

export enum MeetingType {
  PLENIERE = 'PLENIERE',
  PREPARATOIRE = 'PREPARATOIRE',
}

export enum MeetingStatus {
  AVAILABLE = 'AVAILABLE',
  CANCELED = 'CANCELED',
}

type MeetingBaseType = {
  start_date: string
  start_time: string
  end_time: string
  online_meeting_url: string
  type: MeetingType
  meeting_address: Address
  order_of_the_day: InputBaseDataNode[]
  invitations?: Invitation[]
  documents: DocumentType[]
  convocation_file?: DocumentType
  email_template?: Email
  decisions_record?: DecisionsRecordComponentType
  status: MeetingStatus
}

export type MeetingUpdate = {
  start_time?: string
  end_time?: string
  email_template?: Email
  order_of_the_day?: InputBaseDataNode[]
  documents?: DocumentType[]
  status?: MeetingStatus
}

export type Meeting = MeetingBaseType & {
  id: number
  instance: Instance
}
export type MeetingPreview = Pick<
  Meeting,
  | 'id'
  | 'start_date'
  | 'end_time'
  | 'instance'
  | 'start_time'
  | 'online_meeting_url'
  | 'type'
  | 'meeting_address'
  | 'invitations'
  | 'status'
  | 'decisions_record'
>

export type MeetingFormValues = Omit<MeetingBaseType, 'type'> & {
  instance: number
  type: MeetingBaseType['type'] | ''
}

export type MeetingDropdownType = {
  label: string
}

export type MeetingDashboard = Omit<MeetingPreview, 'start_date'> & {
  start_date: Date
}

export type MeetingListType = {
  meetings: MeetingPreview[]
  loadMore: boolean
}
