import { FC } from 'react'

import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ForgotPasswordFormSection from '../components/molecules/auth/forgotPassword/ForgotPasswordFormSection'
import Cover from '../components/molecules/auth/login/Cover'
import '../components/molecules/auth/login/Login.scss'
import NewLoginHeader from '../components/molecules/auth/login/NewLoginHeader'

const ForgotPasswordView: FC = () => {
  return (
    <View>
      <NewLoginHeader />
      <Cover />
      <ViewBody className='LoginBody'>
        <ForgotPasswordFormSection />
      </ViewBody>
    </View>
  )
}

export default ForgotPasswordView
