import React, { FC, ReactText, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { v4 } from 'uuid'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { ATTACHED_DOCUMENT_KEY, ATTACHED_DOCUMENT_ROOT_INDEX_KEY } from '../../../../utils/constants/ChonkyFileBrowser'
import {
  addNodeFromTree,
  computeLevel,
  deleteNodeWithKeyFromTree,
  getGlobalDataFiles,
  InputDataNode,
  updateNode,
} from '../../../../utils/treeUtils'

import { DocumentType, DocumentTypeEnum } from '../../../../types/Document'
import { Meeting } from '../../../../types/Meeting'

import { documentService } from '../../../../services/documentService'
import { meetingService } from '../../../../services/meetingService'
import Button from '../../../atoms/button/Button'
import Icon from '../../../atoms/icon/Icon'
import IconSvg from '../../../atoms/icon/IconSvg'
import Row from '../../../atoms/layout/Row'
import DraggableTree from '../../../atoms/tree/DraggableTree'
import { FileBrowser } from '../../file-browser/FileBrowser'
import FileSelectionModal from '../modal/FileSelectionModal'
import OrderOfTheDayManagerTreeItem from './OrderOfTheDayManagerTreeItem'

interface OrderOfTheDayProps {
  meeting: Meeting
  fileList: DocumentType[]
  treeData: InputDataNode[]
  uploadNewFilesHandler?: (newFileList: File[]) => Promise<DocumentType[]>
  onTreeDataChange: (treeDate: InputDataNode[]) => void
  onFileListChange: (newFileList: DocumentType[]) => void
  onEditClick: (value: boolean) => void
  openPreviewDocument?: (file: DocumentType) => void
}

/**
 * Composant ordre du jour d'un point de vue manager
 * Toute la logique d'edition de l'arbre est içi
 */
const OrderOfTheDayManager: FC<OrderOfTheDayProps> = (props) => {
  const {
    meeting,
    fileList,
    treeData = [],
    onTreeDataChange,
    onFileListChange,
    onEditClick,
    openPreviewDocument,
  } = props

  const getDefaultCurrentDirectoryByTree = () => {
    const defaultCurrentDir: { [key: string]: string } = {}
    for (const data of treeData) {
      if (data.fileBrowserData?.rootFolderId) {
        if (data.key === ATTACHED_DOCUMENT_KEY) {
          defaultCurrentDir[data.key] = `meeting-${meeting.id}`
        } else {
          defaultCurrentDir[data.key] = data.fileBrowserData.rootFolderId
        }
      }
    }
    return defaultCurrentDir
  }

  const flatMapToChild = ({ key, children = [] }: InputDataNode): (string | number)[] => [
    key,
    ...children.flatMap((data) => flatMapToChild(data)),
  ]

  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalCurrentTreeItem, setModalCurrentTreeItem] = useState<InputDataNode>()
  const [expandedKeys, setExpandedKeys] = useState<ReactText[]>(treeData.flatMap((data) => flatMapToChild(data)))
  const [currentTreeKey, setCurrentTreeKey] = useState<string | number>()
  const [currentAttachedDirectory, setCurrentAttachedDirectory] = useState<string>()
  const [currentDirectoryByTree, setCurrentDirectoryByTree] = useState<{ [key: string]: string }>(
    getDefaultCurrentDirectoryByTree(),
  )

  useEffect(() => {
    if (modalCurrentTreeItem) {
      setCurrentTreeKey(modalCurrentTreeItem.key)
    }
  }, [modalCurrentTreeItem])

  const { t } = useTranslation()
  const queryClient = useQueryClient()
  // update le meeting
  const { isLoading, mutate } = useMutation(
    () => meetingService.update(meeting.id, { order_of_the_day: treeData, documents: fileList }),
    {
      mutationKey: ['meeting', 'update', meeting.id],
      onSuccess: () => {
        deleteFile(meeting, treeData)
        queryClient.invalidateQueries(`meeting getOne ${meeting.id}`).then(() => {
          onEditClick(false)
          notifySuccess(t('meeting.orderOfTheDayUpdated'))
        })
      },
      onError: () => notifyError(t('meeting.errorUpdatingOrderOfTheDay')),
    },
  )

  const myMeetingEdit = { order_of_the_day: treeData, documents: fileList }

  const deleteFileRec = (data: InputDataNode) => {
    let treeDataFileId: string[] = []
    if (data.fileBrowserData?.rootFolderId && data.fileBrowserData?.fileMap[data.fileBrowserData.rootFolderId]) {
      const dataChildrenIds = data.fileBrowserData?.fileMap[data.fileBrowserData.rootFolderId].childrenIds
      if (dataChildrenIds !== undefined) {
        treeDataFileId = [...treeDataFileId, ...dataChildrenIds]
      }
    }
    if (data?.children) {
      for (const child of data?.children) {
        const result = deleteFileRec(child)
        if (result) {
          treeDataFileId = [...treeDataFileId, ...result]
        }
      }
    }
    return treeDataFileId
  }

  const deleteFile = (meeting: Meeting, treeData: InputDataNode[]) => {
    let treeDataFileId: string[] = []
    for (const data of treeData) {
      const dataFiles = deleteFileRec(data)
      if (dataFiles) {
        treeDataFileId = [...treeDataFileId, ...dataFiles]
      }
    }

    let orderOfTheDayFileId: string[] = []
    if (meeting.order_of_the_day) {
      for (const data of meeting.order_of_the_day) {
        const orderFiles = deleteFileRec(data)
        if (orderFiles) {
          orderOfTheDayFileId = [...orderOfTheDayFileId, ...orderFiles]
        }
      }

      if (treeDataFileId != orderOfTheDayFileId) {
        const documentsDiff = orderOfTheDayFileId.filter((val) => !treeDataFileId.includes(val))
        for (const doc of documentsDiff) {
          meetingService.deleteDocument(String(meeting.id), Number(doc))
        }
      }
    }
  }

  const uploadNewFiles = (newFileList: File[]) => {
    return documentService
      .uploadDocuments(newFileList, 'meeting', meeting?.id || 0, 'documents', DocumentTypeEnum.MEETING)
      .then((uploadedFileList) => {
        // On ajoute les fichiers uploadés dans la liste des documents
        if (uploadedFileList && Array.isArray(uploadedFileList)) {
          onFileListChange([...fileList, ...uploadedFileList])
          return uploadedFileList
        }
        return []
      })
  }

  const onAdd = (parent?: InputDataNode) => {
    const keyId = v4()
    const rootDirId = v4()
    const elt: InputDataNode = {
      title: '',
      key: keyId,
      level: computeLevel(parent?.level || '0', 1),
      positionTitle: parent?.positionTitle || '',
      children: [],
      fileBrowserData: {
        rootFolderId: rootDirId,
        fileMap: {
          [rootDirId]: {
            id: rootDirId,
            name: ' ',
            isDir: true,
            childrenIds: [],
            childrenCount: 0,
          },
        },
      },
    }
    setCurrentDirectoryByTree({
      ...currentDirectoryByTree,
      [keyId]: rootDirId,
    })
    if (parent?.key) {
      setExpandedKeys([...expandedKeys, parent?.key])
    }
    onTreeDataChange(addNodeFromTree(elt, treeData, parent?.key || ''))
  }

  const onChange = (node: InputDataNode) => {
    onTreeDataChange(updateNode(node, treeData))
  }

  const onDelete = (key: ReactText) => {
    onTreeDataChange(deleteNodeWithKeyFromTree(key, treeData))
  }
  const onExpand = (expandedKeys: ReactText[]) => {
    setExpandedKeys(expandedKeys)
  }
  const onKeyExpand = (expandedKey: ReactText) => {
    setExpandedKeys((prevState) =>
      prevState.includes(expandedKey) ? prevState.filter((key) => key !== expandedKey) : [...prevState, expandedKey],
    )
  }

  const toggleModal = (treeItem?: InputDataNode) => {
    setModalCurrentTreeItem(treeItem)
    setShowModal(!showModal)
  }

  const onSave = () => {
    if (!isLoading) mutate()
  }

  const getCurrentDirectory = (): string | undefined => {
    if (currentTreeKey && currentDirectoryByTree) {
      if (currentDirectoryByTree[currentTreeKey]) {
        return currentDirectoryByTree[currentTreeKey]
      }
      for (const treeItem of treeData) {
        const currentItem = getCurrentDirectoryRec(treeItem)
        if (currentItem) {
          return currentItem
        }
      }
    }
    return undefined
  }

  const getCurrentDirectoryRec = (data: InputDataNode): string | undefined => {
    if (data.key === currentTreeKey) {
      return data.fileBrowserData?.rootFolderId
    }
    if (data?.children) {
      for (const child of data?.children) {
        const result = getCurrentDirectoryRec(child)
        if (result) {
          return result
        }
      }
    }
    return undefined
  }

  return (
    <>
      <div>
        <DraggableTree
          TreeItemComponent={(inputDataNode: InputDataNode) => (
            <OrderOfTheDayManagerTreeItem
              updatedMeeting={myMeetingEdit}
              meetingId={meeting.id}
              inputDataNode={inputDataNode}
              onAdd={onAdd}
              onDelete={onDelete}
              rootFileKeyList={fileList}
              toggleModal={toggleModal}
              onItemChange={onChange}
              openPreviewDocument={openPreviewDocument}
              onKeyExpand={onKeyExpand}
              setCurrentDirectoryByTree={setCurrentDirectoryByTree}
              currentDirectoryByTree={currentDirectoryByTree}
            />
          )}
          treeData={treeData}
          onTreeDataChange={onTreeDataChange}
          expandedKeys={expandedKeys}
          onExpand={onExpand}
        />
        <p onClick={() => onAdd()}>
          <IconSvg name='Plus' size={'3'} className='Picto d-block mx-auto' />
        </p>
        <FileSelectionModal
          currentTreeItem={modalCurrentTreeItem}
          show={showModal}
          toggle={toggleModal}
          uploadNewFilesHandler={uploadNewFiles}
          currentDirectory={getCurrentDirectory()}
          // Si on a un current Tree Item on est sur un point de l'ordre du jour donc les documents sont checkable
          // Sinon, on est sur 'Tous les documents de la réunion' et les documents ne sont pas checkable
          documentCheckable={!!modalCurrentTreeItem}
          title={t(`meeting.documents.modal.${modalCurrentTreeItem ? 'orderOfTheDay' : 'allDocuments'}`)}
          onChange={onChange}
        />
        <Button className='mt-4 mx-auto d-block' label={t('common.save')} onClick={onSave} />
      </div>
      <div className='mt-5'>
        <Row className='justify-content-between'>
          <h3>{t('meeting.documents.title')}</h3>
          {currentAttachedDirectory !== ATTACHED_DOCUMENT_ROOT_INDEX_KEY ? (
            <div />
          ) : (
            <Button
              className='add-file-button mb-3'
              title={t('meeting.documents.manage')}
              outline
              color='primary'
              onClick={() => toggleModal(treeData.find((data) => data.key === ATTACHED_DOCUMENT_KEY))}
            >
              <Icon name='documents' />
            </Button>
          )}
        </Row>
        <FileBrowser
          meetingId={meeting.id}
          updatedMeeting={myMeetingEdit}
          newFileMap={(fileMap) => {
            const modifiedItem = treeData.find(
              (data) => data.fileBrowserData?.rootFolderId === ATTACHED_DOCUMENT_ROOT_INDEX_KEY,
            )
            if (modifiedItem?.fileBrowserData) {
              onChange({
                ...modifiedItem,
                fileBrowserData: {
                  ...modifiedItem.fileBrowserData,
                  fileMap,
                },
              })
            }
          }}
          getCurrentFolderId={(currentFolder) => {
            if (currentDirectoryByTree && setCurrentDirectoryByTree) {
              const currentItem = treeData.find((data) =>
                Object.values(data.fileBrowserData?.fileMap ?? []).some((file) => file.id === currentFolder),
              )
              setCurrentAttachedDirectory(currentItem?.fileBrowserData?.rootFolderId)
              setCurrentDirectoryByTree({
                ...currentDirectoryByTree,
                [ATTACHED_DOCUMENT_KEY]: currentFolder,
              })
            }
          }}
          dataFiles={getGlobalDataFiles(treeData, `meeting-${meeting.id}`)}
          instanceId={`meeting-${meeting.id}`}
          rootFolderId={currentAttachedDirectory}
          isAttachedDocument={true}
        />
      </div>
    </>
  )
}

export default OrderOfTheDayManager
