import { useLocation } from 'react-router-dom'

/*
 * Custom hook permettant de récupérer les query parameters de l'URL.
 * Ils sont accessibles par la suite via la fonction 'get', en donnant la clé de la valeur à récupérer.
 */

const useReactRouterQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search)
}

export default useReactRouterQuery
