import qs from 'qs'
import { generatePath } from 'react-router'
import { Collaborator } from 'types/Collaborator'
import { Delegate, DelegateForManagerType } from 'types/Delegate'
import { Member, MemberForManagerType } from 'types/Member'

import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { UserCollegeType } from '../utils/constants/College'
import { convertDateToStringFromFormat, DATABASE_DATE_FORMAT } from '../utils/dateUtils'
import { setQueryFilterParams } from '../utils/fileUtils'
import { buildDelegatesForManager, buildMembersForManager } from '../utils/mandateUtils'

import { ContactFilterType, MeetingFilterType } from '../types/Filters'
import { Meeting, MeetingListType, MeetingPreview } from '../types/Meeting'
import { QsQueryParamsType } from '../types/Params'
import {
  ContactListType,
  emailUserUpdate,
  tokenEmailUserUpdate,
  User,
  UserCreate,
  UserManagerRights,
  UserPerimeter,
  UserRights,
  UserUpdate,
} from '../types/User'

import { execute } from '../api/api'
import { InstanceListStore } from '../store/InstanceListStore'

const getAll = (): Promise<User[]> => {
  return execute<User[]>(ApiRoutes.USERS.BASE, 'GET')
}

const getUserMeetings = (): Promise<Meeting[]> => {
  return execute<Meeting[]>(`${ApiRoutes.MEETINGS.MY_MEETINGS}?_limit=-1&_sort=start_date:asc`, 'GET')
}

const getUserInstanceMeetings = (): Promise<Meeting[]> => {
  return execute<Meeting[]>(ApiRoutes.MEETINGS.MY_MEETINGS_INSTANCES, 'GET')
}

const getUserDelegateMeetings = (): Promise<MeetingPreview[]> => {
  return execute<MeetingPreview[]>(ApiRoutes.MEETINGS.MY_MEETINGS_DELEGATES, 'GET')
}

const getMe = (): Promise<User> => {
  return execute<User>(ApiRoutes.USERS.ME, 'GET')
}

const getOne = (userId: number): Promise<User> => {
  return execute<User>(generatePath(ApiRoutes.USERS.ID, { id: userId }), 'GET')
}

const updateMe = (body: UserUpdate): Promise<User> => {
  return execute<User>(ApiRoutes.USERS.ME, 'PUT', body)
}

const updateEmail = (body: emailUserUpdate): Promise<User> => {
  return execute<User>(ApiRoutes.USERS.UPDATE_EMAIL, 'PUT', body)
}

const validateEmail = (body: tokenEmailUserUpdate): Promise<User> => {
  return execute<User>(ApiRoutes.USERS.VALIDATE_EMAIL, 'PUT', body)
}

const updateOne = (userId: number, body: User): Promise<User> =>
  execute<User>(generatePath(ApiRoutes.USERS.ID, { id: userId }), 'PUT', body)

/**
 * récupère la liste des mandats membres d'un utilisateur
 * @param userId
 */
const _getMembers = (userId: number): Promise<Member[]> =>
  execute<Member[]>(generatePath(ApiRoutes.USERS.MEMBERS, { id: userId }), 'GET')

const getUserMeetingsWithFilters = (filters: MeetingFilterType): Promise<MeetingListType> => {
  const query: QsQueryParamsType = {
    page: filters.page,
    limit: filters.limit,
  }
  if (filters.instanceList && filters.instanceList.length > 0) {
    query.instanceList = filters.instanceList.join('-')
  }
  if (filters.startDate) {
    query.startDate = convertDateToStringFromFormat(filters.startDate, DATABASE_DATE_FORMAT)
  }
  if (filters.endDate) {
    query.endDate = convertDateToStringFromFormat(filters.endDate, DATABASE_DATE_FORMAT)
  }
  if (filters.status) {
    query.status = filters.status
  }
  if (filters.meetingFilterId) {
    query.meetingFilterId = filters.meetingFilterId
  }

  const qsQuery = `?${qs.stringify(query)}`
  return execute<MeetingListType>(`${ApiRoutes.MEETINGS.MY_MEETINGS_INSTANCES}${qsQuery}`, 'GET')
}

const getContactsWithFilters = (
  filters: ContactFilterType,
  otherParams: Record<string, unknown> = {},
): Promise<ContactListType> => {
  const query: QsQueryParamsType = {
    page: filters.page,
    limit: filters.limit,
    ...otherParams,
  }
  const qsQuery = setQueryFilterParams(query, filters)
  return execute<ContactListType>(`${ApiRoutes.USERS.CONTACTS}${qsQuery}`, 'GET')
}

const getUsersWithFilters = (
  filters: ContactFilterType,
  otherParams: Record<string, unknown> = {},
): Promise<ContactListType> => {
  const query: QsQueryParamsType = {
    page: filters.page,
    limit: filters.limit,
    ...otherParams,
  }
  const qsQuery = setQueryFilterParams(query, filters)
  return execute<ContactListType>(`${ApiRoutes.USERS.USERS}${qsQuery}`, 'GET')
}

const getCollaborators = (userId: number): Promise<Collaborator[]> =>
  execute<Collaborator[]>(generatePath(ApiRoutes.USERS.COLLABORATORS, { id: userId }), 'GET')

const getDelegates = (userId: number): Promise<Delegate[]> =>
  execute<Delegate[]>(generatePath(ApiRoutes.USERS.DELEGATES, { id: userId }), 'GET')

const create = (data: UserCreate): Promise<User> => {
  return execute<User>(ApiRoutes.USERS.BASE, 'POST', data)
}

const getPerimeter = (userId: number, isPermission?: boolean): Promise<UserPerimeter> => {
  const params = {
    is_permission: isPermission,
  }

  const query = qs.stringify(params)

  return execute<UserPerimeter>(
    `${generatePath(ApiRoutes.USERS.PERIMETER, { id: userId, is_permission: isPermission ?? false })}?${query}`,
    'GET',
  )
}

const getUserRights = (userId: number): Promise<UserRights> => {
  return execute<UserRights>(`${generatePath(ApiRoutes.USERS.RIGHTS, { userId: userId })}`, 'GET')
}

/***************************************************************
 *                        UTILS
 *****************************************************************/
/**
 * Ajoute les champs isActive sur une liste
 * @param userId
 * @param managerRights
 */
const fetchMembersWithManagerRightsAndIsCurrent = async (
  userId: number,
  managerRights: UserManagerRights,
): Promise<MemberForManagerType[]> => {
  const members = await _getMembers(userId)
  return buildMembersForManager(members, managerRights)
}

/**
 * Ajoute les champs isActive sur une liste de délégués
 * @param userId
 * @param managerRights
 */
const fetchDelegatesWithManagerRightsAndIsCurrent = async (
  userId: number,
  managerRights: UserManagerRights,
): Promise<DelegateForManagerType[]> => {
  const delegates = await getDelegates(userId)
  return buildDelegatesForManager(delegates, managerRights)
}

const getSubstituteUsers = async (
  userId: number,
  college?: UserCollegeType | null,
  instanceIdList?: (string | number)[],
): Promise<User[]> => {
  const filters = instanceIdList
    ? {
        instanceList: instanceIdList.map((instanceId) => instanceId.toString()),
      }
    : {
        instanceList: InstanceListStore.getState().map((instance) => instance.id.toString()),
      }

  const contacts = await getContactsWithFilters(
    { ...filters, page: 0, limit: undefined, userId: userId },
    {
      _where: [
        {
          // On ne veut pas récupérer l'utilisateur connecté
          id_ne: userId,
        },
        {
          college,
        },
      ],
    },
  )
  return contacts.users
}

const sortUser = (highlightedIds: number[]) => (a: User, b: User): number => {
  const isMemberA = !!highlightedIds.find((id: number) => id === a.id)
  const isMemberB = !!highlightedIds.find((id: number) => id === b.id)
  if (isMemberA && !isMemberB) {
    return -1
  }
  if (!isMemberA && isMemberB) {
    return 1
  }
  return a.firstname.concat(a.lastname).localeCompare(b.firstname.concat(b.lastname))
}

export const userService = {
  fetchMembersWithManagerRightsAndIsCurrent,
  fetchDelegatesWithManagerRightsAndIsCurrent,
  getAll,
  getUserMeetings,
  getUserMeetingsWithFilters,
  getUserInstanceMeetings,
  getUserDelegateMeetings,
  getSubstituteUsers,
  getMe,
  getOne,
  updateMe,
  getContactsWithFilters,
  getUsersWithFilters,
  getCollaborators,
  getDelegates,
  create,
  getPerimeter,
  updateOne,
  sortUser,
  updateEmail,
  validateEmail,
  getUserRights,
}
