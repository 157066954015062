import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import Spinner from 'components/atoms/spinner/Spinner'
import InstanceCollaboratorsTable from 'components/mandate/instance-collaborators/InstanceCollaboratorsTable'
import InstanceMembersTable from 'components/mandate/instance-members/InstanceMembersTable'
import TabbedContainer, { Tab } from 'components/tabbed-container/TabbedContainer'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { BreadcrumbItem, Col } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { Instance } from 'types/Instance'
import { notifyError } from 'utils/alertUtils'
import { usePermissions } from 'utils/hooks/usePermissions'
import { useQuery } from 'utils/hooks/useQuery'

import { PATHS } from '../utils/constants/routes/RoutePaths'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import InstanceEmailModal from '../components/molecules/email/InstanceEmailModal'
import Header from '../components/molecules/heading/Header'

const InstanceMandatesView: FC = () => {
  const { t } = useTranslation()
  const { CONTACTS_CAN_MANAGE, IS_SUPER_ADMIN } = usePermissions()
  const params: { id: string } = useParams()
  const instanceId = parseInt(params.id)

  const { data: instance, isLoading } = useQuery<Instance>({
    queryKey: ['instance', 'getOne'],
    queryFn: () => instanceService.getOne(instanceId),
    onError: () => notifyError(t('toastify.errors.get.instance')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }

  if (!instance) return null

  const title = instance
    ? `${t('mandates.editPageTitle')} ${instance.long_name}${
        instance.association?.name ? ` - ${instance.association?.name || ''}` : ''
      }`
    : ''

  const instanceMandatesTabs: Tab[] = [
    {
      id: 'member',
      label: t('mandates.tabs.member.nav'),
      content: <InstanceMembersTable instanceId={instanceId} instanceName={instance?.name || ''} />,
    },
    {
      id: 'collaborator',
      label: t('mandates.tabs.collaborator.nav'),
      content: <InstanceCollaboratorsTable instanceId={instanceId} instanceName={instance?.name || ''} />,
    },
  ]

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Row grid className='align-items-center'>
            <Col>
              <Row>
                <Header>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={PATHS.CONTACTS.HOME}>{t('contacts.header')}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={PATHS.USER_MANAGEMENT.ALL_INSTANCES}>{t('instances.list')}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>{title}</BreadcrumbItem>
                  </Breadcrumb>
                </Header>
              </Row>
            </Col>
            {CONTACTS_CAN_MANAGE() && IS_SUPER_ADMIN() && (
              <Col xs='auto' className='mt-3'>
                <Row className='flex-row-reverse' grid>
                  <Col xs='auto'>
                    <InstanceEmailModal instance={instance} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {instance && <TabbedContainer tabs={instanceMandatesTabs} />}
          {!instance && <p>{t('instances.noInstance')}</p>}
        </Section>
      </ViewBody>
    </View>
  )
}

export default InstanceMandatesView
