import qs from 'qs'
import { generatePath } from 'react-router'
import { Collaborator } from 'types/Collaborator'
import { Meeting } from 'types/Meeting'
import { Member, MemberForManagerType } from 'types/Member'
import { User, UserManagerRights } from 'types/User'

import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { convertDateToStringFromFormat, DATABASE_DATE_FORMAT } from '../utils/dateUtils'
import { buildMembersForManager } from '../utils/mandateUtils'

import { Email, PrepareToSendEmail } from '../types/Email'
import { Instance, InstanceUpdate } from '../types/Instance'

import { execute, executeFormData } from '../api/api'

const getAll = (): Promise<Instance[]> => {
  return execute<Instance[]>(ApiRoutes.INSTANCES.BASE, 'GET')
}

const fetchMembersWithManagerRightsAndIsCurrent = async (
  instanceId: number,
  managerRights: UserManagerRights,
): Promise<MemberForManagerType[]> => {
  const members = await getMembers(instanceId)
  return buildMembersForManager(members, managerRights)
}

const getMyInstances = (): Promise<Instance[]> => {
  return execute<Instance[]>(ApiRoutes.INSTANCES.MY_INSTANCES, 'GET')
}

const getMyInstancesForPermission = (permission = ''): Promise<Instance[]> => {
  return execute<Instance[]>(
    `${ApiRoutes.INSTANCES.MY_INSTANCES}${permission ? `?permission=${permission}` : ''}`,
    'GET',
  )
}

const getOne = (instanceId: number): Promise<Instance> => {
  return execute<Instance>(generatePath(ApiRoutes.INSTANCES.ID, { id: instanceId }), 'GET')
}

const getMembers = (
  instanceId: number,
  date: string = convertDateToStringFromFormat(new Date(), DATABASE_DATE_FORMAT),
): Promise<Member[]> =>
  execute<Member[]>(`${generatePath(ApiRoutes.INSTANCES.MEMBERS, { id: instanceId })}?date=${date}`, 'GET')

const getCollaborators = (instanceId: number): Promise<Collaborator[]> =>
  execute<Collaborator[]>(generatePath(ApiRoutes.INSTANCES.COLLABORATORS, { id: instanceId }), 'GET')

const getNotInInstanceUsers = (instanceId: number): Promise<User[]> =>
  execute<User[]>(generatePath(ApiRoutes.INSTANCES.NOT_IN_INSTANCE_USERS, { id: instanceId }), 'GET')

const sendEmail = (instanceId: number, body: Email): Promise<void> => {
  const formData = new FormData()
  for (const file of body.attachments) {
    formData.append('files', file)
  }
  body.cc.split(' ').forEach((cc) => formData.append('cc', cc))
  formData.append('subject', body.subject)
  formData.append('content', body.content)

  return executeFormData<void>(
    generatePath(ApiRoutes.INSTANCES.SEND_MAIL_HISTORY, { id: instanceId }),
    'POST',
    formData,
  )
}

const sendEmailToParticipants = (instanceId: number, body: PrepareToSendEmail): Promise<void> => {
  const formData = new FormData()
  for (const file of body.email.attachments) {
    formData.append('files', file)
  }
  body.invitationList.map((invitation) => formData.append('invitationList', invitation))
  body.email.cc.split(' ').map((cc) => formData.append('cc', cc))
  formData.append('subject', body.email.subject)
  formData.append('content', body.email.content)

  return executeFormData<void>(generatePath(ApiRoutes.INSTANCES.SEND_EMAIL, { id: instanceId }), 'POST', formData)
}

const updateInstance = (instanceId: number, body: Instance): Promise<void> =>
  execute<void>(generatePath(ApiRoutes.INSTANCES.ID, { id: instanceId }), 'PUT', body)

const getFromAssociationsIds = (associationsIds: number[]): Promise<Instance[]> => {
  const queryParams = qs.stringify({ _where: { association: associationsIds } })

  return execute<Instance[]>(`${ApiRoutes.INSTANCES.BASE}?${queryParams}`, 'GET')
}

const getInstanceMeetings = (instanceId: number): Promise<Meeting[]> =>
  execute<Meeting[]>(generatePath(ApiRoutes.INSTANCES.MEETINGS_DECISIONS_RECORDS, { id: instanceId }), 'GET')

const getInstanceMeetingsForDelegate = (instanceId: number): Promise<Meeting[]> =>
  execute<Meeting[]>(generatePath(ApiRoutes.INSTANCES.MEETINGS_DELEGATE, { id: instanceId }), 'GET')

const prepareMeetingBody = (body: InstanceUpdate) => {
  return {
    ...body,
  }
}

const update = (id: string | number, updatedInstance: InstanceUpdate): Promise<Instance> => {
  return execute<Instance>(generatePath(ApiRoutes.INSTANCES.ID, { id }), 'PUT', prepareMeetingBody(updatedInstance))
}

export const instanceService = {
  getAll,
  getOne,
  getMembers,
  fetchMembersWithManagerRightsAndIsCurrent,
  getCollaborators,
  getNotInInstanceUsers,
  getMyInstances,
  getMyInstancesForPermission,
  sendEmailToParticipants,
  updateInstance,
  getFromAssociationsIds,
  getInstanceMeetings,
  sendEmail,
  getInstanceMeetingsForDelegate,
  update,
}
