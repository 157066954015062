import { MemberCollegeEnum, MemberFunctionEnum } from 'types/Member'

const NUMBER_OF_CONTACTS_DISPLAYED = 50

export const CONTACT_COLLEGE = [
  {
    key: '',
    label: 'common.enums.college.all',
  },
  {
    key: MemberCollegeEnum.PATRONAL,
    label: 'common.enums.college.patronal',
  },
  {
    key: MemberCollegeEnum.SALARIE,
    label: 'common.enums.college.salarie',
  },
]

export const CONTACT_FUNCTIONS = [
  {
    key: '',
    label: 'common.enums.functions.all',
  },
  {
    key: MemberFunctionEnum.PRESIDENT,
    label: 'common.enums.functions.president',
  },
  {
    key: MemberFunctionEnum.VICE_PRESIDENT,
    label: 'common.enums.functions.vicePresident',
  },
  {
    key: MemberFunctionEnum.SECRETAIRE,
    label: 'common.enums.functions.secretary',
  },
  {
    key: MemberFunctionEnum.SECRETAIRE_ADJOINT,
    label: 'common.enums.functions.assistantSecretary',
  },
  {
    key: MemberFunctionEnum.TRESORIER,
    label: 'common.enums.functions.treasurer',
  },
  {
    key: MemberFunctionEnum.TRESORIER_ADJOINT,
    label: 'common.enums.functions.assistantTreasurer',
  },
]

export const initialFilter = {
  instanceList: [],
  page: 1,
  limit: NUMBER_OF_CONTACTS_DISPLAYED,
}

export const mailHistoryFilter = {
  instanceList: [],
  page: 0,
  limit: 10,
}
