import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ArticleDateAuthorProps {
  date: string
  author?: string
}

const ArticleDateAuthor: FC<ArticleDateAuthorProps> = (props) => {
  const { date, author } = props
  const { t } = useTranslation()

  return (
    <p className='ArticleDateAuthor'>
      {date}
      {author && (
        <>
          {t('kiosk.article.by')}
          <span className='author'>{author}</span>
        </>
      )}
    </p>
  )
}

export default ArticleDateAuthor
