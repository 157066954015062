import Modal from 'components/atoms/modal/Modal'
import Spinner from 'components/atoms/spinner/Spinner'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { userService } from 'services/userService'
import { DelegateMeetingsWithPerimeterAndRights } from 'types/Delegate'
import { User } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { computeFullName } from 'utils/userUtils'

import DelegateForm from './DelegateForm'

interface AddDelegateModalProps {
  user: User
  isOpen: boolean
  toggle: () => void
  refetchDelegates: () => void
}

const AddDelegateModal: FC<AddDelegateModalProps> = ({ user, isOpen, toggle, refetchDelegates }) => {
  const { t } = useTranslation()

  const managerRights = useManagerRights()

  const { data, isLoading } = useQuery<DelegateMeetingsWithPerimeterAndRights>({
    queryKey: ['instance', 'getDelegateMeetingsWithUserPerimeterAndManagerRights', user.id],
    queryFn: async () => {
      const allMeetings = await instanceService.getInstanceMeetingsForDelegate(1)
      const userPerimeter = await userService.getPerimeter(user.id)

      return {
        allMeetings,
        userPerimeter,
        managerRights,
      }
    },
    onError: () => notifyError(t('toastify.errors.get.meeting')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }
  if (!data) {
    return null
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='text-capitalize' toggle={toggle}>{`${t(
        'mandates.addMandate.delegate.modalTitle',
      )} ${computeFullName(user)}`}</ModalHeader>
      <ModalBody>
        <DelegateForm data={data} userId={user.id} refetchDelegates={refetchDelegates} toggle={toggle} />
      </ModalBody>
    </Modal>
  )
}

export default AddDelegateModal
