import { format, isAfter, isBefore } from 'date-fns'
import _ from 'lodash'
import { DataNode } from 'rc-tree/lib/interface'
import { Meeting } from 'types/Meeting'

import { Association } from '../types/Association'
import { PerimeterTypeEnum } from '../types/Collaborator'
import { Instance, InstancesWithRights, UserPerimeter } from '../types/Instance'
import { User, UserManagerRights } from '../types/User'

import { TreeDataType } from '../components/atoms/tree/TreeSelect'

import { parseAsDate } from './dateUtils'

export const getInstancesAsTreeDataType = (instances: Instance[]): TreeDataType[] => {
  const resToInstances = _.reduce(
    instances,
    (acc: Record<string, TreeDataType>, curr) => {
      const currAssociation = curr.association

      if (currAssociation) {
        const accKey = currAssociation.short_name as keyof typeof acc

        if (!acc[accKey]) {
          acc[accKey] = {
            title: currAssociation.name,
            value: `asso_${currAssociation.id}`,
            children: [],
          }
        }

        acc[accKey].children?.push({
          title: curr.long_name,
          value: curr.id,
        })
      }
      return acc
    },
    {},
  )
  return sortInstances(Object.values(resToInstances))
}

const sortInstances = (instances: TreeDataType[]): TreeDataType[] => {
  return instances.map((inst) => {
    return {
      ...inst,
      children: _.sortBy(inst.children, ['title']),
    }
  })
}

export const getInstancesIdFromTreeDataType = (treeDataType: TreeDataType[]): (string | number | undefined)[] => {
  let result: (string | number | undefined)[] = []
  treeDataType.forEach((tree) => {
    if (tree.children) {
      result = [...result, ...tree.children.map((child) => child.value)]
    }
  })

  return result
}

// On récupère la liste d'instance qu'on transforme en data node pour créer une arborescence d'associations et de children instances
// On ajoute un attribut disabled sur les instances ou associations ne pouvant être ajoutées par l'utilisateur connecté (gestionnaire)
export const getAllInstancesAsDataNodeTypeForManager = (
  data: InstancesWithRights,
  userPerimeter?: UserPerimeter,
): DataNode[] => {
  const { allInstances, managerRights } = data
  // On calcule si l'utilisateur gestionnaire a le droit d'ajout sur l'instance
  const isInstanceDisabled = (instanceId: number, associationId: number): boolean => {
    // L'instance ou l'association parente est déjà dans le périmètre de l'utilisateur
    const isAlreadyInUserPerimeter: boolean = userPerimeter
      ? userPerimeter.instancesIds.includes(instanceId) || userPerimeter.associationsIds.includes(associationId)
      : false

    // L'utilisateur dispose des droits gestionnaires sur l'ensemble du périmètre OU sa liste de droits gestionnaires incluent l'instance / l'association parente
    const hasManagerRight: boolean =
      !!managerRights.hasManagerRightsOnAll ||
      !!managerRights.instancesIds?.includes(instanceId) ||
      !!managerRights.associationsIds?.includes(associationId)

    return isAlreadyInUserPerimeter || !hasManagerRight
  }

  // On calcule si l'utilisateur gestionnaire a le droit d'ajout sur l'association
  const isAssociationDisabled = (associationId: number): boolean => {
    // L'association est déjà dans le périmètre de l'utilisateur
    const isAlreadyInUserPerimeter: boolean = userPerimeter
      ? userPerimeter.associationsIds.includes(associationId)
      : false

    // L'utilisateur ne dispose pas des droits gestionnaires sur l'ensemble du périmètre ET sa liste de droits gestionnaires n'incluent pas l'association parente
    const hasNoManagerRight: boolean =
      !managerRights.hasManagerRightsOnAll && !managerRights.associationsIds?.includes(associationId)

    return isAlreadyInUserPerimeter || hasNoManagerRight
  }

  // On transforme la liste d'associations et instances en data node
  const resToInstances = allInstances.reduce((acc: Record<string, DataNode>, curr) => {
    const currAssociation = curr.association

    if (currAssociation) {
      const accKey = currAssociation.short_name as keyof typeof acc

      if (!acc[accKey]) {
        const val = `asso_${currAssociation.id}`
        acc[accKey] = {
          title: currAssociation.name,
          key: val,
          children: [],
          disabled: isAssociationDisabled(currAssociation.id),
        }
      }

      acc[accKey].children?.push({
        title: curr.long_name,
        key: curr.id,
        disabled: isInstanceDisabled(curr.id, currAssociation.id),
      })
    }

    return acc
  }, {})

  return Object.values(resToInstances).map((inst) => {
    return { ...inst, children: _.sortBy(inst.children, ['title']) }
  })
}

export const getInstanceMeetingAsDataNodeTypeForManager = (
  meetings: Meeting[],
  managerRights: UserManagerRights,
  userPerimeter?: UserPerimeter,
): DataNode[] => {
  // On calcule si l'utilisateur gestionnaire a le droit d'ajout sur la réunion
  const isMeetingDisabled = (meetingId: number, instanceId: number, associationId: number): boolean => {
    // La réunion, l'instance ou l'association parente est déjà dans le périmètre de l'utilisateur
    const isAlreadyInUserPerimeter: boolean = userPerimeter
      ? userPerimeter.meetingsIds.includes(meetingId) ||
        userPerimeter.instancesIds.includes(instanceId) ||
        userPerimeter.associationsIds.includes(associationId)
      : false

    // L'utilisateur dispose des droits gestionnaires sur l'ensemble du périmètre OU sa liste de droits gestionnaires incluent l'instance / l'association parente
    const hasManagerRight: boolean =
      !!managerRights.hasManagerRightsOnAll ||
      !!managerRights.instancesIds?.includes(instanceId) ||
      !!managerRights.associationsIds?.includes(associationId)

    return isAlreadyInUserPerimeter || !hasManagerRight
  }

  // On calcule si l'utilisateur gestionnaire a le droit d'ajout sur l'association
  const isAssociationDisabled = (associationId: number): boolean => {
    // L'association est déjà dans le périmètre de l'utilisateur
    const isAlreadyInUserPerimeter: boolean = userPerimeter
      ? userPerimeter.associationsIds.includes(associationId)
      : false

    // L'utilisateur ne dispose pas des droits gestionnaires sur l'ensemble du périmètre ET sa liste de droits gestionnaires n'incluent pas l'association parente
    const hasNoManagerRight: boolean =
      !managerRights.hasManagerRightsOnAll && !managerRights.associationsIds?.includes(associationId)

    return isAlreadyInUserPerimeter || hasNoManagerRight
  }

  // On transforme la liste d'associations et instances en data node
  const meetingsToDataNode = meetings.reduce((acc: Record<string, DataNode>, meeting) => {
    const currentAssociation = meeting.instance.association
    const currentInstance = meeting.instance

    if (currentAssociation) {
      const accKey = currentAssociation.short_name as keyof typeof acc

      if (!acc[accKey]) {
        acc[accKey] = {
          title: currentAssociation.name,
          key: `asso_${currentAssociation.id}`,
          children: [],
          disabled: isAssociationDisabled(currentAssociation.id),
        }
      }

      acc[accKey].children?.push({
        title: `${currentInstance.long_name} - ${format(new Date(meeting.start_date), 'dd/MM/yyyy')}`,
        key: meeting.id,
        disabled: isMeetingDisabled(meeting.id, currentInstance.id, currentAssociation.id),
      })
    }

    return acc
  }, {})

  return Object.values(meetingsToDataNode)
}

export const filterInstances = (search: string, node?: TreeDataType): boolean => {
  if (!node || !node.title) return false

  const nodeAsString = node.title.toString()
  return nodeAsString.toLowerCase().includes(search.toLowerCase())
}

type AssociationInstanceListType = {
  association: Association
  instanceList: Instance[]
}
export const rearrangeInstanceListByAssociation = (instanceList: Instance[]): AssociationInstanceListType[] => {
  const instanceListGrouped = _.groupBy(instanceList, 'association.id')
  const associationList = _.map(_.uniqBy(instanceList, 'association.id'), 'association') as Association[]
  return associationList.map((association) => ({
    association,
    instanceList: instanceListGrouped[association.id],
  }))
}

export const filterUserInstance = (instanceList: Instance[], user: User, isOpco = false): Instance[] => {
  const userInstances: Instance[] = []
  for (const instance of instanceList) {
    if ((isOpco && instance.association?.name.startsWith('OPCO')) || userIsinInstance(instance, user)) {
      userInstances.push(instance)
    }
  }
  return userInstances
}

export const userIsinInstance = (instance: Instance, user: User): boolean => {
  for (const member of user.members) {
    if (
      member.instance?.id === instance?.id &&
      (!member.start_date || isAfter(new Date(), parseAsDate(member.start_date))) &&
      (!member.end_date || isBefore(new Date(), parseAsDate(member.end_date)))
    ) {
      return true
    }
  }
  for (const collaborator of user.collaborators) {
    if (
      (collaborator.perimeter_type === PerimeterTypeEnum.ALL ||
        (collaborator.perimeter_type === PerimeterTypeEnum.ASSOCIATION &&
          collaborator.id_perimeter === instance.association?.id) ||
        (collaborator.perimeter_type === PerimeterTypeEnum.INSTANCE && collaborator.id_perimeter === instance.id)) &&
      (!collaborator.start_date || isAfter(new Date(), parseAsDate(collaborator.start_date))) &&
      (!collaborator.end_date || isBefore(new Date(), parseAsDate(collaborator.end_date)))
    ) {
      return true
    }
  }
  return false
}

export const isMixedChannelInstance = (instanceId: number): boolean => {
  return ![1].includes(instanceId)
}
