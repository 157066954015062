import React, { FC } from 'react'

import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import CalendarGridMeetings from '../components/molecules/calendar-grid/meetings/CalendarGridMeetings'
import KioskSection from '../components/molecules/dashboard-section/KioskSection'
import LastArticles from '../components/molecules/dashboard-section/LastArticles'

// import RefundCard from '../components/molecules/refund-card/RefundCard'

const DashboardView: FC = () => {
  return (
    <View>
      <ViewBody>
        <Section fluid>
          <CalendarGridMeetings />
        </Section>
        <Section fluid>
          <KioskSection />
        </Section>
        <Section fluid>
          <LastArticles />
        </Section>
        {/* Masqué pour le moment */}
        {/* <Section fluid>
          <RefundCard />
        </Section> */}
      </ViewBody>
    </View>
  )
}

export default DashboardView
