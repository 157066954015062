import qs from 'qs'
import { DownloadFileObject } from 'types/File'

import { ContactFilterType } from '../types/Filters'
import { QsQueryParamsType } from '../types/Params'

import { execute } from '../api/api'
import { ApiRoutes } from './constants/ApiRoutes'

export const KILO_BYTE = 1024
// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Byte'

  const k = KILO_BYTE
  const dm = Math.max(0, decimals)
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const convertUint8ArrayToObjectURL = (data: Uint8Array): string => {
  const buffer = Buffer.from(data)
  const blob = new Blob([buffer])

  return window.URL.createObjectURL(blob)
}

export const triggerDownloadFromObjectURL = (objectURL: string, fileName: string): void => {
  downloadFile(objectURL, fileName)
}

export const triggerMultipleDownloadFromObjectUrls = (downloadFileObject: DownloadFileObject[]): void => {
  for (const { objectURL, fileName } of downloadFileObject) {
    downloadFile(objectURL, fileName)
  }
}

export const fetchFileFromUrl = async (url: string, fileName: string): Promise<File> => {
  const response = await fetch(url)
  const data = await response.blob()
  return new File([data], fileName)
}

export const getUrlFromFile = (file: File): string => {
  return window.URL.createObjectURL(file.slice())
}

export const setQueryFilterParams = (query: QsQueryParamsType, filters: ContactFilterType): string => {
  if (filters.instanceList && filters.instanceList.length > 0) {
    query.instanceList = filters.instanceList.join('-')
  }
  if (filters.role) {
    query.role = filters.role
  }
  if (filters.search) {
    query.search = filters.search
  }
  if (filters.userId) {
    query.userId = filters.userId
  }
  if (filters.college) {
    query.college = filters.college
  }
  if (filters.lastname) {
    query.lastname = filters.lastname
  }
  if (filters.firstname) {
    query.firstname = filters.firstname
  }
  if (filters.memberOnly) {
    query.memberOnly = filters.memberOnly
  }
  return `?${qs.stringify(query)}`
}

export const downloadExportDocument = async (
  filters: ContactFilterType,
  otherParams: Record<string, unknown> = {},
): Promise<void> => {
  const query: QsQueryParamsType = {
    page: 1,
    limit: Number.MAX_SAFE_INTEGER,
    ...otherParams,
  }
  const qsQuery = setQueryFilterParams(query, filters)
  const rowData = await execute<Blob>(`${ApiRoutes.USERS.EXPORT}${qsQuery}`, 'GET', {}, {}, {}, 'blob')
  const url = window.URL.createObjectURL(
    new Blob([rowData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
  )
  const date = new Date().toDateString()
  downloadFile(url, `Extraction Utilisateurs ${date}.xlsx`)
}

export const downloadFile = (url: string, fileName: string): void => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  link.setAttribute('id', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
