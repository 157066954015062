import { AutoComplete, AutoCompleteProps } from 'antd'
import React, { FC, FocusEventHandler, Ref, useState } from 'react'

import './AutoCompleteInput.scss'
import TextInput from './TextInput'
import InputGroup from './input-group/InputGroup'

interface Props extends AutoCompleteProps {
  searchOptions: { value: string }[] | undefined
  name?: string
  innerRef?: Ref<HTMLInputElement>
  className?: string
}

const AutoCompleteInput: FC<Props> = ({ searchOptions, name, innerRef, defaultValue, className = '', ...rest }) => {
  const [value, setValue] = useState(defaultValue)
  const [options, setOptions] = useState<{ value: string }[]>(searchOptions || [])
  const handleOptionsChange = (searchText: string) => {
    if (searchOptions) {
      setOptions([
        ...searchOptions.filter((option) =>
          option.value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim()),
        ),
      ])
    }
  }
  const onSearch = (searchText: string) => handleOptionsChange(searchText)
  const onFocus: FocusEventHandler<HTMLInputElement> = (e) => handleOptionsChange(e.target.value)
  const onChange = (data: string) => setValue(data)

  return (
    <InputGroup>
      <AutoComplete
        className={`w-100 ${className}`}
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        onSearch={onSearch}
        defaultValue={defaultValue || undefined}
        {...rest}
      />
      {/* On met un input masqué lié au formulaire avec la valeur sélectionné */}
      <TextInput className='d-none' value={value} name={name} innerRef={innerRef} readOnly />
    </InputGroup>
  )
}

export default AutoCompleteInput
