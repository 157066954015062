import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'

import { useQuery } from '../../../utils/hooks/useQuery'

import { contactInfoService } from '../../../services/contactInfoService'
import ReactMarkdown from '../../atoms/markdown/ReactMarkdown'
import Modal from '../../atoms/modal/Modal'

interface ModalContactInfoProps {
  isOpen: boolean
  toggle: () => void
}
export const ModalContactInfo: FC<ModalContactInfoProps> = (props) => {
  const { isOpen, toggle } = props
  const { t } = useTranslation()
  const { data } = useQuery({
    queryKey: 'contact',
    queryFn: () => contactInfoService.getContactInfo(),
  })

  return (
    <Modal size='lg' isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t('dashboard.modalContactInfo')}</ModalHeader>
      <ModalBody>
        <ReactMarkdown>{data?.contact_info || t('contacts.noContactInfos')}</ReactMarkdown>
      </ModalBody>
    </Modal>
  )
}

export default ModalContactInfo
