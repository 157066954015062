import { useCallback, useState } from 'react'

interface UseToggleReturn {
  state: boolean
  toggle: () => void
  on: () => void
  off: () => void
}

// Hook
export const useToggle = (initialState = false): UseToggleReturn => {
  const [state, setState] = useState(initialState)

  const toggle = useCallback(() => setState((state) => !state), [])
  const on = useCallback(() => setState(true), [])
  const off = useCallback(() => setState(false), [])

  return { state, toggle, on, off }
}
