import React, { Dispatch, FC, SetStateAction } from 'react'

import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icon/IconSvg'
import Row from '../../atoms/layout/Row'

interface ButtonDisplayTypeProps {
  typeDisplay: boolean
  setTypeDisplay: Dispatch<SetStateAction<boolean>>
}

const ButtonDisplayType: FC<ButtonDisplayTypeProps> = ({
  typeDisplay,
  setTypeDisplay,
}: ButtonDisplayTypeProps): JSX.Element => {
  const onChangeDisplayTypeGrid = () => {
    setTypeDisplay(false)
  }

  const onChangeDisplayTypeList = () => {
    setTypeDisplay(true)
  }

  return (
    <Row className={'m-3'}>
      <Button
        onClick={onChangeDisplayTypeGrid}
        color={typeDisplay ? 'white' : 'primary'}
        className={'button-icon p-0 mr-3 mt-3'}
      >
        <IconSvg size='2' name={typeDisplay ? 'GridView' : 'GridViewSelected'} />
      </Button>
      <Button
        onClick={onChangeDisplayTypeList}
        color={typeDisplay ? 'primary' : 'white'}
        className={'button-icon p-0 mt-3'}
      >
        <IconSvg size='2' name={typeDisplay ? 'BulletListSelected' : 'BulletList'} />
      </Button>
    </Row>
  )
}

export default ButtonDisplayType
