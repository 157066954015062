import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import Header from 'components/molecules/heading/Header'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { buildDocumentListFromKioskComponentList, kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { FileTypeEnum } from '../utils/constants/File'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import { DocumentListType } from '../types/Document'

import Button from '../components/atoms/button/Button'
import DocumentsListsWithViewer from '../components/molecules/documents/DocumentsListsWithViewer'
import ModalAddConventionObjectif from '../components/molecules/modals/ModalAddConventionObjectif'

const KioskObjectivesConventionView: FC = () => {
  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.objectivesConvention')

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()

  const { state: isObjectivesConventionTextOpen, toggle: toggleModalObjectivesConventionRecord } = useToggle()

  const { isLoading, data: objectivesConvention, refetch } = useQuery({
    queryKey: ['kiosk', 'getKioskObjectiveConvention'],
    queryFn: () => kioskService.getKioskObjectiveConvention(),
  })

  const toggleModal = () => {
    toggleModalObjectivesConventionRecord()
    refetch()
  }

  const { mutate: deleteDocuments } = useMutation(
    ['objectifConvention', 'deleteObjectifConvention'],
    async (documentList: DocumentListType) => {
      if (objectivesConvention) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateObjectifConvention({
          id: objectivesConvention.id,
          document: objectivesConvention.document
            ?.filter((doc) => !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              year: doc.year,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetch()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const documentList = buildDocumentListFromKioskComponentList('year', 'media', objectivesConvention?.document)
    .map((docList) => ({
      ...docList,
      documents: docList.documents.map((doc) => ({
        ...doc,
        extension: (doc.ext?.substring(1) as FileTypeEnum) || FileTypeEnum.UNKNOWN,
        url: `/upload/files/${doc.id}/download`,
      })),
    }))
    .sort((a, b) => b.label.localeCompare(a.label))

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && (
              <Button onClick={toggleModalObjectivesConventionRecord}>{t('kiosk.addObjectivesConvention')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          {documentList && !isLoading && (
            <DocumentsListsWithViewer
              lists={documentList}
              onDeleteDocument={deleteDocuments}
              deletable={canManageKiosk}
            />
          )}
          {objectivesConvention?.document?.length === 0 && !isLoading && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: rubricLabel.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddConventionObjectif
          toggle={toggleModal}
          isOpen={isObjectivesConventionTextOpen}
          oldDocuments={objectivesConvention?.document ?? []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskObjectivesConventionView
