import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import Header from 'components/molecules/heading/Header'
import moment from 'moment/moment'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError } from '../utils/alertUtils'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'
import { FileBrowserFileMapNode } from '../utils/treeUtils'

import { DocumentTypeEnum } from '../types/Document'
import { NotificationInterface, NotificationType } from '../types/Notification'

import Button from '../components/atoms/button/Button'
import { FileBrowser } from '../components/molecules/file-browser/FileBrowser'
import ModalAddFile from '../components/molecules/modals/ModalAddFile'

import { documentService } from '../services/documentService'
import { notificationService } from '../services/notificationService'
import { NotificationStore } from '../store/NotificationStore'

const initialFileBrowser = {
  rootFolderId: 'root-folder',
  fileMap: {
    'root-folder': {
      id: 'root-folder',
      name: 'Tous les dossiers',
      isDir: true,
      childrenIds: [],
      childrenCount: 0,
    },
  },
}

const KioskCommunicationToolsView: FC = () => {
  const queryClient = useQueryClient()
  const getNotificationsKey = ['user', 'getUserNotifications']

  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.communicationTools')
  const [currentFolderId, setCurrentFolderId] = useState<string>(initialFileBrowser.rootFolderId)

  const { data: communicationsTools, refetch, isLoading } = useQuery({
    queryKey: ['communicationsTools', 'getCommunicationTools'],
    queryFn: () => kioskService.getKioskCommunicationTools(),
  })

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddCommunicationToolsOpen, toggle: toggleModalCommunicationToolsRecord } = useToggle()

  const { mutate: updateCommunicationTools } = useMutation(
    ['communicationTools', 'updateCommunicationTools'],
    async (fileBrowserMapData: FileBrowserFileMapNode) => {
      await kioskService.updateCommunicationTools({
        fileBrowser: {
          rootFolderId: communicationsTools?.fileBrowser?.rootFolderId || initialFileBrowser.rootFolderId,
          fileMap: fileBrowserMapData,
        },
      })
    },
    {
      onSuccess: async () => {
        refetch()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const toggleModal = () => {
    toggleModalCommunicationToolsRecord()
    refetch()
  }

  const uploadNewFiles = async (newFileList: File[]) => {
    return await documentService.uploadDocuments(
      newFileList,
      'kiosk-communication-tools',
      Math.floor(Math.random() * 10000000),
      'documents',
      DocumentTypeEnum.KIOSK_COMMUNICATION_TOOLS,
    )
  }

  const addFile = async (newFileList: File[]) => {
    await uploadNewFiles(newFileList).then(async (uploadedFileList) => {
      if (uploadedFileList && Array.isArray(uploadedFileList)) {
        const fileBrowseData: FileBrowserFileMapNode = {}
        for (const uploadedFile of uploadedFileList) {
          fileBrowseData[uploadedFile.id] = {
            id: uploadedFile.id.toString(),
            size: parseInt(uploadedFile.size),
            name: uploadedFile.name,
            modDate: moment(uploadedFile.date, 'DD MMMM YYYY', 'fr').toDate(),
            parentId: currentFolderId,
            url: uploadedFile.url,
          }
        }

        await updateCommunicationTools({
          ...(communicationsTools?.fileBrowser?.fileMap || {}),
          ...fileBrowseData,
          [currentFolderId]: {
            ...(communicationsTools?.fileBrowser?.fileMap[currentFolderId].id
              ? communicationsTools?.fileBrowser?.fileMap[currentFolderId]
              : initialFileBrowser.fileMap['root-folder']),
            childrenIds: [
              ...(communicationsTools?.fileBrowser?.fileMap[currentFolderId].childrenIds || []),
              ...Object.keys(fileBrowseData),
            ],
          },
        })
      }
    })
  }

  useEffect(() => {
    const notifications = NotificationStore.getState()
    const boardNotifications = notifications.filter(
      (notification) => [NotificationType.KIOSK_COMMUNICATION_TOOLS].includes(notification.type) && !notification.view,
    )

    const now = new Date()
    if (boardNotifications.length > 0) {
      boardNotifications.forEach((notification) => {
        notificationService.update(notification.id, { view: now }).then(() => {
          queryClient.setQueryData(
            getNotificationsKey,
            notifications.map((item: NotificationInterface) => {
              if (item.type === NotificationType.KIOSK_COMMUNICATION_TOOLS) {
                return {
                  ...item,
                  view: now,
                }
              }
              return item
            }),
          )
        })
      })
    }
  }, [])

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && (
              <Button onClick={toggleModalCommunicationToolsRecord}>{t('kiosk.addCommunicationToolsFile')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          {communicationsTools && !isLoading && (
            <FileBrowser
              dataFiles={communicationsTools.fileBrowser || initialFileBrowser}
              newFileMap={(fileBrowserFileMapNode: FileBrowserFileMapNode) => {
                updateCommunicationTools(fileBrowserFileMapNode)
              }}
              readOnly={!canManageKiosk}
              getCurrentFolderId={(currentFolderId) => {
                setCurrentFolderId(currentFolderId)
              }}
            />
          )}
        </Section>
        <ModalAddFile
          show={isAddCommunicationToolsOpen}
          toggle={toggleModal}
          onChange={async (newFileList: File[]) => {
            await addFile(newFileList)
          }}
          modalTitle={'kiosk.addCommunicationToolsFile'}
        />
      </ViewBody>
    </View>
  )
}

export default KioskCommunicationToolsView
