import { ICON_SVG_MAP } from '../../components/atoms/icon/IconSvgMap'

export enum FileTypeEnum {
  PNG = 'png',
  JPEG = 'jpeg',
  JPG = 'jpg',
  PDF = 'pdf',
  MP3 = 'mp3',
  MP4 = 'mp4',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  UNKNOWN = '*',
}

interface FileType {
  iconName: keyof typeof ICON_SVG_MAP
}

interface FileTypes {
  [key: string]: FileType
}

export const FILE_TYPES: FileTypes = {
  [FileTypeEnum.PNG]: {
    iconName: 'FilePNG',
  },
  [FileTypeEnum.JPEG]: {
    iconName: 'FileJPG',
  },
  [FileTypeEnum.JPG]: {
    iconName: 'FileJPG',
  },
  [FileTypeEnum.PDF]: {
    iconName: 'FilePDF',
  },
  [FileTypeEnum.MP3]: {
    iconName: 'FileMP3',
  },
  [FileTypeEnum.MP4]: {
    iconName: 'FileMP4',
  },
  [FileTypeEnum.DOCX]: {
    iconName: 'FileDOCX',
  },
  [FileTypeEnum.XLS]: {
    iconName: 'FileXLS',
  },
  [FileTypeEnum.XLSX]: {
    iconName: 'FileXLS',
  },
  [FileTypeEnum.UNKNOWN]: {
    iconName: 'FileUNKNOWN',
  },
}
