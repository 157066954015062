import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Input, Label, ModalBody, ModalHeader } from 'reactstrap'
import { InstanceLetterComponentType } from 'types/KioskInstanceLetter'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'

import { DocumentType, DocumentTypeEnum } from '../../../types/Document'

import { documentService } from '../../../services/documentService'
import { kioskService } from '../../../services/kioskService'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import Modal from '../../atoms/modal/Modal'
import DocumentCarrousel from '../documents/DocumentCarrousel'
import './Modals.scss'

interface ModalAddInstanceLetterProps {
  toggle: () => void
  isOpen: boolean
  oldDocuments: InstanceLetterComponentType[]
}
const ModalAddInstanceLetter: FC<ModalAddInstanceLetterProps> = ({ toggle, isOpen, oldDocuments }) => {
  const initialState = {
    year: new Date().getFullYear(),
    files: [],
  }
  const { t } = useTranslation()
  const [filterValues, setFilterValues] = useState<{
    year?: number
    files: File[]
  }>(initialState)

  const { mutate: uploadFoundingText, isLoading: isUploadLoading } = useMutation(
    async () => {
      if (!filterValues.year || filterValues.files.length === 0) {
        throw t('toastify.errors.kioskUpload')
      }
      const documents = await documentService.uploadDocuments(
        filterValues.files,
        'instance-letter',
        Math.floor(Math.random() * 10000000),
        'documents',
        DocumentTypeEnum.KIOSK_INSTANCE_LETTER,
      )
      const newActivityReports = documents.map((doc) => ({
        year: filterValues.year,
        media: doc.id,
      }))

      await kioskService.updateInstanceLetter({
        documents: [
          ...oldDocuments.map((oldDocument) => ({
            id: oldDocument.id,
            year: oldDocument.year,
            media: oldDocument.media.id,
          })),
          ...newActivityReports,
        ],
      })
    },
    {
      mutationKey: ['update', 'instanceLetter'],
      onError: () => {
        notifyError(t('toastify.errors.instanceLetterUpload'))
      },
      onSuccess: () => {
        setFilterValues(initialState)
        toggle()
        notifySuccess(t('toastify.success.instanceLetterUploaded'))
      },
    },
  )

  const changeFilterValues = (field: string, value: string | number | File[] | undefined) => {
    if (field === 'files') {
      value = [...filterValues.files, ...(value as File[])]
    }
    setFilterValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onDeleteDocument = (documentList: DocumentType[]): void => {
    const docsName = documentList.map((documentList) => documentList.name)
    setFilterValues((prevState) => ({
      ...prevState,
      files: filterValues.files.filter((file) => !docsName.includes(file.name)),
    }))
  }

  const onSubmit = () => {
    return !isUploadLoading && uploadFoundingText()
  }

  return (
    <Modal toggle={toggle} isOpen={isOpen} size='xl'>
      <ModalHeader toggle={toggle}>{t('kiosk.addInstanceLetter')}</ModalHeader>
      <ModalBody>
        <div className='col-12 mb-4'>
          <Label>{t('common.year')}</Label>
          <Input
            type='number'
            className='my-0'
            value={filterValues.year}
            onChange={(val) => changeFilterValues('year', parseInt(val.target.value))}
          />
        </div>
        <DocumentCarrousel
          fileList={(filterValues.files as unknown) as DocumentType[]}
          onDeleteDocuments={onDeleteDocument}
          closable={false}
          deletable={true}
          checkable={true}
          downloadable={false}
        />
        <Dropzone
          multiple={true}
          maxFiles={10}
          className='d-block my-3'
          label={t('meeting.dropzonePlaceholder')}
          addNewFiles={(newFileList) => {
            changeFilterValues('files', newFileList)
          }}
        />
        <Button className='mx-auto px-5' isLoading={isUploadLoading} onClick={onSubmit}>
          {!isUploadLoading && t('common.add')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddInstanceLetter
