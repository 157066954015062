import { DocumentFilterType } from '../../types/Filters'

const NUMBER_OF_DOCUMENTS_DISPLAYED = 20

export const initialFilter: DocumentFilterType = {
  instanceList: [],
  page: 1,
  limit: NUMBER_OF_DOCUMENTS_DISPLAYED,
  favorite: false,
}
