import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'
import { usePermissions } from '../../../utils/hooks/usePermissions'

import { DocumentType, DocumentTypeEnum } from '../../../types/Document'
import { FoundingTextComponentType } from '../../../types/KioskFoundingTexts'

import { associationService } from '../../../services/associationService'
import { documentService } from '../../../services/documentService'
import { kioskService } from '../../../services/kioskService'
import { InstanceListStore } from '../../../store/InstanceListStore'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import Modal from '../../atoms/modal/Modal'
import DocumentCarrousel from '../documents/DocumentCarrousel'
import AssociationSelectFilter from '../filters/AssociationSelectFilter'
import './Modals.scss'

interface ModalAddFoundingTextProps {
  toggle: () => void
  isOpen: boolean
  oldDocuments: FoundingTextComponentType[]
}

const ModalAddFoundingText: FC<ModalAddFoundingTextProps> = ({ toggle, isOpen, oldDocuments }) => {
  const initialState = {
    associationId: undefined,
    files: [],
  }

  const { t } = useTranslation()
  const { USER_IS_COLLABORATOR_MANAGER_IN_INSTANCE } = usePermissions()
  const [filterValues, setFilterValues] = useState<{
    associationId?: string
    files: File[]
  }>(initialState)

  const myInstances = useStore(InstanceListStore).filter((instance) =>
    USER_IS_COLLABORATOR_MANAGER_IN_INSTANCE(instance.id),
  )

  const associationList = associationService.getAssociationFromInstanceList(myInstances)

  const { mutate: uploadFoundingText, isLoading: isUploadLoading } = useMutation(
    async () => {
      if (!filterValues.associationId && filterValues.files.length > 0) {
        throw t('toastify.errors.foundingTextUpload')
      }

      const documents = await documentService.uploadDocuments(
        filterValues.files,
        'kiosk.founding-text',
        Math.floor(Math.random() * 10000000),
        'documents',
        DocumentTypeEnum.KIOSK_FOUNDING_TEXTS,
      )

      await kioskService.updateFoundingText({
        documents: [
          ...oldDocuments.map((oldDocument) => ({
            id: oldDocument.id,
            association: oldDocument.association.id,
            media: oldDocument.media.id,
          })),
          ...documents.map((doc) => ({
            association: parseInt(filterValues.associationId || ''),
            media: doc.id,
          })),
        ],
      })
    },
    {
      mutationKey: ['update', 'foundingText'],
      onError: () => {
        notifyError(t('toastify.errors.foundingTextUpload'))
      },
      onSuccess: () => {
        setFilterValues(initialState)
        toggle()
        notifySuccess(t('toastify.success.decisionRecordUploaded'))
      },
    },
  )

  const changeFilterValues = (field: string, value: string | number | File[] | undefined) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onSubmit = () => !isUploadLoading && uploadFoundingText()

  return (
    <Modal toggle={toggle} isOpen={isOpen} size='xl'>
      <ModalHeader toggle={toggle}>{t('kiosk.addFoundingText')}</ModalHeader>
      <ModalBody>
        <div className='ModalAddDecisionRecord col-8 mb-4'>
          <AssociationSelectFilter
            className='my-0'
            associationId={filterValues.associationId}
            associationList={associationList}
            onAssociationSelect={(associationId) => {
              changeFilterValues('associationId', isNaN(associationId) ? undefined : associationId)
            }}
          />
        </div>
        {filterValues.files?.length > 0 ? (
          <DocumentCarrousel
            fileList={(filterValues.files as unknown) as DocumentType[]}
            closable={false}
            checkable={false}
            downloadable={false}
          />
        ) : (
          <Dropzone
            className='d-block my-3'
            label={t('meeting.dropzonePlaceholder')}
            addNewFiles={(newFileList) => {
              changeFilterValues('files', newFileList)
            }}
          />
        )}
        <Button className='mx-auto px-5' isLoading={isUploadLoading} onClick={onSubmit}>
          {!isUploadLoading && t('common.add')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddFoundingText
