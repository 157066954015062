import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import ReactQuill from 'react-quill'
import { Col, Form, Input, ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { EmailValidationSchema } from '../../../../utils/constants/Email'
import { fetchFileFromUrl } from '../../../../utils/fileUtils'

import { Email } from '../../../../types/Email'
import { MailHistory } from '../../../../types/MailHistory'

import { instanceService } from '../../../../services/instanceService'
import Button from '../../../atoms/button/Button'
import TextInput from '../../../atoms/input/TextInput'
import Row from '../../../atoms/layout/Row'
import Modal from '../../../atoms/modal/Modal'
import AttachmentCard from '../../documents/AttachmentCard'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['blockquote'],

  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  ['link', 'image'],
  ['clean'], // remove formatting button
]

interface ModalMailDisplayProps {
  isOpen: boolean
  toggle: () => void
  mail: MailHistory
  isEditable: boolean
  answerMode: boolean
}

//TODO: refetch on sucess

const ModalMailDisplay: FC<ModalMailDisplayProps> = ({
  isOpen,
  toggle,
  mail,
  isEditable,
  answerMode,
}: ModalMailDisplayProps) => {
  const [mailState, setContactState] = useState(mail)
  const { register, handleSubmit, setValue, reset, errors } = useForm<MailHistory>({
    mode: 'onChange',
    resolver: yupResolver(EmailValidationSchema(false)),
  })
  const [files, setFiles] = useState<File[]>([])

  const { t } = useTranslation()
  const onReactQuillChange = (value: string) => setValue('content', value)

  async function loadFileFromDocType() {
    const fileListFromUrl = await Promise.all(
      mail.attachment.map(async (attachement) => {
        return await fetchFileFromUrl(attachement.url, attachement.name)
      }),
    )
    setFiles(fileListFromUrl)
  }

  useEffect(() => {
    reset(mail)
    setContactState(mail)

    loadFileFromDocType()
  }, [mail])

  const { mutate, isLoading } = useMutation(
    (emailData: Email): Promise<void> => {
      return instanceService.sendEmail(mail.reunion.id, emailData)
    },
    {
      mutationKey: ['instance', 'sendEmail', 'mailHistory'],
      onSuccess: async () => {
        notifySuccess(t('toastify.success.emailSuccessfullySent'))
        toggle()
      },
      onError: () => notifyError(t('toastify.errors.unknown')),
    },
  )
  const onSubmit = (emailData: Email) => {
    if (!isLoading) mutate({ ...emailData, attachments: files })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader toggle={toggle}>
        {mailState && <span className='d-flex'>{t('email.editTemplate')}</span>}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column flex-grow-1'>
          <TextInput
            type='text'
            placeholder={t('email.subject')}
            innerRef={register}
            name='subject'
            readOnly={true}
            error={errors?.subject?.message && t(errors?.subject?.message)}
          />
          <TextInput
            className='mt-3'
            type='text'
            placeholder={t('email.cc')}
            innerRef={register}
            name='cc'
            readOnly={isEditable ? isEditable : answerMode}
            defaultValue={mail.Cc.join('; ')}
          />
          <div className='my-4'>
            {t('email.attachments')}
            <Row grid className='overflow-auto' flexWrap='nowrap'>
              {mail?.attachment.map((file) => (
                <Col xs={2} key={file.name}>
                  <AttachmentCard file={file} />
                </Col>
              ))}
            </Row>
          </div>
          <Input name='content' innerRef={register} hidden />
          <ReactQuill
            className='Quill'
            readOnly={isEditable ? isEditable : !answerMode}
            value={mail?.content}
            onChange={onReactQuillChange}
            modules={{
              toolbar: toolbarOptions,
            }}
          />
          {!isEditable && (
            <Button type='submit' className='mx-auto mt-3'>
              {t('common.submit')}
            </Button>
          )}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalMailDisplay
