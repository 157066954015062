export const PATHS = {
  DASHBOARD: '/tableau-de-bord',
  USER_PROFILE: '/mon-profil',
  DOCUMENTS: '/documents',
  NEWS: {
    HOME: '/actualites',
    PAGE: '/actualites/:slug',
  },
  KIOSK: {
    HOME: '/kiosque',
    FOUNDING_TEXTS: '/kiosque/textes-fondateurs',
    ACTIVITY_REPORTS: '/kiosque/rapports-activite',
    SUMMARY_BOARD_DIRECTORS: '/kiosque/synthese-ca-opco2i',
    ACTIVITY: '/kiosque/activite',
    FORMATIONS: '/kiosque/formations',
    COMMUNICATION_TOOLS: '/kiosque/outils-de-communications',
    DECISIONS_RECORDS: {
      ALL_INSTANCES: '/kiosque/releves-decisions',
      INSTANCE: '/kiosque/releves-decisions/:id',
    },
    OBJECTIVES_CONVENTION: '/kiosque/convention-objectifs-et-moyens',
    EXPENSES_PROCEDURES: '/kiosque/procedures-notes-de-frais',
    FINANCIAL_DATA: '/kiosque/donnees-financieres',
    INSTANCE_LETTER: '/kiosque/lettre-instance',
  },
  CONTACTS: {
    HOME: '/contacts',
  },
  USER_MANAGEMENT: {
    HOME: '/user-management',
    CREATE_USER: '/user-management/creation',
    INSTANCE: '/user-management/instances/:id',
    ALL_INSTANCES: '/user-management/instances',
    MANDATES: '/user-management/:id/mandates',
    MANDATES_RIGHTS: '/user-management/:id/mandates/:idMandate/rights',
  },
  MAIL_HISTORY: {
    HOME: '/mail-history',
  },
  MAIL_HISTORY_FORGOT_PWD: {
    HOME: '/forgotten-password',
  },
  FAVORITES: {
    HOME: '/favorites',
  },
  MEETINGS: {
    MEETING: '/reunions',
    MEETING_CREATE: '/reunions/:action(create)/:date?',
    MEETING_DETAILS: '/reunions/:id',
    MEETING_UPDATE: '/reunions/:id/:action(update)',
    MEETINGS_PARTICIPANTS: '/reunions/:id/membres',
  },
  HELP: { HOME: '/aide', MANAGER: '/aide/gestionnaires' },
  LOGIN: '/login',
  SEARCH: '/recherche',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  COMPONENTS_EXAMPLE: '/composants',
  VALIDATE_NEW_EMAIL: '/email/validate/:slug',
}
