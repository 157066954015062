import React, { FC } from 'react'
import { DocumentType } from 'types/Document'

import { BASE_PATH } from '../../../api/api'
import Figure from '../figure/Figure'

interface Props {
  video: DocumentType
  onClick?: (video: DocumentType) => void
}

// On charge uniquement les métadonnées pour n'afficher que la première frame en preview
const VideoThumbnail: FC<Props> = ({ video, onClick }) => {
  const videoClassName = 'w-100' + (onClick ? ' cursor-pointer' : '')
  return (
    <Figure>
      <video
        src={BASE_PATH + video.url}
        preload='metadata'
        className={videoClassName}
        onClick={() => onClick?.(video)}
      />
    </Figure>
  )
}

export default VideoThumbnail
