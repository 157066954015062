import _ from 'lodash'
import qs from 'qs'
import { generatePath } from 'react-router'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { AbsenceOption } from '../types/AbsenceOption'
import { BinaryDataType } from '../types/File'
import { AbsenceOptionType, AbsenceValidationType, Invitation } from '../types/Invitation'
import { QsQueryParamsType } from '../types/Params'

import { execute, executeFormData } from '../api/api'

const getAll = (): Promise<Invitation[]> => {
  return execute<Invitation[]>(ApiRoutes.INVITATIONS.BASE, 'GET')
}

// TODO Max : Strapi : Route pour ne récupérer que ses invitations
// TODO Max : Utiliser QS au lieu de mettre la query en dur
const getAllMyInvitations = (): Promise<Invitation[]> => {
  return execute<Invitation[]>(
    `${ApiRoutes.INVITATIONS.BASE}?_sort=meeting.start_date:ASC,meeting.start_time:ASC`,
    'GET',
  )
}

const getAllInvitationsByMeeting = (meetingId: string): Promise<Invitation[]> => {
  return execute<Invitation[]>(generatePath(ApiRoutes.MEETINGS.INVITATIONS, { id: meetingId }), 'GET')
}

const createInvitation = (meetingId: string, userId: string): Promise<unknown> => {
  return execute(ApiRoutes.INVITATIONS.BASE, 'POST', { meetingId, userId })
}

const updateInvitation = async (id: string | number, value: Record<string, unknown>): Promise<Invitation> => {
  return execute(generatePath(ApiRoutes.INVITATIONS.ID, { id }), 'PUT', { ...value })
}

const postAbsenceDelegation = async (
  invitationId: number,
  userId?: string,
  file?: File,
  absenceOption?: AbsenceOptionType,
  absence_validation?: AbsenceValidationType,
): Promise<Invitation> => {
  const formData = new FormData()
  userId && formData.append('substituteUserId', userId.toString())
  file && formData.append('absence_justificatif', file)
  formData.append('absence_option', absenceOption || '')
  formData.append('absence_validation', absence_validation || 'PENDING')

  return executeFormData<Invitation>(generatePath(ApiRoutes.INVITATIONS.ID, { id: invitationId }), 'PUT', formData)
}

const getDelegationTemplate = async (
  invitationId: number,
  absenceOption: AbsenceOptionType,
): Promise<BinaryDataType> => {
  const path =
    absenceOption === AbsenceOption.POWER
      ? ApiRoutes.INVITATIONS.ID_POWER_TEMPLATE
      : ApiRoutes.INVITATIONS.ID_REPLACEMENT_TEMPLATE

  return execute<BinaryDataType>(generatePath(path, { id: invitationId }), 'GET')
}

const deleteInvitations = (invitationList: Invitation[]): Promise<unknown> => {
  const query: QsQueryParamsType = {
    invitationIdList: _.map(invitationList, 'id'),
  }
  const qsQuery = `?${qs.stringify(query)}`
  return execute(`${ApiRoutes.INVITATIONS.BASE}${qsQuery}`, 'DELETE', { invitationIdList: _.map(invitationList, 'id') })
}

export const invitationService = {
  getAll,
  getAllMyInvitations,
  getAllInvitationsByMeeting,
  createInvitation,
  updateInvitation,
  postAbsenceDelegation,
  getDelegationTemplate,
  deleteInvitations,
}
