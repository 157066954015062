import Card from 'components/atoms/card/Card'
import Row from 'components/atoms/layout/Row'
import MandateInstanceAssociationName from 'components/molecules/mandate/MandateInstanceAssociationName'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardFooter, CardText, CardTitle, Col } from 'reactstrap'
import { isCollaboratorOnAllPerimeter } from 'utils/hooks/usePermissions'

import { getUniqueInstancesFromMembersAndCollaborators, isMandateCurrent } from '../../../../utils/mandateUtils'

import { Instance } from '../../../../types/Instance'
import { User } from '../../../../types/User'

import { InstanceListStore } from '../../../../store/InstanceListStore'
import Badge from '../../../atoms/badge/Badge'
import UserName from '../../../atoms/custom/UserName'
import TooltipWithLabel from '../../../atoms/tooltip/TooltipWithLabel'
import ProfilePicture from '../../profile/ProfilePicture'
import './ContactCard.scss'

const NUMBER_OF_BADGES_SHOWN = 2

const ContactCard: FC<User> = (props) => {
  const user = props

  let instances: Instance[] = []
  const userHasAllPerimeter = isCollaboratorOnAllPerimeter(user.collaborators)

  if (!userHasAllPerimeter) {
    const allInstanceList = InstanceListStore.getState() || []
    const currentMembers = user.members.filter((member) => isMandateCurrent(member.start_date, member.end_date))
    const currentCollaborators = user.collaborators.filter((collab) =>
      isMandateCurrent(collab.start_date, collab.end_date),
    )
    instances = getUniqueInstancesFromMembersAndCollaborators(currentMembers, currentCollaborators, allInstanceList)
  }
  const { t } = useTranslation()

  const contactHasAllPerimeter = isCollaboratorOnAllPerimeter(user.collaborators)

  const additionnalBadgesCount = contactHasAllPerimeter ? 0 : (instances?.length || 0) - NUMBER_OF_BADGES_SHOWN
  return (
    <Card className='ContactCard' clickable>
      <CardBody>
        <ProfilePicture user={user} hideProfilePicture={user.hide_profile_picture} className='ContactCardFigure' />

        <CardTitle className='text-white bg-primary border-radius-md'>
          <UserName user={user} />
        </CardTitle>
        <CardText>{user.email}</CardText>
        <CardText>{!user.hide_telephone && user.telephone}</CardText>
        <CardText>{user.organization}</CardText>
      </CardBody>
      <CardFooter className='flex-grow-1'>
        <Row grid gutters='no' className='Badges'>
          {!instances || instances.length === 0 ? (
            <Col xs='12'>
              <p className='legend'>{t('contacts.noAssociation')}</p>
            </Col>
          ) : contactHasAllPerimeter ? (
            <Col xs='auto'>
              <Badge color='primary' outline filled className='ContactBadge'>
                <span>{t('common.fields.mandate.allPerimeter')}</span>
              </Badge>
            </Col>
          ) : (
            instances.map((instance, index) => {
              if (index < NUMBER_OF_BADGES_SHOWN)
                return (
                  <Col xs='auto' key={`userCard_${user.id}_instance_${instance.id}`}>
                    <TooltipWithLabel
                      target={`badge_${user.id}_${instance.id}`}
                      labelClassName='w-100'
                      label={
                        <Badge color='primary' outline filled className='ContactBadge'>
                          <MandateInstanceAssociationName instance={instance} nameType='short_name' />
                        </Badge>
                      }
                    >
                      <MandateInstanceAssociationName instance={instance} nameType='name' />
                    </TooltipWithLabel>
                  </Col>
                )
            })
          )}
          {additionnalBadgesCount > 0 && (
            <Col xs='auto'>
              <Badge color='medium' className='ContactBadge d-flex align-items-center ml-3'>
                + {additionnalBadgesCount}
              </Badge>
            </Col>
          )}
        </Row>
      </CardFooter>
    </Card>
  )
}

export default ContactCard
