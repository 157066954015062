import Section from 'components/atoms/layout/Section'
import KioskArticles from 'components/molecules/kiosk/kiosk-articles/KioskArticles'
// import KioskVideos from 'components/molecules/kiosk/kiosk-videos/KioskVideos'
// import RefundCard from 'components/molecules/refund-card/RefundCard'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem } from 'reactstrap'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'

const KioskView: FC = () => {
  const { t } = useTranslation()

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>{t('nav.kiosk')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <KioskArticles />
        </Section>
        {/* Masqué pour le moment */}
        {/* <Section fluid>
          <RefundCard />
        </Section> */}
        {/*<Section fluid>*/}
        {/*  <LastArticles />*/}
        {/*</Section>*/}
        {/*<Section fluid>*/}
        {/*  <KioskVideos />*/}
        {/*</Section>*/}
      </ViewBody>
    </View>
  )
}

export default KioskView
