import { FC } from 'react'
import { InputProps as RSInputProps } from 'reactstrap'

import TextInput from './TextInput'

type TextAreaProps = RSInputProps & {
  size?: 'sm' | 'lg'
  rows?: number
}

const TextareaInput: FC<TextAreaProps> = (props) => {
  return <TextInput type={'textarea'} {...props} />
}

export default TextareaInput
