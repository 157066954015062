import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import Header from 'components/molecules/heading/Header'
import { useStore } from 'effector-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import { PATHS } from 'utils/constants/routes/RoutePaths'

import { GENERAL_PERMISSION } from '../utils/constants/Permissions'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'
import { filterUserInstance, rearrangeInstanceListByAssociation } from '../utils/instances'

import Button from '../components/atoms/button/Button'
import KioskInstanceCard from '../components/molecules/kiosk/kiosk-rubrics/KioskInstanceCard'
import ModalAddDecisionRecord from '../components/molecules/modals/ModalAddDecisionRecord'

import { InstanceListStore } from '../store/InstanceListStore'
import { UserStore } from '../store/UserStore'

const KioskDecisionsRecordsAllInstancesView: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useStore(UserStore) || undefined

  const { CAN_MANAGE_DECISION_RECORDS, HAS_GENERAL_PERMISSION } = usePermissions()

  const [canManageDecisionRecords, canAccessDecisionRecordOfOpco2i] = [
    CAN_MANAGE_DECISION_RECORDS(),
    HAS_GENERAL_PERMISSION(GENERAL_PERMISSION.KIOSK_ACCESS_ALL_DECISION_RECORD),
  ]

  const { state: isAddDecisionRecordOpen, toggle: toggleModalAddDecisionRecord } = useToggle()

  const associationKeyInstanceValueList = user
    ? rearrangeInstanceListByAssociation(
        filterUserInstance(useStore(InstanceListStore), user, canAccessDecisionRecordOfOpco2i),
      )
    : []

  const onCardClick = (href: string) => history.push(href)

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{t('kiosk.rubrics.decisionsRecords')}</BreadcrumbItem>
            </Breadcrumb>
            {canManageDecisionRecords && (
              <Button onClick={toggleModalAddDecisionRecord}>{t('kiosk.addDecisionRecords')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {associationKeyInstanceValueList.map(({ association, instanceList }) => (
            <KioskInstanceCard
              instanceList={instanceList}
              association={association}
              onClick={onCardClick}
              key={association.id}
            />
          ))}
        </Section>
        <Section fluid>
          {associationKeyInstanceValueList.length <= 0 && <span>{t('instances.noInstance')}</span>}
        </Section>
        <ModalAddDecisionRecord toggle={toggleModalAddDecisionRecord} isOpen={isAddDecisionRecordOpen} />
      </ViewBody>
    </View>
  )
}

export default KioskDecisionsRecordsAllInstancesView
