import { Instance } from './Instance'
import { MemberStatusType } from './MemberStatus'
import { User, UserManagerRights } from './User'

export enum MemberTypeEnum {
  MANDAT_TEMPORAIRE = 'MANDAT_TEMPORAIRE',
}

export enum MemberFunctionEnum {
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  SECRETAIRE = 'SECRETAIRE',
  SECRETAIRE_ADJOINT = 'SECRETAIRE_ADJOINT',
  TRESORIER = 'TRESORIER',
  TRESORIER_ADJOINT = 'TRESORIER_ADJOINT',
}

export enum MemberCollegeEnum {
  PATRONAL = 'PATRONAL',
  SALARIE = 'SALARIE',
}

export enum MemberRelationEnum {
  USER = 'USER',
  INSTANCE = 'INSTANCE',
}

export type MemberBaseType = {
  id: number
  status?: MemberStatusType
  function?: MemberFunctionEnum | null
  start_date?: string | null
  end_date?: string | null
}

export type Member = MemberBaseType & {
  instance: Instance
  users_permissions_user: User
}

export type MemberFormValues = MemberBaseType & {
  users_permissions_user: number
  instance: number
}

export type MemberForManagerType = Member & {
  is_current?: boolean
  has_manager_right?: boolean
}

export type UserMembersForManagerQuery = {
  userMemberList: MemberForManagerType[]
  managerRights: UserManagerRights
}

export type InstanceMembersForManagerQuery = {
  instanceMembersList: MemberForManagerType[]
  managerRights: UserManagerRights
}
