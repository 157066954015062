import * as Yup from 'yup'

import { ccRegexp } from './RegexConstants'

export const EmailValidationSchema = (isEditingTemplate: boolean): Yup.AnyObjectSchema =>
  Yup.object().shape(
    !isEditingTemplate
      ? {
          subject: Yup.string().trim().required('common.validation.required'),
          cc: Yup.string().matches(ccRegexp, 'common.validation.ccInvalid'),
        }
      : {},
  )
