import _ from 'lodash'
import { User } from 'types/User'

export const computeFullName = (user: User, withOrganisation = false): string => {
  return `${_.capitalize(user.firstname)} ${_.upperCase(user.lastname)} ${
    withOrganisation && user.organization ? `- ${user.organization}` : ' '
  }`
}
export const formatFirstname = (firstname: string): string => {
  const separators: string[] = [' ', '-']
  const regex = new RegExp('(^|[' + separators.join('') + '])(\\w)', 'g')
  return firstname.toLowerCase().replace(regex, function (x) {
    return x.toUpperCase()
  })
}
