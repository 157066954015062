import React, { FC, ReactNode } from 'react'
import { Table as RSTable, TableProps as RSTableProps } from 'reactstrap'

import './Table.scss'

interface TableProps extends RSTableProps {
  children: ReactNode
  className?: string
}

const Table: FC<TableProps> = (props) => {
  const { children, className = '', responsive = true, hover = false } = props

  return (
    <RSTable className={`Table ${className}`} responsive={responsive} hover={hover}>
      {children}
    </RSTable>
  )
}

export default Table
