import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { DocumentType } from 'types/Document'
import { HelpTutorialTypeEnum } from 'utils/constants/Help'

import DocumentsListsWithViewer from '../documents/DocumentsListsWithViewer'
import VideosViewer from '../videos/VideosViewer'

interface HelpSectionProps {
  slidesList?: DocumentType[]
  videoList?: DocumentType[]
}

const HelpSection: FC<HelpSectionProps> = ({ videoList, slidesList }) => {
  const { t } = useTranslation()

  return (
    <Section fluid>
      <Row grid>
        <Col>
          {!!videoList?.length && <VideosViewer videoList={videoList} />}
          {!videoList?.length && (
            <span>
              {t('help.noDocument', {
                type: HelpTutorialTypeEnum.VIDEO.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Col>
      </Row>
      <Row grid>
        <Col>
          {!!slidesList?.length && (
            <DocumentsListsWithViewer
              lists={[
                {
                  label: t('help.userManuals').toLocaleUpperCase(),
                  documents: slidesList,
                },
              ]}
            />
          )}
          {!slidesList?.length && (
            <span>
              {t('help.noDocument', {
                type: HelpTutorialTypeEnum.SLIDES.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Col>
      </Row>
    </Section>
  )
}

export default HelpSection
