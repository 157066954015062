import MandateInstanceAssociationName from 'components/molecules/mandate/MandateInstanceAssociationName'
import ConfirmationModal from 'components/molecules/modals/ConfirmationModal'
import { capitalize } from 'lodash'
import React, { ChangeEvent, FC, Ref, useState } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Member, MemberRelationEnum } from 'types/Member'
import { USER_COLLEGE_ENUM } from 'utils/constants/College'
import { usePermissions } from 'utils/hooks/usePermissions'

import MandateDateCell from '../tables-cells/MandateDateCell'
import MemberFunctionCell from '../tables-cells/MemberFunctionCell'
import MemberStatusCell from '../tables-cells/MemberStatusCell'
import './RenderMember.scss'

interface RenderMemberProps {
  member: Member
  index: number
  errors: DeepMap<
    {
      data: Member[]
    },
    FieldError
  >
  register: Ref<HTMLInputElement>
  canEdit: boolean
  submitUpdate: (memberId: number, data: Partial<Member>) => void
  submitDelete: (memberId: number) => void
  current: boolean
  relation: MemberRelationEnum
}

const RenderMember: FC<RenderMemberProps> = ({
  member: {
    instance,
    users_permissions_user: { firstname, lastname, email, organization, college },
    ...member
  },
  index,
  errors,
  register,
  canEdit,
  submitUpdate,
  submitDelete,
  current,
  relation,
}) => {
  const submitDate = (e: ChangeEvent<HTMLInputElement>, dateType: 'start_date' | 'end_date') => {
    submitUpdate(member.id, { [dateType]: e.target.value })
  }

  function deleteMember(id: number) {
    submitDelete(id)
  }

  const { t } = useTranslation()

  const { IS_SUPER_ADMIN } = usePermissions()

  const [isOpenModal, setOpenModal] = useState(false)

  return (
    <tr key={member.id} className={current ? '' : 'member-row-archived'}>
      {relation === MemberRelationEnum.USER && (
        <td>
          <MandateInstanceAssociationName instance={instance} nameType='long_name' />
        </td>
      )}
      {relation === MemberRelationEnum.INSTANCE && (
        <>
          <td>{lastname?.toUpperCase()}</td>
          <td>{capitalize(firstname)}</td>
          <td>{email}</td>
          <td>
            {`${college && USER_COLLEGE_ENUM[college] ? t(USER_COLLEGE_ENUM[college].shortLabel) + ' ' : ''}${
              organization || ''
            }`}
          </td>
        </>
      )}
      <MandateDateCell
        error={errors.data?.[index]?.start_date?.message}
        id={`data[${index}].start_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={member.id}
        dateType='start_date'
      />
      <MandateDateCell
        error={errors.data?.[index]?.end_date?.message}
        id={`data[${index}].end_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={member.id}
        dateType='end_date'
      />
      <MemberFunctionCell
        id={`data[${index}].function`}
        register={register}
        disabled={!canEdit}
        submit={submitUpdate}
        memberId={member.id}
      />
      <MemberStatusCell
        id={`data[${index}].status`}
        register={register}
        disabled={!canEdit}
        submit={submitUpdate}
        memberId={member.id}
      />
      {IS_SUPER_ADMIN() && (
        <td>
          <button type='button' onClick={() => setOpenModal(true)} className='btn btn-default btn-sm my-1'>
            &#10060;
          </button>

          <ConfirmationModal
            show={isOpenModal}
            toggle={() => setOpenModal(false)}
            onChange={() => {
              deleteMember(member.id)
              setOpenModal(false)
            }}
          />
        </td>
      )}
    </tr>
  )
}

export default RenderMember
