import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Label } from 'reactstrap'

import { MEETING_DROPDOWN, MEETING_ENUM } from '../../../utils/constants/Meeting'

import TextInput from '../../atoms/input/TextInput'

interface MeetingDropdownProps {
  onMeetingFilterChange: (id: MEETING_ENUM) => void
  meetingFilterId?: MEETING_ENUM
}
const MeetingSelectFilter: FC<MeetingDropdownProps> = (props) => {
  const { onMeetingFilterChange, meetingFilterId } = props
  const { t } = useTranslation()

  const onSelectedValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const meetingDropdownId = parseInt(event.target.value)
    onMeetingFilterChange(meetingDropdownId)
  }

  return (
    <FormGroup>
      <Label>{t('meeting.filters.meetings')}</Label>
      <TextInput type='select' id='statusSelect' value={meetingFilterId} onChange={onSelectedValueChange}>
        {Object.entries(MEETING_DROPDOWN).map(([key, value]) => (
          <option key={`select_${value.label}`} value={key}>
            {t(value.label)}
          </option>
        ))}
      </TextInput>
    </FormGroup>
  )
}

export default MeetingSelectFilter
