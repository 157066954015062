import CustomInput from 'components/atoms/input/CustomInput'
import React, { ChangeEvent, FC, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { CollaboratorForManagerType } from 'types/Collaborator'

interface CollaboratorStateCellProps {
  id: string
  register: Ref<HTMLInputElement>
  disabled: boolean
  submit: (collaboratorId: number, data: Partial<CollaboratorForManagerType>) => void
  collaboratorId: number
}

const CollaboratorStateCell: FC<CollaboratorStateCellProps> = ({ register, id, disabled, submit, collaboratorId }) => {
  const { t } = useTranslation()

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => submit(collaboratorId, { ['is_manager']: e.target.checked })

  // TODO Nico: Déplacer CustomInput dans les composants
  return (
    <td className='align-middle'>
      <CustomInput
        className='text-center'
        id={id}
        name={id}
        type='switch'
        disabled={disabled}
        innerRef={register}
        // TODO Nico: Ajouter lecture en label ?
        label={t('common.fields.mandate.manager')}
        onChange={onCheck}
      />
    </td>
  )
}

export default CollaboratorStateCell
