import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardTitle } from 'reactstrap'

import { User } from '../../../types/User'

import Card from '../../atoms/card/Card'
import ContactCardList from '../contacts/ContactCardList'
import ModalContact from '../modals/contacts/ModalContact'
import './Search.scss'

interface SearchUsersProps {
  users: User[]
}

const SearchUsers: FC<SearchUsersProps> = ({ users }) => {
  const { t } = useTranslation()
  const [selectedUser, setSelectedUser] = useState<User>()

  const onModalClose = () => {
    setSelectedUser(undefined)
  }

  const onContactClicked = (user: User) => {
    setSelectedUser(user)
  }

  return (
    <Card className='search-card'>
      <CardBody>
        <CardTitle>{t('search.users.title')}</CardTitle>
        {users.length > 0 ? (
          <ContactCardList contactList={users} onContactClicked={onContactClicked} contactWithoutMandate={true} />
        ) : (
          <div className='no-result d-flex flex-column justify-content-center'>
            <p>{t('search.noResult')}</p>
          </div>
        )}
      </CardBody>
      <ModalContact isOpen={!!selectedUser} toggle={onModalClose} contact={selectedUser} editRight={false} />
    </Card>
  )
}

export default SearchUsers
