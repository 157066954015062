import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import { FileBrowserFileMapNode, InputDataNode } from '../../../../utils/treeUtils'

import { DocumentType } from '../../../../types/Document'

import Dropzone from '../../../atoms/dropzone/Dropzone'
import Row from '../../../atoms/layout/Row'
import Modal from '../../../atoms/modal/Modal'
import './FileSelectionModal.scss'

interface FileSelectionModalProps {
  currentTreeItem?: InputDataNode
  show: boolean
  toggle: () => void
  uploadNewFilesHandler?: (newFileList: File[]) => Promise<DocumentType[]>
  documentCheckable?: boolean
  title: string
  currentDirectory?: string
  onChange: (inputDataNode: InputDataNode) => void
}

const FileSelectionModal: FC<FileSelectionModalProps> = (props) => {
  const { currentTreeItem, show, toggle, uploadNewFilesHandler, title, currentDirectory, onChange } = props

  const { t } = useTranslation()

  const [newFileBrowserData, setNewFileBrowserData] = useState<FileBrowserFileMapNode>()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [backdrop, setBackdrop] = useState<boolean | 'static'>(true)

  useEffect(() => {
    if (currentTreeItem?.fileBrowserData && newFileBrowserData && currentDirectory) {
      const fileBrowser = {
        ...currentTreeItem.fileBrowserData,
        fileMap: {
          ...currentTreeItem.fileBrowserData.fileMap,
          ...newFileBrowserData,
          [currentDirectory]: {
            ...currentTreeItem.fileBrowserData.fileMap[currentDirectory],
            childrenIds: [
              ...(currentTreeItem.fileBrowserData.fileMap[currentDirectory].childrenIds ?? []),
              ...Object.keys(newFileBrowserData ?? {}),
            ],
          },
        },
      }
      currentTreeItem.fileBrowserData = fileBrowser
      const newCurrentTreeItem: InputDataNode = {
        ...currentTreeItem,
        fileBrowserData: {
          ...currentTreeItem.fileBrowserData,
          ...fileBrowser,
        },
      }

      onChange(newCurrentTreeItem)
    }
  }, [newFileBrowserData])

  /**
   * On upload les fichiers puis on les ajoute au documents ddu point de l'ordre du jour
   * @param newFileList
   */
  const uploadNewFilesHandlerAndUpdateSelectFileList = (newFileList: File[]) => {
    if (uploadNewFilesHandler !== undefined) {
      setIsUploading(true)
      setBackdrop('static')
      return uploadNewFilesHandler(newFileList)
        .then((uploadedFileList) => {
          if (uploadedFileList && Array.isArray(uploadedFileList) && currentTreeItem) {
            const fileBrowseData: FileBrowserFileMapNode = {}
            for (const uploadedFile of uploadedFileList) {
              fileBrowseData[uploadedFile.id] = {
                id: uploadedFile.id.toString(),
                size: parseInt(uploadedFile.size),
                name: uploadedFile.name,
                modDate: moment(uploadedFile.date, 'DD MMMM YYYY', 'fr').toDate(),
                parentId: currentDirectory,
                url: uploadedFile.url,
              }
            }
            setNewFileBrowserData(fileBrowseData)
          }
          toggle()
        })
        .finally(() => {
          setIsUploading(false)
          setBackdrop(true)
        })
    }
  }

  const onClickClose = () => {
    if (isUploading) {
      return
    }
    toggle()
  }

  const closeBtn = (
    <button className='close' onClick={onClickClose} type='button'>
      &times;
    </button>
  )

  return (
    <>
      <Modal className='FileSelectionModal' isOpen={show} toggle={toggle} backdrop={backdrop} centered size='lg'>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {title}
        </ModalHeader>
        <ModalBody>
          <Row grid fluid className='p-3'>
            <Col>
              {isUploading ? (
                <>
                  <p>{t('meeting.uploading')}</p>
                  <Spinner />
                </>
              ) : (
                <Dropzone
                  label={t('meeting.dropzonePlaceholder')}
                  addNewFiles={uploadNewFilesHandlerAndUpdateSelectFileList}
                />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default FileSelectionModal
