import Row from 'components/atoms/layout/Row'
import Spinner from 'components/atoms/spinner/Spinner'
import Heading from 'components/molecules/heading/Heading'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { PATHS } from 'utils/constants/routes/RoutePaths'

import { notifyError } from '../../../utils/alertUtils'
import { useQuery } from '../../../utils/hooks/useQuery'

import { Article } from '../../../types/Article'

import { articleService } from '../../../services/articlesService'
import CardArticle from '../cards/article/CardArticle'
import './LastArticles.scss'

interface LastArticlesProps {
  currentArticleSlug?: string
}

const LastArticles: FC<LastArticlesProps> = (props) => {
  const { currentArticleSlug } = props
  const { t } = useTranslation()
  const link = {
    href: PATHS.NEWS.HOME,
    label: t('dashboard.seeAllArticles'),
  }

  const { isLoading, data: articles = [] } = useQuery<Article[]>({
    queryKey: ['article', 'getLastArticles', currentArticleSlug],
    queryFn: () => articleService.getLastArticles(currentArticleSlug),
    onError: () => notifyError(t('toastify.errors.get.article')),
  })

  if (isLoading) return <Spinner />

  return (
    <>
      <Heading className='mb-5' title={t('dashboard.lastArticles')} />
      <Row grid>
        {articles.length > 0 &&
          articles.map((article) => (
            <Col xs={12} sm={6} md={6} lg={6} xl={4} key={article.id}>
              <CardArticle {...article} />
            </Col>
          ))}
      </Row>
      <Heading title={t(' ')} link={link} arrowAsLinkBreakpoint='sm' />
      {articles.length === 0 && <p className='align-self-end'>{t('kiosk.article.noArticle')}</p>}
    </>
  )
}

export default LastArticles
