import { format } from 'date-fns'
import frenchLocale from 'date-fns/locale/fr'
import qs from 'qs'
import { generatePath } from 'react-router'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { Article } from '../types/Article'

import { execute } from '../api/api'

//---------------------------------- API ----------------------------------

// Le paramètre _sort ne fonctionne pas avec qs donc on le concatène au stringify des paramètres
const getAllArticles = (params?: { [key: string]: unknown }, sort = ''): Promise<Article[]> => {
  const queryParams = params ? qs.stringify(params) : ''
  const query = sort ? `?${queryParams}${queryParams && sort ? '&' : ''}${sort}` : ''

  return execute(`${ApiRoutes.ARTICLES.BASE}${query}`, 'GET')
}

const getFilteredArticles = (search: string): Promise<Article[]> => {
  const params = search
    ? {
        title_contains: search,
      }
    : undefined
  return getAllArticles(params, '_sort=published_at:DESC')
}

const getLastArticles = (articleSlugToExclude?: string): Promise<Article[]> => {
  const params = {
    slug_ne: articleSlugToExclude,
    _limit: 6,
  }

  const sort = '_sort=published_at:DESC'
  return getAllArticles(params, sort)
}

const findBySlug = (slug: string): Promise<Article> => {
  const params = {
    slug,
    _limit: 1,
  }

  const query = qs.stringify(params)
  return execute<Article>(`${generatePath(ApiRoutes.ARTICLES.SLUG, { slug })}?${query}`, 'GET')
}

//---------------------------------- API ----------------------------------

const formatDate = (date: string): string => {
  return format(new Date(date), 'd MMMM y', { locale: frenchLocale })
}

export const articleService = {
  getAllArticles,
  getLastArticles,
  getFilteredArticles,
  findBySlug,
  formatDate,
}
