import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import CustomInput from 'components/atoms/input/CustomInput'
import InputWithLabel from 'components/atoms/input/InputWithLabel'
import { capitalize } from 'lodash'
import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import collaboratorService, { CollaboratorCreateValues } from 'services/collaboratorService'
import { Collaborator, PerimeterTypeEnum } from 'types/Collaborator'
import { User } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { createCollaboratorValidationSchema } from 'utils/constants/Collaborator'

interface CollaboratorFormProps {
  users: User[]
  instanceId: number
  refetchCollaborators: () => void
  toggle: () => void
}

const CollaboratorForm: FC<CollaboratorFormProps> = ({ users, instanceId, refetchCollaborators, toggle }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, errors } = useForm<Collaborator>({
    mode: 'onSubmit',
    resolver: yupResolver(createCollaboratorValidationSchema),
  })

  const { mutate } = useMutation((data: CollaboratorCreateValues) => collaboratorService.create(data), {
    mutationKey: ['collaborator', 'create', instanceId],
    onSuccess: () => {
      notifySuccess(t('toastify.success.createMandate'))
      toggle()
      refetchCollaborators()
    },
    onError: () => notifyError(t('toastify.errors.create.collaborator')),
  })

  const submit = (values: CollaboratorCreateValues) => {
    const collaborator: CollaboratorCreateValues = {
      id_perimeter: instanceId,
      perimeter_type: PerimeterTypeEnum.INSTANCE,
      users_permissions_user: values.users_permissions_user,
      start_date: values.start_date || null,
      end_date: values.end_date || null,
      is_manager: !!values.is_manager,
    }
    mutate(collaborator)
  }

  return (
    <div>
      {/* TODO Nico recherche */}
      <InputWithLabel
        labelText={t('forms.selectUser')}
        type='select'
        id='users_permissions_user'
        name='users_permissions_user'
        innerRef={register}
        invalid={!!errors.users_permissions_user}
      >
        {users.map(({ id, firstname, lastname }) => (
          <option key={id} value={id}>
            {`${capitalize(firstname)} ${lastname?.toUpperCase()}`}
          </option>
        ))}
      </InputWithLabel>
      <InputWithLabel
        labelText={t('common.fields.mandate.startDate')}
        type='date'
        id='start_date'
        name='start_date'
        innerRef={register}
        invalid={!!errors.start_date}
      />
      <InputWithLabel
        labelText={t('common.fields.mandate.endDate')}
        type='date'
        id='end_date'
        name='end_date'
        innerRef={register}
        invalid={!!errors.end_date}
      />
      <CustomInput
        type='checkbox'
        id='is_manager'
        name='is_manager'
        innerRef={register}
        label={t('common.fields.mandate.manager')}
      />
      <Button label={t('mandates.addMandate.addButton')} onClick={handleSubmit(submit)} />
    </div>
  )
}

export default CollaboratorForm
