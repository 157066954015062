import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

import Row from '../../../atoms/layout/Row'
import './CalendarCells.scss'
import NotificationDot from './NotificationDot'

const CalendarLegend: FC = () => {
  const { t } = useTranslation()
  return (
    <Row className='CalendarCells flex-row mt-4' grid>
      <Col xs='auto' className='cell date-invitations flex-row'>
        <span className='number legend'>
          <NotificationDot show='DotMain' />
        </span>
        <p className='ml-2'> {t('meeting.myMeetings')}</p>
      </Col>
      <Col xs='auto' className='cell date-meetings-non-invited flex-row'>
        <span className='number legend'>
          <NotificationDot show='DotOther' />
        </span>
        <p className='ml-2'>{t('meeting.otherMeetings')}</p>
      </Col>
    </Row>
  )
}

export default CalendarLegend
