import { generatePath } from 'react-router'
import { Delegate, DelegateSubmit } from 'types/Delegate'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { execute } from '../api/api'

export type DelegateCreateValues = {
  users_permissions_user: number
  meeting: number
  start_date: string | null
  end_date: string | null
}

export type DelegatePerimeterType = {
  perimeter_list: number[]
}

const updateOne = (delegateId: number, data: Partial<Delegate>): Promise<Delegate> =>
  execute<Delegate>(generatePath(ApiRoutes.DELEGATES.ID, { id: delegateId }), 'PUT', data)

const create = (data: DelegateCreateValues): Promise<Delegate> =>
  execute<Delegate>(ApiRoutes.DELEGATES.BASE, 'POST', { data })

const createMany = (data: Partial<DelegateSubmit>[]): Promise<Delegate[]> =>
  execute<Delegate[]>(ApiRoutes.DELEGATES.BASE, 'POST', { data })

const delegateService = {
  updateOne,
  create,
  createMany,
}

export default delegateService
