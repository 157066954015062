import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

const DocumentLoader: FC = (): JSX.Element => {
  const screenWidth = window.innerWidth

  return (
    <ContentLoader style={{ width: '100%', height: '100%' }}>
      <rect x='10' y='5' rx='3' ry='3' width='15' height='15' />
      <rect x='40' y='8' rx='3' ry='3' width='120' height='8' />

      <rect x='10' y='30' rx='3' ry='3' width='2000' height='2' />

      <rect x={screenWidth / 100} y='40' rx='3' ry='3' width='70' height='10' />
      <rect x={screenWidth / 10} y='40' rx='3' ry='3' width='70' height='10' />
      <rect x={screenWidth / 2.5} y='40' rx='3' ry='3' width='70' height='10' />
      <rect x={screenWidth / 2} y='40' rx='3' ry='3' width='70' height='10' />
      <rect x={screenWidth / 1.6666666667} y='40' rx='3' ry='3' width='70' height='10' />

      <rect x='10' y='60' rx='3' ry='3' width='2000' height='2' />

      {[70, 100, 130, 160, 190, 220, 250, 280, 310, 340, 370, 400].map((item) => (
        <>
          <rect x={screenWidth / 100} y={item - 3} rx='3' ry='3' width='15' height='15' />
          <rect x={screenWidth / 10} y={item} rx='3' ry='3' width='70' height='8' />
          <rect x={screenWidth / 2.5} y={item} rx='3' ry='3' width='110' height='8' />
          <rect x={screenWidth / 2} y={item} rx='3' ry='3' width='70' height='8' />
          <rect x={screenWidth / 1.6666666667} y={item} rx='3' ry='3' width='70' height='8' />

          <rect x='10' y={item + 20} rx='3' ry='3' width='2000' height='1' />
        </>
      ))}
    </ContentLoader>
  )
}

export default DocumentLoader
