import Button from 'components/atoms/button/Button'
import { format } from 'date-fns'
import { useStore } from 'effector-react'
import { FC, useState } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { usePermissions } from 'utils/hooks/usePermissions'

import { PATHS } from '../../../../utils/constants/routes/RoutePaths'

import { MeetingDashboard, MeetingStatus } from '../../../../types/Meeting'

import { meetingService } from '../../../../services/meetingService'
import { UserStore } from '../../../../store/UserStore'
import Popover from '../../../atoms/popover/Popover'
import PopoverBody from '../../../atoms/popover/popover-body/PopoverBody'
import PopoverHeader from '../../../atoms/popover/popover-header/PopoverHeader'
import { locale } from './Calendar'
import NotificationDot from './NotificationDot'

interface CalendarDateCellProps {
  id: string
  label: string
  isHidden: boolean
  isSameMonth: boolean
  isSelected: boolean
  meetingList: MeetingDashboard[]
  onClick: () => void
  day: Date
}

const CalendarDateCell: FC<CalendarDateCellProps> = (props) => {
  const { id, label, isHidden, isSameMonth, isSelected, meetingList, day, ...rest } = props
  const [popoverOpen, setPopoverOpen] = useState(false)
  const userStore = useStore(UserStore)
  const { MEETINGS_CAN_CREATE_FROM_AGENDA } = usePermissions()
  const invitedMeetings = meetingList.filter((meeting) =>
    meeting.invitations?.some((invitation) => (invitation.user as unknown) === userStore?.id),
  )
  const nonInvitedMeetings = meetingList.filter(
    (meeting) => !meeting.invitations?.some((invitation) => (invitation.user as unknown) === userStore?.id),
  )
  const formattedDay = format(day, 'EEEE d LLLL', { locale })
  const popoverDate = format(day, 'yyyy-MM-dd', { locale })
  const [canCreateMeetings] = [MEETINGS_CAN_CREATE_FROM_AGENDA()]
  const canPopover = meetingList.length > 0 || canCreateMeetings

  const cellCx = isHidden
    ? 'd-none'
    : [
        'col cell',
        isSelected && 'selected',
        !isSelected && invitedMeetings.length > 0 && 'date-invitations',
        !isSelected && invitedMeetings.length <= 0 && nonInvitedMeetings.length > 0 && 'date-meetings-non-invited',
        !isSameMonth && 'cell-different-month',
      ]
        .filter((c) => !!c)
        .join(' ')

  const onHover = (show: boolean) => setPopoverOpen(show)

  return (
    <div className={cellCx} onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)} {...rest}>
      <span id={id} className='number'>
        <>
          {invitedMeetings.length > 0 && <NotificationDot show='DotMain' />}
          {nonInvitedMeetings.length > 0 && <NotificationDot show='DotOther' />}
        </>
        {label}
      </span>
      {canPopover && (
        <Popover isOpen={popoverOpen} target={id} placement='right'>
          <PopoverHeader className='text-capitalize font-weight-bold'>{formattedDay}</PopoverHeader>
          <PopoverBody>
            {meetingList.map((meeting) => {
              const isUserInvitedInMeeting = invitedMeetings.find((invitedMeeting) => invitedMeeting.id === meeting.id)

              return (
                <MeetingSummary
                  key={`Meeting_Summary_${meeting.id}`}
                  meeting={meeting}
                  dotColor={isUserInvitedInMeeting ? 'DotMain' : 'DotOther'}
                />
              )
            })}

            {canCreateMeetings && (
              <Link to={generatePath(PATHS.MEETINGS.MEETING_CREATE, { action: 'create', date: popoverDate })}>
                <Button className='ml-auto' label='Créer une réunion' size='sm' />
              </Link>
            )}
          </PopoverBody>
        </Popover>
      )}
    </div>
  )
}

export default CalendarDateCell

interface MeetingSummaryProps {
  dotColor: false | 'DotMain' | 'DotOther' | undefined
  meeting: MeetingDashboard
}

const MeetingSummary: FC<MeetingSummaryProps> = (props) => {
  const { dotColor, meeting } = props
  const meetingName = `${meeting.instance.long_name} - ${meeting.instance.association?.name}`
  const meetingStartTime = meetingService.formatTime(meeting.start_time, false)
  const meetingEndTime = meetingService.formatTime(meeting.end_time, false)
  const postponed = meeting.status === MeetingStatus.CANCELED

  return (
    <Link to={generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: meeting.id })} className='my-3 d-flex'>
      <NotificationDot show={dotColor} />
      <div className={`ml-2 ${postponed ? 'text-secondary' : ''}`}>
        <b>{meetingName}</b>
        <div>
          {meetingStartTime}
          {meetingEndTime ? ' - ' + meetingEndTime : null}
        </div>
      </div>
    </Link>
  )
}
