import Button from 'components/atoms/button/Button'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Meeting } from 'types/Meeting'
import { notifySuccess } from 'utils/alertUtils'

import IconSvg from '../../atoms/icon/IconSvg'
import './UploadConvocationButton.scss'
import UploadConvocationModal from './UploadConvocationModal'

interface UploadConvocationButtonProps {
  meeting: Meeting
  queryKey: string[]
}

const UploadConvocationButton: FC<UploadConvocationButtonProps> = ({ meeting, queryKey }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
  const queryClient = useQueryClient()

  const onUploadSuccess = async () => {
    notifySuccess(t('toastify.success.uploadConvocation'))
    closeModal()
    await queryClient.refetchQueries(queryKey)
  }
  return (
    <div>
      <Button onClick={openModal} color='primary'>
        <IconSvg name='ConvocationEdit' className='mr-3' />
        {t('meeting.convocation.modalTitle')}
      </Button>

      {modalOpen && (
        <UploadConvocationModal
          meeting={meeting}
          toggle={closeModal}
          onUploadSuccess={onUploadSuccess}
          isOpen={modalOpen}
        />
      )}
    </div>
  )
}

export default UploadConvocationButton
