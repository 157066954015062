import _ from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { BreadcrumbItem, Col } from 'reactstrap'

import { notify, notifyError } from '../utils/alertUtils'
import { mailHistoryFilter } from '../utils/constants/Contacts'
import { SEARCH_DEBOUNCE_DELAY } from '../utils/constants/Filters'
import { PERMISSIONS } from '../utils/constants/Permissions'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'
import { filterInstances, getInstancesAsTreeDataType, getInstancesIdFromTreeDataType } from '../utils/instances'

import { MailFilterType } from '../types/Filters'
import { MailHistory, MailHistoryPreview } from '../types/MailHistory'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import InputWithLabel from '../components/atoms/input/InputWithLabel'
import Row from '../components/atoms/layout/Row'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Spinner from '../components/atoms/spinner/Spinner'
import Table from '../components/atoms/table/Table'
import { TreeDataType } from '../components/atoms/tree/TreeSelect'
import InstanceFilter from '../components/molecules/filters/InstanceFilter'
import Header from '../components/molecules/heading/Header'
import ModalMailDisplay from '../components/molecules/modals/mail/ModalMailDisplay'
import Reducer from '../components/molecules/reducer/Reducer'

import { mailService } from '../services/MailService'
import { instanceService } from '../services/instanceService'

const MailHistoryView: FC = () => {
  const { t } = useTranslation()
  const { CONTACTS_CAN_MANAGE } = usePermissions()
  const [loadMore, setLoadMore] = useState(true)
  const [answerMode, setAnswerMode] = useState(false)
  const [editRight, setEditRight] = useState(false)
  const [mailFilters, setMailFilters] = useState<MailFilterType>(mailHistoryFilter)
  const [selectedMail, setSelectedMail] = useState<MailHistory>()

  const { data: instances } = useQuery<TreeDataType[]>({
    queryKey: ['instance', 'getMine'],
    queryFn: () => fetchUserInstances(),
    onError: () => notifyError(t('toastify.errors.get.instance')),
  })

  const fetchUserInstances = async () => {
    return instanceService
      .getMyInstancesForPermission(PERMISSIONS.READ_DOC_ORDER_OF_THE_DAY)
      .then((serverInstances) => getInstancesAsTreeDataType(serverInstances))
  }

  const { data: meetingList = [], isLoading: areMeetingsLoading } = useQuery<MailHistoryPreview[]>({
    queryKey: ['user', 'getUserMeetings', instances, mailFilters],
    queryFn: async (): Promise<MailHistoryPreview[]> => {
      const freshMeetingList = instances
        ? await mailService.getMailHistoryWithFilters(mailFilters, getInstancesIdFromTreeDataType(instances))
        : { mails: [], count: 0 }

      setLoadMore(
        !(freshMeetingList.mails.length && mailFilters.page + freshMeetingList.mails.length === freshMeetingList.count),
      )
      if (mailFilters.page == 0) return freshMeetingList.mails
      else return meetingList.concat(freshMeetingList.mails)
    },
    onError: () => notify(t('toastify.errors.get.meeting'), 'error'),
    cacheTime: 0,
    keepPreviousData: loadMore,
  })

  const onInstanceChange = (selectedInstances: string[]) => {
    setLoadMore(true)
    setMailFilters((prevState) => ({
      ...prevState,
      instanceList: selectedInstances,
      page: 0,
    }))
  }
  const onSearchInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onSearchChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )
  const onSearchChange = (value: string) => {
    setLoadMore(true)
    setMailFilters((prevState) => ({
      ...prevState,
      recipient: value,
      page: 0,
    }))
  }

  const history = useHistory()

  if (!CONTACTS_CAN_MANAGE()) {
    history.push('/')
  }
  const loadMoreHandle = () => {
    !areMeetingsLoading &&
      loadMore &&
      setMailFilters((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 10,
        }
      })
  }

  const onMailClicked = (mail: MailHistory, right: boolean, mode: boolean) => {
    setAnswerMode(mode)
    setEditRight(right)
    setSelectedMail(mail)
  }
  const onModalClose = () => {
    setSelectedMail(undefined)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Row className='align-items-center' grid>
            <Col>
              <Header className='flex-column flex-sm-row'>
                <Breadcrumb>
                  <BreadcrumbItem>{t('nav.mailHistory')}</BreadcrumbItem>
                </Breadcrumb>
              </Header>
            </Col>
          </Row>
          <Reducer label={t('meeting.filters.filter')}>
            <Row>
              <Col xs={12} lg={5}>
                <InstanceFilter
                  defaultExpandAll
                  label={t('contacts.filters.instance.label')}
                  placeholder={t('contacts.filters.instance.disabled')}
                  filterTreeNode={filterInstances}
                  value={mailFilters.instanceList}
                  onChange={onInstanceChange}
                  data={instances || []}
                  hideAssociation={true}
                />
              </Col>
              <Col xs={12} lg={3}>
                <InputWithLabel
                  name={'contact-filter-fullSearch'}
                  type='text'
                  placeholder={t('email.filters.recipient')}
                  labelText={t('email.filters.recipient').toUpperCase()}
                  onChange={onSearchInputChange}
                  labelClassName='font-weight-normal'
                />
              </Col>
            </Row>
          </Reducer>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {meetingList && meetingList.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th scope='col'>{t('common.mailHistory.mailTitle')}</th>
                    <th scope='col'>{t('common.mailHistory.meetingInstance')}</th>
                    <th scope='col'>{t('common.mailHistory.sendDate')}</th>
                    <th scope='col'>{t('common.mailHistory.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {meetingList.map((meeting) => (
                    <tr key={meeting.sending_date}>
                      <td className={'cursor-pointer'} onClick={() => onMailClicked(meeting, true, false)}>
                        {meeting.subject}
                      </td>
                      <td className={'cursor-pointer'} onClick={() => onMailClicked(meeting, true, false)}>
                        {meeting.reunion.instance.long_name}
                      </td>
                      <td className={'cursor-pointer'} onClick={() => onMailClicked(meeting, true, false)}>
                        {new Date(meeting.sending_date).toLocaleDateString('fr-FR', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </td>
                      <td className={'d-flex'}>
                        <Button className={'mr-3'} onClick={() => onMailClicked(meeting, false, false)}>
                          {t('common.mailHistory.transfer')}
                        </Button>
                        <Button onClick={() => onMailClicked(meeting, false, true)}>
                          {t('common.mailHistory.replyAll')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {loadMore && (
                <Button
                  className='d-block mx-auto mt-3'
                  onClick={loadMoreHandle}
                  label={t('documents.filters.seeMoreButton.label')}
                />
              )}
            </>
          ) : (
            <>{areMeetingsLoading ? <Spinner /> : <h3 className='mt-3 text-center'>{t('common.noResult')}</h3>}</>
          )}
          {selectedMail && (
            <ModalMailDisplay
              isOpen={!!selectedMail}
              toggle={onModalClose}
              mail={selectedMail}
              isEditable={editRight}
              answerMode={answerMode}
            />
          )}
        </Section>
      </ViewBody>
    </View>
  )
}

export default MailHistoryView
