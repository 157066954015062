import qs from 'qs'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { MailFilterType, MailForgotPwdFilterType } from '../types/Filters'
import { EmailHistory, MailHistoryPreview } from '../types/MailHistory'
import { QsQueryParamsType } from '../types/Params'

import { execute } from '../api/api'

const getMailHistoryWithFilters = (
  filters: MailFilterType,
  defaultInstanceList: (string | number | undefined)[],
): Promise<{ mails: MailHistoryPreview[]; count: number }> => {
  const query: QsQueryParamsType = {
    _start: filters.page,
    _limit: filters.limit,
  }
  if (filters.instanceList && filters.instanceList.length > 0) {
    query['reunion.instance_in'] = filters.instanceList
  } else {
    query['reunion.instance_in'] = defaultInstanceList
  }
  if (filters.recipient) {
    query.destination_email_contains = filters.recipient
  }

  const qsQuery = `?${qs.stringify(query)}`
  return execute<{ mails: MailHistoryPreview[]; count: number }>(`${ApiRoutes.MAIL_HISTORY.MAILS}${qsQuery}`, 'GET')
}

const getMailHistoryForgottenPasswordWithFilters = (
  filters: MailForgotPwdFilterType,
): Promise<{ mails: EmailHistory[]; count: number }> => {
  const query: QsQueryParamsType = {
    _start: filters.page,
    _limit: filters.limit,
  }

  if (filters.recipient) {
    query.destination_email_contains = filters.recipient
  }

  const qsQuery = `?${qs.stringify(query)}`
  return execute<{ mails: EmailHistory[]; count: number }>(
    `${ApiRoutes.MAIL_HISTORY.MAILS_FORGOT_PWD}${qsQuery}`,
    'GET',
  )
}

const getMailHistoryCount = (): Promise<number> => {
  return execute<number>(ApiRoutes.MAIL_HISTORY.COUNT, 'GET')
}

export const mailService = {
  getMailHistoryWithFilters,
  getMailHistoryForgottenPasswordWithFilters,
  getMailHistoryCount,
}
