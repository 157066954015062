import TextInput from 'components/atoms/input/TextInput'
import React, { ChangeEvent, FC, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { Member, MemberFunctionEnum } from 'types/Member'
import { CONTACT_FUNCTIONS } from 'utils/constants/Contacts'

interface MemberFunctionCellProps {
  id: string
  register: Ref<HTMLInputElement>
  disabled: boolean
  submit: (memberId: number, data: Partial<Member>) => void
  memberId: number
}

const MemberFunctionCell: FC<MemberFunctionCellProps> = ({ id, register, disabled, submit, memberId }) => {
  const { t } = useTranslation()

  const onFunctionSelect = (e: ChangeEvent<HTMLInputElement>) =>
    submit(memberId, { ['function']: (e.target.value as MemberFunctionEnum) || null })

  return (
    <td>
      <TextInput type='select' id={id} name={id} innerRef={register} onChange={onFunctionSelect} disabled={disabled}>
        {CONTACT_FUNCTIONS.map(({ key, label }) => (
          <option key={key} value={key}>
            {key ? t(label) : t('common.enums.functions.any')}
          </option>
        ))}
      </TextInput>
    </td>
  )
}

export default MemberFunctionCell
