import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import React, { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { Col } from 'reactstrap'
import { Meeting } from 'types/Meeting'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { convertDateToStringFromFormat, DATE_SHORT_FORMAT } from '../../../../utils/dateUtils'

import { DocumentListType } from '../../../../types/Document'

import { getMyMeetingsKey } from '../../../../views/KioskDecisionsRecordsInstanceView'

import { meetingService } from '../../../../services/meetingService'
import YearPicker from '../../datepicker/YearPicker'

interface Props {
  meetingList: Meeting[]
  yearList: string[]
  canDeleteDecisionRecords?: boolean
}

const KioskDecisionsRecords: FC<Props> = ({ meetingList, yearList, canDeleteDecisionRecords = false }) => {
  const [yearFilter, setYearFilter] = useState('')
  const { t } = useTranslation()

  const onSelectYear = (selectedYear: string) => {
    setYearFilter(selectedYear)
  }

  const queryClient = useQueryClient()
  const { mutate: deleteDocuments, isLoading: isDeleteDocumentsLoading } = useMutation(
    ['meeting', 'deleteDecisionRecord'],
    async (documentList: DocumentListType) => {
      for (const document of documentList.documents) {
        await meetingService.deleteDecisionRecords((documentList.entityId || 0).toString(), document.id)
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        await queryClient.refetchQueries(getMyMeetingsKey)
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )
  const onDeleteMultipleDocuments = (documentList: DocumentListType) => {
    !isDeleteDocumentsLoading && deleteDocuments(documentList)
  }

  const filteredMeetingList = !yearFilter
    ? meetingList
    : meetingList.filter((meeting) => meetingService.getMeetingYear(meeting) === yearFilter)
  const filteredDecisionRecordList = filteredMeetingList.map((meeting) => ({
    label: convertDateToStringFromFormat(new Date(meeting.start_date), DATE_SHORT_FORMAT),
    entityId: meeting.decisions_record?.id || 0,
    documents: meeting.decisions_record?.documents || [],
  }))

  return (
    <Fragment>
      <Col sm='4' lg='3'>
        <YearPicker onSelectYear={onSelectYear} yearList={yearList} />
      </Col>
      <DocumentsListsWithViewer
        lists={filteredDecisionRecordList}
        deletable={canDeleteDecisionRecords}
        onDeleteDocument={onDeleteMultipleDocuments}
      />
    </Fragment>
  )
}

export default KioskDecisionsRecords
