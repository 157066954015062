import Section from 'components/atoms/layout/Section'
import MeetingCard from 'components/molecules/calendar-grid/meetings/MeetingCard'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Col } from 'reactstrap'
import { FilterStore, updateFilters } from 'store/FilterStore'

import { notify } from '../utils/alertUtils'
import { CalendarSelectionMode } from '../utils/constants/Date'
import { MEETING_ENUM } from '../utils/constants/Meeting'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { useInfiniteScroll } from '../utils/hooks/useInfiniteScroll'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'
import { filterInstances, getInstancesAsTreeDataType } from '../utils/instances'

import { MeetingFilterType } from '../types/Filters'
import { InvitationStatusType } from '../types/InvitationStatus'
import { MeetingPreview } from '../types/Meeting'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import Row from '../components/atoms/layout/Row'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import MeetingLoader from '../components/molecules/calendar-grid/meetings/MeetingLoader'
import BasicDatePicker from '../components/molecules/datepicker/BasicDatePicker'
import InstanceFilter from '../components/molecules/filters/InstanceFilter'
import MeetingSelectFilter from '../components/molecules/filters/MeetingSelectFilter'
import StatusSelectFilter from '../components/molecules/filters/StatusSelectFilter'
import Header from '../components/molecules/heading/Header'
import Reducer from '../components/molecules/reducer/Reducer'

import { userService } from '../services/userService'
import { InstanceListStore } from '../store/InstanceListStore'
import { UserStore } from '../store/UserStore'

const viewBodyId = 'meetingList'
const MeetingsView: FC = () => {
  const { t } = useTranslation()
  const { MEETINGS_CAN_CREATE } = usePermissions()
  const userStore = useStore(UserStore)
  const filterStore = useStore(FilterStore)
  const [meetingFilters, setMeetingFilters] = useState<MeetingFilterType>(filterStore)
  const [loadMore, setLoadMore] = useState<boolean>(false)

  const { data: meetingList = [], isLoading: areMeetingsLoading } = useQuery<MeetingPreview[]>({
    queryKey: ['user', 'getUserMeetings', meetingFilters],
    queryFn: async (): Promise<MeetingPreview[]> => {
      updateFilters(meetingFilters)
      const freshMeetingList = await userService.getUserMeetingsWithFilters(meetingFilters)
      setLoadMore(freshMeetingList.loadMore)
      return meetingFilters.page === 1 ? freshMeetingList.meetings : meetingList.concat(freshMeetingList.meetings)
    },
    onError: () => notify(t('toastify.errors.get.meeting'), 'error'),
    cacheTime: 0, // éviter le cache render quand la liste de réunion a changé
    keepPreviousData: loadMore,
  })

  const { data: instanceList } = useQuery({
    initialData: getInstancesAsTreeDataType(useStore(InstanceListStore)),
  })

  const loadMoreHandle = () => {
    !areMeetingsLoading &&
      loadMore &&
      setMeetingFilters((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        }
      })
  }
  useInfiniteScroll(viewBodyId, [meetingList], () => loadMoreHandle())

  const onInstanceChange = (selectedInstances: string[]) => {
    setMeetingFilters((prevState: MeetingFilterType) => ({
      ...prevState,
      instanceList: selectedInstances,
      page: 1,
    }))
  }

  const onDateChange = async (mode: CalendarSelectionMode, startDate?: Date, endDate?: Date) => {
    if (
      (!startDate && !endDate) ||
      (CalendarSelectionMode.SINGLE_DATE === mode && startDate) ||
      (CalendarSelectionMode.RANGE_DATE === mode && startDate && endDate)
    ) {
      setMeetingFilters((prevState) => ({
        ...prevState,
        startDate: startDate,
        endDate: endDate || startDate,
        page: 1,
      }))
    }
  }

  const onMeetingFilterChange = async (meetingFilterId: MEETING_ENUM) => {
    setMeetingFilters((prevState) => ({
      ...prevState,
      meetingFilterId,
      page: 1,
    }))
  }

  const onStatusChange = async (status: InvitationStatusType) => {
    setMeetingFilters((prevState) => ({
      ...prevState,
      status,
      page: 1,
    }))
  }

  const hasMeetings = meetingList && meetingList.length > 0

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>{t('nav.myMeetings')}</BreadcrumbItem>
            </Breadcrumb>
            {MEETINGS_CAN_CREATE() && (
              <Link to={generatePath(PATHS.MEETINGS.MEETING_CREATE, { action: 'create' })}>
                {t('meeting.addNewMeeting')}
              </Link>
            )}
          </Header>
        </Section>
        <Section fluid>
          <Reducer label={t('meeting.filters.filter')}>
            <Row grid>
              <Col xs='12' md='3'>
                <MeetingSelectFilter
                  onMeetingFilterChange={onMeetingFilterChange}
                  meetingFilterId={filterStore.meetingFilterId}
                />
              </Col>
              <Col xs='12' md='3'>
                <InstanceFilter
                  defaultExpandAll
                  label={t('meeting.filters.instance')}
                  placeholder={t('meeting.filters.instance')}
                  filterTreeNode={filterInstances}
                  data={instanceList || []}
                  value={filterStore.instanceList}
                  onChange={onInstanceChange}
                />
              </Col>
              <Col xs='12' md='3'>
                <BasicDatePicker
                  inputLabelText={t('meeting.filters.date').toUpperCase()}
                  inputName='documents-filters-date'
                  updateDateFilters={onDateChange}
                  meetingFilterId={filterStore.meetingFilterId}
                />
              </Col>
              <Col xs='12' md='3'>
                <StatusSelectFilter value={filterStore.status} onStatusChange={onStatusChange} />
              </Col>
            </Row>
          </Reducer>
        </Section>
      </ViewHead>
      <ViewBody id={viewBodyId}>
        <Section fluid>
          <Row className='mt-4' grid>
            {!areMeetingsLoading && !hasMeetings && <h3 className='mt-3 mx-auto'>{t('common.noResult')}</h3>}
            {hasMeetings && (
              <>
                {meetingList
                  .filter((meeting) => {
                    const userInvitation = meeting.invitations?.find(
                      (invitation) => (invitation.user as unknown) === userStore?.id,
                    )

                    if (userInvitation?.parent == null) {
                      return true
                    }

                    // En cas de remplacement, on vérifie que l'absence du parent a bien été acceptée
                    const parentInvitation = meeting.invitations?.find(
                      (invitation) => invitation.id === (userInvitation.parent as unknown),
                    )

                    return parentInvitation?.absence_validation === 'ACCEPTED'
                  })
                  .map((meeting) => {
                    // Le populate fait que invitation.user est l'id du user et non pas tout l'objet. Donc on force à
                    // unknown pour que Typescript ne nous embête pas sur l'égalité entre un number et un User
                    const userInvitation = meeting.invitations?.find(
                      (invitation) => (invitation.user as unknown) === userStore?.id,
                    )

                    return (
                      <Col key={meeting.id} lg={6} className='mb-4'>
                        <MeetingCard status={userInvitation?.status} meeting={meeting} clickable subtitled />
                      </Col>
                    )
                  })}
              </>
            )}
            {areMeetingsLoading && <MeetingLoader />}
          </Row>
          {loadMore && (
            <Button
              className='d-block mx-auto mt-3'
              onClick={loadMoreHandle}
              label={t('documents.filters.seeMoreButton.label')}
            />
          )}
        </Section>
      </ViewBody>
    </View>
  )
}

export default MeetingsView
