import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import Button from '../../atoms/button/Button'
import Modal from '../../atoms/modal/Modal'

interface ModalAreYouSureProps {
  toggle: () => void
  isOpen: boolean
  onConfirmClick: () => void
  onCancelClick?: () => void
  modalHeaderText?: string
  modalBody?: React.ReactNode
}

const ModalAreYouSure: FC<ModalAreYouSureProps> = (props) => {
  const { toggle, isOpen, onConfirmClick, onCancelClick, modalHeaderText, modalBody } = props
  const { t } = useTranslation()
  const onCancel = () => {
    onCancelClick && onCancelClick()
    toggle()
  }
  const onConfirm = () => {
    onConfirmClick()
    toggle()
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader>{modalHeaderText || t('modal.areYouSure')}</ModalHeader>
      <ModalBody>{modalBody}</ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button color='primary' onClick={onConfirm}>
          {t('common.confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalAreYouSure
