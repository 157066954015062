import Spinner from 'components/atoms/spinner/Spinner'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { User } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import { useQuery } from 'utils/hooks/useQuery'

import Modal from '../../atoms/modal/Modal'
import CollaboratorForm from './CollaboratorForm'

interface AddCollaboratorFromInstanceModalProps {
  instanceId: number
  instanceName: string
  isOpen: boolean
  toggle: () => void
  refetchCollaborators: () => void
}

const AddCollaboratorFromInstanceModal: FC<AddCollaboratorFromInstanceModalProps> = ({
  instanceId,
  refetchCollaborators,
  isOpen,
  toggle,
  instanceName,
}) => {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery<User[]>({
    queryFn: () => instanceService.getNotInInstanceUsers(instanceId),
    queryKey: ['instance', 'getNotInInstanceUsers', instanceId],
    onError: () => notifyError(t('toastify.errors.get.user')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }
  if (!data) {
    return null
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`${t('mandates.addMandate.collaborator.modalTitle')} ${instanceName}`}</ModalHeader>
      <ModalBody>
        <CollaboratorForm
          users={data}
          instanceId={instanceId}
          refetchCollaborators={refetchCollaborators}
          toggle={toggle}
        />
      </ModalBody>
    </Modal>
  )
}

export default AddCollaboratorFromInstanceModal
