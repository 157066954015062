import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem } from 'reactstrap'

import { notifyError } from '../utils/alertUtils'
import { PERMISSIONS } from '../utils/constants/Permissions'
import { useQuery } from '../utils/hooks/useQuery'
import useReactRouterQuery from '../utils/hooks/useReactRouterQuery'
import { getInstancesAsTreeDataType, getInstancesIdFromTreeDataType } from '../utils/instances'

import { SearchType } from '../types/Search'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import { TreeDataType } from '../components/atoms/tree/TreeSelect'
import Header from '../components/molecules/heading/Header'
import Search from '../components/molecules/search/Search'

import { instanceService } from '../services/instanceService'
import { searchService } from '../services/searchService'

const MandatesRightView: FC = (): JSX.Element => {
  const { t } = useTranslation()

  const query = useReactRouterQuery().get('q')

  const { data: instanceList, isFetched: isInstanceFetched } = useQuery<TreeDataType[]>({
    queryKey: ['instance', 'getMine'],
    queryFn: () => fetchUserInstances(),
    onError: () => notifyError(t('toastify.errors.get.instance')),
  })

  const { isLoading, data: searchResults } = useQuery<SearchType>({
    queryKey: ['search', 'getSearch', query, isInstanceFetched],
    queryFn: () => {
      return query && instanceList
        ? searchService.getSearch(query, getInstancesIdFromTreeDataType(instanceList ?? []).join('-'))
        : new Promise<SearchType>(() => {
            return null
          })
    },
  })

  const fetchUserInstances = async () => {
    return instanceService
      .getMyInstancesForPermission(PERMISSIONS.READ_DOC_ORDER_OF_THE_DAY)
      .then((serverInstances) => getInstancesAsTreeDataType(serverInstances))
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <span className='d-flex align-items-center'>{t('search.header')}</span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        {!isLoading && !searchResults ? (
          <p className='text-center'>{t('search.noSearch')}</p>
        ) : (
          <Search searchResults={searchResults} isLoading={isLoading} />
        )}
      </ViewBody>
    </View>
  )
}

export default MandatesRightView
