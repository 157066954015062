export enum PERMISSIONS {
  READ_ORDER_OF_THE_DAY = 'READ_ORDER_OF_THE_DAY',
  READ_DOC_ORDER_OF_THE_DAY = 'READ_DOC_ORDER_OF_THE_DAY',
  READ_CONVOCATION_MEMBER = 'READ_CONVOCATION_MEMBER',
  MANAGER_RIGHT = 'MANAGER_RIGHT',
}

export enum GENERAL_PERMISSION {
  READ_ORDER_OF_THE_DAY_OPCO_2I = 'READ_ORDER_OF_THE_DAY_OPCO_2I',
  KIOSK_ACCESS_ALL_DECISION_RECORD = 'KIOSK_ACCESS_ALL_DECISION_RECORD',
  SUPER_ADMIN = 'SUPER_ADMIN',
  // KIOSK_ACCESS_ALL_BOARD_SUMMARY = 'KIOSK_ACCESS_ALL_BOARD_SUMMARY',
}

export const SUPER_ADMIN = 'SUPER_ADMIN'
