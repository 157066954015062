import Button from 'components/atoms/button/Button'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { documentService } from 'services/documentService'
import { Meeting, MeetingStatus } from 'types/Meeting'
import { notifyError } from 'utils/alertUtils'

import { DocumentTypeEnum } from '../../../types/Document'

import { meetingService } from '../../../services/meetingService'
import Modal from '../../atoms/modal/Modal'
import ButtonDownloadFile from '../buttons/files/ButtonDownloadFile'
import ConvocationFileInput from './ConvocationFileInput'

interface UploadConvocationModalProps {
  meeting: Meeting
  toggle: () => void
  onUploadSuccess: () => void
  isOpen: boolean
}

const UploadConvocationModal: FC<UploadConvocationModalProps> = (props) => {
  const { meeting, toggle, onUploadSuccess, isOpen } = props
  const { t } = useTranslation()

  // File input and preview
  const [convocationFilePreview, setConvocationFilePreview] = useState<File | undefined>()
  const onAddPreview = (file: File) => setConvocationFilePreview(file)
  const onDeletePreview = () => setConvocationFilePreview(undefined)

  // Upload convocation on meeting
  const { mutate: uploadConvocation } = useMutation(
    (convocationFile: File) =>
      documentService.uploadDocuments(
        [convocationFile],
        'meeting',
        meeting.id,
        'convocation_file',
        DocumentTypeEnum.CONVOCATION,
      ),
    {
      mutationKey: ['convocation', 'upload', meeting.id],
      onSuccess: onUploadSuccess,
      onError: () => notifyError(t('toastify.errors.uploadDocument')),
    },
  )

  const onSaveConvocation = (convocation?: File) => {
    if (convocation) uploadConvocation(convocation)
    else toggle()
  }

  const meetingId = meeting.id
  const postponed = meeting.status === MeetingStatus.CANCELED
  const downloadTemplateQuery = () => meetingService.getConvocationTemplate(meetingId)
  return (
    <Modal isOpen={isOpen} toggle={toggle} size='md'>
      <ModalHeader toggle={toggle}>{t('meeting.convocation.modalTitle')}</ModalHeader>
      <ModalBody>
        <ConvocationFileInput
          convocationFilePreview={convocationFilePreview}
          convocationRecord={meeting.convocation_file}
          onAddPreview={onAddPreview}
          onDeletePreview={onDeletePreview}
        />
      </ModalBody>
      <ModalFooter>
        {postponed && <p className='text-center'>{t('meeting.cannotUpdateAPostponedMeeting')}</p>}
        {!postponed && (
          <>
            <ButtonDownloadFile
              queryKey={['convocationTemplate', 'getOne', meetingId]}
              queryFn={downloadTemplateQuery}
              buttonLabel={t('meeting.convocation.downloadTemplateButton')}
              templateName={t('meeting.convocation.templateFileName', { date: format(new Date(), 'yyyy_MM_dd_HH_mm') })}
            />
            <Button
              className='mx-auto'
              label={t('common.save')}
              onClick={() => onSaveConvocation(convocationFilePreview)}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default UploadConvocationModal
