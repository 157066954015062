import ToastAlert from 'components/atoms/alert/ToastAlert'
import Spinner from 'components/atoms/spinner/Spinner'
import { useStore } from 'effector-react/effector-react.cjs'
import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { LoggedInDelegateRoutes, LoggedInRoutes, LoggedOutRoutes } from './utils/constants/routes/AppRoutes'
import { PATHS } from './utils/constants/routes/RoutePaths'
import { usePermissions } from './utils/hooks/usePermissions'
import { useQuery } from './utils/hooks/useQuery'
import { useUpdateStore } from './utils/hooks/useUpdateStore'

import { NotificationInterface } from './types/Notification'

import ModalCgu from './components/molecules/modals/ModalCgu'
import Navbar from './components/molecules/navbars/Navbar'
import Topbar from './components/molecules/topbar/Topbar'

import './App.scss'
import { notificationService } from './services/notificationService'
import { setNotifications } from './store/NotificationStore'
import { UserStore } from './store/UserStore'

const App: FC = () => {
  const { isLoading, isLoggedIn } = useUpdateStore()
  const { USER_IS_DELEGATE } = usePermissions()
  const userStore = useStore(UserStore)
  const getNotificationsKey = ['user', 'getUserNotifications']

  useQuery({
    queryKey: getNotificationsKey,
    queryFn: () => notificationService.getUserNotifications(),
    onSuccess: (notifications: NotificationInterface[]) => setNotifications(notifications),
    refetchInterval: 30000,
    enabled: isLoggedIn,
  })

  if (isLoading) {
    return <Spinner />
  }
  return (
    <div className='App'>
      <ToastAlert />
      {!isLoggedIn ? (
        <>
          <Switch>
            {LoggedOutRoutes.map((route) => (
              <Route key={route.path} exact path={route.path} component={route.component} />
            ))}
            <Redirect to={PATHS.LOGIN} />
          </Switch>
        </>
      ) : (
        <>
          <ModalCgu />
          <Navbar />
          <Topbar />
          {!USER_IS_DELEGATE() && (
            <Switch>
              {LoggedInRoutes.map((route) => (
                <Route key={route.path} exact path={route.path} component={route.component} />
              ))}
              <Redirect to={PATHS.DASHBOARD} />
            </Switch>
          )}
          {USER_IS_DELEGATE() && (
            <Switch>
              {LoggedInDelegateRoutes.map((route) => (
                <Route key={route.path} exact path={route.path} component={route.component} />
              ))}
              <Redirect
                to={
                  userStore?.delegates && userStore.delegates.length > 0
                    ? `/reunions/${userStore?.delegates[0].meeting.id}`
                    : PATHS.MEETINGS.MEETING
                }
              />
            </Switch>
          )}
        </>
      )}
    </div>
  )
}

export default App
