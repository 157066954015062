import React, { FC, Fragment } from 'react'
import { generatePath } from 'react-router'
import { CardBody, CardTitle, Col } from 'reactstrap'

import { PATHS } from '../../../../utils/constants/routes/RoutePaths'

import { Association } from '../../../../types/Association'
import { Instance } from '../../../../types/Instance'

import { BASE_PATH } from '../../../../api/api'
import Card from '../../../atoms/card/Card'
import Figure from '../../../atoms/figure/Figure'
import Row from '../../../atoms/layout/Row'
import HeadingListGroup from '../../heading/HeadingListGroup'

interface KioskInstanceCardProps {
  association: Association
  instanceList: Instance[]
  onClick: (href: string) => void
}

const KioskInstanceCard: FC<KioskInstanceCardProps> = (props) => {
  const { instanceList, association, onClick } = props
  if (!association) return null
  return (
    <Fragment>
      <HeadingListGroup>{association.name}</HeadingListGroup>
      <Row grid gutters='md'>
        {instanceList
          .filter((instance) => instance.association?.id === association.id && instance.long_name !== 'BUREAU')
          .sort((instance1, instance2) => (instance1.long_name > instance2.long_name ? 0 : -1))
          .map((instance) => (
            <Col key={instance.id} className='mb-5' xs='12' sm='6' lg='3'>
              <Card
                clickable
                onClick={() => onClick(generatePath(PATHS.KIOSK.DECISIONS_RECORDS.INSTANCE, { id: instance.id }))}
              >
                <CardBody>
                  <CardTitle className='text-center' tag='h5'>
                    {instance.long_name}
                  </CardTitle>
                  {association?.logo?.url && (
                    <Figure>
                      <img
                        src={`${BASE_PATH}${association.logo.url}`}
                        alt={association.name}
                        height={100}
                        className='mx-auto my-3'
                      />
                    </Figure>
                  )}
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
    </Fragment>
  )
}

export default KioskInstanceCard
