import React, { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentListType, DocumentType } from 'types/Document'

import HeadingListGroup from '../heading/HeadingListGroup'
import DocumentCarrousel from './DocumentCarrousel'
import DocumentViewer from './DocumentViewer'

interface Props {
  lists: DocumentListType[]
  onDeleteDocument?: (documentList: DocumentListType) => void
  deletable?: boolean
  showHeader?: boolean
  xlSize?: number
  flexWrap?: 'wrap' | 'nowrap'
}

const DocumentsListsWithViewer: FC<Props> = ({
  lists,
  onDeleteDocument,
  deletable,
  showHeader = true,
  xlSize = 2,
  flexWrap = 'nowrap',
}) => {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<DocumentType>()

  const onSelectedFileHandle = (file?: DocumentType) => {
    setSelectedFile(file)
  }
  const onDeleteHandle = (documentListType: DocumentListType, documentList: DocumentType[]) => {
    onDeleteDocument && onDeleteDocument({ ...documentListType, documents: documentList })
  }

  return (
    <Fragment>
      {lists.length > 0 &&
        lists.map((list) => (
          <Fragment key={`list_${list.label}`}>
            <div key={list.label}>
              {showHeader && <HeadingListGroup>{list.label}</HeadingListGroup>}
              <DocumentCarrousel
                fileList={list.documents}
                openPreviewDocument={onSelectedFileHandle}
                onDeleteDocuments={(documentList) => onDeleteHandle(list, documentList)}
                closable={false}
                deletable={deletable}
                xlSize={xlSize}
                flexWrap={flexWrap}
              />
            </div>
          </Fragment>
        ))}
      {lists.length <= 0 && <h3 className='mt-3 text-center'>{t('meeting.decisionsRecords.empty')}</h3>}
      {selectedFile && (
        <DocumentViewer file={selectedFile} isOpen={Boolean(selectedFile)} onClose={onSelectedFileHandle} />
      )}
    </Fragment>
  )
}

export default DocumentsListsWithViewer
