import { FC, SyntheticEvent } from 'react'

import { InputDataNode } from '../../../../utils/treeUtils'

import IconSvg from '../../../atoms/icon/IconSvg'

interface OrderOfTheDayTitleProps {
  item: InputDataNode
  showTitle?: boolean
  onClick?: (e: SyntheticEvent) => void
}

const OrderOfTheDayTitle: FC<OrderOfTheDayTitleProps> = (props) => {
  const { item, showTitle = true, onClick } = props
  const { title, positionTitle } = item

  const isPrimary = positionTitle && Number(positionTitle)
  return (
    <h3 className='Title' onClick={onClick}>
      <span className={`PositionContainer ${isPrimary ? 'Primary' : ''}`}>
        {positionTitle && <div className='Position'>{positionTitle}</div>}
        <div className='Icon'>
          <IconSvg name='ChevronRight' />
        </div>
      </span>
      {showTitle && title}
    </h3>
  )
}

export default OrderOfTheDayTitle
