import { useStore } from 'effector-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, Col } from 'reactstrap'

import { notify } from '../utils/alertUtils'
import { useQuery } from '../utils/hooks/useQuery'

import { MeetingPreview } from '../types/Meeting'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Row from '../components/atoms/layout/Row'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import MeetingCard from '../components/molecules/calendar-grid/meetings/MeetingCard'
import MeetingLoader from '../components/molecules/calendar-grid/meetings/MeetingLoader'
import Header from '../components/molecules/heading/Header'

import { userService } from '../services/userService'
import { UserStore } from '../store/UserStore'

const MeetingsDelegateView: FC = () => {
  const { t } = useTranslation()
  const userStore = useStore(UserStore)

  const { data: meetingList = [], isLoading: areMeetingsLoading } = useQuery<MeetingPreview[]>({
    queryKey: ['user', 'getUserDelegateMeetings'],
    queryFn: async (): Promise<MeetingPreview[]> => {
      return await userService.getUserDelegateMeetings()
    },
    onError: () => notify(t('toastify.errors.get.meeting'), 'error'),
    cacheTime: 0,
  })

  const hasMeetings = meetingList && meetingList.length > 0

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>{t('nav.myMeetings')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <Row className='mt-4' grid>
            {!areMeetingsLoading && !hasMeetings && <h3 className='mt-3 mx-auto'>{t('common.noResult')}</h3>}
            {hasMeetings && (
              <>
                {meetingList.map((meeting) => {
                  // Le populate fait que invitation.user est l'id du user et non pas tout l'objet. Donc on force à
                  // unknown pour que Typescript ne nous embête pas sur l'égalité entre un number et un User
                  const userInvitation = meeting.invitations?.find(
                    (invitation) => (invitation.user as unknown) === userStore?.id,
                  )

                  return (
                    <Col key={meeting.id} lg={6} className='mb-4'>
                      <MeetingCard status={userInvitation?.status} meeting={meeting} clickable subtitled />
                    </Col>
                  )
                })}
              </>
            )}
            {areMeetingsLoading && <MeetingLoader />}
          </Row>
        </Section>
      </ViewBody>
    </View>
  )
}

export default MeetingsDelegateView
