import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardTitle } from 'reactstrap'

import { DocumentListType } from '../../../types/Document'

import Card from '../../atoms/card/Card'
import DocumentsListsWithViewer from '../documents/DocumentsListsWithViewer'
import './Search.scss'

interface SearchKioskProps {
  kiosk: DocumentListType[]
}

const SearchKiosk: FC<SearchKioskProps> = ({ kiosk }) => {
  const { t } = useTranslation()

  return (
    <Card className='search-card'>
      <CardBody>
        <CardTitle>{t('search.kiosks.title')}</CardTitle>
        {kiosk.length > 0 ? (
          <DocumentsListsWithViewer
            lists={kiosk.map((k) => ({
              ...k,
              label: t('search.kiosks.' + k.label),
            }))}
            xlSize={4}
            flexWrap='wrap'
          />
        ) : (
          <div className='no-result d-flex flex-column justify-content-center'>
            <p>{t('search.noResult')}</p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default SearchKiosk
