import { FC } from 'react'
import { Col } from 'reactstrap'

import Row from '../components/atoms/layout/Row'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import Cover from '../components/molecules/auth/login/Cover'
import '../components/molecules/auth/login/Login.scss'
import LoginFormSection from '../components/molecules/auth/login/LoginFormSection'
import LoginTextSection from '../components/molecules/auth/login/LoginTextSection'
import NewLoginHeader from '../components/molecules/auth/login/NewLoginHeader'

const LoginView: FC = () => {
  return (
    <View>
      <NewLoginHeader />
      <Cover />
      <ViewBody className='LoginBody'>
        <Row grid>
          <Col sm={12} lg={5}>
            <LoginFormSection />
          </Col>
          <Col sm={12} lg={7}>
            <LoginTextSection />
          </Col>
        </Row>
      </ViewBody>
    </View>
  )
}

export default LoginView
