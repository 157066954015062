import Button from 'components/atoms/button/Button'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { ButtonProps } from 'reactstrap'
import { notifyError } from 'utils/alertUtils'

import { convertUint8ArrayToObjectURL, triggerDownloadFromObjectURL } from '../../../../utils/fileUtils'

import { BinaryDataType } from '../../../../types/File'

interface ButtonDownloadFileProps extends Omit<ButtonProps, 'color'> {
  queryKey: (string | number)[]
  queryFn: () => Promise<BinaryDataType>
  templateName: string
  buttonLabel: string
  className?: string
}

const ButtonDownloadFile: FC<ButtonDownloadFileProps> = ({ queryKey, queryFn, templateName, buttonLabel, ...rest }) => {
  const { t } = useTranslation()

  const [clicked, setClicked] = useState(false)

  const { isFetching } = useQuery<BinaryDataType>({
    queryKey,
    queryFn,
    onSuccess: (res) => {
      const url = convertUint8ArrayToObjectURL(res.data)
      triggerDownloadFromObjectURL(url, templateName)
    },
    onError: () => notifyError(t('toastify.errors.downloadTemplate')),
    onSettled: () => setClicked(false),
    enabled: clicked,
  })

  const onButtonClick = () => setClicked(true)

  return (
    <Button disabled={isFetching} onClick={onButtonClick} color='primary' {...rest}>
      {buttonLabel}
    </Button>
  )
}

export default ButtonDownloadFile
