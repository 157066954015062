import Spinner from 'components/atoms/spinner/Spinner'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { userService } from 'services/userService'
import { InstancesWithPerimeterAndRights } from 'types/Instance'
import { User } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { computeFullName } from 'utils/userUtils'

import Modal from '../../atoms/modal/Modal'
import CollaboratorForm from './CollaboratorForm'

interface AddCollaboratorModalProps {
  user: User
  isOpen: boolean
  toggle: () => void
  refetchCollaborators: () => void
  isOther?: boolean
}

const AddCollaboratorModal: FC<AddCollaboratorModalProps> = ({
  user,
  refetchCollaborators,
  isOpen,
  toggle,
  isOther,
}) => {
  const { t } = useTranslation()

  const managerRights = useManagerRights()

  const { data, isLoading } = useQuery<InstancesWithPerimeterAndRights>({
    queryKey: ['instance', 'getAllWithUserPerimeterAndManagerRights', user.id],
    queryFn: async () => {
      const allInstances = await instanceService.getAll()
      const userPerimeter = await userService.getPerimeter(user.id, isOther)
      const userRights = await userService.getUserRights(user.id)
      return {
        allInstances,
        userPerimeter,
        managerRights,
        userRights,
      }
    },
    onError: () => notifyError(t('toastify.errors.get.instance')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }

  if (!data) {
    return null
  } else {
    if (isOther) {
      data.userPerimeter.associationsIds = data.userRights.permission.association
      data.userPerimeter.instancesIds = data.userRights.permission.instance
    } else {
      data.userPerimeter.associationsIds = data.userRights.collaborator.association
      data.userPerimeter.instancesIds = data.userRights.collaborator.instance
    }
  }
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='text-capitalize' toggle={toggle}>{`${t(
        `mandates.addMandate.${isOther ? 'permission' : 'collaborator'}.modalTitle`,
      )} ${computeFullName(user)}`}</ModalHeader>
      <ModalBody>
        <CollaboratorForm
          data={data}
          userId={user.id}
          refetchCollaborators={refetchCollaborators}
          toggle={toggle}
          isOther={isOther}
        />
      </ModalBody>
    </Modal>
  )
}

export default AddCollaboratorModal
