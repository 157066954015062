import React, { FC } from 'react'
import { FormGroup, Label } from 'reactstrap'

import TreeSelect, { TreeDataType } from '../../atoms/tree/TreeSelect'
import './InstanceFilter.scss'

interface ContactTreeSelectProps {
  defaultExpandAll: boolean
  label: string
  placeholder: string
  data: TreeDataType[]
  value?: string[]
  onChange: (value: string[]) => void
  defaultValue?: string[]
  filterTreeNode?: (search: string, node: TreeDataType | undefined) => boolean
  className?: string
  hideAssociation?: boolean
}

const InstanceFilter: FC<ContactTreeSelectProps> = (props) => {
  const {
    defaultExpandAll,
    label,
    placeholder,
    data,
    value = [],
    className = '',
    onChange,
    defaultValue,
    filterTreeNode,
    hideAssociation = false,
  } = props

  const isTree = (item: TreeDataType | undefined): item is TreeDataType => {
    return !!item
  }

  let trees = data
  if (hideAssociation) {
    trees = data.flatMap((node) => node?.children).filter(isTree)
  }

  data.forEach((node) => {
    if (node.title === 'OPCO 2i') {
      node.title = 'Tout'
    }
  })

  return (
    <FormGroup className={`InstanceFilter ${className}`}>
      <Label>{label}</Label>
      <TreeSelect
        defaultExpandAll={defaultExpandAll}
        data={trees}
        className='InstanceFilterTreeSelect'
        defaultValue={defaultValue}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        filterTreeNode={filterTreeNode}
        showParent={true}
      />
    </FormGroup>
  )
}

export default InstanceFilter
