import Badge from 'components/atoms/badge/Badge'
import Icon from 'components/atoms/icon/Icon'
import React, { FC } from 'react'
import { Col } from 'reactstrap'
import { User } from 'types/User'

import './EditableProfilePicture.scss'
import ProfilePicture from './ProfilePicture'

interface Props {
  user: User
  onEditProfilePicture: () => void
}

const EditableProfilePicture: FC<Props> = ({ user, onEditProfilePicture }) => {
  return (
    <Col className='EditableProfilePicture w-25 mr-auto ml-auto mt-4 mt-sm-0'>
      <ProfilePicture user={user} />
      <Badge
        size='md'
        className='add-picture-badge position-absolute rounded cursor-pointer bg-transparent border-0'
        onClick={onEditProfilePicture}
      >
        <Icon name='camera-1' />
      </Badge>
    </Col>
  )
}

export default EditableProfilePicture
