import { generatePath } from 'react-router'
import { Collaborator, CollaboratorSubmit, CollaboratorForManagerType, PerimeterTypeEnum } from 'types/Collaborator'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { execute } from '../api/api'

export type CollaboratorCreateValues = {
  id_perimeter: number
  users_permissions_user: number
  perimeter_type: PerimeterTypeEnum
  start_date: string | null
  end_date: string | null
  is_manager: boolean
}

export type CollaboratorPerimeterType = {
  perimeter_list: string[]
  is_manager: boolean
}

const updateOne = (collaboratorId: number, data: Partial<Collaborator>): Promise<CollaboratorForManagerType> =>
  execute<CollaboratorForManagerType>(generatePath(ApiRoutes.COLLABORATORS.ID, { id: collaboratorId }), 'PUT', data)

const deleteOne = (collaboratorId: number): Promise<CollaboratorForManagerType> =>
  execute<CollaboratorForManagerType>(generatePath(ApiRoutes.COLLABORATORS.ID, { id: collaboratorId }), 'DELETE')

const create = (data: CollaboratorCreateValues): Promise<Collaborator> =>
  execute<Collaborator>(ApiRoutes.COLLABORATORS.BASE, 'POST', { data })

const createMany = (data: Partial<CollaboratorSubmit>[]): Promise<Collaborator[]> =>
  execute<Collaborator[]>(ApiRoutes.COLLABORATORS.BASE, 'POST', { data })

const getOne = (collaboratorId: number): Promise<Collaborator> =>
  execute<CollaboratorForManagerType>(generatePath(ApiRoutes.COLLABORATORS.ID, { id: collaboratorId }), 'GET')

const collaboratorService = {
  updateOne,
  deleteOne,
  create,
  createMany,
  getOne,
}

export default collaboratorService
