import Row from 'components/atoms/layout/Row'
import VideoViewerModal from 'components/atoms/video/VideoViewerModal'
import React, { FC, useState } from 'react'
import { Col } from 'reactstrap'
import { DocumentType } from 'types/Document'

import VideoCard from './VideoCard'
import './VideosViewer.scss'

interface Props {
  videoList: DocumentType[]
  className?: string
}

const VideosViewer: FC<Props> = ({ className = '', videoList = [] }) => {
  const [selectedVideo, setSelectedVideo] = useState<DocumentType>()
  const [isOpen, setIsOpen] = useState(false)
  const onOpenHandler = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }
  const onVideoSelect = (video: DocumentType) => {
    onOpenHandler(true)
    setSelectedVideo(video)
  }

  const [firstVideo, ...otherVideos] = videoList

  return (
    <>
      <div className={`VideosViewer ${className}`}>
        <Row grid className='VideosViewerGrid'>
          <Col xs='12' md='9' className='VideosViewerMain'>
            <VideoCard video={firstVideo} onVideoSelect={onVideoSelect} />
          </Col>
          {!!otherVideos?.length && (
            <Col xs='12' md='3' className='VideosViewerList'>
              <div className='VideoGridListContainer'>
                <div className='VideoGridListWrapper'>
                  <Row grid gutters='md'>
                    {otherVideos.map((video, index) => (
                      <VideoCard key={video.url + index.toString()} video={video} onVideoSelect={onVideoSelect} />
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
      {selectedVideo && <VideoViewerModal video={selectedVideo} isOpen={isOpen} onOpen={onOpenHandler} />}
    </>
  )
}

export default VideosViewer
