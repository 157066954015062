import { FC } from 'react'
import { default as LibReactMarkdown } from 'react-markdown'
import gfm from 'remark-gfm'

interface ReactMarkdownProps {
  className?: string
  children: string
}

const ReactMarkdown: FC<ReactMarkdownProps> = (props) => {
  const { className = '', children } = props

  return (
    <LibReactMarkdown className={className} allowDangerousHtml plugins={[[gfm, { singleTilde: false }]]}>
      {children}
    </LibReactMarkdown>
  )
}

export default ReactMarkdown
