import React, { FC } from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'

import { DocumentType } from '../../../types/Document'

import { BASE_PATH } from '../../../api/api'
import Modal from '../../atoms/modal/Modal'
import Figure from '../figure/Figure'

interface Props {
  video: DocumentType
  isOpen: boolean
  onOpen: (isOpen: boolean) => void
}

// TODO Nico: Download / youtube ?
const VideoViewerModal: FC<Props> = ({ video, isOpen, onOpen }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => onOpen(false)} size='auto'>
      <ModalHeader toggle={() => onOpen(false)}>
        <h4>{video.name}</h4>
      </ModalHeader>
      <ModalBody>
        <Figure>
          <video src={BASE_PATH + video.url} controls />
        </Figure>
      </ModalBody>
    </Modal>
  )
}

export default VideoViewerModal
