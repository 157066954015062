import Row from 'components/atoms/layout/Row'
import React, { FC } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

import { Address } from '../../../types/Address'

import InputWithLabel from '../../atoms/input/InputWithLabel'

export interface AddressFormPropsInterface {
  name?: string
  register?: React.Ref<HTMLInputElement | HTMLTextAreaElement>
  isInEditMode?: boolean
  errors?: DeepMap<Address, FieldError>
}

/**
 * Composant du formulaire d'adresse d'un utilisateur
 */
const AddressForm: FC<AddressFormPropsInterface> = (props) => {
  const { register, name = 'address', isInEditMode = false, errors } = props
  const { t } = useTranslation()
  const postCodeErrorMessage = errors?.postal_code?.message

  return (
    <>
      <Row grid>
        <Col sm={12} md={6} lg={12} xl={6}>
          <InputWithLabel
            type='text'
            name={`${name}.line_1`}
            innerRef={register}
            placeholder={t('forms.line1')}
            labelText={t('forms.line1')}
            readOnly={!isInEditMode}
          />
        </Col>
        <Col sm={12} md={6} lg={12} xl={6}>
          <InputWithLabel
            type='text'
            name={`${name}.line_2`}
            innerRef={register}
            placeholder={t('forms.line2')}
            labelText={t('forms.line2')}
            readOnly={!isInEditMode}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputWithLabel
            type='text'
            name={`${name}.postal_code`}
            innerRef={register}
            placeholder={t('forms.postalCode')}
            labelText={t('forms.postalCode')}
            readOnly={!isInEditMode}
            error={postCodeErrorMessage && t(postCodeErrorMessage)}
          />
        </Col>
        <Col sm={12} md={6}>
          <InputWithLabel
            type='text'
            name={`${name}.city`}
            innerRef={register}
            placeholder={t('forms.city')}
            labelText={t('forms.city')}
            readOnly={!isInEditMode}
          />
        </Col>
      </Row>
    </>
  )
}

export default AddressForm
