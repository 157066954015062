import React, { FC } from 'react'
import { Spinner as RSSpinner, SpinnerProps } from 'reactstrap'

import './Spinner.scss'

const Spinner: FC<SpinnerProps> = (props) => (
  <div className='d-flex justify-content-center align-items-center mx-auto'>
    <RSSpinner {...props} />
  </div>
)

export default Spinner
