import classnames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, { FC, ReactText, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Col, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import { notifyError, notifySuccess, notifyWarning } from '../../../../utils/alertUtils'
import { ATTACHED_DOCUMENT_KEY, ATTACHED_DOCUMENT_ROOT_INDEX_KEY } from '../../../../utils/constants/ChonkyFileBrowser'
import { convertDateToStringFromFormat, EXPORT_FORMAT } from '../../../../utils/dateUtils'
import { downloadFile } from '../../../../utils/fileUtils'
import { usePermissions } from '../../../../utils/hooks/usePermissions'
import { useQuery } from '../../../../utils/hooks/useQuery'
import { FileBrowserFileMapNode, InputBaseDataNode, InputDataNode } from '../../../../utils/treeUtils'

import { DocumentType } from '../../../../types/Document'
import { Meeting, MeetingStatus } from '../../../../types/Meeting'
import { NotificationInterface, NotificationType } from '../../../../types/Notification'

import { getZipFile, meetingService } from '../../../../services/meetingService'
import { notificationService } from '../../../../services/notificationService'
import Button from '../../../atoms/button/Button'
import Row from '../../../atoms/layout/Row'
import Nav from '../../../atoms/nav/Nav'
import Tree from '../../../atoms/tree/Tree'
import { Tab } from '../../../tabbed-container/TabbedContainer'
import DocumentViewer from '../../documents/DocumentViewer'
import SimplerDocumentTable from '../../documents/SimplerDocumentTable'
import OrderOfTheDayManager from './OrderOfTheDayManager'
import OrderOfTheDayMember from './OrderOfTheDayMember'
import OrderOfTheDayMemberTreeItem from './OrderOfTheDayMemberTreeItem'

interface OrderOfTheDayProps {
  className?: string
  meeting: Meeting
  showOnlyOrderOfTheDay: boolean
}

/**
 * Composant ordre du jour
 * C'est le composant principal de l'ordre du jour qui regroupe:
 * Le mode édition
 * La vue des fichiers d'une réunion
 * La modal de prévisualisation d'un document
 * Les services d'upload et d'update
 */

const OrderOfTheDay: FC<OrderOfTheDayProps> = (props) => {
  const { className = '', meeting, showOnlyOrderOfTheDay } = props
  const tabParam = new URLSearchParams(useLocation().search).get('tab')
  const [isZipLoading, setIsZipLoading] = useState<boolean>(false)
  const frenchMonths = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ]
  const englishMonths = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]

  const lastWeek = moment().startOf('day').subtract(1, 'week').format('D MMMM YYYY')
  const today = moment().format('D MMMM YYYY')
  const { MEETINGS_CAN_MANAGE } = usePermissions()

  const [canManageMeeting] = [MEETINGS_CAN_MANAGE(meeting)]

  const addAttachedDefaultFiles = (dataNodes: InputDataNode[]): InputDataNode[] => {
    const documentNotInOrder: FileBrowserFileMapNode = {}

    for (const doc of meeting.documents) {
      if (!dataNodes.some((dataNode) => dataNode.documentKeyList?.includes(doc.id.toString()))) {
        documentNotInOrder[doc.id.toString()] = {
          id: doc.id.toString(),
          modDate: doc.date,
          name: doc.name,
          parentId: ATTACHED_DOCUMENT_ROOT_INDEX_KEY,
          size: parseInt(doc.size),
          url: doc.url,
        }
      }
    }

    const indexAttachedDocument = dataNodes.findIndex((dataNode) => dataNode.key === ATTACHED_DOCUMENT_KEY)

    if (indexAttachedDocument === -1) {
      return dataNodes
    }

    dataNodes[indexAttachedDocument] = {
      ...dataNodes[indexAttachedDocument],
      fileBrowserData: {
        rootFolderId:
          dataNodes[indexAttachedDocument].fileBrowserData?.rootFolderId || ATTACHED_DOCUMENT_ROOT_INDEX_KEY,
        fileMap: {
          ...(dataNodes[indexAttachedDocument].fileBrowserData?.fileMap || {}),
          ...documentNotInOrder,
          [ATTACHED_DOCUMENT_ROOT_INDEX_KEY]: {
            ...(dataNodes[indexAttachedDocument].fileBrowserData?.fileMap || {})[ATTACHED_DOCUMENT_ROOT_INDEX_KEY],
            childrenIds: [
              //              ...(dataNodes[indexAttachedDocument].fileBrowserData?.fileMap || {})[ATTACHED_DOCUMENT_ROOT_INDEX_KEY]
              //                .childrenIds,
              ...(dataNodes[indexAttachedDocument].fileBrowserData?.fileMap[ATTACHED_DOCUMENT_ROOT_INDEX_KEY]
                .childrenIds || []),
              ...Object.keys(documentNotInOrder),
            ],
          },
        },
      },
    }
    return dataNodes
  }

  const flatMapToChild = ({ key, children = [] }: InputDataNode): (string | number)[] => [
    key,
    ...children.flatMap((data) => flatMapToChild(data)),
  ]

  const [treeData, setTreeData] = useState<InputDataNode[]>()

  const [expandedKeys, setExpandedKeys] = useState<ReactText[]>(
    (treeData || []).flatMap((data) => flatMapToChild(data)),
  )

  useEffect(() => {
    const oldOrderOfTheDay = _.cloneDeep(meeting.order_of_the_day)
    const isNew = oldOrderOfTheDay && !oldOrderOfTheDay.some((oldOrder) => oldOrder.key === ATTACHED_DOCUMENT_KEY)
    // Si c'est un ancien ordre du jour qui n'a pas été migré on le parse pour ajouter les documents qui n'étaient dans aucun ordre du jour
    const defaultTreeData = isNew
      ? addAttachedDefaultFiles(meetingService.parseOrderOfTheDay(meeting))
      : meetingService.parseOrderOfTheDay(meeting)

    setTreeData(defaultTreeData)
    setExpandedKeys((defaultTreeData || []).flatMap((data) => flatMapToChild(data)))
    if (isNew) {
      mutate(defaultTreeData)
    }
  }, [])

  const [isInEditMode, setIsInEditMode] = useState(false)

  const [fileList, setFileList] = useState<DocumentType[]>(meeting.documents || [])
  const [selectedFile, setSelectedFile] = useState<DocumentType>()

  const postponed = meeting.status === MeetingStatus.CANCELED

  const { t } = useTranslation()

  const { data: lastNotification, refetch: refetchLastNotification } = useQuery<NotificationInterface>({
    queryKey: ['meeting', 'document', 'notification'],
    queryFn: async () => {
      return (await notificationService.findNotification(NotificationType.DOCUMENT_ADDED_1, meeting.id.toString()))[0]
    },
    onError: () => notifyError(t('toastify.errors.get.notification')),
    refetchOnMount: true,
    refetchInterval: 30000,
  })

  const { mutate } = useMutation(
    (mutateTree: InputBaseDataNode[]) => meetingService.update(meeting.id, { order_of_the_day: mutateTree }),
    {
      mutationKey: ['meeting', 'update', meeting.id],
    },
  )

  const { mutate: sendNotification, isLoading } = useMutation(
    (meetingId: number) => meetingService.notifyNewDocuments(meetingId),
    {
      mutationKey: ['meeting', 'document', 'notification'],
      onSuccess: () => {
        void refetchLastNotification()
        notifySuccess(t('toastify.success.meetingDocumentsAddedNotification'))
      },
      onError: () => notifyError(t('toastify.errors.meetingDocumentsAddedNotification')),
    },
  )

  const onFileListChange = (newFileList: DocumentType[]) => {
    setFileList(newFileList)
  }
  const onEditClick = (value: boolean) => {
    setIsInEditMode(value)
  }
  const onCancelClick = () => {
    setIsInEditMode(false)
    const oldOrderOfTheDay = _.cloneDeep(meeting.order_of_the_day)
    // Si c'est un ancien ordre du jour qui n'a pas été migré on le parse pour ajouter les documents qui n'étaient dans aucun ordre du jour
    const defaultTreeData =
      oldOrderOfTheDay && !oldOrderOfTheDay.some((oldOrder) => oldOrder.key === ATTACHED_DOCUMENT_KEY)
        ? addAttachedDefaultFiles(meetingService.parseOrderOfTheDay(meeting))
        : meetingService.parseOrderOfTheDay(meeting)

    onTreeDataChange(_.cloneDeep(defaultTreeData))
  }

  const onTreeDataChange = (newTreeData: InputDataNode[]) => {
    setTreeData(newTreeData)
    setExpandedKeys((newTreeData || []).flatMap((data) => flatMapToChild(data)))
  }

  const onFileSelected = (file?: DocumentType) => {
    setSelectedFile(file)
  }

  const onNotifyClick = () => {
    sendNotification(meeting.id)
  }

  const downloadZipDocument = async (meeting: Meeting): Promise<void> => {
    try {
      const rowData = await getZipFile(meeting.id)
      const url = window.URL.createObjectURL(new Blob([rowData], { type: 'application/zip' }))
      const time = convertDateToStringFromFormat(new Date(), EXPORT_FORMAT)

      downloadFile(url, `Documents_${meeting.instance.short_name}_${meeting.start_date}_${time}.zip`)
    } catch (error) {
      notifyError(t('toastify.errors.zipError'))
    }
  }

  const onFileChange = (file?: DocumentType) => {
    setSelectedFile(file)
  }
  const documentTabs: Tab[] = [
    {
      id: 'odj',
      label: t('common.AllDocument'),
      content: (
        <Row>
          <Col lg={12} sm={12} className='ManagerTreeItem'>
            {!isInEditMode && Array.isArray(treeData) && treeData.length <= 0 && (
              <p>{t('meeting.emptyOrderOfTheDay')}</p>
            )}
            {/** L'ordre du jour n'est modifiable que par les collaborateurs gestionnaires de l'instance de la réunion*/}
            {isInEditMode && canManageMeeting && (
              <OrderOfTheDayManager
                treeData={treeData || []}
                onTreeDataChange={onTreeDataChange}
                meeting={meeting}
                fileList={fileList}
                onFileListChange={onFileListChange}
                onEditClick={onEditClick}
                openPreviewDocument={onFileSelected}
              />
            )}
            {/** Les documents de l'ordre du jour sont visibles si :
             - L'utilisateur est invité à la réunion OU
             - L'utilisateur est collaborateur actif de l'instance de la réunion OU
             - L'utilisateur est membre actif de l'instance de la réunion
             */}
            {!isInEditMode && (
              <OrderOfTheDayMember
                treeData={treeData || []}
                openPreviewDocument={onFileSelected}
                showDocuments={false}
                meeting={meeting}
              />
            )}
          </Col>
        </Row>
      ),
      show: true,
      idDefault: tabParam === 'allDoc',
    },
    {
      id: 'recent',
      label: t('common.recentDocument'),
      content: (
        /**
          We have to check if the date store in the doc formated as follow : "D MMMM YYYY" and in french
          is between today and last week. Since the function isBetween form moment doesnt work with french date
          we need to find what month number it is and conver it back to english
        */
        <SimplerDocumentTable
          fileList={meeting.documents.filter((doc) => {
            // Set moment in french
            moment.locale('fr', {
              months: frenchMonths,
            })
            const date = moment(doc.date, 'D MMMM YYYY')
            // Set back moment in english
            moment.locale('en')
            // Get the month number
            const nbrMonth = frenchMonths.indexOf(moment(date).format('MMMM'))
            if (
              moment(`${moment(date).format('D')} ${englishMonths[nbrMonth]} ${moment(date).format('YYYY')}`).isBetween(
                lastWeek,
                today,
                undefined,
                '(]',
              )
            )
              return true
            return false
          })}
          onFileChange={onFileChange}
        />
      ),
      show: true,
      idDefault: tabParam === 'recentDoc',
    },
  ]

  const [activeTab, setActiveTab] = useState<string>(
    documentTabs.find((tab) => tab.idDefault === true)?.id ||
      documentTabs.find((tab) => tab.show === true)?.id ||
      documentTabs[0].id,
  )

  const toggleTab = (tabId: string) => {
    if (activeTab !== tabId) setActiveTab(tabId)
  }

  const onExpand = (expandedKeys: ReactText[]) => {
    setExpandedKeys(expandedKeys)
  }

  const onKeyExpand = (expandedKey: ReactText) => {
    setExpandedKeys((prevState) =>
      prevState.includes(expandedKey) ? prevState.filter((key) => key !== expandedKey) : [...prevState, expandedKey],
    )
  }

  return (
    <div className={`${className}`}>
      <Row className='mt-3' grid>
        <Col className='ManagerTreeItem'>
          <h2>{t('meeting.orderOfTheDay')}</h2>
          <Tree
            className='bg-transparent my-3'
            selectable={false}
            switcherIcon={<></>}
            blockNode
            defaultExpandAll
            treeData={(treeData || []).filter((data) => data.key !== ATTACHED_DOCUMENT_KEY)}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            titleRender={(dataNode: InputDataNode) => (
              <OrderOfTheDayMemberTreeItem
                item={dataNode}
                openPreviewDocument={onFileSelected}
                showDocuments={false}
                onKeyExpand={onKeyExpand}
              />
            )}
          />
        </Col>
        {/** L'ordre du jour n'est modifiable que par les collaborateurs gestionnaires de l'instance de la réunion*/}
        {canManageMeeting && !postponed && (
          <>
            {lastNotification && (
              <Col md='auto' xs='12'>
                <small className='d-flex align-items-top'>{`${t(
                  'notifications.lastNotification',
                )}${notificationService.getNotificationTime(lastNotification)}`}</small>
              </Col>
            )}
            <Col md='auto' sm='6' xs='12'>
              <Button
                isLoading={isLoading}
                color='secondary'
                className='custom-button'
                label={t('notifications.notifyDocumentsAdded')}
                onClick={onNotifyClick}
              />
            </Col>
            <Col md='auto' sm='6' xs='12'>
              <Button
                className='custom-button'
                label={t(isInEditMode ? 'common.cancel' : 'common.edit')}
                onClick={() => (isInEditMode ? onCancelClick() : onEditClick(true))}
              />
            </Col>
          </>
        )}
      </Row>
      {!showOnlyOrderOfTheDay && (
        <>
          <Row className='justify-space-between m-0 mb-3'>
            <h2 className='m-0'>{t('meeting.documents.title')}</h2>
            <Col md='auto' sm='6' xs='12'>
              <Button
                className='mt-2'
                disabled={isZipLoading}
                label={isZipLoading ? t('meeting.downloading') : t('meeting.download')}
                onClick={async () => {
                  if (meeting.order_of_the_day) {
                    setIsZipLoading(true)
                    await downloadZipDocument(meeting)
                    setIsZipLoading(false)
                  } else {
                    notifyWarning(t('toastify.errors.zipEmpty'))
                  }
                }}
              />
            </Col>
          </Row>
          <div className='TabbedContainer'>
            <Nav tabs={true}>
              {documentTabs.map(({ id, label, show = true }) => (
                <NavItem key={`nav${id}`} className={`nav-item ${show ? '' : 'd-none'}`}>
                  <NavLink
                    className={classnames({ active: activeTab === id })}
                    onClick={() => {
                      toggleTab(id)
                    }}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            {documentTabs.map(({ id, content }) => (
              <TabPane key={`tab${id}`} tabId={id}>
                {content}
              </TabPane>
            ))}
          </TabContent>
        </>
      )}
      {selectedFile && <DocumentViewer file={selectedFile} isOpen={Boolean(selectedFile)} onClose={onFileSelected} />}
    </div>
  )
}

export default OrderOfTheDay
