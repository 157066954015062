import { Tree, TreeProps } from 'antd'
import { DataNode } from 'antd/lib/tree'
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes'
import { EventDataNode, Key } from 'rc-tree/lib/interface'
import React, { FC, ReactNode } from 'react'

import { ATTACHED_DOCUMENT_KEY } from '../../../utils/constants/ChonkyFileBrowser'
import { moveNodeInTree } from '../../../utils/treeUtils'

import './DraggableTree.scss'

export interface InfoDropNode extends NodeDragEventParams {
  dragNode: EventDataNode // l'objet node dragged
  dragNodesKeys: Key[] // array de l'ensemble des keys drag -> dragging groupé
  dropPosition: number // index de l'élément drop dans l'array des dragNodesKeys
  dropToGap: boolean // true si on drop entre 2 éléments
}
interface DraggableTreeProps extends TreeProps {
  TreeItemComponent: (dataNode: DataNode) => ReactNode
  treeData: DataNode[]
  onTreeDataChange: (newTree: DataNode[]) => void
  className?: string
}

const DraggableTree: FC<DraggableTreeProps> = (props) => {
  const { TreeItemComponent, treeData, onTreeDataChange, className = '', ...rest } = props

  const onDrop = (info: InfoDropNode) => {
    onTreeDataChange(moveNodeInTree(info, treeData))
  }

  return (
    <Tree
      className={`draggable-tree TreeWithIcon ${className}`}
      draggable
      blockNode
      defaultExpandAll
      onDrop={onDrop}
      treeData={treeData.filter((data) => data.key !== ATTACHED_DOCUMENT_KEY)}
      titleRender={TreeItemComponent}
      switcherIcon={<></>}
      {...rest}
    />
  )
}

export default DraggableTree
