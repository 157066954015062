import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import { isPast } from 'date-fns'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath } from 'react-router'
import { Link, useHistory, useParams } from 'react-router-dom'
import { BreadcrumbItem, Col, Form } from 'reactstrap'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { MeetingValidationSchema } from '../utils/constants/Meeting'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { convertDateToStringFromFormat, DATABASE_DATE_FORMAT } from '../utils/dateUtils'
import { useQuery } from '../utils/hooks/useQuery'

import { Invitation } from '../types/Invitation'
import { Meeting, MeetingFormValues, MeetingStatus } from '../types/Meeting'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'
import MeetingForm from '../components/molecules/meeting/MeetingForm'
import ModalAreYouSure from '../components/molecules/modals/ModalAreYouSure'
import AddressForm from '../components/molecules/profile/AddressForm'

import { meetingService } from '../services/meetingService'
import { UserStore } from '../store/UserStore'

type actionType = {
  action: 'create' | 'update'
  id?: string
  date?: string
}
/**
 * Vue où l'utilisateur peut créer une nouvelle réunion
 */
const MeetingFormView: FC = () => {
  const { action, id = '', date } = useParams<actionType>()
  const history = useHistory()
  const { t } = useTranslation()
  const user = useStore(UserStore)
  const queryCache = useQueryClient()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showPostponeModal, setShowPostponeModal] = useState<boolean>(false)

  const isCreating = action === 'create'

  const queryKey = `meeting getOne ${id}`

  const { data: meeting, isLoading: isMeetingLoading } = useQuery<Meeting>({
    queryKey: queryKey,
    queryFn: () => meetingService.getOne(id),
    onSuccess: (meeting) => {
      reset({
        instance: meeting?.instance.id || undefined,
        type: meeting?.type || '',
        online_meeting_url: meeting?.online_meeting_url || '',
        start_date: meeting?.start_date || undefined,
        start_time: meetingService.formatTime(meeting?.start_time) || undefined,
        end_time: meetingService.formatTime(meeting?.end_time) || undefined,
        meeting_address: meeting?.meeting_address || undefined,
      })
    },
    enabled: !isCreating && !!id,
    cacheTime: 0,
  })
  const { register, handleSubmit, errors, reset } = useForm<MeetingFormValues>({
    mode: 'onChange',
    resolver: yupResolver(MeetingValidationSchema),
    defaultValues: {
      instance: undefined,
      type: '',
    },
  })

  const { mutate: deleteMeeting, isLoading: isDeleteLoading } = useMutation(
    (meetingId: string) => meetingService.deleteMeeting(meetingId),
    {
      onSuccess: () => {
        notifySuccess(t('toastify.success.deleteMeeting'))
        history.push(PATHS.MEETINGS.MEETING)
      },
      onError: () => {
        notifyError(t('toastify.errors.deleteMeeting'))
      },
    },
  )

  const { mutate: postponeMeeting, isLoading: isPostponeLoading } = useMutation(
    (meetingId: string) => meetingService.update(meetingId, { status: MeetingStatus.CANCELED }),
    {
      onSuccess: () => {
        notifySuccess(t('toastify.success.postponeMeeting'))
        history.push(generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id }))
      },
      onError: () => {
        notifyError(t('toastify.errors.postponeMeeting'))
      },
    },
  )

  const { mutate, isLoading: isCreateLoading } = useMutation(
    (values: Meeting) => (isCreating ? meetingService.create(values) : meetingService.update(id, values)),
    {
      mutationKey: isCreating ? ['meeting', 'create'] : ['meeting', 'update', id],
      onSuccess: async (newMeeting) => {
        notifySuccess(isCreating ? t('toastify.success.createMeeting') : t('toastify.success.updateMeeting'))
        history.push(generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: newMeeting.id }))
      },
      onError: () => notifyError(t(isCreating ? 'toastify.errors.create.meeting' : 'toastify.errors.update.meeting')),
    },
  )

  const onSubmit = (values: Meeting) => {
    void queryCache.resetQueries(['user', 'getUserMeetings'])
    mutate({ ...values, start_date: convertDateToStringFromFormat(new Date(values.start_date), DATABASE_DATE_FORMAT) })
  }

  const onDeleteMeeting = () => {
    if (meeting?.invitations?.find((invitation: Invitation) => invitation.user.id === user?.id)) {
      void queryCache.resetQueries(['user', 'getUserMeetings'])
    }
    !isDeleteLoading && deleteMeeting(id)
  }

  const toggleDeleteModal = () => setShowDeleteModal((state: boolean) => !state)

  const togglePostponeModal = () => setShowPostponeModal((state: boolean) => !state)

  if (isMeetingLoading) return null

  const isMeetingPast = meeting && isPast(new Date(meeting?.start_date))
  const postponed = meeting?.status === MeetingStatus.CANCELED
  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Row className='align-items-center' grid>
            <Col>
              <Header>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={PATHS.MEETINGS.MEETING}>{t('nav.myMeetings')}</Link>
                  </BreadcrumbItem>
                  {!isCreating && (
                    <BreadcrumbItem>
                      <Link to={generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: id })}>
                        {meeting?.instance.long_name || ''}
                      </Link>
                    </BreadcrumbItem>
                  )}
                  <BreadcrumbItem> {!isCreating ? t('meeting.updateMeeting') : t('meeting.newMeeting')}</BreadcrumbItem>
                </Breadcrumb>
              </Header>
            </Col>
            {!isCreating && !postponed && (
              <Col xs='auto'>
                <Button
                  className='ml-auto'
                  color='pending'
                  label={t('meeting.postpone')}
                  onClick={togglePostponeModal}
                  disabled={isPostponeLoading}
                />
              </Col>
            )}
            {!isCreating && (
              <Col xs='auto'>
                <Button
                  className='ml-auto'
                  color='secondary'
                  label={t('meeting.delete')}
                  onClick={toggleDeleteModal}
                  disabled={isDeleteLoading}
                />
              </Col>
            )}
          </Row>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column'>
            <Col xs={12} lg={{ size: 8, offset: 2 }}>
              <Row className='flex-row-reverse'>
                <MeetingForm register={register} errors={errors} isCreating={isCreating} start_date={date} />
              </Row>
              <Row>
                <AddressForm name='meeting_address' errors={errors.meeting_address} register={register} isInEditMode />
              </Row>
              <div className='mt-5'>
                {!isMeetingPast && !postponed && (
                  <Button className='mx-auto' isLoading={isCreateLoading}>
                    {t(isCreateLoading && isCreating ? 'meeting.createMeetingLoading' : 'common.save')}
                  </Button>
                )}
                {isMeetingPast && <p className='d-block text-center'>{t('meeting.cannotUpdateAPastMeeting')}</p>}
                {postponed && <p className='d-block text-center'>{t('meeting.cannotUpdateAPostponedMeeting')}</p>}
              </div>
            </Col>
          </Form>
        </Section>
      </ViewBody>
      <ModalAreYouSure
        toggle={toggleDeleteModal}
        isOpen={showDeleteModal}
        onConfirmClick={onDeleteMeeting}
        modalHeaderText={t('modal.areYouSureToDeleteMeeting')}
        modalBody={<p>{t('modal.actionCanNotBeUndone')}</p>}
      />
      <ModalAreYouSure
        toggle={togglePostponeModal}
        isOpen={showPostponeModal}
        onConfirmClick={() => postponeMeeting(id)}
        modalHeaderText={t('modal.areYouSureToPostponeMeeting')}
        modalBody={<p>{t('modal.actionCanNotBeUndone')}</p>}
      />
    </View>
  )
}

export default MeetingFormView
