import Section from 'components/atoms/layout/Section'
import Spinner from 'components/atoms/spinner/Spinner'
import UserCollaboratorsTable from 'components/mandate/user-collaborators/UserCollaboratorsTable'
import UserDelegatesTable from 'components/mandate/user-delegates/UserDelegatesTable'
import UserMembersTable from 'components/mandate/user-members/UserMembersTable'
import TabbedContainer, { Tab } from 'components/tabbed-container/TabbedContainer'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect, useLocation, useParams } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import { userService } from 'services/userService'
import { User } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import { isDelegate, usePermissions } from 'utils/hooks/usePermissions'
import { useQuery } from 'utils/hooks/useQuery'

import { PATHS } from '../utils/constants/routes/RoutePaths'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import UserName from '../components/atoms/custom/UserName'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'

const UserMandatesView: FC = () => {
  const { t } = useTranslation()
  const params: { id: string } = useParams()
  const userId = parseInt(params.id)
  const tabParam = new URLSearchParams(useLocation().search).get('tab')
  const { USER_CAN_ADD_MANDATE, CONTACTS_CAN_MANAGE, IS_SUPER_ADMIN } = usePermissions()

  if (!(IS_SUPER_ADMIN() || (CONTACTS_CAN_MANAGE() && tabParam !== 'permission'))) {
    return <Redirect to='/' />
  }

  const { data: user, isLoading, refetch } = useQuery<User>({
    queryKey: ['user', 'getOne'],
    queryFn: () => userService.getOne(userId),
    onError: () => notifyError(t('toastify.errors.get.user')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }

  const canAddMandate: boolean = USER_CAN_ADD_MANDATE(user)

  const userMandatesTabs: Tab[] = user
    ? [
        {
          id: 'member',
          label: t('mandates.tabs.member.nav'),
          content: <UserMembersTable user={user} canAddMandate={canAddMandate} />,
          show: IS_SUPER_ADMIN() && CONTACTS_CAN_MANAGE(),
          idDefault: tabParam === 'member',
        },
        {
          id: 'collaborator',
          label: t('mandates.tabs.collaborator.nav'),
          content: <UserCollaboratorsTable user={user} canAddMandate={canAddMandate} />,
          show: IS_SUPER_ADMIN(),
          idDefault: tabParam === 'collaborator',
        },
        {
          id: 'delegate',
          label: t('mandates.tabs.delegate.nav'),
          content: <UserDelegatesTable user={user} canAddMandate={canAddMandate} />,
          show: isDelegate(user.delegates),
          idDefault: tabParam === 'delegate',
        },
        {
          id: 'permission',
          label: t('mandates.tabs.other.nav'),
          content: (
            <UserCollaboratorsTable user={user} canAddMandate={canAddMandate} isOther={true} refetchUser={refetch} />
          ),
          show: IS_SUPER_ADMIN(),
          idDefault: tabParam === 'permission',
        },
      ]
    : []

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.USER_MANAGEMENT.HOME}>{t('nav.userManagement')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <span className='d-flex align-items-center'>{user && <UserName user={user} className='m-0' />}</span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {user && <TabbedContainer tabs={userMandatesTabs} />}
          {!user && <p className='text-center'>{t('auth.noUserFound')}</p>}
        </Section>
      </ViewBody>
    </View>
  )
}

export default UserMandatesView
