import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Label } from 'reactstrap'

import { convertDateToStringFromFormat, DATE_SHORT_FORMAT } from '../../../utils/dateUtils'

import { MeetingPreview } from '../../../types/Meeting'

import TextInput from '../../atoms/input/TextInput'

interface MeetingDatePickerProps {
  meetingList: MeetingPreview[]
  onSelectMeeting: (meetingId: string) => void
}

const MeetingDatePicker: FC<MeetingDatePickerProps> = (props) => {
  const { meetingList, onSelectMeeting } = props
  const { t } = useTranslation()
  const computeMeetingDate = (meeting: MeetingPreview) =>
    convertDateToStringFromFormat(new Date(meeting.start_date), DATE_SHORT_FORMAT)

  return (
    <div>
      <Label>{t('meeting.meetingDate')}</Label>
      <TextInput id='yearSelect' type='select' onChange={(e) => onSelectMeeting(e.target.value)} defaultValue=''>
        <option value=''>{t('meeting.selectMeeting')}</option>
        {meetingList.map((meeting) => (
          <option key={meeting.id} value={meeting.id}>
            {`${meeting.instance.association?.name} - ${meeting.instance.long_name}`}
            {` (${computeMeetingDate(meeting)})`}
          </option>
        ))}
      </TextInput>
    </div>
  )
}

export default MeetingDatePicker
