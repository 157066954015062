import { differenceInDays } from 'date-fns'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { convertDateToStringFromFormat, DATE_SHORT_FORMAT } from '../utils/dateUtils'

import { Article } from '../types/Article'
import { Instance } from '../types/Instance'
import { Meeting } from '../types/Meeting'
import {
  NotificationInterface,
  NotificationType,
  NotificationUpdate,
  UnreadKioskNotification,
} from '../types/Notification'

import { execute } from '../api/api'

// API
const getUserNotifications = (): Promise<NotificationInterface[]> => {
  return execute<NotificationInterface[]>(ApiRoutes.NOTIFICATIONS.ME, 'GET')
}

const findNotification = (
  type: NotificationType,
  relatedId: string,
  limit = 1,
  sort = 'created_at:DESC',
): Promise<NotificationInterface[]> => {
  return execute<NotificationInterface[]>(
    `${ApiRoutes.NOTIFICATIONS.BASE}?type=${type}&related_id=${relatedId}&_limit=${limit}&_sort=${sort}`,
    'GET',
  )
}

const update = (id: number, toUpdate: NotificationUpdate): Promise<NotificationInterface> => {
  return execute<NotificationInterface>(generatePath(ApiRoutes.NOTIFICATIONS.ID, { id }), 'PUT', toUpdate)
}

// Fonctions utilitaires

const getNotificationMessage = (notification: NotificationInterface): string => {
  const { t } = useTranslation()
  let related
  let instance

  switch (notification.type) {
    case NotificationType.ARTICLE_ADDED:
      return t(`notifications.messages.${notification.type}`)
    case NotificationType.DOCUMENT_ADDED_1:
    case NotificationType.DOCUMENT_ADDED_2:
      related = notification.related as Meeting
      instance = ((related.instance as unknown) as Instance[])[0]
      return t(`notifications.messages.DOCUMENT_ADDED_2`, {
        meeting: `${instance.long_name}`,
      })
    case NotificationType.CONVOCATION_ADDED:
    case NotificationType.PRESENCE_REQUESTED:
      related = notification.related as Meeting
      return t(`notifications.messages.${notification.type}`, {
        meeting: `${related?.instance.long_name} - ${related?.instance.association?.name}`,
      })
    case NotificationType.REMINDER_CONVOCATION_20:
    case NotificationType.REMINDER_CONVOCATION_16:
      related = notification.related as Meeting
      return t(`notifications.messages.${notification.type}`, {
        meeting: `${related?.instance.long_name} - ${related?.instance.association?.name}`,
        date: related && convertDateToStringFromFormat(new Date(related.start_date), DATE_SHORT_FORMAT),
      })
    case NotificationType.SUMMARIES_BOARD:
      return t(`notifications.messages.${notification.type}`)
    case NotificationType.KIOSK_ACTIVITY:
      return t(`notifications.messages.${notification.type}`)
    default:
      break
  }

  return t(`notifications.messages.UNKNOWN`)
}

const getNotificationPath = (notification: NotificationInterface): string => {
  let related

  switch (notification.type) {
    case NotificationType.ARTICLE_ADDED:
      related = notification.related as Article
      return generatePath(PATHS.NEWS.PAGE, { slug: related?.slug })
    case NotificationType.DOCUMENT_ADDED_1:
    case NotificationType.DOCUMENT_ADDED_2:
      related = notification.related as Meeting
      return `${generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: related?.id })}?tab=recentDoc`
    case NotificationType.CONVOCATION_ADDED:
    case NotificationType.REMINDER_CONVOCATION_20:
    case NotificationType.REMINDER_CONVOCATION_16:
    case NotificationType.PRESENCE_REQUESTED:
      related = notification.related as Meeting
      return generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id: related?.id })
    case NotificationType.SUMMARIES_BOARD:
      return generatePath(PATHS.KIOSK.SUMMARY_BOARD_DIRECTORS)
    case NotificationType.KIOSK_ACTIVITY:
      return generatePath(PATHS.KIOSK.ACTIVITY)
    case NotificationType.KIOSK_FORMATIONS:
      return generatePath(PATHS.KIOSK.FORMATIONS)
    default:
      break
  }

  return ''
}

const getNotificationTime = (notification: NotificationInterface): string => {
  const date = new Date(notification.created_at)
  const now = new Date()

  const delta = now.getTime() - date.getTime()

  const hour = 1000 * 60 * 60
  const day = hour * 24

  if (delta >= 7 * day) {
    return convertDateToStringFromFormat(date, DATE_SHORT_FORMAT)
  } else if (delta >= day) {
    return `${differenceInDays(now, date)} j`
  } else if (delta >= hour) {
    return `${Math.round(delta / 36e5)} h`
  } else {
    const minutes = Math.round(delta / 6e4)
    return minutes === 0 ? i18next.t('notifications.fewSeconds') : `${minutes} min`
  }
}

const isMeetingNotification = (notification: NotificationInterface): boolean => {
  return [
    NotificationType.CONVOCATION_ADDED,
    NotificationType.REMINDER_CONVOCATION_16,
    NotificationType.REMINDER_CONVOCATION_20,
    NotificationType.DOCUMENT_ADDED_1,
    NotificationType.DOCUMENT_ADDED_2,
    NotificationType.PRESENCE_REQUESTED,
  ].includes(notification.type)
}

const isArticleNotification = (notification: NotificationInterface): boolean => {
  return [NotificationType.ARTICLE_ADDED].includes(notification.type)
}

const isKioskNotification = (notification: NotificationInterface): boolean => {
  return [NotificationType.SUMMARIES_BOARD, NotificationType.KIOSK_ACTIVITY].includes(notification.type)
}

const isKioskActivityNotification = (notification: NotificationInterface): boolean => {
  return [NotificationType.KIOSK_ACTIVITY].includes(notification.type)
}

const isKioskBoardNotification = (notification: NotificationInterface): boolean => {
  return [NotificationType.SUMMARIES_BOARD].includes(notification.type)
}

const notViewedKioskNotificationsPerRubric = (notifications: NotificationInterface[]): UnreadKioskNotification[] => {
  const UnreadNotify = []

  UnreadNotify.push({
    unreadNumber: notifications.filter((notif) => !notif.view && isKioskBoardNotification(notif)).length,
    title: 'kiosk.rubrics.summaryBoardDirectors',
  })
  UnreadNotify.push({
    unreadNumber: notifications.filter((notif) => !notif.view && isKioskActivityNotification(notif)).length,
    title: 'kiosk.rubrics.activity',
  })
  return UnreadNotify
}

export const notificationService = {
  findNotification,
  getUserNotifications,
  update,
  getNotificationMessage,
  getNotificationPath,
  getNotificationTime,
  isMeetingNotification,
  isArticleNotification,
  isKioskNotification,
  notViewedKioskNotificationsPerRubric,
}
