import { useStore } from 'effector-react'
import _ from 'lodash'
import { Association } from 'types/Association'
import { Delegate } from 'types/Delegate'

import { Collaborator, PerimeterTypeEnum } from '../../types/Collaborator'
import { Instance } from '../../types/Instance'
import { Meeting } from '../../types/Meeting'
import { Member } from '../../types/Member'
import { User } from '../../types/User'

import { InstanceListStore } from '../../store/InstanceListStore'
import { UserStore } from '../../store/UserStore'
import { SUPER_ADMIN } from '../constants/Permissions'
import { isMandateCurrent } from '../mandateUtils'

/**
 * hook qui renvoi une instance à partir d'une instance ou de son id en allant chercher dans le InstanceListStore
 * @param instanceTargeted
 */
export const useInstance = (instanceTargeted?: number | Instance): Instance | undefined => {
  const instanceList = useStore(InstanceListStore)
  if (instanceTargeted != undefined) {
    const idInstanceTargeted = typeof instanceTargeted == 'number' ? instanceTargeted : instanceTargeted.id
    return instanceList.find((instance) => instance.id === idInstanceTargeted)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePermissions = (): any => {
  //On utilise des hooks useStore et pas xxxStore.getState pour que les composants prennent en compte les changements dans le store si cela arrive
  const user = useStore(UserStore)
  let connectedUser: User | null = user
  if (user) {
    connectedUser = {
      ...user,
      collaborators:
        user.collaborators?.filter((collaborator) =>
          isMandateCurrent(collaborator.start_date, collaborator.end_date),
        ) || [],
      members: user.members?.filter((member) => isMandateCurrent(member.start_date, member.end_date)) || [],
    }
  }

  return {
    /**
     * Seuls les utilisateurs qui sont collaborateurs, gestionnaires sur le meme périmètre
     * peuvent modifer les mandats d'autres personnes qui ne possède pas le périmètre all
     * Il n'est pas possible de modifier ses propres mandats
     */
    USERS_CAN_MANAGE_MANDATES: (targetUser: User) => {
      return (
        targetUser &&
        connectedUser &&
        !_isTargetedUserMe(targetUser, connectedUser) &&
        _isManagerInSamePerimeterThanUser(targetUser, connectedUser) &&
        !targetUser.collaborators.some(
          (collaborator) => collaborator.perimeter_type === PerimeterTypeEnum.ALL && !collaborator.is_permission,
        )
      )
    },
    /**
     * Seuls les utilisateurs qui sont utilisateur collaborateurs gestionnaires
     * peuvent ajouter des mandats à d'autres utilisateurs qui ne possèdent pas le périmètre all
     */
    USER_CAN_ADD_MANDATE: (targetUser: User) => {
      return (
        targetUser &&
        connectedUser &&
        !_isTargetedUserMe(targetUser, connectedUser) &&
        _isCollaboratorManager(connectedUser.collaborators) &&
        !targetUser.collaborators.some(
          (collaborator) => collaborator.perimeter_type === PerimeterTypeEnum.ALL && !collaborator.is_permission,
        )
      )
    },

    /**
     * Check si l'utilisateur est membre actif
     */
    USER_IS_MEMBER: () => connectedUser && isMember(connectedUser.members),

    /**
     * Check si l'utilisateur est délégué actif
     */
    USER_IS_DELEGATE_ACTIVE: () => connectedUser && _isDelegateActive(connectedUser.delegates),

    /**
     * Check si l'utilisateur est délégué actif
     */
    USER_IS_DELEGATE: () => connectedUser && isDelegate(connectedUser.delegates),

    /**
     * Check si l'utilisateur est membre actif d'une instance
     */
    USER_IS_MEMBER_IN_INSTANCE: (instanceId: number) => {
      const targetedInstance = useInstance(instanceId)
      return connectedUser && targetedInstance && _isMemberInInstance(connectedUser.members, targetedInstance)
    },

    /**
     * Check si l'utilisateur est collaborateur actif d'une instance
     */
    USER_IS_COLLABORATOR_IN_INSTANCE: (instanceId: number) => {
      const targetedInstance = useInstance(instanceId)
      return (
        connectedUser && targetedInstance && _isCollaboratorInInstance(connectedUser.collaborators, targetedInstance)
      )
    },

    /**
     * Check si l'utilisateur a un mandat de permission actif d'une instance et d'une permission
     */
    HAS_PERMISSION: (instanceId: number, permission: string) => {
      const targetedInstance = useInstance(instanceId)
      return (
        connectedUser &&
        targetedInstance &&
        _hasPermissionInInstance(connectedUser.collaborators, targetedInstance, permission)
      )
    },

    /**
     * Check si l'utilisateur a une permission général
     */
    HAS_GENERAL_PERMISSION: (permission: string) => {
      return connectedUser && connectedUser.common_permission?.includes(permission)
    },

    /**
     * Check si l'utilisateur est collaborateur actif d'une instance
     */
    USER_IS_COLLABORATOR_MANAGER_IN_INSTANCE: (instanceId: number) => {
      const targetedInstance = useInstance(instanceId)
      return (
        connectedUser &&
        targetedInstance &&
        _isCollaboratorManagerInInstance(connectedUser.collaborators, targetedInstance)
      )
    },

    /**
     * Check si l'utilisateur peut gérer les relevés de décision sur une instance donnée
     * */
    CAN_MANAGE_DECISION_RECORDS_IN_INSTANCE: (instanceId: number) => {
      const targetedInstance = useInstance(instanceId)
      return (
        connectedUser &&
        targetedInstance &&
        _isCollaboratorManagerInInstance(connectedUser.collaborators, targetedInstance)
      )
    },
    /**
     * Check si l'utilisateur peut gérer les relevés de décision au moins sur une instance
     */
    CAN_MANAGE_DECISION_RECORDS: () => {
      return connectedUser && _isCollaboratorManager(connectedUser.collaborators)
    },
    /**
     * Check si l'utilisateur peut gérer le kiosk
     */
    IS_SUPER_ADMIN: () => {
      return connectedUser && (connectedUser.common_permission ?? []).includes(SUPER_ADMIN)
    },
    /**
     * Check si l'utilisateur est collaborateur actif d'une instance
     */
    USER_IS_COLLABORATOR_MANAGER: () => {
      return connectedUser && _isCollaboratorManager(connectedUser.collaborators)
    },

    /**
     * L'utilisateur est invité à la réunion
     */
    USER_IS_INVITED_IN_MEETING: (targetedMeeting: Meeting) =>
      connectedUser && _isUserInvitedInMeeting(connectedUser, targetedMeeting),

    /**
     * Seuls les utilisateur qui sont collaborateur avec droit de gestion peuvent créer une nouvelle réunion
     */
    MEETINGS_CAN_CREATE: () => {
      return _isCollaboratorManager(connectedUser?.collaborators)
    },
    /**
     * Seuls les gestionnaires peuvent créer une nouvelle réunion directement depuis l'agenda
     */
    MEETINGS_CAN_CREATE_FROM_AGENDA: () => connectedUser && _isCollaboratorManager(connectedUser.collaborators),
    /**
     * TODO prendre en compte le périmètre
     * Seuls les utilisateurs qui sont collaborateurs sur l'instance peuvent voir les participants avec une invitation non envoyée (statut NOT_SENT)
     */
    MEETINGS_INVITATIONS_CAN_VIEW_NOT_SENT: (meetingTargeted: Meeting) => {
      const targetedInstance = useInstance(meetingTargeted.instance)
      return targetedInstance && _isCollaboratorInInstance(connectedUser?.collaborators, targetedInstance)
    },
    /**
     * Seuls les utilisateurs étant invités ou collaborateurs peuvent voir les informations détaillées d'une réunion
     */
    MEETINGS_CAN_SEE_DETAILS: (meetingTargeted?: Meeting) => {
      const instanceTargeted = useInstance(meetingTargeted?.instance)
      return (
        meetingTargeted &&
        instanceTargeted &&
        connectedUser &&
        (_isCollaboratorInInstance(connectedUser.collaborators, instanceTargeted) ||
          _isUserInvitedInMeeting(connectedUser, meetingTargeted))
      )
    },
    /**
     * Seuls les utilisateurs qui sont collaborateur avec droit de gestion sur l'instance du meeting peuvent gérer la réunion
     */
    MEETINGS_CAN_MANAGE: (meetingTargeted?: Meeting) => {
      const targetedInstance = useInstance(meetingTargeted?.instance)
      return targetedInstance && _isCollaboratorManagerInInstance(connectedUser?.collaborators, targetedInstance)
    },
    /**
     * Seuls les utilisateurs qui sont collaborateur sur l'instance du meeting peuvent voir les relevés de décisions
     */
    MEETINGS_CAN_VIEW_DECISIONS: (meetingTargeted?: Meeting) => {
      const targetedInstance = useInstance(meetingTargeted?.instance)
      return targetedInstance && _isCollaboratorInInstance(connectedUser?.collaborators, targetedInstance)
    },

    /**
     * Seuls les utilisateurs qui sont collaborateurs gestionnaires peuvent ajouter un nouvel utilisateur ou gérer les mandats
     */
    CONTACTS_CAN_MANAGE: () => {
      return _isCollaboratorManager(connectedUser?.collaborators)
    },

    USER_IS_OTHER_COLLABORATOR: () => {
      return _isOtherCollaborator(connectedUser?.collaborators)
    },

    /**
     * Seuls les utilisateurs qui sont collaborateurs, gestionnaires et qui ont le meme périmètre
     * peuvent modifer les informations d'autres personnes
     * Il n'est pas possible de se modifier soi même depuis les contacts
     */
    CONTACTS_CAN_EDIT: (targetUser: User) =>
      targetUser &&
      connectedUser &&
      !_isTargetedUserMe(targetUser, connectedUser) &&
      _isManagerInSamePerimeterThanUser(targetUser, connectedUser),

    /**
     * Seuls les utilisateurs gestionnaires peuvent accéder à la section d'aide adressée aux gestionnaires
     */
    USER_CAN_ACCESS_MANAGER_HELP: () => connectedUser && _isCollaboratorManager(connectedUser.collaborators),

    /**
     * Check si un itilisateur donné à au moins un mandat actif (collaborator, delegate, member)
     */
    USER_HAS_MANDATE: (targetUser: User) =>
      isMember(targetUser.members) ||
      _isDelegateActive(targetUser.delegates) ||
      isCollaboratorActif(targetUser.collaborators),
  }
}

//---------------------------------USERS-----------------------------------------------
/**
 * check si un utilisateur donné est le même que l'utilisateur connecté
 */
const _isTargetedUserMe = (targetedUser: User, me: User): boolean => {
  return targetedUser.id === me.id
}

//---------------------------------PERIMETER-----------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _isInstanceInUserPerimeter = (user: User, instance: Instance) => {
  return _isMemberInInstance(user.members, instance) || _isCollaboratorInInstance(user.collaborators, instance)
}

/**
 * un utilisateur est gestionnaire dans le meme périmètre que moi s'il possède au moins un mandat avec un role gestionnaire dont l'instance est commune avec les miens
 */
const _isManagerInSamePerimeterThanUser = (targetUser: User, me: User) => {
  const instanceList = InstanceListStore.getState() || []

  const meManagerCollaborators = me.collaborators.filter((collaborator) => collaborator.is_manager)
  return (
    _.intersectionBy(
      _.unionBy(
        _getCollaboratorInstanceList(instanceList, targetUser.collaborators),
        _getMemberInstanceList(instanceList, targetUser.members),
        _getDelegateInstanceList(
          instanceList,
          targetUser.delegates.filter((delegate) => delegate.meeting.instance != null),
        ),
        'id',
      ),
      _.unionBy(_getCollaboratorInstanceList(instanceList, meManagerCollaborators), 'id'),
    ).length > 0
  )
}

//---------------------------------MEETINGS-----------------------------------------------
/**
 * check si un utilisateur est invité à une réunion
 * @param user
 * @param meeting
 */
const _isUserInvitedInMeeting = (user: User, meeting: Meeting) => {
  return Boolean(meeting?.invitations?.find((invitation) => invitation?.user?.id === user.id))
}

//---------------------------------MEMBERS-----------------------------------------------
/**
 * check si un mandat membre cible une instance donnée
 *
 */
const _isMemberInInstance = (memberList: Member | Member[] | undefined, targetedInstance: Instance): boolean => {
  if (memberList !== undefined) {
    if (Array.isArray(memberList)) {
      return memberList.some((member) => _isMemberInInstance(member, targetedInstance))
    }
    return (
      memberList.instance.id === targetedInstance.id && isMandateCurrent(memberList.start_date, memberList.end_date)
    )
  }
  return false
}
/**
 * return la liste de toutes les instances ciblés d'une liste de mandats membres
 */
export const _getMemberInstanceList = (allInstanceList: Instance[], memberList?: Member[]): Instance[] => {
  return memberList
    ? allInstanceList.filter((instance) =>
        _.map(memberList, (member) => {
          if (typeof member.instance === 'number') {
            return member.instance
          } else {
            return member.instance.id
          }
        }).includes(instance.id),
      )
    : []
}
/**
 * Renvoie true si l'utilisateur est membre sur au moins un périmètre
 * @param memberList
 */
export const isMember = (memberList?: Member[]): boolean => {
  return Array.isArray(memberList) && memberList.some((member) => isMandateCurrent(member.start_date, member.end_date))
}

//---------------------------------DELEGATES-----------------------------------------------
/**
 * return la liste de toutes les instances ciblés d'une liste de mandats membres
 */
export const _getDelegateInstanceList = (allInstanceList: Instance[], delegateList?: Delegate[]): Instance[] => {
  return delegateList
    ? allInstanceList.filter((instance) =>
        _.map(delegateList, (delegate) => {
          if (typeof delegate.meeting.instance === 'number') {
            return delegate.meeting.instance
          } else {
            return delegate.meeting.instance.id
          }
        }).includes(instance.id),
      )
    : []
}

/**
 * Renvoie true si l'utilisateur est délégué actif sur au moins une réunion
 * @param delegateList
 */
export const _isDelegateActive = (delegateList?: Delegate[]): boolean => {
  return (
    Array.isArray(delegateList) &&
    delegateList.some((delegate) => isMandateCurrent(delegate.start_date, delegate.end_date))
  )
}

/**
 * Renvoie true si l'utilisateur est délégué sur au moins une réunion
 * @param delegateList
 */
export const isDelegate = (delegateList?: Delegate[]): boolean => {
  return delegateList !== undefined && delegateList.length > 0
}

//---------------------------------COLLABORATORS-----------------------------------------------
/**
 * return la liste de toutes les instances ciblés d'une liste de mandats collaborateurs
 */
export const _getCollaboratorInstanceList = (
  allInstanceList: Instance[],
  collaboratorList?: Collaborator[],
): Instance[] => {
  if (!Array.isArray(collaboratorList)) {
    return []
  }
  if (
    collaboratorList.find(
      (collaborator) => collaborator.perimeter_type === PerimeterTypeEnum.ALL && !collaborator.is_permission,
    )
  ) {
    return allInstanceList
  }

  return collaboratorList.reduce((acc: Instance[], collaborator) => {
    if (collaborator.perimeter_type === PerimeterTypeEnum.ASSOCIATION && !collaborator.is_permission) {
      const instancesInAssociation = allInstanceList.filter(
        (instance) => instance.association?.id === collaborator.id_perimeter,
      )
      return [...acc, ...instancesInAssociation]
    } else if (collaborator.perimeter_type === PerimeterTypeEnum.INSTANCE && !collaborator.is_permission) {
      const instance = allInstanceList.find((instance) => instance.id === collaborator.id_perimeter)
      return instance ? [...acc, instance] : acc
    }
    return acc
  }, [])
}

/**
 * return la liste de toutes les associations ciblées d'une liste de mandats collaborateurs
 */
export const _getCollaboratorAssociationList = (
  allInstanceList: Instance[],
  collaboratorList?: Collaborator[],
): Association[] => {
  if (!Array.isArray(collaboratorList)) {
    return []
  }

  const allAssociationList = _getAssociationInInstanceList(allInstanceList)

  if (
    collaboratorList.find(
      (collaborator) => collaborator.perimeter_type === PerimeterTypeEnum.ALL && !collaborator.is_permission,
    )
  ) {
    return allAssociationList
  }

  return collaboratorList.reduce((acc: Association[], collaborator) => {
    if (collaborator.perimeter_type === PerimeterTypeEnum.ASSOCIATION && !collaborator.is_permission) {
      const association = allAssociationList.find((association) => association.id === collaborator.id_perimeter)
      return association ? [...acc, association] : acc
    }
    return acc
  }, [])
}

/**
 * Renvoie true si l'utilisateur est collaborateur avec droit de gestion sur au moins un périmètre
 * @param collaboratorList
 */
const _isCollaboratorManager = (collaboratorList?: Collaborator[]): boolean => {
  return collaboratorList !== undefined && collaboratorList.some((collaborator) => collaborator.is_manager)
}

const _isOtherCollaborator = (collaboratorList?: Collaborator[]): boolean => {
  return (
    collaboratorList !== undefined &&
    collaboratorList.some((collaborator) => isMandateCurrent(collaborator.start_date, collaborator.end_date))
  )
}

export const isPermissionCollaborator = (collaboratorList?: Collaborator[]): boolean => {
  return collaboratorList !== undefined && collaboratorList.some((collaborator) => collaborator.is_permission === true)
}

/**
 * Renvoie true si l'utilisateur est collaborateur sur au moins un périmètre
 * @param collaboratorList
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isCollaborator = (collaboratorList?: Collaborator[]): boolean => {
  return (
    collaboratorList !== undefined &&
    collaboratorList.length > 0 &&
    collaboratorList.some((collaborator) => collaborator.is_permission === false)
  )
}

/**
 * Renvoie true si l'utilisateur collaborateur  à au moins un mandat actif
 * @param memberList
 */
export const isCollaboratorActif = (collaboratorList?: Collaborator[]): boolean => {
  return (
    Array.isArray(collaboratorList) &&
    collaboratorList.some((collaborator) => isMandateCurrent(collaborator.start_date, collaborator.end_date))
  )
}

/**
 * Fonction qui vérifie qu'un mandat ou un des mandats Collaborateur match sur une instance
 * Soit c'est un collaborateur de l'instance, soit un collaborateur de l'association de l'instanec soir un collaborateur avec tout le périmètre
 * @param collaboratorList
 * @param targetedInstance
 */
const _isCollaboratorInInstance = (
  collaboratorList: Collaborator | Collaborator[] | undefined,
  targetedInstance: Instance,
): boolean => {
  if (collaboratorList !== undefined) {
    if (Array.isArray(collaboratorList)) {
      return collaboratorList.some((collaborator) => _isCollaboratorInInstance(collaborator, targetedInstance))
    }
    // Si c'est une permission on ne le considère pas comme un collaborateur
    if (collaboratorList.is_permission) {
      return false
    }
    const isMandateActive = isMandateCurrent(collaboratorList.start_date, collaboratorList.end_date)
    switch (collaboratorList.perimeter_type) {
      case PerimeterTypeEnum.ALL:
        return isMandateActive
      case PerimeterTypeEnum.ASSOCIATION:
        return collaboratorList.id_perimeter === targetedInstance?.association?.id && isMandateActive
      case PerimeterTypeEnum.INSTANCE:
        return collaboratorList.id_perimeter === targetedInstance?.id && isMandateActive
      default:
        return false
    }
  }
  return false
}

/**
 * Fonction qui vérifie qu'un mandat de permission ou un des mandats de permission match sur une instance et sur une permission
 * Soit c'est un mandat de permission de l'instance, soit un mandat de permission de l'association de l'instance soir un mandat de permission avec tout le périmètre
 * @param collaboratorList
 * @param targetedInstance
 * @param permission
 */
const _hasPermissionInInstance = (
  collaboratorList: Collaborator | Collaborator[] | undefined,
  targetedInstance: Instance,
  permission: string,
): boolean => {
  if (collaboratorList !== undefined) {
    if (Array.isArray(collaboratorList)) {
      return collaboratorList.some((collaborator) =>
        _hasPermissionInInstance(collaborator, targetedInstance, permission),
      )
    }
    // Si ce n'est pas une permission on ne le considère pas comme un mandat de permission
    if (!collaboratorList.is_permission) {
      return false
    }
    const isMandateActive = isMandateCurrent(collaboratorList.start_date, collaboratorList.end_date)
    switch (collaboratorList.perimeter_type) {
      case PerimeterTypeEnum.ALL:
        return isMandateActive && (collaboratorList.permission ?? []).includes(permission)
      case PerimeterTypeEnum.ASSOCIATION:
        return (
          collaboratorList.id_perimeter === targetedInstance?.association?.id &&
          isMandateActive &&
          (collaboratorList.permission ?? []).includes(permission)
        )
      case PerimeterTypeEnum.INSTANCE:
        return (
          collaboratorList.id_perimeter === targetedInstance?.id &&
          isMandateActive &&
          (collaboratorList.permission ?? []).includes(permission)
        )
      default:
        return false
    }
  }
  return false
}

/**
 * Fonction qui vérifie qu'un mandat ou un des mandats Collaborateur match sur une instance avec les droits manager
 * @param collaboratorList
 * @param targetedInstance
 */
const _isCollaboratorManagerInInstance = (
  collaboratorList: Collaborator | Collaborator[] | undefined,
  targetedInstance: Instance,
): boolean => {
  if (collaboratorList !== undefined) {
    if (Array.isArray(collaboratorList)) {
      return collaboratorList.some((collaborator) => _isCollaboratorManagerInInstance(collaborator, targetedInstance))
    }
    return _isCollaboratorInInstance(collaboratorList, targetedInstance) && collaboratorList?.is_manager
  }
  return false
}

/**
 * Fonction qui vérifie si l'utilisateur est collaborateur sur tout le périmètre à partir de ses mandats
 * @param collaboratorList
 * @returns {boolean}
 */
export const isCollaboratorOnAllPerimeter = (collaboratorList: Collaborator[]): boolean =>
  collaboratorList.some(
    (collaborator) => collaborator.perimeter_type === PerimeterTypeEnum.ALL && !collaborator.is_permission,
  )

//---------------------------------ASSOCIATION-----------------------------------------------
/**
 * retrouve la liste d'associations d'une liste d'instances
 */

const _getAssociationInInstanceList = (instanceList: Instance[]) =>
  _.uniqBy(
    instanceList.flatMap(({ association }) => {
      if (association) {
        return [association]
      } else {
        return []
      }
    }),
    (association) => association.id,
  )
