import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'

import { DocumentType, DocumentTypeEnum } from '../../../types/Document'
import { FinancialDataComponentType } from '../../../types/KioskFinancialData'

import { documentService } from '../../../services/documentService'
import { kioskService } from '../../../services/kioskService'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import Modal from '../../atoms/modal/Modal'
import DocumentCarrousel from '../documents/DocumentCarrousel'
import './Modals.scss'

interface ModalAddFinancialDataProps {
  toggle: () => void
  isOpen: boolean
  oldDocuments: FinancialDataComponentType[]
}

const ModalAddFinancialData: FC<ModalAddFinancialDataProps> = ({ toggle, isOpen, oldDocuments }) => {
  const initialState = {
    files: [],
  }

  const { t } = useTranslation()
  const [filterValues, setFilterValues] = useState<{
    files: File[]
  }>(initialState)

  const { mutate: uploadFoundingText, isLoading: isUploadLoading } = useMutation(
    async () => {
      if (filterValues.files.length === 0) {
        throw t('toastify.errors.kioskUpload')
      }

      const documents = await documentService.uploadDocuments(
        filterValues.files,
        'kiosk.financial-data',
        Math.floor(Math.random() * 10000000),
        'documents',
        DocumentTypeEnum.KIOSK_FINANCIAL_DATA,
      )

      const newFinancialDatas = documents.map((doc) => ({
        media: doc.id,
      }))

      await kioskService.updateFinancialData({
        documents: [
          ...oldDocuments.map((oldDocument) => ({
            id: oldDocument.id,
            media: oldDocument.media.id,
          })),
          ...newFinancialDatas,
        ],
      })
    },
    {
      mutationKey: ['update', 'updateFinancialData'],
      onError: () => {
        notifyError(t('toastify.errors.foundingTextUpload'))
      },
      onSuccess: () => {
        setFilterValues(initialState)
        toggle()
        notifySuccess(t('toastify.success.financialDataUploaded'))
      },
    },
  )

  const changeFilterValues = (field: string, value: string | number | File[] | undefined) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onSubmit = () => {
    return !isUploadLoading && uploadFoundingText()
  }

  return (
    <Modal toggle={toggle} isOpen={isOpen} size='xl'>
      <ModalHeader toggle={toggle}>{t('kiosk.addFinancialData')}</ModalHeader>
      <ModalBody>
        <DocumentCarrousel
          fileList={(filterValues.files as unknown) as DocumentType[]}
          closable={false}
          checkable={false}
          downloadable={false}
        />
        <Dropzone
          className='d-block my-3'
          label={t('meeting.dropzonePlaceholder')}
          addNewFiles={(newFileList) => {
            changeFilterValues('files', newFileList)
          }}
        />
        <Button className='mx-auto px-5' isLoading={isUploadLoading} onClick={onSubmit}>
          {!isUploadLoading && t('common.add')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddFinancialData
