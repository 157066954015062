import { FC } from 'react'
import { InputGroup as RSInputGroup, InputGroupProps as RSInputGroupProps } from 'reactstrap'

interface InputGroupProps extends RSInputGroupProps {
  className?: string
}

const InputGroup: FC<InputGroupProps> = (props) => {
  const { className = '', children, ...rest } = props

  return (
    <RSInputGroup className={`InputGroup ${className}`} {...rest}>
      {children}
    </RSInputGroup>
  )
}

export default InputGroup
