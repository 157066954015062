import { FC } from 'react'
import {
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputProps as RSInputProps,
} from 'reactstrap'

import './TextInput.scss'

type InputProps = RSInputProps & {
  addonText?: string
  placeholder?: string
  size?: 'sm' | 'lg'
  className?: string
  error?: string
}

const InputAddon = ({ addonText }: { addonText: string }) => {
  return (
    <InputGroupAddon addonType='prepend'>
      <InputGroupText>{addonText}</InputGroupText>
    </InputGroupAddon>
  )
}

const TextInput: FC<InputProps> = ({ addonText, placeholder, size, className = '', error, ...rest }) => (
  <InputGroup size={size} className={`TextInput ${className}`}>
    {addonText && <InputAddon addonText={addonText} />}
    <Input placeholder={placeholder} invalid={!!error} {...rest} />
    {error && <FormFeedback>{error}</FormFeedback>}
  </InputGroup>
)

export default TextInput
