import React, { FC } from 'react'

import { User } from '../../../types/User'

interface UserNameProps {
  user: User
  additionalText?: string
  className?: string
}

const UserName: FC<UserNameProps> = (props) => {
  const { user, additionalText, className = '' } = props
  return (
    <p className={className}>
      <span className='text-uppercase'>{user.lastname}</span> &nbsp;
      <span className='text-capitalize'>{user.firstname}</span>
      {additionalText && <span>{additionalText}</span>}
    </p>
  )
}

export default UserName
