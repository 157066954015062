import Spinner from 'components/atoms/spinner/Spinner'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { useQuery } from '../../../../utils/hooks/useQuery'
import { computeFullName } from '../../../../utils/userUtils'

import { Invitation } from '../../../../types/Invitation'
import { User } from '../../../../types/User'

import { invitationService } from '../../../../services/invitationService'
import { userService } from '../../../../services/userService'
import { UserStore } from '../../../../store/UserStore'
import Button from '../../../atoms/button/Button'
import SelectSearchInput from '../../../atoms/input/select/search/SelectSearchInput'
import Option from '../../../atoms/input/select/search/options/Option'
import Modal from '../../../atoms/modal/Modal'
import Heading from '../../heading/Heading'

interface ModalCreateInvitationProps {
  invitations: Invitation[]
  meetingId: string
  notInvitedMembersIds: number[]
}

const ModalCreateInvitation: FC<ModalCreateInvitationProps> = (props) => {
  const { invitations, meetingId, notInvitedMembersIds } = props
  const { t } = useTranslation()
  const user = useStore(UserStore)
  const queryCache = useQueryClient()

  const [open, setOpen] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState<string>('')
  const { isLoading, data: users = [] } = useQuery<User[]>({
    queryKey: ['user', 'getAll'],
    queryFn: () => userService.getAll(),
    onError: () => notifyError(t('toastify.errors.get.user')),
    // On execute la fonction que lorsque la variable open est à true
    enabled: open,
  })

  const onToggle = () => setOpen(() => false)
  const queryClient = useQueryClient()
  const { mutate, isLoading: isCreateInvitationLoading } = useMutation(
    () => {
      if (selectedParticipant.split(',').find((participant: string) => participant === user?.id.toString())) {
        void queryCache.resetQueries(['user', 'getUserMeetings'])
      }
      return Promise.all(
        selectedParticipant.split(',').map((id: string) => invitationService.createInvitation(meetingId, id)),
      )
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.createMeetingInvitation'))
        setSelectedParticipant('')
        queryClient.refetchQueries(['invitation', 'getAllInvitationsByMeeting', meetingId]).then(onToggle)
      },
      onError: () => notifyError(t('toastify.errors.createMeetingInvitation')),
    },
  )

  const onSelectParticipant = (userId: string | number) => {
    setSelectedParticipant(userId.toString())
  }

  const onInvitationButtonClick = () => mutate()

  const isButtonDisabled = isLoading || !selectedParticipant || isCreateInvitationLoading
  const buttonTitle = isButtonDisabled ? t('meeting.invitations.button') : ''

  // On filtre les utilisateurs déjà invités de ceux récupérés par le webservice.
  const invitedUsers = invitations.map((invitation) => invitation.user)
  const nonInvitedUsers = users
    .filter((user) => !invitedUsers.some((invitedUser) => invitedUser.id === user.id))
    .sort(userService.sortUser(notInvitedMembersIds))

  return (
    <div>
      <Modal isOpen={open} toggle={onToggle} size='lg'>
        <ModalHeader toggle={onToggle}>
          <Heading title={t('meeting.invitations.modal.title')} />
        </ModalHeader>
        <ModalBody>
          {isLoading && <Spinner />}

          {!isLoading && (
            <SelectSearchInput
              mode='multiple'
              className='mx-auto w-100'
              placeholder={t('meeting.invitations.chooseAUser')}
              onChange={onSelectParticipant}
            >
              {nonInvitedUsers.map((user: User) => {
                const isMember = !!notInvitedMembersIds.find((id: number) => id === user.id)
                return (
                  <Option
                    key={`modal-option-${user.id}`}
                    value={user.id.toString()}
                    className={isMember ? 'text-danger' : ''}
                  >
                    {computeFullName(user, true)}
                  </Option>
                )
              })}
            </SelectSearchInput>
          )}
          <Button
            className='mt-5 mx-auto'
            title={buttonTitle}
            disabled={isButtonDisabled}
            onClick={onInvitationButtonClick}
          >
            {isCreateInvitationLoading && <Spinner />}
            {!isCreateInvitationLoading &&
              t('meeting.invitations.modal.button', { count: selectedParticipant.split(',').length })}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalCreateInvitation
