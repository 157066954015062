import Row from 'components/atoms/layout/Row'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { PATHS } from 'utils/constants/routes/RoutePaths'

import { notificationService } from '../../../../services/notificationService'
import { NotificationStore } from '../../../../store/NotificationStore'
import { ICON_SVG_MAP } from '../../../atoms/icon/IconSvgMap'
import KioskArticleCard from './KioskArticleCard'
import './KioskArticles.scss'

export type KioskArticleType = {
  title?: string
  text?: string
  icon: ReactElement
  href?: string
}

export const kioskArticlesList: KioskArticleType[] = [
  {
    title: 'kiosk.rubrics.foundingTexts',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.FoundingText,
    href: PATHS.KIOSK.FOUNDING_TEXTS,
  },
  {
    title: 'kiosk.rubrics.objectivesConvention',
    icon: ICON_SVG_MAP.Convention,
    href: PATHS.KIOSK.OBJECTIVES_CONVENTION,
  },
  {
    title: 'kiosk.rubrics.decisionsRecords',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.DecisionRecord,
    href: PATHS.KIOSK.DECISIONS_RECORDS.ALL_INSTANCES,
  },
  {
    title: 'kiosk.rubrics.activityReports',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.ActivityReport,
    href: PATHS.KIOSK.ACTIVITY_REPORTS,
  },
  {
    title: 'kiosk.rubrics.expensesProcedures',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.ExpenseProcedure,
    href: PATHS.KIOSK.EXPENSES_PROCEDURES,
  },
  {
    title: 'kiosk.rubrics.financialData',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.FinancialData,
    href: PATHS.KIOSK.FINANCIAL_DATA,
  },
  {
    title: 'kiosk.rubrics.summaryBoardDirectors',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.SummaryBoardDirector,
    href: PATHS.KIOSK.SUMMARY_BOARD_DIRECTORS,
  },
  {
    title: 'kiosk.rubrics.activity',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.Activity,
    href: PATHS.KIOSK.ACTIVITY,
  },
  {
    title: 'kiosk.rubrics.instanceLetter',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.InstanceLetter,
    href: PATHS.KIOSK.INSTANCE_LETTER,
  },
  {
    title: 'kiosk.rubrics.formations',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.Formations,
    href: PATHS.KIOSK.FORMATIONS,
  },
  {
    title: 'kiosk.rubrics.communicationTools',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt blablablablabla blablablabla blablablabla',
    icon: ICON_SVG_MAP.CommunicationTools,
    href: PATHS.KIOSK.COMMUNICATION_TOOLS,
  },
]

interface KioskArticlesProps {
  kioskArticles?: KioskArticleType[]
  previewMode?: boolean
}
const KioskArticles: FC<KioskArticlesProps> = (props) => {
  const { kioskArticles = kioskArticlesList, previewMode = false } = props
  const { t } = useTranslation()
  const notifications = NotificationStore.getState()

  if (!kioskArticlesList?.length) {
    return null
  }

  const notViewedKioskNotificationsPerRubric = notificationService.notViewedKioskNotificationsPerRubric(notifications)

  return (
    <Row grid justifyContent='between' width='100%'>
      {kioskArticles.map(({ title, ...article }, index) => {
        //const isInFirstHalf = index < (kioskArticles.length - 1) / 2
        // const isMiddle = index === (kioskArticles.length - 1) / 2
        //const borderClass = isInFirstHalf ? 'border-right ' : 'border-left'
        const unreadNotifNumber = notViewedKioskNotificationsPerRubric.find((rubric) => rubric.title === title)
        return (
          <Col key={title} xs={previewMode ? 3 : 12} lg={previewMode ? 3 : 6} xl={previewMode ? 1 : 4}>
            <KioskArticleCard
              title={title ? t(title) : 'Article ' + index}
              {...article}
              previewMode={previewMode}
              unreadNotifNumber={unreadNotifNumber}
            />
          </Col>
        )
      })}
    </Row>
  )
}

export default KioskArticles
