import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import Header from 'components/molecules/heading/Header'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import { DocumentListType } from '../types/Document'

import Button from '../components/atoms/button/Button'
import ModalAddActivityReports from '../components/molecules/modals/ModalAddActivityReports'

const KioskActivityReportsView: FC = () => {
  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.activityReports')

  const { isLoading, data: documentList, refetch } = useQuery({
    queryKey: ['kiosk', 'fetchActivityReportsAndBuildDocumentList'],
    queryFn: () => kioskService.fetchActivityReportsAndBuildDocumentList(),
  })

  const { data: activityReports, refetch: refetchActivityReports } = useQuery({
    queryKey: ['foundingTexts', 'getKioskActivityReports'],
    queryFn: () => kioskService.getKioskActivityReports(),
  })

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddActivityReportOpen, toggle: toggleModalActivityReportRecord } = useToggle()

  const { mutate: deleteDocuments } = useMutation(
    ['activityReports', 'deleteKioskActivityReports'],
    async (documentList: DocumentListType) => {
      if (activityReports) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateActivityReport({
          id: activityReports.id,
          documents: activityReports.documents
            ?.filter((doc) => !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              year: doc.year,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetch()
        refetchActivityReports()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const toggleModal = () => {
    toggleModalActivityReportRecord()
    refetch()
    refetchActivityReports()
  }

  const onDeleteDocument = (deletedDocuments: DocumentListType) => {
    deleteDocuments(deletedDocuments)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && (
              <Button onClick={toggleModalActivityReportRecord}>{t('kiosk.addActivityReport')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          {documentList && !isLoading && (
            <DocumentsListsWithViewer
              lists={documentList}
              onDeleteDocument={onDeleteDocument}
              deletable={canManageKiosk}
            />
          )}
          {!documentList?.length && !isLoading && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: rubricLabel.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddActivityReports
          toggle={toggleModal}
          isOpen={isAddActivityReportOpen}
          oldDocuments={activityReports?.documents ?? []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskActivityReportsView
