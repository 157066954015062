import Icon from 'components/atoms/icon/Icon'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { usePermissions } from 'utils/hooks/usePermissions'

import { User } from '../../../types/User'

import Table from '../../atoms/table/Table'

interface ContactCardListProps {
  contactList: User[]
  onContactClicked: (user: User) => void
  contactWithoutMandate: boolean
}

const ContactCardList: FC<ContactCardListProps> = ({
  contactList,
  onContactClicked,
  contactWithoutMandate,
}): JSX.Element => {
  const { t } = useTranslation()

  const { USER_HAS_MANDATE } = usePermissions()

  return (
    <Table className={'table-hover'}>
      <thead>
        <tr>
          <th scope='col'>{t('forms.lastname')}</th>
          <th scope='col'>{t('forms.firstname')}</th>
          <th scope='col'>{t('forms.email')}</th>
          <th scope='col'>{t('forms.college')}</th>
          <th scope='col'>{t('forms.organization')}</th>
          <th scope='col'>{t('forms.organizationGroup')}</th>
          {contactWithoutMandate && <th scope='col'>{t('forms.activeMandate')}</th>}
        </tr>
      </thead>
      <tbody>
        {contactList
          .filter((user: User) => contactWithoutMandate || USER_HAS_MANDATE(user))
          .map((user: User) => (
            <tr key={user.id} className={'cursor-pointer'} onClick={() => onContactClicked(user)}>
              <td>{user.lastname}</td>
              <td>{user.firstname}</td>
              <td>{user.email}</td>
              <td>
                {t(user.college === 'PATRONAL' ? 'common.enums.college.patronal' : 'common.enums.college.salarie')}
              </td>
              <td>{user.organization}</td>
              <td>{user.organization_group}</td>
              {contactWithoutMandate && (
                <td>
                  <Icon name={USER_HAS_MANDATE(user) ? 'down-open' : 'cancel'} />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default ContactCardList
