import Nav from 'components/atoms/nav/Nav'
import { FC } from 'react'

import { NAVBAR_BOTTOM_ITEMS_LIST, NAVBAR_TOP_ITEMS_LIST } from '../../../../utils/constants/Navbar'
import { usePermissions } from '../../../../utils/hooks/usePermissions'

import { NotificationInterface } from '../../../../types/Notification'

import { notificationService } from '../../../../services/notificationService'
import { NotificationStore } from '../../../../store/NotificationStore'
import Badge from '../../../atoms/badge/Badge'
import NavbarItem from '../navbar-item/NavbarItem'

const NavbarItemGroup: FC = () => {
  const notifications = NotificationStore.getState()
  const { CONTACTS_CAN_MANAGE, USER_IS_DELEGATE, IS_SUPER_ADMIN } = usePermissions()

  const notViewedMeetingNotifications = notifications.filter(
    (notification: NotificationInterface) =>
      !notification.view && notificationService.isMeetingNotification(notification),
  )
  const meetingCount = notViewedMeetingNotifications.length
  const notViewedArticleNotifications = notifications.filter(
    (notification: NotificationInterface) =>
      !notification.view && notificationService.isArticleNotification(notification),
  )
  const articleCount = notViewedArticleNotifications.length

  const notViewedKioskNotifications = notifications.filter(
    (notification: NotificationInterface) =>
      !notification.view && notificationService.isKioskNotification(notification),
  )
  const kioskCount = notViewedKioskNotifications.length

  return (
    <div className='NavbarItemGroup h-100 d-flex flex-column justify-content-between'>
      <Nav vertical>
        {NAVBAR_TOP_ITEMS_LIST.map((item) => {
          return (!item.needNoDelegate && !item.needRight) ||
            (!item.needRight && !USER_IS_DELEGATE()) ||
            (!item.needNoDelegate && CONTACTS_CAN_MANAGE()) ||
            (!USER_IS_DELEGATE() && CONTACTS_CAN_MANAGE()) ||
            IS_SUPER_ADMIN() ? (
            <NavbarItem {...item.interface} key={item.interface.key}>
              <>
                {item.interface.key === 'myMeetings' && meetingCount > 0 ? (
                  <Badge pill color='red'>
                    {meetingCount}
                  </Badge>
                ) : undefined}
                {item.interface.key === 'news' && articleCount > 0 ? (
                  <Badge pill color='red'>
                    {articleCount}
                  </Badge>
                ) : undefined}
                {item.interface.key === 'kiosk' && kioskCount > 0 ? (
                  <Badge pill color='red'>
                    {kioskCount}
                  </Badge>
                ) : undefined}
              </>
            </NavbarItem>
          ) : undefined
        })}
      </Nav>
      <Nav vertical>
        {NAVBAR_BOTTOM_ITEMS_LIST.map((item) => (
          <NavbarItem {...item} key={item.key} />
        ))}
      </Nav>
    </div>
  )
}

export default NavbarItemGroup
