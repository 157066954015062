import { isAfter, parse } from 'date-fns'
import * as Yup from 'yup'

import { MeetingFilterType } from '../../types/Filters'
import { MeetingDropdownType } from '../../types/Meeting'

import { addressValidationSchema } from './Address'

export const MeetingValidationSchema = Yup.object().shape({
  start_date: Yup.date().typeError('common.validation.required').required('common.validation.required'),
  start_time: Yup.string(),
  end_time: Yup.string().when('start_time', {
    is: (start_time: string) => !!start_time,
    then: Yup.string().test('is-greater', 'common.validation.endTimeShouldBeAfter', function (value = '') {
      const { start_time, end_time } = this.parent
      return !end_time || isAfter(parse(value, 'HH:mm', new Date()), parse(start_time, 'HH:mm', new Date()))
    }),
  }),
  instance: Yup.string().notOneOf(['0'], 'common.validation.required'),
  online_meeting_url: Yup.string(),
  //type: Yup.string().trim().required('common.validation.required'),
  meeting_address: addressValidationSchema,
})

export enum MEETING_ENUM {
  INCOMING_MEETINGS = 1,
  PAST_MEETINGS,
  ALL_INCOMING_MEETINGS,
  ALL_PAST_MEETINGS,
  ALL_MEETINGS,
}

export const MEETING_DROPDOWN: Record<
  Extract<
    MEETING_ENUM,
    | MEETING_ENUM.INCOMING_MEETINGS
    | MEETING_ENUM.PAST_MEETINGS
    | MEETING_ENUM.ALL_INCOMING_MEETINGS
    | MEETING_ENUM.ALL_PAST_MEETINGS
  >,
  MeetingDropdownType
> = {
  [MEETING_ENUM.INCOMING_MEETINGS]: {
    label: 'meeting.filters.incomingMeetings',
  },
  [MEETING_ENUM.PAST_MEETINGS]: {
    label: 'meeting.filters.pastMeetings',
  },
  [MEETING_ENUM.ALL_INCOMING_MEETINGS]: {
    label: 'meeting.filters.allIncomingMeetings',
  },
  [MEETING_ENUM.ALL_PAST_MEETINGS]: {
    label: 'meeting.filters.allPastMeetings',
  },
}

const NUMBER_OF_MEETINGS_DISPLAYED = 15

export const initialFilter: MeetingFilterType = {
  instanceList: [],
  page: 1,
  limit: NUMBER_OF_MEETINGS_DISPLAYED,
  meetingFilterId: MEETING_ENUM.INCOMING_MEETINGS,
  status: '',
}
