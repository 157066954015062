import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import Header from 'components/molecules/heading/Header'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import { DocumentListType } from 'types/Document'
import { notifySuccess, notifyError } from 'utils/alertUtils'
import { PATHS } from 'utils/constants/routes/RoutePaths'

import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'
import { useToggle } from '../utils/hooks/useToggle'

import Button from '../components/atoms/button/Button'
import Spinner from '../components/atoms/spinner/Spinner'
import ModalAddInstanceLetter from '../components/molecules/modals/ModalAddInstanceLetter'

import { kioskService } from '../services/kioskService'

const KioskInstanceLetterView: FC = () => {
  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.instanceLetter')

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddInstanceLetterOpen, toggle: toggleModalInstanceLetter } = useToggle()

  const { isLoading, data: documentList, refetch } = useQuery({
    queryKey: ['kiosk', 'fetchInstanceLetterAndBuildDocumentList'],
    queryFn: () => kioskService.fetchInstanceLettersAndBuildDocumentList(),
  })

  const { data: instanceLetter, refetch: refetchInstanceLetter } = useQuery({
    queryKey: ['foundingTexts', 'getKioskInstanceLetter'],
    queryFn: () => kioskService.getKioskInstanceLetter(),
  })

  const { mutate: deleteDocuments } = useMutation(
    ['instanceletter', 'deleteKioskInstanceLetter'],
    async (documentList: DocumentListType) => {
      if (instanceLetter) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateInstanceLetter({
          id: instanceLetter.id,
          documents: instanceLetter.documents
            ?.filter((doc) => !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              year: doc.year,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetch()
        refetchInstanceLetter()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const toggleModal = () => {
    toggleModalInstanceLetter()
    refetch()
    refetchInstanceLetter()
  }

  const onDeleteDocument = (deletedDocuments: DocumentListType) => {
    deleteDocuments(deletedDocuments)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && <Button onClick={toggleModalInstanceLetter}>{t('kiosk.instanceLetterEdit')}</Button>}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          {documentList && !isLoading && (
            <DocumentsListsWithViewer
              lists={documentList}
              onDeleteDocument={onDeleteDocument}
              deletable={canManageKiosk}
            />
          )}
          {!documentList?.length && !isLoading && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: rubricLabel.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddInstanceLetter
          toggle={toggleModal}
          isOpen={isAddInstanceLetterOpen}
          oldDocuments={instanceLetter?.documents ?? []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskInstanceLetterView
