import TextInput from 'components/atoms/input/TextInput'
import React, { ChangeEvent, FC, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { FormFeedback } from 'reactstrap'

interface MandateDateCellProps {
  id: string
  register: Ref<HTMLInputElement>
  disabled: boolean
  error?: string
  submit: (e: ChangeEvent<HTMLInputElement>, dateType: 'start_date' | 'end_date') => void
  entityId: number
  dateType: 'start_date' | 'end_date'
}

const MandateDateCell: FC<MandateDateCellProps> = ({ id, register, disabled, error, submit, dateType }) => {
  const { t } = useTranslation()

  const onDatePick = (e: ChangeEvent<HTMLInputElement>) => {
    if (error) {
      return
    }
    submit(e, dateType)
  }

  return (
    <td>
      <TextInput
        type='date'
        onBlur={onDatePick}
        id={id}
        name={id}
        innerRef={register}
        disabled={disabled}
        invalid={!!error}
      />
      {/* TODO Nico: Pourquoi y a t'il un display none ? */}
      {!!error && <FormFeedback style={{ display: 'block' }}>{t(error)}</FormFeedback>}
    </td>
  )
}

export default MandateDateCell
