import React, { FunctionComponent } from 'react'

import './Figure.scss'

type FigureProps = {
  children?: JSX.Element
  className?: string
  ratio?: '2-3' | '3-4' | '1-1' | '4-3' | '3-2' | '16-9'
  circle?: boolean
  occupation?: '50' | '60' | '70' | '80' | '90'
}

const Figure: FunctionComponent<FigureProps> = ({ children, className = '', ratio, circle, occupation }) => {
  const FigureClassName =
    (ratio ? ' ratio-' + ratio : '') +
    (circle ? ' circle' : '') +
    (occupation ? ' occupation-' + occupation : '') +
    ` ${className}`
  return <figure className={`Figure ${FigureClassName}`}>{children}</figure>
}

export default Figure
