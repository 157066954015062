import React, { FC } from 'react'
import { Col } from 'reactstrap'

import { SearchType } from '../../../types/Search'

import Row from '../../atoms/layout/Row'
import './Search.scss'
import SearchDocument from './SearchDocument'
import SearchKiosk from './SearchKiosk'
import SearchLoader from './SearchLoader'
import SearchNews from './SearchNews'
import SearchUsers from './SearchUsers'

interface SearchProps {
  searchResults: SearchType | undefined
  isLoading: boolean
}

const Search: FC<SearchProps> = ({ searchResults, isLoading }) => {
  if (!searchResults || isLoading) {
    return <SearchLoader />
  }

  return (
    <div className='search'>
      <Row>
        <Col xs={12} sm={6} className={'pt-3 pl-md-5'}>
          <SearchDocument documents={searchResults.documents} />
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pr-md-5'}>
          <SearchNews news={searchResults.news} />
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pl-md-5'}>
          <SearchUsers users={searchResults.users} />
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pr-md-5'}>
          <SearchKiosk kiosk={searchResults.kiosk} />
        </Col>
      </Row>
    </div>
  )
}

export default Search
