import { addBusinessDays, format, isSameMonth, isSameYear, isValid, isWeekend, parse, startOfMonth } from 'date-fns'
import fr from 'date-fns/locale/fr'

// ex: 01 janvier 2021
export const VERBAL_DATE_FORMAT = 'dd MMMM yyyy'
// ex: 01/01/2021
export const DATE_SHORT_FORMAT = 'dd/MM/yyyy'
// ex: 2021-01-01
export const DATABASE_DATE_FORMAT = 'yyyy-MM-dd'
export const DATABASE_FULL_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss.SSS'
export const EXPORT_FORMAT = 'yyyy-MM-dd'
// ex: 1h04, 16h33
export const TIME_FORMAT = "H'h'mm"

export const convertDateToStringFromFormat = (date: Date, dateFormat: string, locale: Locale = fr): string => {
  if (!date) {
    return ''
  }
  return format(date, dateFormat, { locale })
}

export const isDateOnThisMonth = (date: Date): boolean => {
  const today = new Date()

  return isSameMonth(date, today) && isSameYear(date, today)
}

export const getTime = (date: Date): string => {
  if (!isValid(date)) return 'date.undefinedHour'
  return format(date, TIME_FORMAT)
}

export const getNextMonday = (date: Date): Date => {
  const firstDayOfMonth = startOfMonth(date)
  return isWeekend(firstDayOfMonth) ? addBusinessDays(firstDayOfMonth, 1) : firstDayOfMonth
}

export const parseAsDate = (date: string): Date => {
  return parse(date, DATABASE_DATE_FORMAT, new Date())
}

export const parseFullDateAsDate = (date: string): Date => {
  return parse(date, DATABASE_FULL_DATE_FORMAT, new Date())
}

export const convertDateObjectToStringWithLocale = (
  date: Date,
  locale: string,
  options: Intl.DateTimeFormatOptions,
): string | undefined => {
  if (!date) return undefined

  return date.toLocaleDateString(locale, options)
}
