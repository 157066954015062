import * as React from 'react'

import { ReactComponent as IconEye } from '../../../assets/icons/eye.svg'
import { ReactComponent as InstanceLetter } from '../../../assets/icons/instance-letter.svg'
import { ReactComponent as Icon2i } from '../../../assets/icons/picto-2i.svg'
import { ReactComponent as IconAccepted } from '../../../assets/icons/picto-accepted.svg'
import { ReactComponent as IconActivity } from '../../../assets/icons/picto-activite.svg'
import { ReactComponent as IconBulletList } from '../../../assets/icons/picto-bullet-list.svg'
import { ReactComponent as IconChevronDown } from '../../../assets/icons/picto-chevron-down.svg'
import { ReactComponent as IconChevronLeft } from '../../../assets/icons/picto-chevron-left.svg'
import { ReactComponent as IconChevronRight } from '../../../assets/icons/picto-chevron-right.svg'
import { ReactComponent as IconChevronUp } from '../../../assets/icons/picto-chevron-up.svg'
import { ReactComponent as IconCommunicationTools } from '../../../assets/icons/picto-communication-tools.svg'
import { ReactComponent as IconConvention } from '../../../assets/icons/picto-convention-objectif-et-moyen.svg'
import { ReactComponent as IconConvocationEdit } from '../../../assets/icons/picto-convocation-edit.svg'
import { ReactComponent as IconFinancialData } from '../../../assets/icons/picto-donnees-financieres.svg'
import { ReactComponent as IconExpenseReport } from '../../../assets/icons/picto-expense-report.svg'
import { ReactComponent as IconFileDocx } from '../../../assets/icons/picto-file-doc.svg'
import { ReactComponent as IconFileJpg } from '../../../assets/icons/picto-file-jpg.svg'
import { ReactComponent as IconFileMp3 } from '../../../assets/icons/picto-file-mp3.svg'
import { ReactComponent as IconFileMp4 } from '../../../assets/icons/picto-file-mp4.svg'
import { ReactComponent as IconFilePdf } from '../../../assets/icons/picto-file-pdf.svg'
import { ReactComponent as IconFilePng } from '../../../assets/icons/picto-file-png.svg'
import { ReactComponent as IconFileUnknown } from '../../../assets/icons/picto-file-unknown.svg'
import { ReactComponent as IconFileXls } from '../../../assets/icons/picto-file-xls.svg'
import { ReactComponent as IconFormations } from '../../../assets/icons/picto-formation.svg'
import { ReactComponent as IconGridView } from '../../../assets/icons/picto-grid-view.svg'
import { ReactComponent as IconMinus } from '../../../assets/icons/picto-minus.svg'
import { ReactComponent as IconExpenseProcedure } from '../../../assets/icons/picto-notes-frais.svg'
import { ReactComponent as IconHidePassword } from '../../../assets/icons/picto-password-hide.svg'
import { ReactComponent as IconShowPassword } from '../../../assets/icons/picto-password-show.svg'
import { ReactComponent as IconPending } from '../../../assets/icons/picto-pending.svg'
import { ReactComponent as IconPlus } from '../../../assets/icons/picto-plus.svg'
import { ReactComponent as IconPortailInstanceLogo } from '../../../assets/icons/picto-portail-instance-logo.svg'
import { ReactComponent as IconQuestionMark } from '../../../assets/icons/picto-question-mark.svg'
import { ReactComponent as IconActivityReport } from '../../../assets/icons/picto-rapport-activite.svg'
import { ReactComponent as IconDecisionRecord } from '../../../assets/icons/picto-releve-decisisions.svg'
import { ReactComponent as IconCogWheel } from '../../../assets/icons/picto-roue-dentee.svg'
import { ReactComponent as IconSummaryBoardDirector } from '../../../assets/icons/picto-synthese-ca-opco.svg'
import { ReactComponent as IconFoundingText } from '../../../assets/icons/picto-textes-fondateurs.svg'

export type IconSvgMapKey = keyof typeof ICON_SVG_MAP
export const ICON_SVG_MAP = {
  ChevronLeft: <IconChevronLeft />,
  ChevronRight: <IconChevronRight />,
  ChevronUp: <IconChevronUp />,
  ChevronDown: <IconChevronDown />,
  ConvocationEdit: <IconConvocationEdit />,
  FileJPG: <IconFileJpg />,
  FilePDF: <IconFilePdf />,
  FilePNG: <IconFilePng />,
  FileMP3: <IconFileMp3 />,
  FileMP4: <IconFileMp4 />,
  FileDOCX: <IconFileDocx />,
  FileXLS: <IconFileXls />,
  FileUNKNOWN: <IconFileUnknown />,
  Minus: <IconMinus />,
  Plus: <IconPlus />,
  Edit: <IconCogWheel />,
  PasswordShow: <IconShowPassword />,
  PasswordHide: <IconHidePassword />,
  Accepted: <IconAccepted color='green' />,
  Pending: <IconPending color='orange' />,
  GridView: <IconGridView />,
  GridViewSelected: <IconGridView color='white' />,
  BulletList: <IconBulletList />,
  BulletListSelected: <IconBulletList color='white' />,
  IconExpenseReport: <IconExpenseReport />,
  IconQuestionMark: <IconQuestionMark />,

  // Kiosk
  ActivityReport: <IconActivityReport color='orange' />,
  InstanceLetter: <InstanceLetter />,
  SummaryBoardDirector: <IconSummaryBoardDirector color='orange' />,
  Activity: <IconActivity color='orange' />,
  Formations: <IconFormations color='#1f3765' />,
  CommunicationTools: <IconCommunicationTools color='#1f3765' />,
  FoundingText: <IconFoundingText color='orange' />,
  DecisionRecord: <IconDecisionRecord color='orange' />,
  ExpenseProcedure: <IconExpenseProcedure color='orange' />,
  Convention: <IconConvention color='orange' />,
  FinancialData: <IconFinancialData color='orange' />,

  Logo2i: <Icon2i />,
  PortailInstanceLogo: <IconPortailInstanceLogo />,

  IconEye: <IconEye color='#1E3764' />,
}
