import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Spinner from 'components/atoms/spinner/Spinner'
import Table from 'components/atoms/table/Table'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { CardBody } from 'reactstrap'
import collaboratorService from 'services/collaboratorService'
import { userService } from 'services/userService'
import { Collaborator, CollaboratorForManagerType, CollaboratorRelationEnum } from 'types/Collaborator'
import { User, UserManagerRights } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { updateCollaboratorValidationSchema } from 'utils/constants/Collaborator'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { buildCollaboratorsForManager } from 'utils/mandateUtils'

import { GENERAL_PERMISSION } from '../../../utils/constants/Permissions'

import { QueryObserverResult, RefetchOptions } from 'react-query/types/core/types'

import Card from '../../atoms/card/Card'
import MandateRight, { MandateRightType } from '../../molecules/mandate/mandatePermission'
import AddCollaboratorModal from '../add-mandate/AddCollaboratorModal'
import RenderCollaborator from './RenderCollaborator'

interface UserCollaboratorsTableProps {
  user: User
  canAddMandate: boolean
  isOther?: boolean
  refetchUser?: (options?: RefetchOptions) => Promise<QueryObserverResult>
}

const fetchCollaboratorsWithManagerRightsAndIsCurrent = async (
  userId: number,
  managerRights: UserManagerRights,
  isOther = false,
) => {
  const collaborators = await userService.getCollaborators(userId)
  return buildCollaboratorsForManager(
    collaborators.filter((collaborator) => collaborator.is_permission === isOther),
    managerRights,
  )
}

const UserCollaboratorsTable: FC<UserCollaboratorsTableProps> = ({
  user,
  canAddMandate,
  isOther = false,
  refetchUser,
}) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { register, errors, reset } = useForm<{ data: CollaboratorForManagerType[] }>({
    mode: 'onChange',
    resolver: yupResolver(updateCollaboratorValidationSchema),
  })

  const managerRights = useManagerRights()

  const { isLoading, data: userCollaboratorsWithManagerRights, refetch } = useQuery({
    queryKey: [
      `user-${isOther ? 'permission' : 'collaborator'}`,
      `getCollaboratorsForManager-${isOther ? 'permission' : 'collaborator'}`,
      user.id,
    ],
    queryFn: () => fetchCollaboratorsWithManagerRightsAndIsCurrent(user.id, managerRights, isOther),
    onSuccess: (data) => reset({ data }),
    onError: () => {
      notifyError(t('toastify.errors.get.collaborator'))
    },
    cacheTime: 0,
  })

  const { mutate } = useMutation(
    ({ collaboratorId, data }: { collaboratorId: number; data: Partial<Collaborator> }) =>
      collaboratorService.updateOne(collaboratorId, data),
    {
      mutationKey: ['collaborator', 'updateMe'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMe'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const { mutate: mutateUser } = useMutation(
    ({ userId, data }: { userId: number; data: User }) => userService.updateOne(userId, data),
    {
      mutationKey: ['user', 'updateUser'],
      onSuccess: () => {
        notifySuccess(t('toastify.success.updateMe'))
        refetchUser && refetchUser()
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submit = (collaboratorId: number, data: Partial<Collaborator>) => {
    mutate({ collaboratorId, data })
  }

  const { mutate: mutateDelete } = useMutation(
    ({ collaboratorId }: { collaboratorId: number }) => collaboratorService.deleteOne(collaboratorId),
    {
      mutationKey: ['user', 'deleteUser'],
      onSuccess: () => {
        notifySuccess(t('toastify.success.deleteMandate'))
        refetch()
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitDelete = (collaboratorId: number) => {
    mutateDelete({ collaboratorId })
  }

  const setCurrentPermissions = (permissions: string[]) => {
    return Object.keys(GENERAL_PERMISSION).map((permission) => {
      return {
        title: t('permissions.' + permission),
        permission: permission,
        checked: permissions.includes(permission),
      }
    })
  }

  const [rights, setRights] = useState<MandateRightType[]>(setCurrentPermissions([]))

  useEffect(() => {
    if (user?.common_permission) {
      setRights(setCurrentPermissions(user.common_permission))
    }
  }, [user])

  const onChecked = (checked: boolean, permission: string) => {
    if (user) {
      const permissionUser = user.common_permission ?? []
      const newUser = {
        ...user,
        common_permission: checked
          ? [...permissionUser, permission]
          : permissionUser.filter((perm) => perm !== permission),
      }
      mutateUser({ userId: user.id, data: newUser })
      setRights(
        rights.map((right) => {
          if (right.permission === permission) {
            return {
              ...right,
              checked,
            }
          }
          return right
        }),
      )
    }
  }

  const headers = [
    { label: t('common.fields.mandate.perimeter') },
    { label: t(`common.fields.${isOther ? 'permission' : 'mandate'}.startDate`) },
    { label: t(`common.fields.${isOther ? 'permission' : 'mandate'}.endDate`) },
    { label: isOther ? '' : t('common.fields.mandate.state') },
    { label: isOther ? ' ' : '' },
  ]

  if (isLoading) return <Spinner />

  return (
    <>
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                {headers.map(({ label }, index) => (
                  <th className={`${label.length === 0 ? 'd-none' : ''}`} key={`${label}${index}`}>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userCollaboratorsWithManagerRights && userCollaboratorsWithManagerRights.length > 0 ? (
                userCollaboratorsWithManagerRights.map((collaborator, index) => (
                  <RenderCollaborator
                    current={!!collaborator.is_current}
                    canEdit={!!collaborator.has_manager_right}
                    collaborator={collaborator}
                    key={collaborator.id}
                    index={index}
                    errors={errors}
                    register={register}
                    submit={submit}
                    submitDelete={submitDelete}
                    relation={CollaboratorRelationEnum.USER}
                    isOther={isOther}
                    userId={user.id}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length}>
                    <p className='text-center mb-0'>{t('mandates.noMandatesFound')} </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {canAddMandate && (
        <Button
          className='mt-3 mx-auto d-block'
          color='primary'
          label={t(`mandates.addMandate.${isOther ? 'permission' : 'collaborator'}.modalButton`)}
          onClick={openModal}
        />
      )}
      {modalOpen && (
        <AddCollaboratorModal
          user={user}
          toggle={closeModal}
          isOpen={modalOpen}
          refetchCollaborators={refetch}
          isOther={isOther}
        />
      )}
      {isOther && <MandateRight rights={rights} onChange={onChecked} />}
    </>
  )
}

export default UserCollaboratorsTable
