import { format, isAfter, isSameDay, isValid, parse } from 'date-fns'
import { generatePath } from 'react-router'
import { v4 } from 'uuid'

import { ApiRoutes } from '../utils/constants/ApiRoutes'
import { parseAsDate } from '../utils/dateUtils'
import { FileBrowserDataNode, FileBrowserFileMapNode, InputDataNode, updateNodes } from '../utils/treeUtils'

import { Address } from '../types/Address'
import { BinaryDataType } from '../types/File'
import { Meeting, MeetingDashboard, MeetingPreview, MeetingUpdate } from '../types/Meeting'

import { execute } from '../api/api'

export const meetingTimeFormat = 'HH:mm'
export const meetingApiTimeFormat = 'HH:mm:SS.sss'

// --------------------------------- API -------------------------------------

const prepareMeetingBody = (body: MeetingUpdate) => {
  const { start_time, end_time } = body
  return {
    ...body,
    // Formatage du temps au format de l'API. On vérifie que l'utilisateur renseigne bien une heure
    start_time: (start_time && formatTime(start_time, true)) || undefined,
    end_time: (end_time && formatTime(end_time, true)) || undefined,
  }
}
const create = (body: Meeting): Promise<Meeting> => {
  return execute<Meeting>(ApiRoutes.MEETINGS.BASE, 'POST', prepareMeetingBody(body))
}

const getAll = (): Promise<Meeting[]> => {
  return execute<Meeting[]>(ApiRoutes.MEETINGS.BASE, 'GET')
}

const getOne = (id: string): Promise<Meeting> => {
  return execute<Meeting>(generatePath(ApiRoutes.MEETINGS.ID, { id }), 'GET')
}
const update = (id: string | number, updatedMeeting: MeetingUpdate): Promise<Meeting> => {
  return execute<Meeting>(generatePath(ApiRoutes.MEETINGS.ID, { id }), 'PUT', prepareMeetingBody(updatedMeeting))
}

const updateMeetingInvitations = (meetingId: string): Promise<void> => {
  return execute<void>(generatePath(ApiRoutes.MEETINGS.UPDATE_MEETING_INVITATIONS, { meetingId }), 'PUT')
}

const deleteMeeting = (id: string): Promise<void> => {
  return execute<void>(generatePath(ApiRoutes.MEETINGS.ID, { id }), 'DELETE')
}

const deleteDocument = (meetingId: string, documentId: number | string): Promise<void> => {
  return execute<void>(generatePath(ApiRoutes.MEETINGS.DOCUMENTS, { id: meetingId, documentId: documentId }), 'DELETE')
}

const deleteDecisionRecords = (decisionRecordId: string, documentId: number): Promise<void> => {
  return execute<void>(
    generatePath(ApiRoutes.MEETINGS.DECISIONS_RECORDS, { id: decisionRecordId, documentId: documentId }),
    'DELETE',
  )
}

const generateIcsPath = (id: string): string => generatePath(ApiRoutes.MEETINGS.ICS, { id })

const notifyNewDocuments = (meetingId: number): Promise<void> => {
  return execute<void>(generatePath(ApiRoutes.MEETINGS.DOCUMENT_NOTIFICATION, { id: meetingId }), 'POST')
}

const getConvocationTemplate = (meetingId: number): Promise<BinaryDataType> => {
  return execute<BinaryDataType>(generatePath(ApiRoutes.MEETINGS.CONVOCATION_TEMPLATE, { id: meetingId }), 'GET')
}
const getSignInSheet = (meetingId: number): Promise<BinaryDataType> => {
  return execute<BinaryDataType>(generatePath(ApiRoutes.MEETINGS.SIGN_IN_SHEET, { id: meetingId }), 'GET')
}

export const getZipFile = (meetingId: number): Promise<Blob> => {
  return execute<Blob>(generatePath(ApiRoutes.MEETINGS.DOWNLOAD_ZIP, { meetingId }), 'GET', {}, {}, {}, 'blob')
}

/*******************************************************************************
 *                              UTILS
 ******************************************************************************/
const formatAddress = (meetingAdress: Address): string => {
  return meetingAdress
    ? [meetingAdress.line_1, meetingAdress.line_2, meetingAdress.postal_code, meetingAdress.city].join(' ')
    : ''
}
const parseOrderOfTheDay = (meeting: Meeting): InputDataNode[] => {
  const parsedTree = meeting.order_of_the_day || []
  updateNodes(parsedTree, true, true, true)
  return checkAndUpdateFileBrowser(parsedTree, meeting)
}

const checkAndUpdateFileBrowser = (inputDataNode: InputDataNode[], meeting: Meeting): InputDataNode[] => {
  return inputDataNode.map((inputData) => ({
    ...inputData,
    fileBrowserData: inputData.fileBrowserData ?? getFileBrowserDataIfNotExist(inputData, meeting),
    children: checkAndUpdateFileBrowser(inputData.children ?? [], meeting),
  }))
}

const getFileBrowserDataIfNotExist = (inputData: InputDataNode, meeting: Meeting): FileBrowserDataNode | undefined => {
  const rootDirId = v4()
  const fileMap: FileBrowserFileMapNode = {}

  for (const documentKey of inputData.documentKeyList ?? []) {
    const document = Object.values(meeting.documents).find((doc) => doc.id.toString() === documentKey)
    if (document) {
      fileMap[documentKey] = {
        id: document.id.toString(),
        url: document.url,
        modDate: document.date,
        name: document.name,
        parentId: rootDirId,
        size: parseInt(document.size),
      }
    }
  }
  return {
    rootFolderId: rootDirId,
    fileMap: {
      [rootDirId]: {
        id: rootDirId,
        name: inputData.title,
        isDir: true,
        childrenIds: inputData.documentKeyList,
        childrenCount: inputData.documentKeyList?.length,
      },
      ...fileMap,
    },
  }
}

/**
 *
 * @param meetingTime - date a formater
 * @param toApi - si on formate la date pour l'envoyer à l'api
 *
 * si on l'envoie à l'api alors on convertit le format de l'app en format de l'api sinon on fait l'inverse
 */
const formatTime = (meetingTime: string, toApi = false): string => {
  const date = parse(meetingTime, toApi ? meetingTimeFormat : meetingApiTimeFormat, new Date())
  return isValid(date) ? format(date, toApi ? meetingApiTimeFormat : meetingTimeFormat) : ''
}
const formatTimeSchedule = (startTime: string, endTime: string): string | undefined => {
  const { startT, endT } = { startT: formatTime(startTime), endT: formatTime(endTime) }
  return startT ? `${startT}${endT ? ` - ${endT}` : ''}` : undefined
}

const parseMeetingsDates = (meetings: MeetingPreview[]): MeetingDashboard[] => {
  return meetings.map((meeting) => ({
    ...meeting,
    start_date: parseAsDate(meeting.start_date),
  }))
}

const getMeetingYear = (meeting: MeetingPreview | Meeting): string => {
  return new Date(meeting.start_date).getFullYear().toString()
}

const getUpcomingMeetings = (meetings: MeetingDashboard[], selectedDate: Date): MeetingDashboard[] => {
  return meetings
    .filter((meeting) => {
      // On cherche les invitations qui sont après ou à aujourd'hui
      // (on n'utilise pas isBefore car il prend aussi les dates d'aujourd'hui, mais pas le isAfter)
      return isAfter(meeting.start_date, selectedDate) || isSameDay(meeting.start_date, selectedDate)
    })
    .slice(0, 3)
}

const findUserMeetings = (meetings: MeetingDashboard[], userId: number): MeetingDashboard[] =>
  meetings.filter((meeting) =>
    meeting.invitations?.some((invitation) => {
      const invitationUserId = typeof invitation.user === 'number' ? invitation.user : invitation.user.id
      return invitationUserId === userId
    }),
  )

//https://www.typescriptlang.org/docs/handbook/advanced-types.html
function isMeeting(meeting: Meeting | MeetingPreview | MeetingDashboard): meeting is Meeting {
  return (meeting as Meeting).convocation_file !== undefined
}

export const meetingService = {
  create,
  getAll,
  getOne,
  update,
  updateMeetingInvitations,
  deleteMeeting,
  parseOrderOfTheDay,
  generateIcsPath,
  formatAddress,
  formatTime,
  formatTimeSchedule,
  parseMeetingsDates,
  getUpcomingMeetings,
  getConvocationTemplate,
  findUserMeetings,
  deleteDocument,
  deleteDecisionRecords,
  getSignInSheet,
  notifyNewDocuments,
  isMeeting,
  getMeetingYear,
  getZipFile,
}
