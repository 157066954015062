import { Article } from './Article'
import { Meeting } from './Meeting'
import { User } from './User'

export enum NotificationType {
  CONVOCATION_ADDED = 'CONVOCATION_ADDED',
  DOCUMENT_ADDED_1 = 'DOCUMENT_ADDED_1',
  DOCUMENT_ADDED_2 = 'DOCUMENT_ADDED_2',
  ARTICLE_ADDED = 'ARTICLE_ADDED',
  REMINDER_CONVOCATION_20 = 'REMINDER_CONVOCATION_20',
  REMINDER_CONVOCATION_16 = 'REMINDER_CONVOCATION_16',
  PRESENCE_REQUESTED = 'PRESENCE_REQUESTED',
  SUMMARIES_BOARD = 'SUMMARIES_BOARD',
  KIOSK_ACTIVITY = 'KIOSK_ACTIVITY',
  KIOSK_FORMATIONS = 'KIOSK_FORMATIONS',
  KIOSK_COMMUNICATION_TOOLS = 'KIOSK_COMMUNICATION_TOOLS',
}

export type NotificationInterface = {
  id: number
  user: User
  type: NotificationType
  related_type: string
  related_id: string
  related?: Article | Meeting | User
  author?: User
  view?: string
  created_at: string
}

export type NotificationUpdate = {
  view?: Date
}

export type UnreadKioskNotification = {
  unreadNumber: number
  title: string
}
