import Section from 'components/atoms/layout/Section'
import Spinner from 'components/atoms/spinner/Spinner'
import MeetingCard from 'components/molecules/calendar-grid/meetings/MeetingCard'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router'
import { Link, Redirect, useParams } from 'react-router-dom'
import { BreadcrumbItem, Col } from 'reactstrap'

import { notifyError } from '../utils/alertUtils'
import { GENERAL_PERMISSION, PERMISSIONS } from '../utils/constants/Permissions'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'

import { StrapiError } from '../types/Errors'
import { Meeting, MeetingStatus } from '../types/Meeting'
import { ParamsType } from '../types/Params'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Row from '../components/atoms/layout/Row'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'
import ParticipantCard from '../components/molecules/meeting/ParticipantCard'
import OrderOfTheDay from '../components/molecules/meeting/order-of-the-day/OrderOfTheDay'

import { meetingService } from '../services/meetingService'

const MeetingDetailsView: FC = () => {
  const { id } = useParams<ParamsType>()
  const { t } = useTranslation()
  const history = useHistory()

  const { isLoading, data: meeting } = useQuery<Meeting>({
    //la key est de cette forme car la forme ['meeting', 'getOne', id] n'est
    queryKey: `meeting getOne ${id}`,
    queryFn: () => meetingService.getOne(id),
    onError: (err) => {
      if ((err as StrapiError).status === 404) {
        notifyError(t('toastify.errors.meeting404'))
      } else {
        notifyError(t('toastify.errors.get.meeting'))
      }
      history.push(PATHS.MEETINGS.MEETING)
    },
    cacheTime: 0,
    refetchOnMount: true,
  })

  const {
    USER_IS_DELEGATE,
    USER_IS_INVITED_IN_MEETING,
    USER_IS_MEMBER_IN_INSTANCE,
    USER_IS_COLLABORATOR_IN_INSTANCE,
    MEETINGS_CAN_MANAGE,
    HAS_PERMISSION,
    HAS_GENERAL_PERMISSION,
  } = usePermissions()

  const [
    isDelegate,
    isUserInvitedInMeeting,
    isUserMemberInInstance,
    isUserCollaboratorInInstance,
    canManageMeeting,
    canReadOrderOfTheDay,
    canReadConvocation,
    canReadOrderOfTheDayOfOpco2i,
    canReadDocOrderOfTheDay,
  ] = [
    USER_IS_DELEGATE(),
    USER_IS_INVITED_IN_MEETING(meeting),
    USER_IS_MEMBER_IN_INSTANCE(meeting?.instance.id),
    USER_IS_COLLABORATOR_IN_INSTANCE(meeting?.instance.id),
    MEETINGS_CAN_MANAGE(meeting),
    HAS_PERMISSION(meeting?.instance.id, PERMISSIONS.READ_ORDER_OF_THE_DAY),
    HAS_PERMISSION(meeting?.instance.id, PERMISSIONS.READ_CONVOCATION_MEMBER),
    HAS_GENERAL_PERMISSION(GENERAL_PERMISSION.READ_ORDER_OF_THE_DAY_OPCO_2I),
    HAS_PERMISSION(meeting?.instance.id, PERMISSIONS.READ_DOC_ORDER_OF_THE_DAY),
  ]

  useEffect(() => {
    if (!canManageMeeting && MeetingStatus.CANCELED === meeting?.status) {
      // L'utilisateur n'a pas les droits d'accéder à la réunion car elle est reportée
      notifyError(t('toastify.errors.meetingPostponed'))
      history.push(PATHS.MEETINGS.MEETING)
    }
  }, [meeting?.status, canManageMeeting])

  if (isLoading) {
    return <Spinner />
  } else if (!meeting) {
    return <Redirect to={PATHS.MEETINGS.MEETING} />
  }

  const meetingInvitations = meeting.invitations || []
  const instanceId = meeting.instance.id

  const canReadAllOrderOfThDay =
    isUserInvitedInMeeting || isUserMemberInInstance || isUserCollaboratorInInstance || canReadDocOrderOfTheDay

  // Si l'utilisateur est un membre de l'AG de l'Opco 2i alors on n'affiche pas la card membre (Exception du métier)
  const showMemberCardForMember =
    meeting?.instance?.association?.name !== 'OPCO 2i' || meeting?.instance?.long_name !== 'ASSEMBLEE GENERALE'

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.MEETINGS.MEETING}>{t('nav.myMeetings')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{meeting.instance.long_name}</BreadcrumbItem>
            </Breadcrumb>
            {canManageMeeting && (
              <Link to={generatePath(PATHS.MEETINGS.MEETING_UPDATE, { action: 'update', id: meeting.id })}>
                {t('meeting.updateMeeting')}
              </Link>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <Row grid>
            {/** La carte de la réunion (informations minimales) est accessible par tous
                MAIS l'utilisateur peut télécharger la convocation:
             
                - si l'utilisateur est invité à la réunion OU
                - si l'utilisateur est collaborateur actif de l'instance de la réunion OU
                - si l'utilisateur est membre actif de l'instance de la réunion
             */}
            <Col lg={6} sm={12}>
              <MeetingCard
                meeting={meeting}
                showDownloadConvocation={
                  isUserInvitedInMeeting || isUserMemberInInstance || isUserCollaboratorInInstance
                }
                subtitled
              />
            </Col>

            {/** La carte participant n'est visible que :
             
             - si l'utilisateur est invité à la réunion OU
             - si l'utilisateur est membre actif de l'instance de la réunion OU
             - si l'utilisateur est collaborateur actif de l'instance de la réunion
             - si l'utilisateur n'est pas un membre             
             */}
            {(instanceId != 1 || canManageMeeting) && // pas assemblé général
              (isUserInvitedInMeeting ||
                ((isUserCollaboratorInInstance || canReadConvocation) &&
                  !isDelegate &&
                  (isUserCollaboratorInInstance || (isUserMemberInInstance && showMemberCardForMember)))) && (
                <Col lg={6} sm={12}>
                  <ParticipantCard
                    title={`${t('common.participant_plural')}`}
                    meeting={meeting}
                    instanceId={instanceId}
                    absence_options={meeting.instance.available_absence_options || ''}
                    invitations={meetingInvitations}
                    canManageParticipants={canManageMeeting}
                  />
                </Col>
              )}
          </Row>
          {/**
           L'ordre du jour est visible si:
           - l'utilisateur est invité à la réunion OU
           - l'utilisateur est membre actif (quelque soit son instance) OU
           - l'utilisateur est un collaborateur actif de l'instance de la réunion
           (/!\ un collaborateur externe à l'instance ne peut pas voir l'ordre du jour contrairement à un membre externe)

           /!\ La visibilité des documents est géré dans le composant `OrderOfTheDay`
           */}
          {(canReadAllOrderOfThDay ||
            canReadOrderOfTheDay ||
            (canReadOrderOfTheDayOfOpco2i && meeting.instance.association?.name?.startsWith('OPCO 2i'))) && (
            <Row grid>
              <Col lg={12} sm={12}>
                <OrderOfTheDay meeting={meeting} showOnlyOrderOfTheDay={!canReadAllOrderOfThDay} />
              </Col>
            </Row>
          )}

          {/*{meeting.decisions_record && meeting.decisions_record.documents.length > 0 && canViewDecisions && (*/}
          {/*  <MeetingDecisionsRecords decisionsRecordList={meeting.decisions_record?.documents} />*/}
          {/*)}*/}
          {/** Le téléchargement des données Ics est possible si:
           - si l'utilisateur est invité à la réunion OU
           - si l'utilisateur est membre actif de l'instance de la réunion OU
           - si l'utilisateur est collaborateur actif de l'instance de la réunion

           */}
          {(isUserInvitedInMeeting || isUserMemberInInstance || isUserCollaboratorInInstance) && (
            <a href={meetingService.generateIcsPath(id)} download className='d-block text-center my-3'>
              {t('meeting.downloadIcs')}
            </a>
          )}
        </Section>
      </ViewBody>
    </View>
  )
}

export default MeetingDetailsView
