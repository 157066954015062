import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { ContactInfo } from '../types/ContactInfo'

import { execute } from '../api/api'

const getContactInfo = (): Promise<ContactInfo> => {
  return execute<ContactInfo>(ApiRoutes.CONTACT.BASE, 'GET')
}

export const contactInfoService = {
  getContactInfo,
}
