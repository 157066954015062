import { BASE_PATH } from 'api/api'
import { contentType, extension as mimeExtension } from 'mime-types'
import React, { FC } from 'react'

import { FILE_TYPES, FileTypeEnum } from '../../../utils/constants/File'
import { getUrlFromFile } from '../../../utils/fileUtils'

import { DocumentType } from '../../../types/Document'

import IconSvg from '../../atoms/icon/IconSvg'

interface FileCardProps {
  file: File | DocumentType
  onDeleteFile?: (file: File) => void
}

const AttachmentCard: FC<FileCardProps> = (props) => {
  const { file, onDeleteFile } = props
  const type = contentType(file.name) || ''
  const extension = mimeExtension(type) || ''
  const isFile = file instanceof File
  const fileHref = isFile
    ? getUrlFromFile(file as File)
    : (file as DocumentType).url
    ? BASE_PATH + `/upload/files/${(file as DocumentType).id}/download`
    : (file as DocumentType).url

  return (
    <>
      {onDeleteFile && (
        <span className='d-block text-danger text-right cursor-pointer' onClick={() => onDeleteFile(file as File)}>
          x
        </span>
      )}
      <a download href={fileHref} className='text-center'>
        <IconSvg
          className='d-block text-center'
          name={FILE_TYPES[extension]?.iconName || FILE_TYPES[FileTypeEnum.UNKNOWN].iconName}
          size='5'
        />
        <span className='d-block text-center text-ellipsis' style={{ overflowWrap: 'break-word' }}>
          {file.name}
        </span>
      </a>
    </>
  )
}

export default AttachmentCard
