import { Association } from 'types/Association'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { Instance } from '../types/Instance'

import { execute } from '../api/api'

const getAll = (): Promise<Association[]> => {
  return execute<Association[]>(ApiRoutes.ASSOCIATIONS.BASE, 'GET')
}

const getAssociationFromInstanceList = (instances: Instance[]): Association[] => {
  const associations = instances.map((instance) => instance.association)

  return associations.reduce((previousValue: Association[], currentValue) => {
    if (currentValue && !previousValue.map((asso) => asso.id).includes(currentValue.id)) {
      return [...previousValue, currentValue]
    }
    return previousValue
  }, [])
}

export const associationService = {
  getAll,
  getAssociationFromInstanceList,
}
