import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { LegalNotice } from '../types/LegalNotice'

import { execute } from '../api/api'

const getLegalNotice = (): Promise<LegalNotice> => {
  return execute<LegalNotice>(ApiRoutes.LEGAL_NOTICE.BASE, 'GET')
}

export const legalNoticeService = {
  getLegalNotice,
}
