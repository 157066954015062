import { Key } from 'react'

import { UserCollegeType } from '../utils/constants/College'

import { Address } from './Address'
import { Collaborator } from './Collaborator'
import { Delegate } from './Delegate'
import { DocumentType } from './Document'
import { Instance } from './Instance'
import { Member } from './Member'

export type User = {
  id: number
  email: string
  username: string
  firstname: string
  lastname: string
  telephone?: string
  confirmed: boolean
  blocked: boolean
  address: Address
  college: UserCollegeType | null
  organization?: string
  instances?: Instance[]
  members: Member[]
  delegates: Delegate[]
  imgSrc?: string
  collaborators: Collaborator[]
  organization_group?: string
  cgu?: string
  hide_telephone?: boolean
  hide_address?: boolean
  hide_profile_picture?: boolean
  profilePicture?: DocumentType
  favorite: number[]
  common_permission?: string[]
}

export type UserUpdate = {
  firstname?: string
  lastname?: string
  email?: string
  telephone?: string
  address?: Address
  college?: UserCollegeType | null
  organization?: string
  cgu?: string
  favorite?: number[]
}

export type emailUserUpdate = {
  updateEmailValue: string
  formerEmailValue: string
}

export type tokenEmailUserUpdate = {
  validateEmailToken: string
}

export type UserCreate = {
  email: string
  firstname: string
  lastname: string
  college: UserCollegeType | null
  organization?: string
  organization_group?: string
  forceDuplicate?: boolean
}

export type UserCreateWithPerimeter = UserCreate & {
  profile: UserProfileEnum
  is_manager?: boolean
  perimeter_list?: Key[]
}

export enum UserProfileEnum {
  COLLABORATOR = 'COLLABORATOR',
  MEMBER = 'MEMBER',
  OTHER = 'OTHER',
  DELEGATE = 'DELEGATE',
}

export type UserManagerRights = { hasManagerRightsOnAll?: boolean; instancesIds?: number[]; associationsIds?: number[] }

export type UserPerimeter = {
  instancesIds: number[]
  associationsIds: number[]
  meetingsIds: number[]
}

export type UserRights = {
  superAdmin: boolean
  manager: {
    association: number[]
    instance: number[]
  }
  collaborator: {
    association: number[]
    instance: number[]
  }
  member: {
    instance: number[]
  }
  permission: {
    association: number[]
    instance: number[]
  }
}

export type ContactListType = {
  users: User[]
  loadMore: boolean
}
