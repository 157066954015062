import { yupResolver } from '@hookform/resolvers/yup'
import TextInput from 'components/atoms/input/TextInput'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { CardBody, CardHeader, Col, Form } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { resetPasswordValidationSchema } from '../../../../utils/constants/Auth'
import { PATHS } from '../../../../utils/constants/routes/RoutePaths'
import useReactRouterQuery from '../../../../utils/hooks/useReactRouterQuery'

import { ResetPasswordValuesType } from '../../../../types/Auth'

import { loginService } from '../../../../services/loginService'
import Button from '../../../atoms/button/Button'
import Card from '../../../atoms/card/Card'

const ResetPasswordFormSection: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  // TODO Max : Gérer le cas où il n'y a pas de code dans l'URL
  const code = useReactRouterQuery().get('code')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const { register, handleSubmit, errors } = useForm<ResetPasswordValuesType>({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordValidationSchema),
  })
  const { mutate } = useMutation(
    ({ password, passwordConfirmation }: ResetPasswordValuesType) =>
      loginService.resetPassword(password, passwordConfirmation, code),
    {
      mutationKey: 'resetPassword',
      onSuccess: () => {
        notifySuccess(t('toastify.success.resetPassword'))
        history.push(PATHS.LOGIN)
      },
      // TODO Max : traiter le cas pour indiquer que le code est expiré
      onError: () => notifyError(t('toastify.errors.resetPassword')),
    },
  )

  const onSubmitResetPassword = (values: ResetPasswordValuesType) => {
    setIsSubmitted(true)
    mutate(values)
  }

  return (
    <Card className='LoginCard' boxShadow='none' banner bannerColor='orange' borderRadius='xl'>
      <CardHeader>
        <h2 className='BannerContent'> {t('auth.resetPassword.header')}</h2>
      </CardHeader>
      <CardBody>
        <Col
          sm={12}
          lg={{ size: 6, offset: 3 }}
          className='mb-5 mb-lg-0 py-5 px-lg-5 d-flex justify-content-center align-items-center'
        >
          <Form className='w-100' onSubmit={handleSubmit(onSubmitResetPassword)}>
            <h2>{t('auth.resetPassword.password')}</h2>
            <TextInput
              name='password'
              type='password'
              innerRef={register}
              className='my-4'
              error={errors.password?.message && t(errors.password.message)}
              placeholder={t('auth.resetPassword.passwordPlaceholder')}
            />
            <h2>{t('auth.resetPassword.passwordConfirmation')}</h2>
            <TextInput
              name='passwordConfirmation'
              innerRef={register}
              type='password'
              className='my-4'
              error={errors.passwordConfirmation?.message && t(errors.passwordConfirmation.message)}
              placeholder={t('auth.resetPassword.passwordConfirmationPlaceholder')}
            />
            <Button
              type='submit'
              label={t('common.submit')}
              className={'d-block mx-auto mt-4'}
              disabled={isSubmitted}
            />
            <Link to={PATHS.LOGIN} className='d-block text-center mt-4'>
              {t('auth.redirectLogin')}
            </Link>
          </Form>
        </Col>
      </CardBody>
    </Card>
  )
}

export default ResetPasswordFormSection
