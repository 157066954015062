import { getMonth, getYear } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

import { MONTHS, YEARS } from '../../../utils/constants/Date'

import Row from '../../atoms/layout/Row'

type BasicDatePickerProps = {
  date: Date
  changeYear: (year: number) => void
  changeMonth: (month: number) => void
  decreaseMonth: () => void
  increaseMonth: () => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
  multiple: boolean
  changeMultiple: (multiple: boolean) => void
}

const BasicDatePickerHeader: FC<BasicDatePickerProps> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  multiple,
  changeMultiple,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <Col>
          <input
            type='radio'
            id='simple'
            name='selection'
            value='simple'
            checked={!multiple}
            onClick={() => changeMultiple(false)}
          />
          <label htmlFor='simple'>{t('documents.filters.date.calendar.simple')}</label>
        </Col>
        <Col>
          <input
            type='radio'
            id='multiple'
            name='selection'
            value='multiple'
            checked={multiple}
            onClick={() => changeMultiple(true)}
          />
          <label htmlFor='multiple'>{t('documents.filters.date.calendar.multiple')}</label>
        </Col>
      </Row>
      <div
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {'<'}
        </button>
        <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(parseInt(value))}>
          {YEARS.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select value={MONTHS[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(MONTHS.indexOf(value))}>
          {MONTHS.map((option) => (
            <option key={option} value={option}>
              {t(`documents.filters.date.months.${option}`)}
            </option>
          ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {'>'}
        </button>
      </div>
    </>
  )
}

export default BasicDatePickerHeader
