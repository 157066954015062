import i18next from 'i18next'
import React, { FC, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import { CalendarSelectionMode } from '../../../utils/constants/Date'
import { LOCALES } from '../../../utils/constants/Languages'
import { MEETING_ENUM } from '../../../utils/constants/Meeting'

import { documentService } from '../../../services/documentService'
import InputWithLabel from '../../atoms/input/InputWithLabel'
import './BasicDatePicker.scss'
import BasicDatePickerHeader from './BasicDatePickerHeader'

type BasicDatePickerProps = {
  inputName: string
  inputLabelText: string
  inputPlaceholder?: string
  updateDateFilters: (mode: CalendarSelectionMode, startDate?: Date, endDate?: Date) => void
  meetingFilterId?: MEETING_ENUM
}

// registering calendar locale in order to display correct days of week
registerLocale(i18next.language, (LOCALES[i18next.language] || LOCALES['fr-FR']).localeData)

const BasicDatePicker: FC<BasicDatePickerProps> = ({
  inputName,
  inputLabelText,
  inputPlaceholder,
  updateDateFilters,
  meetingFilterId,
}): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [inputValue, setInputValue] = useState<string>()
  const [multiple, setMultiple] = useState<boolean>(false)

  const changeMultiple = (range: boolean) => {
    setMultiple(range)
    setStartDate(undefined)
    setEndDate(undefined)
    setInputValue('')
    updateDateFilters(
      multiple ? CalendarSelectionMode.RANGE_DATE : CalendarSelectionMode.SINGLE_DATE,
      undefined,
      undefined,
    )
  }

  const onChange = (dates: Date | Date[] | null) => {
    if (dates) {
      if (Array.isArray(dates)) {
        // multiple selection mode
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
        setInputValue(
          `${documentService.getDocumentDisplayDate(start)}${
            end ? `   -   ${documentService.getDocumentDisplayDate(end)}` : ''
          }`,
        )
        updateDateFilters(CalendarSelectionMode.RANGE_DATE, start, end)
      } else {
        // single date selection mode
        setStartDate(dates)
        setInputValue(documentService.getDocumentDisplayDate(dates))
        updateDateFilters(CalendarSelectionMode.SINGLE_DATE, dates)
      }
    }
  }

  const hasMinDate =
    meetingFilterId === MEETING_ENUM.INCOMING_MEETINGS || meetingFilterId === MEETING_ENUM.ALL_INCOMING_MEETINGS
  const hasMaxDate =
    meetingFilterId === MEETING_ENUM.PAST_MEETINGS || meetingFilterId === MEETING_ENUM.ALL_PAST_MEETINGS
  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange={multiple}
        value={inputValue}
        customInput={
          <InputWithLabel
            type='text'
            name={inputName}
            placeholder={inputPlaceholder}
            labelText={inputLabelText}
            labelClassName='font-weight-normal'
          />
        }
        shouldCloseOnSelect={false}
        popperPlacement='bottom'
        portalId='root-portal'
        minDate={hasMinDate ? new Date() : null}
        maxDate={hasMaxDate ? new Date() : null}
        locale={i18next.language}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <BasicDatePickerHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            multiple={multiple}
            changeMultiple={changeMultiple}
          />
        )}
      />
    </>
  )
}

export default BasicDatePicker
