import { FC } from 'react'
import { Popover as RSPopover, PopoverProps as RSPopoverProps } from 'reactstrap'

import './Popover.scss'

interface PopoverProps extends RSPopoverProps {
  className?: string
}

const Popover: FC<PopoverProps> = (props) => {
  const { className = '', children, ...rest } = props

  return (
    <RSPopover className={`Popover ${className}`} {...rest}>
      {children}
    </RSPopover>
  )
}

export default Popover
