import { FC } from 'react'
import { PopoverHeader as RSPopoverHeader, PopoverHeaderProps as RSPopoverHeaderProps } from 'reactstrap'

import './PopoverHeader.scss'

interface PopoverHeaderProps extends RSPopoverHeaderProps {
  className?: string
}

const PopoverHeader: FC<PopoverHeaderProps> = (props) => {
  const { className = '', children, ...rest } = props

  return (
    <RSPopoverHeader className={`PopoverHeader ${className}`} {...rest}>
      {children}
    </RSPopoverHeader>
  )
}

export default PopoverHeader
