import React, { FC, useState } from 'react'

interface ReducerProps {
  className?: string
  children?: React.ReactNode
  label?: string
}

const Reducer: FC<ReducerProps> = (props) => {
  const { className = '', children = null, label = '' } = props

  const [open, setOpen] = useState(true)
  const onIconClick = () => {
    setOpen((prevState) => !prevState)
  }

  const icon = open ? 'icon-up-open' : 'icon-down-open'

  return (
    <div className={`${className}`}>
      <p className='mb-0 mx-3 text-right d-block d-lg-none' onClick={onIconClick}>
        {label && !open && <a>{label}</a>}
        <i className={`${icon} cursor-pointer`} />
      </p>
      {open && children}
    </div>
  )
}

export default Reducer
