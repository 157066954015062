import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Spinner from 'components/atoms/spinner/Spinner'
import Table from 'components/atoms/table/Table'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { instanceService } from 'services/instanceService'
import memberService from 'services/memberService'
import { Member, MemberRelationEnum } from 'types/Member'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { updateMemberValidationSchema } from 'utils/constants/Member'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'

import Card from '../../atoms/card/Card'
import RenderMember from '../user-members/RenderMember'
import AddMemberFromInstanceModal from './AddMemberFromInstanceModal'

interface InstanceMembersTableProps {
  instanceId: number
  instanceName: string
}

const InstanceMembersTable: FC<InstanceMembersTableProps> = ({ instanceId, instanceName }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { register, errors, reset } = useForm<{ data: Member[] }>({
    mode: 'onChange',
    resolver: yupResolver(updateMemberValidationSchema),
  })

  const connectedUserManagerRights = useManagerRights()

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['instance', 'getMembers', instanceId],
    queryFn: () => instanceService.fetchMembersWithManagerRightsAndIsCurrent(instanceId, connectedUserManagerRights),
    onSuccess: (data) => reset({ data }),
    onError: () => notifyError(t('toastify.errors.get.member')),
  })

  const { mutate: mutateUpdate } = useMutation(
    ({ memberId, data }: { memberId: number; data: Partial<Member> }) => memberService.updateOne(memberId, data),
    {
      mutationKey: ['member', 'updateOne'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitUpdate = (memberId: number, data: Partial<Member>) => {
    mutateUpdate({ memberId, data })
  }

  const { mutate: mutateDelete } = useMutation(
    ({ memberId }: { memberId: number }) => memberService.deleteOne(memberId),
    {
      mutationKey: ['member', 'deleteOne'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.deleteMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitDelete = (memberId: number) => {
    mutateDelete({ memberId })
  }

  if (isLoading) {
    return <Spinner />
  }

  const headers = [
    { label: t('forms.lastname') },
    { label: t('forms.firstname') },
    { label: t('forms.email') },
    { label: t('forms.organization') },
    { label: t('common.fields.mandate.startDate') },
    { label: t('common.fields.mandate.endDate') },
    { label: t('common.fields.mandate.function') },
    { label: t('common.fields.mandate.status') },
  ]

  return (
    <>
      <Card>
        <Table>
          <thead>
            <tr>
              {headers.map(({ label }, index) => (
                <th key={`${label}${index}`}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data
                .filter(
                  (member) => member.instance != null && member.id != null && member.users_permissions_user != null,
                )
                .map((member, index) => (
                  <RenderMember
                    current={!!member.is_current}
                    canEdit={!!member.has_manager_right}
                    member={member}
                    key={member.id}
                    index={index}
                    errors={errors}
                    register={register}
                    submitUpdate={submitUpdate}
                    submitDelete={submitDelete}
                    relation={MemberRelationEnum.INSTANCE}
                  />
                ))
            ) : (
              <tr>
                <td colSpan={headers.length}>
                  <p className='text-center mb-0'>{t('mandates.noMandatesFound')} </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Button
        color='primary'
        label={t('mandates.addMandate.member.modalButton')}
        onClick={openModal}
        className='d-block mx-auto mt-3'
      />
      {modalOpen && (
        <AddMemberFromInstanceModal
          instanceId={instanceId}
          instanceName={instanceName}
          toggle={closeModal}
          isOpen={modalOpen}
          refetchMembers={refetch}
        />
      )}
    </>
  )
}

export default InstanceMembersTable
