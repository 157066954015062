import { useStore } from 'effector-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'
import { convertDateToStringFromFormat, DATABASE_FULL_DATE_FORMAT } from '../../../utils/dateUtils'
import { useQuery } from '../../../utils/hooks/useQuery'

import { cguService } from '../../../services/cguService'
import { userService } from '../../../services/userService'
import { UserStore } from '../../../store/UserStore'
import Button from '../../atoms/button/Button'
import Modal from '../../atoms/modal/Modal'
import CguCard from '../cgu/CguCard'

const ModalCgu: FC = () => {
  const { t } = useTranslation()
  const user = useStore(UserStore)
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: 'cgu',
    queryFn: () => cguService.getCgu(),
  })

  const onAccept = () => {
    if (user) {
      const now = convertDateToStringFromFormat(new Date(), DATABASE_FULL_DATE_FORMAT)
      userService
        .updateMe({ cgu: now })
        .then(() => {
          notifySuccess(t('toastify.success.cgu'))
          void queryClient.setQueryData(['user', 'getMe'], { ...user, cgu: now })
        })
        .catch(() => {
          notifyError(t('toastify.errors.cgu'))
        })
    }
  }

  const showCgu = !user?.cgu || (!!data?.file && new Date(data.updated_at) > new Date(user.cgu))

  return (
    <Modal isOpen={showCgu}>
      <ModalHeader>{t('modal.cgu')}</ModalHeader>
      <ModalBody>
        <p className='mb-5'>{t('modal.newCgu')}</p>
        <CguCard cgu={data?.file} />
        <p className='mt-5'>{t('modal.readCgu')}</p>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={onAccept}>
          {t('common.accept')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalCgu
