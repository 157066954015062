import { execute } from 'api/api'
import { DocumentListType, DocumentType } from 'types/Document'
import { KioskActivityReportsType, KioskActivityReportsUpdateType } from 'types/KioskActivityReports'
import { KioskExpensesProceduresType, KioskExpensesProceduresUpdateType } from 'types/KioskExpensesProcedures'
import { KioskFinancialDataType, KioskFinancialDataUpdateType } from 'types/KioskFinancialData'
import { KioskFoundingTextsType, KioskFoundingTextsUpdateType } from 'types/KioskFoundingTexts'
import { KioskObjectivesConventionType, KioskObjectivesConventionUpdateType } from 'types/KioskObjectivesConvention'
import { ApiRoutes } from 'utils/constants/ApiRoutes'

import { KioskActivityType, KioskActivityUpdateType } from '../types/KioskActivity'
import { KioskCommunicationToolsType, KioskCommunicationToolsUpdateType } from '../types/KioskCommunicationTools'
import { KioskFormationsType, KioskFormationsUpdateType } from '../types/KioskFormations'
import { KioskInstanceLetterType, KioskInstanceLetterUpdateType } from '../types/KioskInstanceLetter'
import {
  KioskSummaryBoardDirectorsType,
  KioskSummaryBoardDirectorsUpdateType,
} from '../types/KioskSummaryBoardDirectors'

/**
 * Permet de créer une liste labelisée de documents regroupés par un attribut
 */
export const buildDocumentListFromKioskComponentList = (
  groupKeyName: string,
  documentKeyName: string,
  kioskComponentList: {
    [key: string]: string | number | DocumentType
  }[] = [],
): DocumentListType[] => {
  const uniqueGroupKeyList = Array.from(new Set(kioskComponentList.map((component) => component[groupKeyName])))

  return uniqueGroupKeyList.map((groupKey) => ({
    label: groupKey?.toString() || '',
    documents: kioskComponentList
      .filter((component) => component[groupKeyName] === groupKey)
      .map((filteredComponent) => filteredComponent[documentKeyName] as DocumentType),
  }))
}

const getKioskSummaryBoardDirectors = (): Promise<KioskActivityReportsType> =>
  execute<KioskActivityReportsType>(ApiRoutes.KIOSK.SUMMARY_BOARD_DIRECTORS, 'GET')

const getKioskActivityReports = (): Promise<KioskActivityReportsType> =>
  execute<KioskActivityReportsType>(ApiRoutes.KIOSK.ACTIVITY_REPORTS, 'GET')

const getKioskExpensesProcedures = (): Promise<KioskExpensesProceduresType> =>
  execute<KioskExpensesProceduresType>(ApiRoutes.KIOSK.EXPENSES_PROCEDURES, 'GET')

const getKioskFoundingTexts = (): Promise<KioskFoundingTextsType> =>
  execute<KioskFoundingTextsType>(ApiRoutes.KIOSK.FOUNDING_TEXTS, 'GET')

const getKioskObjectiveConvention = (): Promise<KioskObjectivesConventionType> =>
  execute<KioskObjectivesConventionType>(ApiRoutes.KIOSK.OBJECTIVES_CONVENTION, 'GET')

const getKioskActivity = (): Promise<KioskActivityType> => execute<KioskActivityType>(ApiRoutes.KIOSK.ACTIVITY, 'GET')

const getKioskFormations = (): Promise<KioskFormationsType> =>
  execute<KioskFormationsType>(ApiRoutes.KIOSK.FORMATIONS, 'GET')

const getKioskCommunicationTools = (): Promise<KioskCommunicationToolsType> =>
  execute<KioskCommunicationToolsType>(ApiRoutes.KIOSK.COMMUNICATION_TOOLS, 'GET')

/**
 * On fetch les rapports d'activités puis on regroupe les documents par année pour former des listes
 */
const fetchActivityReportsAndBuildDocumentList = async (): Promise<DocumentListType[]> => {
  const kioskActivityReports = await getKioskActivityReports()
  const { documents: activityReports } = kioskActivityReports

  return buildDocumentListFromKioskComponentList('year', 'media', activityReports).sort((a, b) =>
    b.label.localeCompare(a.label),
  )
}

const updateFoundingText = (data: KioskFoundingTextsUpdateType): Promise<KioskFoundingTextsType> => {
  return execute<KioskFoundingTextsType>(ApiRoutes.KIOSK.FOUNDING_TEXTS, 'PUT', data)
}

const updateObjectifConvention = (
  data: KioskObjectivesConventionUpdateType,
): Promise<KioskObjectivesConventionType> => {
  return execute<KioskObjectivesConventionType>(ApiRoutes.KIOSK.OBJECTIVES_CONVENTION, 'PUT', data)
}

const updateActivityReport = (data: KioskActivityReportsUpdateType): Promise<KioskActivityReportsType> => {
  return execute<KioskActivityReportsType>(ApiRoutes.KIOSK.ACTIVITY_REPORTS, 'PUT', data)
}

const updateFinancialData = (data: KioskFinancialDataUpdateType): Promise<KioskFinancialDataType> => {
  return execute<KioskFinancialDataType>(ApiRoutes.KIOSK.FINANCIAL_DATA, 'PUT', data)
}

const updateExpensesProcedures = (data: KioskExpensesProceduresUpdateType): Promise<KioskExpensesProceduresType> => {
  return execute<KioskExpensesProceduresType>(ApiRoutes.KIOSK.EXPENSES_PROCEDURES, 'PUT', data)
}

const updateInstanceLetter = (data: KioskInstanceLetterUpdateType): Promise<KioskInstanceLetterType> => {
  return execute<KioskInstanceLetterType>(ApiRoutes.KIOSK.INSTANCE_LETTER, 'PUT', data)
}

const updateActivity = (data: KioskActivityUpdateType): Promise<KioskActivityType> => {
  return execute<KioskActivityType>(ApiRoutes.KIOSK.ACTIVITY, 'PUT', data)
}

const updateFormations = (data: KioskFormationsUpdateType): Promise<KioskFormationsType> => {
  return execute<KioskActivityType>(ApiRoutes.KIOSK.FORMATIONS, 'PUT', data)
}

const updateCommunicationTools = (data: KioskCommunicationToolsUpdateType): Promise<KioskCommunicationToolsType> => {
  return execute<KioskActivityType>(ApiRoutes.KIOSK.COMMUNICATION_TOOLS, 'PUT', data)
}

const updateSummaryBoardDirector = (
  data: KioskSummaryBoardDirectorsUpdateType,
): Promise<KioskSummaryBoardDirectorsType> => {
  return execute<KioskSummaryBoardDirectorsType>(ApiRoutes.KIOSK.SUMMARY_BOARD_DIRECTORS, 'PUT', data)
}

/**
 * On fetch les synthèses Conseil d’administration OPCO 2i puis on regroupe les documents par année pour former des listes
 */
const fetchSummaryBoardDirectorsAndBuildDocumentList = async (): Promise<DocumentListType[]> => {
  const kioskSummaryBoardDirectors = await getKioskSummaryBoardDirectors()
  const { documents: summaryBoardDirectors } = kioskSummaryBoardDirectors

  return buildDocumentListFromKioskComponentList('year', 'media', summaryBoardDirectors).sort((a, b) =>
    b.label.localeCompare(a.label),
  )
}

/**
 * On fetch les textes fondateurs puis on regroupe les documents par association pour former des listes
 */
const fetchFoundingTextsAndBuildDocumentList = async (): Promise<DocumentListType[]> => {
  const kioskFoundingTexts = await getKioskFoundingTexts()
  const { documents: foundingTexts = [] } = kioskFoundingTexts

  const listLabels = ['OPCO 2i', 'AR2i']

  return listLabels.map((label) => ({
    label,
    documents: foundingTexts
      ?.filter((foundingText) => {
        if (foundingText.association.id === 1) {
          return label === listLabels[0]
        } else {
          return label === listLabels[1]
        }
      })
      .map((filteredFoundindText) => filteredFoundindText.media),
  }))
}

const fetchInstanceLettersAndBuildDocumentList = async (): Promise<DocumentListType[]> => {
  const kioskActivityReports = await getKioskInstanceLetter()
  const { documents: activityReports } = kioskActivityReports

  return buildDocumentListFromKioskComponentList('year', 'media', activityReports).sort((a, b) =>
    b.label.localeCompare(a.label),
  )
}

const getKioskFinancialData = (): Promise<KioskFinancialDataType> =>
  execute<KioskFinancialDataType>(ApiRoutes.KIOSK.FINANCIAL_DATA, 'GET')

const getKioskInstanceLetter = (): Promise<KioskInstanceLetterType> =>
  execute<KioskInstanceLetterType>(ApiRoutes.KIOSK.INSTANCE_LETTER, 'GET')

export const kioskService = {
  fetchActivityReportsAndBuildDocumentList,
  fetchSummaryBoardDirectorsAndBuildDocumentList,
  getKioskExpensesProcedures,
  fetchFoundingTextsAndBuildDocumentList,
  getKioskObjectiveConvention,
  getKioskFinancialData,
  updateFoundingText,
  getKioskFoundingTexts,
  updateObjectifConvention,
  updateActivityReport,
  getKioskActivityReports,
  updateSummaryBoardDirector,
  getKioskSummaryBoardDirectors,
  updateExpensesProcedures,
  getKioskActivity,
  updateActivity,
  updateFinancialData,
  getKioskInstanceLetter,
  fetchInstanceLettersAndBuildDocumentList,
  updateInstanceLetter,
  getKioskFormations,
  getKioskCommunicationTools,
  updateFormations,
  updateCommunicationTools,
}
