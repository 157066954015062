import Card from 'components/atoms/card/Card'
import { addDays, addMonths, endOfMonth, getMonth, startOfMonth } from 'date-fns'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardHeader, Col } from 'reactstrap'
import { meetingService } from 'services/meetingService'
import { userService } from 'services/userService'
import { UserStore } from 'store/UserStore'
import { MeetingDashboard } from 'types/Meeting'

import { notifyError } from '../../../../utils/alertUtils'
import { initialFilter, MEETING_ENUM } from '../../../../utils/constants/Meeting'
import { PATHS } from '../../../../utils/constants/routes/RoutePaths'
import { getNextMonday, isDateOnThisMonth } from '../../../../utils/dateUtils'
import { usePermissions } from '../../../../utils/hooks/usePermissions'
import { useQuery } from '../../../../utils/hooks/useQuery'

import Row from '../../../atoms/layout/Row'
import Heading from '../../heading/Heading'
import Calendar from '../calendar/Calendar'
import MeetingCard from './MeetingCard'

const CalendarGridMeetings: FC = () => {
  const today = new Date()
  const { t } = useTranslation()
  const userStore = useStore(UserStore)
  const [selectedDate, setSelectedDate] = useState(today)
  const { CONTACTS_CAN_MANAGE, USER_IS_OTHER_COLLABORATOR } = usePermissions()

  const { data: calendarMeetings = [] } = useQuery<MeetingDashboard[]>({
    queryKey: ['meetings', 'getUserMeetings', getMonth(selectedDate)],
    queryFn: async () => {
      const meetingsWithFilters = await userService.getUserMeetingsWithFilters({
        ...initialFilter,
        meetingFilterId: MEETING_ENUM.ALL_MEETINGS,
        limit: -1,
        startDate: addDays(startOfMonth(selectedDate), -7),
        endDate: addDays(endOfMonth(selectedDate), 7),
      })
      return meetingService.parseMeetingsDates(meetingsWithFilters.meetings)
    },
    refetchOnMount: true,
    onError: () => notifyError(t('toastify.errors.get.meeting')),
  })

  const { data: upcomingMeetings = [] } = useQuery<MeetingDashboard[]>({
    queryKey: ['meetings', 'getUpcomingMeeting', selectedDate],
    queryFn: async () => {
      const meetingsWithFilters = await userService.getUserMeetingsWithFilters({
        ...initialFilter,
        meetingFilterId:
          CONTACTS_CAN_MANAGE() || USER_IS_OTHER_COLLABORATOR()
            ? MEETING_ENUM.ALL_MEETINGS
            : MEETING_ENUM.INCOMING_MEETINGS,
        startDate: selectedDate,
        limit: 3,
      })
      return meetingService.parseMeetingsDates(meetingsWithFilters.meetings)
    },
    onError: () => notifyError(t('toastify.errors.get.meeting')),
    cacheTime: 10000,
  })

  const onChangeMonthClick = (amount: number) => {
    setSelectedDate((prevState) => {
      const newMonth = addMonths(prevState, amount)

      // Les samedi et dimanche n'étant pas affichés sur le calendrier, si le 1er jour du mois tombe
      // sur un de ces jours, alors il ne sera pas affiché. On cherche alors à récupérer le premier
      // Lundi du mois. (note : à ce jour, la doc de date-fns présente déjà la fonction "nextMonday"
      // mais elle n'est pas disponible.
      return isDateOnThisMonth(newMonth) ? today : getNextMonday(newMonth)
    })
  }

  const onSelectDay = (date: Date) => {
    setSelectedDate(date)
  }
  const link = {
    href: PATHS.MEETINGS.MEETING,
    label: t('meeting.seeAll'),
  }
  return (
    <>
      <Row>
        <Col lg={12} xl={6}>
          <Card padding='lg' className='CalendarCard' banner bannerColor='orange'>
            <CardHeader>
              <h2 className='BannerContent'>{t('dashboard.calendar')}</h2>
            </CardHeader>
            <CardBody>
              <div>
                <Calendar
                  meetings={calendarMeetings}
                  selectedDate={selectedDate}
                  onSelectDate={onSelectDay}
                  onIncrementMonth={() => onChangeMonthClick(1)}
                  onDecrementMonth={() => onChangeMonthClick(-1)}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={12} xl={6}>
          {upcomingMeetings
            .filter((meeting) => {
              const userInvitation = meeting.invitations?.find(
                (invitation) => (invitation.user as unknown) === userStore?.id,
              )
              if (userInvitation?.parent == null) {
                return true
              }

              // En cas de remplacement, on vérifie que l'absence du parent a bien été acceptée
              const parentInvitation = meeting.invitations?.find(
                (invitation) => invitation.id === (userInvitation.parent as unknown),
              )

              return parentInvitation?.absence_validation === 'ACCEPTED'
            })
            .map((meeting) => {
              const userInvitation = meeting.invitations?.find(
                (invitation) => (invitation.user as unknown) === userStore?.id,
              )

              return (
                <div className='mt-3' key={`upcommingMeeting-${meeting.id}`}>
                  <MeetingCard meeting={meeting} status={userInvitation?.status} clickable subtitled dashboardMode />
                </div>
              )
            })}
          <Heading className='mt-5' title=' ' link={link} arrowAsLinkBreakpoint='sm' />
        </Col>
      </Row>
    </>
  )
}

export default CalendarGridMeetings
