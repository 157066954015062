import { DataNode } from 'antd/lib/tree'
import Button from 'components/atoms/button/Button'
import CustomInput from 'components/atoms/input/CustomInput'
import React, { FC, Key, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import collaboratorService, { CollaboratorPerimeterType } from 'services/collaboratorService'
import { CollaboratorSubmit, PerimeterTypeEnum } from 'types/Collaborator'
import { InstancesWithPerimeterAndRights } from 'types/Instance'
import { notify, notifyError, notifySuccess } from 'utils/alertUtils'
import { getAllInstancesAsDataNodeTypeForManager } from 'utils/instances'

import InstancesTreeInput from './InstancesTreeInput'

// On filtre les instances liées aux associations sur les données envoyées au serveur OU on retourne uniquement 'ALL' pour tout le périmètre
export const buildPerimeterData = (perimeterKeys: Key[], allInstancesAsDataNode: DataNode[]): string[] => {
  const allPerimeterSelected = perimeterKeys.some((perimeter) => perimeter === PerimeterTypeEnum.ALL)
  if (allPerimeterSelected) {
    // Tout le périmètre est sélectionné
    return [PerimeterTypeEnum.ALL]
  }

  let perimeterData = perimeterKeys
  // On récupère les associations sélectionnées
  const associationsKeysList = perimeterKeys.filter((perimeter) => perimeter.toString().startsWith('asso'))

  for (const associationKey of associationsKeysList) {
    // On récupère la liste des children (instances) de l'assocation
    const foundAssociation = allInstancesAsDataNode.find((instance) => instance.key === associationKey)
    const associationChildrenList = foundAssociation?.children?.map((children) => children.key)

    // On filtre les ids des instances liées à l'association
    perimeterData = perimeterKeys.filter((v) => !associationChildrenList?.includes(v))
  }

  // On retourne des données sous forme de strings pour faciliter le traitement d'envoi des données au serveur
  return perimeterData.map((perimeter) => perimeter.toString())
}

interface CollaboratorFormProps {
  data: InstancesWithPerimeterAndRights
  userId: number
  refetchCollaborators: () => void
  toggle: () => void
  isOther?: boolean
}

const CollaboratorForm: FC<CollaboratorFormProps> = ({
  data,
  userId,
  refetchCollaborators,
  toggle,
  isOther = false,
}) => {
  const { t } = useTranslation()

  const { register, handleSubmit, setValue } = useForm<CollaboratorPerimeterType>({
    mode: 'onSubmit',
  })

  const { mutate } = useMutation((data: Partial<CollaboratorSubmit>[]) => collaboratorService.createMany(data), {
    mutationKey: ['collaborator', 'createMany'],
    onSuccess: (newCollaboratorList) => {
      if (newCollaboratorList.length) {
        notifySuccess(t('toastify.success.createMandate'))
      } else {
        notify(t('toastify.info.noNewMandateCreated'))
      }
      toggle()
      refetchCollaborators()
    },
    onError: () => notifyError(t('toastify.errors.create.collaborator')),
  })

  const submit = async (values: CollaboratorPerimeterType) => {
    const baseCollaborator = {
      users_permissions_user: userId,
      is_manager: values.is_manager,
      is_permission: isOther,
    }

    const collaborators = values.perimeter_list.map((perimeter) => {
      if (perimeter === PerimeterTypeEnum.ALL) {
        return {
          ...baseCollaborator,
          perimeter_type: perimeter,
        }
      } else if (perimeter.startsWith('asso_')) {
        return {
          ...baseCollaborator,
          perimeter_type: PerimeterTypeEnum.ASSOCIATION,
          id_perimeter: parseInt(perimeter.replace('asso_', '')),
        }
      } else {
        return {
          ...baseCollaborator,
          perimeter_type: PerimeterTypeEnum.INSTANCE,
          id_perimeter: parseInt(perimeter),
        }
      }
    })

    return mutate(collaborators)
  }

  const [checkedPerimeterList, setCheckedPerimeterList] = useState<Key[]>([])

  const allInstancesAsDataNode = getAllInstancesAsDataNodeTypeForManager(
    {
      allInstances: data.allInstances,
      managerRights: data.managerRights,
    },
    { ...data.userPerimeter },
  )
  const instancesTreeData = [
    {
      title: t('common.fields.mandate.allPerimeter'),
      key: 'ALL',
      children: allInstancesAsDataNode,
      disabled: !data.managerRights.hasManagerRightsOnAll,
    },
  ]

  useEffect(() => {
    // TODO Nico: Voir input pouvant stocker les valeurs dans un array
    register('perimeter_list')
    const perimeterData = buildPerimeterData(checkedPerimeterList, allInstancesAsDataNode)
    setValue('perimeter_list', perimeterData)
  }, [register, setValue, checkedPerimeterList])

  const onChangePerimeterList = (checkedPerimeter: Key[]) => setCheckedPerimeterList(checkedPerimeter)

  return (
    <div>
      {!isOther ?? (
        <CustomInput
          id='is_manager'
          name='is_manager'
          type='switch'
          innerRef={register}
          label={t('common.fields.mandate.manager')}
        />
      )}
      <InstancesTreeInput
        checkedPerimeterList={checkedPerimeterList}
        onChangePerimeterList={onChangePerimeterList}
        instancesTreeData={instancesTreeData}
      />
      <Button label={t('mandates.addMandate.addButton')} onClick={handleSubmit(submit)} />
    </div>
  )
}

export default CollaboratorForm
