import Spinner from 'components/atoms/spinner/Spinner'
import { isBefore, isValid } from 'date-fns'
import { useStore } from 'effector-react'
import _ from 'lodash'
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, Link, useParams } from 'react-router-dom'
import { BreadcrumbItem, CardBody, Col } from 'reactstrap'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { useQuery } from '../utils/hooks/useQuery'

import { Invitation } from '../types/Invitation'
import { InvitationStatusType } from '../types/InvitationStatus'
import { Meeting, MeetingStatus } from '../types/Meeting'
import { Member } from '../types/Member'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import Card from '../components/atoms/card/Card'
import UserName from '../components/atoms/custom/UserName'
import Row from '../components/atoms/layout/Row'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import ButtonDownloadFile from '../components/molecules/buttons/files/ButtonDownloadFile'
import UploadConvocationButton from '../components/molecules/convocation/UploadConvocationButton'
import SendEmailModal from '../components/molecules/email/SendEmailModal'
import Header from '../components/molecules/heading/Header'
import ParticipantsFilters from '../components/molecules/meeting/participants/ParticipantsFilters'
import ParticipantsTable from '../components/molecules/meeting/participants/ParticipantsTable'
import ModalAreYouSure from '../components/molecules/modals/ModalAreYouSure'
import ModalCreateInvitation from '../components/molecules/modals/invitations/ModalCreateInvitation'

import { instanceService } from '../services/instanceService'
import { invitationService } from '../services/invitationService'
import { meetingService } from '../services/meetingService'
import { UserStore } from '../store/UserStore'
import './MeetingInvitationsView.scss'

type ParticipantsFiltersType = {
  search: string
  participation?: InvitationStatusType
}

interface RouteParams {
  id: string
}

const MeetingInvitationsView: FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<RouteParams>()

  const user = useStore(UserStore)
  const queryCache = useQueryClient()

  const [checkedInvitations, setCheckedInvitations] = useState<Invitation[]>([])

  const [filters, setFilters] = useState<ParticipantsFiltersType>({
    search: '',
    participation: undefined,
  })
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false)
  const [members, setMembers] = useState<Member[]>([])
  const [isLoadingInvitations, setIsLoadingInvitations] = useState<boolean>(false)
  const [notInvitedMembersIds, setNotInvitedMembersIds] = useState<number[]>([])
  const [selectedInvitationId, setSelectedInvitationId] = useState<number>()
  const onAreYouSureModalOpen = () => setAreYouSureModalOpen(true)
  const onAreYouSureModalClose = () => setAreYouSureModalOpen(false)

  const queryClient = useQueryClient()

  const queryMeetingKey = ['meeting', 'getOne', id]
  const { data: meeting } = useQuery<Meeting>({
    queryKey: queryMeetingKey,
    queryFn: () => meetingService.getOne(id),
    onError: () => notifyError(t('toastify.errors.get.meeting')),
    cacheTime: 0,
  })

  const queryKey = ['invitation', 'getAllInvitationsByMeeting', id]
  const { isLoading: isInvitationsLoading, data: invitations = [], refetch: refetchInvitations } = useQuery<
    Invitation[]
  >({
    queryKey: queryKey,
    queryFn: () => invitationService.getAllInvitationsByMeeting(id),
    onSuccess: (freshInvitations: Invitation[]) => {
      updateNotInvitedMembers(freshInvitations, members)
      // Mise à jour des invitations dans l'objet meeting afin de ne pas supprimer les nouvelles invitations lorsqu'on édite le template de mail
      const cachedMeeting: Meeting | undefined = queryClient.getQueryData(queryMeetingKey)
      if (cachedMeeting) {
        queryClient.setQueryData(queryMeetingKey, { ...cachedMeeting, invitations: freshInvitations })
      }
    },
    onError: () => notifyError(t('toastify.errors.get.invitation')),
    cacheTime: 0,
  })

  const { mutate: deleteInvitations, isLoading: isDeletingLoading } = useMutation(
    async (invitationList: Invitation[]) => await invitationService.deleteInvitations(invitationList),
    {
      mutationKey: ['invitation', 'deleteAll', checkedInvitations],
      onSuccess: () => {
        setCheckedInvitations([])
        notifySuccess(t('toastify.success.deleteParticipants'))
      },
      onError: () => {
        notifyError(t('toastify.errors.deleteParticipants'))
      },
      onSettled: async () => {
        await refetchInvitations()
      },
    },
  )

  const { mutate: updateMeetingInvitations } = useMutation(
    async () => await meetingService.updateMeetingInvitations(id),
    {
      mutationKey: ['meeting', 'updateMeetingInvitations'],
      onSuccess: () => {
        notifySuccess(t('toastify.success.meetingInvitations'))
      },
      onError: () => {
        notifyError(t('toastify.errors.update.meetingInvitations'))
      },
      onSettled: async () => {
        await refetchInvitations()
        setIsLoadingInvitations(false)
      },
    },
  )

  const alreadyUpdated = useRef<boolean>(false)

  useEffect(() => {
    if (meeting && !alreadyUpdated.current) {
      if (!isExpiredMeeting && !postponed) {
        alreadyUpdated.current = true
        setIsLoadingInvitations(true)
        updateMeetingInvitations()
      }
    }
  }, [meeting])

  const onDeleteParticipant = () => {
    if (checkedInvitations.find((invitation: Invitation) => invitation.user.id === user?.id)) {
      void queryCache.resetQueries(['user', 'getUserMeetings'])
    }
    !isDeletingLoading &&
      deleteInvitations(checkedInvitations.filter((invit) => !!invit).map((invit) => invit as Invitation))
  }

  useQuery({
    queryKey: ['instance', 'getMembers', meeting],
    queryFn: () => {
      if (meeting) {
        return instanceService.getMembers(meeting.instance.id, meeting.start_date)
      }
    },
    onSuccess: (freshMembers: Member[]) => {
      if (freshMembers) {
        setMembers(freshMembers)
        updateNotInvitedMembers(invitations, freshMembers)
      }
    },
    onError: () => notifyError(t('toastify.errors.get.member')),
  })

  const updateNotInvitedMembers = (freshInvitations: Invitation[], freshMembers: Member[]) => {
    setNotInvitedMembersIds(
      _.map(
        freshMembers.filter((member: Member) => {
          return !freshInvitations.find(
            (invitation: Invitation) => invitation.user.id === member.users_permissions_user?.id,
          )
        }),
        'users_permissions_user.id',
      ),
    )
  }

  // ----- FILTERS -----
  const onFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const filteredInvitations = invitations.filter(
    (invitation) =>
      `${invitation.user.firstname} ${invitation.user.lastname}`.toLowerCase().includes(filters.search.toLowerCase()) &&
      (!filters.participation || invitation.status === filters.participation),
  )

  // --- CHECKBOXES ---
  const onAllCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: checkedValue },
    } = event

    // On map filteredInvitations car on ne veut sélectionner que les utilisateurs affichés dans le tableau, pas tous
    // Pareil pour la dé-selection
    const newCheckedValues = checkedValue
      ? filteredInvitations
      : checkedInvitations.filter(
          (invitation) => !filteredInvitations.some((filteredInvitation) => invitation.id === filteredInvitation.id),
        )
    setCheckedInvitations(newCheckedValues)
  }

  const onInvitationCheckboxClick = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, checked: checkedValue },
    } = event

    const invitation = invitations.find((invit) => invit.id.toString() === id)
    if (invitation) {
      setCheckedInvitations((prevState) => {
        return checkedValue
          ? [...prevState, invitation]
          : prevState.filter((prevInvitation) => prevInvitation.id !== invitation.id)
      })
    }
  }

  if (!meeting) return null

  const downloadQueryKey = ['meeting', 'getSignInSheet', meeting.id]
  const downloadQueryFn = () => meetingService.getSignInSheet(meeting.id)

  // TODO : Implémenter les règles suivantes :
  //  - Si gestionnaire, laisser la possibilité d'ajouter des participants/éditer les présences
  //    après la fin de la réunion mais pas après l'envoi du document signé (voir avec Léo)
  const meetingDate = new Date(meeting.start_date)
  const isExpiredMeeting = isValid(meetingDate) && isBefore(meetingDate, new Date())

  const checkedInvitationId = checkedInvitations.map((invit) => invit.id.toString())
  const historyQueryKey = ['invitationHistory', 'getAllByInvitationId', selectedInvitationId]
  const postponed = meeting.status === MeetingStatus.CANCELED

  return (
    <View>
      {isLoadingInvitations ? (
        <div className='loader'>
          <span className='loader-text'>{t('meeting.invitations.loadingInvitations')}</span>
          <Spinner />
        </div>
      ) : null}
      <ViewHead>
        <Section fluid>
          <Row grid className='align-items-center'>
            <Col>
              <Row>
                <Header>
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={PATHS.MEETINGS.MEETING}>{t('nav.myMeetings')}</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={generatePath(PATHS.MEETINGS.MEETING_DETAILS, { id })}>
                        {meeting?.instance.long_name || t('meeting.details')}
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>{t('meeting.invitations.header')}</BreadcrumbItem>
                  </Breadcrumb>
                </Header>
              </Row>
            </Col>
            <Col xs='auto' className='mt-3'>
              <ButtonDownloadFile
                queryKey={downloadQueryKey}
                queryFn={downloadQueryFn}
                buttonLabel={t('meeting.downloadSignInSheet')}
                templateName={t('meeting.SignInSheetFilename', {
                  meeting: meeting.instance.name,
                  date: meeting.start_date,
                })}
              />
            </Col>
            <Col xs='auto' className='mt-3'>
              <SendEmailModal
                isEditingTemplate
                meeting={meeting}
                queryKey={queryMeetingKey}
                selectedInvitations={checkedInvitationId}
                historyQueryKey={historyQueryKey}
              />
            </Col>
            <Col xs='auto' className='mt-3'>
              <UploadConvocationButton meeting={meeting} queryKey={queryMeetingKey} />
            </Col>
            <Row>
              <ParticipantsFilters
                search={filters.search}
                participation={filters.participation}
                instanceId={meeting.instance.id}
                onFilterChange={onFilterChange}
              />
            </Row>
            {checkedInvitationId.length > 0 && (
              <Row className='flex-row-reverse' grid>
                <Col xs='auto'>
                  <Button color='secondary' onClick={onAreYouSureModalOpen}>
                    {t('meeting.invitations.deleteParticipant')}
                  </Button>
                </Col>
                {!postponed && (
                  <Col xs='auto'>
                    <SendEmailModal
                      meeting={meeting}
                      selectedInvitations={checkedInvitationId}
                      refetchInvitations={refetchInvitations}
                      convocation={meeting?.convocation_file}
                      historyQueryKey={historyQueryKey}
                    />
                  </Col>
                )}
              </Row>
            )}
          </Row>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          <Card padding='sm' className='mb-3 p-0'>
            <CardBody>
              {isInvitationsLoading && <Spinner />}
              {!isInvitationsLoading && (
                <ParticipantsTable
                  meetingId={id}
                  disabled={postponed}
                  invitations={invitations}
                  invitationsQueryKey={queryKey}
                  filteredInvitations={filteredInvitations}
                  selectedInvitations={checkedInvitationId}
                  onAllCheckboxClick={onAllCheckboxClick}
                  onInvitationCheckboxClick={onInvitationCheckboxClick}
                  absence_option={meeting.instance.available_absence_options}
                  selectedInvitationId={selectedInvitationId}
                  setSelectedInvitationId={setSelectedInvitationId}
                  historyQueryKey={historyQueryKey}
                />
              )}
            </CardBody>
          </Card>
          {isExpiredMeeting && <p className='text-center'>{t('meeting.meetingExpired')}</p>}
          {postponed && <p className='text-center'>{t('meeting.cannotUpdateAPostponedMeeting')}</p>}
          {!isExpiredMeeting && !postponed && (
            <ModalCreateInvitation
              invitations={invitations}
              meetingId={id}
              notInvitedMembersIds={notInvitedMembersIds}
            />
          )}
        </Section>
      </ViewBody>
      <ModalAreYouSure
        toggle={onAreYouSureModalClose}
        isOpen={areYouSureModalOpen}
        onConfirmClick={onDeleteParticipant}
        modalHeaderText={t('modal.areYouSureToDeleteParticipant')}
        modalBody={
          <>
            <p>{t('modal.participantsWillBeDeleted')}</p>
            {checkedInvitations.length > 0 &&
              checkedInvitations.map((checkedInvitation) => {
                const childrenInvitation = invitations.find(
                  (invitation) => invitation.parent && invitation.parent.id === checkedInvitation.id,
                )
                return (
                  <>
                    <UserName user={checkedInvitation?.user} />
                    {childrenInvitation && (
                      <UserName
                        user={childrenInvitation?.user}
                        additionalText={` (${t('meeting.invitations.external')})`}
                      />
                    )}
                  </>
                )
              })}
          </>
        }
      />
    </View>
  )
}

export default MeetingInvitationsView
