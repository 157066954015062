import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import Header from 'components/molecules/heading/Header'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import { DocumentListType } from '../types/Document'

import Button from '../components/atoms/button/Button'
import ModalAddFoundingText from '../components/molecules/modals/ModalAddFoundingText'

const KioskFoundingTextsView: FC = () => {
  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.foundingTexts')

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddFoundingTextOpen, toggle: toggleModalFoundingTextRecord } = useToggle()

  const { isLoading, data: documentList, refetch: refetchDocumentList } = useQuery({
    queryKey: ['kiosk', 'fetchFoundingTextsAndBuildDocumentList'],
    queryFn: () => kioskService.fetchFoundingTextsAndBuildDocumentList(),
  })

  const { isLoading: isLoadingFoundingText, data: foundingText, refetch: refetchFoundingText } = useQuery({
    queryKey: ['foundingTexts', 'getKioskFoundingTexts'],
    queryFn: () => kioskService.getKioskFoundingTexts(),
  })

  const { mutate: deleteDocuments } = useMutation(
    ['foundingTexts', 'deleteKioskFoundingTexts'],
    async (documentList: DocumentListType) => {
      if (foundingText) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateFoundingText({
          id: foundingText.id,
          documents: foundingText.documents
            ?.filter((doc) => !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              association: doc.association.id,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetchDocumentList()
        refetchFoundingText()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const toggleModal = () => {
    toggleModalFoundingTextRecord()
    refetchDocumentList()
    refetchFoundingText()
  }

  const onDeleteDocument = (deletedDocuments: DocumentListType) => {
    deleteDocuments(deletedDocuments)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && <Button onClick={toggleModalFoundingTextRecord}>{t('kiosk.addFoundingText')}</Button>}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && isLoadingFoundingText && <Spinner />}
          {documentList && !isLoading && !isLoadingFoundingText && (
            <DocumentsListsWithViewer
              lists={documentList}
              onDeleteDocument={onDeleteDocument}
              deletable={canManageKiosk}
            />
          )}
          {!documentList?.length && !isLoading && !isLoadingFoundingText && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: rubricLabel.toLocaleLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddFoundingText
          toggle={toggleModal}
          isOpen={isAddFoundingTextOpen}
          oldDocuments={foundingText?.documents ?? []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskFoundingTextsView
