import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import DocumentsListsWithViewer from 'components/molecules/documents/DocumentsListsWithViewer'
import Header from 'components/molecules/heading/Header'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Col, Spinner } from 'reactstrap'
import { kioskService } from 'services/kioskService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import { DocumentListType } from '../types/Document'

import Button from '../components/atoms/button/Button'
import ModalAddFinancialData from '../components/molecules/modals/ModalAddFinancialData'

const KioskFinancialDataView: FC = () => {
  const { t } = useTranslation()
  const rubricLabel = t('kiosk.rubrics.financialData')

  const { isLoading, data: financialData, refetch } = useQuery({
    queryKey: ['kiosk', 'getKioskFinancialData'],
    queryFn: () => kioskService.getKioskFinancialData(),
  })

  const financialDataMediaList =
    financialData?.documents?.filter((file) => !!file.media).map((document) => document.media) || []

  const { IS_SUPER_ADMIN } = usePermissions()
  const canManageKiosk = IS_SUPER_ADMIN()
  const { state: isAddFinancialDataOpen, toggle: toggleModalFinancialData } = useToggle()

  const { mutate: deleteDocuments } = useMutation(
    ['financialData', 'deleteKioskFinancialData'],
    async (documentList: DocumentListType) => {
      if (financialData) {
        const deletedDocIds = documentList.documents.map((docList) => docList.id)
        await kioskService.updateFinancialData({
          id: financialData.id,
          documents: financialData.documents
            ?.filter((doc) => doc.media && !deletedDocIds.includes(doc.media.id))
            .map((doc) => ({
              id: doc.id,
              media: doc.media.id,
            })),
        })
      }
    },
    {
      onSuccess: async () => {
        notifySuccess(t('toastify.success.deleteDocuments'))
        refetch()
      },
      onError: () => {
        notifyError(t('toastify.errors.unknown'))
      },
    },
  )

  const toggleModal = () => {
    toggleModalFinancialData()
    refetch()
  }

  const onDeleteDocument = (deletedDocuments: DocumentListType) => {
    deleteDocuments(deletedDocuments)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{rubricLabel}</BreadcrumbItem>
            </Breadcrumb>
            {canManageKiosk && <Button onClick={toggleModalFinancialData}>{t('kiosk.addFinancialData')}</Button>}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <Spinner />}
          <Row grid>
            <Col>
              {!!financialDataMediaList.length && !isLoading && (
                <DocumentsListsWithViewer
                  lists={[
                    {
                      label: t('kiosk.rubrics.documents').toLocaleUpperCase(),
                      documents: financialDataMediaList,
                    },
                  ]}
                  deletable={canManageKiosk}
                  onDeleteDocument={onDeleteDocument}
                />
              )}
              {!financialDataMediaList.length && !isLoading && (
                <span>
                  {t('kiosk.rubrics.noDocumentOnRubric', {
                    rubricLabel: rubricLabel.toLocaleLowerCase(),
                  })}
                </span>
              )}
            </Col>
          </Row>
        </Section>
        <ModalAddFinancialData
          isOpen={isAddFinancialDataOpen}
          toggle={toggleModal}
          oldDocuments={financialData?.documents?.filter((file) => !!file.media) || []}
        />
      </ViewBody>
    </View>
  )
}

export default KioskFinancialDataView
