import { toast, ToastOptions } from 'react-toastify'

type NotificationType = 'info' | 'success' | 'warning' | 'error' | 'dark'

type Toast = {
  [key in NotificationType]: (message: string, options?: ToastOptions) => void
}

export const notify = (message: string, type: NotificationType = 'info', options?: ToastOptions): void =>
  (toast as Toast)[type](message, options)

export const notifySuccess = (message: string, options?: ToastOptions): void => notify(message, 'success', options)
export const notifyError = (message: string, options?: ToastOptions): void => notify(message, 'error', options)
export const notifyWarning = (message: string, options?: ToastOptions): void => notify(message, 'warning', options)
