import { generatePath } from 'react-router'
import { Member, MemberFormValues, MemberFunctionEnum } from 'types/Member'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { MemberStatusEnum } from '../types/MemberStatus'

import { execute } from '../api/api'

export type MemberCreateValues = {
  instance: number
  users_permissions_user: number
  start_date: string | null
  end_date: string | null
  status: MemberStatusEnum
  function: MemberFunctionEnum
}

export type MemberPerimeterType = {
  perimeter_list: number[]
}

const updateOne = (memberId: number, data: Partial<Member>): Promise<Member> =>
  execute<Member>(generatePath(ApiRoutes.MEMBERS.ID, { id: memberId }), 'PUT', data)

export const deleteOne = (memberId: number): Promise<Member> =>
  execute<Member>(generatePath(ApiRoutes.MEMBERS.ID, { id: memberId }), 'DELETE')

const create = (data: MemberCreateValues): Promise<Member> => execute<Member>(ApiRoutes.MEMBERS.BASE, 'POST', { data })

const createMany = (data: Partial<MemberFormValues>[]): Promise<Member[]> =>
  execute<Member[]>(ApiRoutes.MEMBERS.BASE, 'POST', { data })

const memberService = {
  updateOne,
  create,
  createMany,
  deleteOne,
}

export default memberService
