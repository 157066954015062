import Button from 'components/atoms/button/Button'
import Icon from 'components/atoms/icon/Icon'
import Row from 'components/atoms/layout/Row'
import * as dateFns from 'date-fns'
import { FC } from 'react'
import { Col } from 'reactstrap'

import { locale } from './Calendar'
import './CalendarHeader.scss'

interface CalendarHeaderProps {
  selectedDate: Date
  onIncrementMonth: () => void
  onDecrementMonth: () => void
}

const yearFormat = 'yyyy'
const desktopMonthFormat = 'MMMM'
const mobileMonthFormat = 'MMM'
const CalendarHeader: FC<CalendarHeaderProps> = (props) => {
  const { selectedDate, onDecrementMonth, onIncrementMonth } = props

  return (
    <Row className='CalendarHeader' gutters='xs' justifyContent='between' alignItems='baseline'>
      <Col className='CalendarHeaderNav'>
        <div className='month'>
          <span className='d-none d-md-block'>{dateFns.format(selectedDate, desktopMonthFormat, { locale })}</span>
          <span className='d-block d-md-none'>{dateFns.format(selectedDate, mobileMonthFormat, { locale })}</span>
        </div>
        <div className='actions'>
          <Button boxShadow='none' size='sm' color='light' className='previous' onClick={onDecrementMonth}>
            <Icon name='left-open' />
          </Button>
          <Button boxShadow='none' size='sm' color='light' className='next' onClick={onIncrementMonth}>
            <Icon name='right-open' />
          </Button>
        </div>
      </Col>
      <Col className='year'>{dateFns.format(selectedDate, yearFormat, { locale })}</Col>
    </Row>
  )
}

export default CalendarHeader
