import CustomInput from 'components/atoms/input/CustomInput'
import React, { FC, Ref } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Col, FormFeedback, FormGroup, Label } from 'reactstrap'

import { notify } from '../../../utils/alertUtils'
import { useQuery } from '../../../utils/hooks/useQuery'

import { Instance } from '../../../types/Instance'
import { MeetingFormValues } from '../../../types/Meeting'

import { instanceService } from '../../../services/instanceService'
import InputWithLabel from '../../atoms/input/InputWithLabel'
import InputGroup from '../../atoms/input/input-group/InputGroup'
import Row from '../../atoms/layout/Row'

export interface MeetingFormPropsInterface {
  register: Ref<HTMLInputElement>
  errors: DeepMap<MeetingFormValues, FieldError>
  isCreating: boolean
  start_date?: string
}

/**
 * Composant du formulaire de la création d'une réunion
 */
const MeetingForm: FC<MeetingFormPropsInterface> = (props) => {
  const { register, errors, isCreating = true, start_date } = props
  const { t } = useTranslation()

  const { data: instances = [] } = useQuery<Instance[]>({
    queryFn: instanceService.getMyInstances,
    queryKey: ['instance', 'getMyInstances'],
    onError: () => notify(t('toastify.errors.get.instance'), 'error'),
  })
  const instanceOptions = instances.map((instance) => ({
    label: `${instance.association?.name} - ${instance.long_name}`,
    value: instance.id,
  }))

  return (
    <Row>
      {instances?.length > 0 && (
        <Col lg={12}>
          <FormGroup>
            <Label for='instance' className='font-weight-bold'>
              {t('forms.instance')} *
            </Label>
            <InputGroup>
              <CustomInput
                type='select'
                id='instance'
                name='instance'
                invalid={!!errors.instance}
                innerRef={register}
                disabled={!isCreating}
              >
                <option value='0'>{t('forms.selectInstance')}</option>
                {instanceOptions
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </CustomInput>
              {errors.instance?.message && <FormFeedback>{t(errors.instance.message)}</FormFeedback>}
            </InputGroup>
          </FormGroup>
        </Col>
      )}
      <Col lg={12}>
        <InputWithLabel
          type='text'
          name='online_meeting_url'
          innerRef={register}
          placeholder={t('forms.onlineMeetingUrl')}
          labelText={t('forms.onlineMeetingUrl')}
          error={errors?.online_meeting_url?.message && t(errors.online_meeting_url.message)}
        />
      </Col>
      <Col sm={12} lg={6}>
        <InputWithLabel
          // required
          type='date'
          name='start_date'
          innerRef={register}
          value={start_date}
          placeholder={t('forms.startDate')}
          labelText={t('forms.startDate')}
          error={errors?.start_date?.message && t(errors.start_date.message)}
        />
      </Col>
      <Col sm={12} lg={6}>
        <InputWithLabel
          type='time'
          name='start_time'
          innerRef={register}
          placeholder={t('forms.startTime')}
          labelText={t('forms.startTime')}
          error={errors?.start_time?.message && t(errors.start_time.message)}
        />
        <InputWithLabel
          type='time'
          name='end_time'
          innerRef={register}
          placeholder={t('forms.endTime')}
          labelText={t('forms.endTime')}
          error={errors?.end_time?.message && t(errors.end_time.message)}
        />
      </Col>
    </Row>
  )
}

export default MeetingForm
