import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/atoms/button/Button'
import Spinner from 'components/atoms/spinner/Spinner'
import Table from 'components/atoms/table/Table'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { CardBody } from 'reactstrap'
import memberService from 'services/memberService'
import { userService } from 'services/userService'
import { Member, MemberForManagerType, MemberRelationEnum } from 'types/Member'
import { User } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { updateMemberValidationSchema } from 'utils/constants/Member'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'

import Card from '../../atoms/card/Card'
import AddMemberModal from '../add-mandate/AddMemberModal'
import RenderMember from './RenderMember'

interface UserMembersTableProps {
  user: User
  canAddMandate: boolean
}

const UserMembersTable: FC<UserMembersTableProps> = ({ user, canAddMandate }) => {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const { register, errors, reset } = useForm<{ data: Member[] }>({
    mode: 'onChange',
    resolver: yupResolver(updateMemberValidationSchema),
  })

  const managerRights = useManagerRights()

  const { isLoading, data: userMembersWithManagerRights, refetch } = useQuery<MemberForManagerType[]>({
    queryKey: ['user', 'getMembers', user.id],
    queryFn: () => userService.fetchMembersWithManagerRightsAndIsCurrent(user.id, managerRights),
    onSuccess: (data) => reset({ data }),
    onError: () => notifyError(t('toastify.errors.get.member')),
    cacheTime: 0,
  })

  const { mutate: mutateUpdate } = useMutation(
    ({ memberId, data }: { memberId: number; data: Partial<Member> }) => memberService.updateOne(memberId, data),
    {
      mutationKey: ['member', 'updateOne'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitUpdate = (memberId: number, data: Partial<Member>) => {
    mutateUpdate({ memberId, data })
  }

  const { mutate: mutateDelete } = useMutation(
    ({ memberId }: { memberId: number }) => memberService.deleteOne(memberId),
    {
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.deleteMandate'))
      },
      onError: () => notifyError(t('toastify.errors.update.user')),
    },
  )

  const submitDelete = (memberId: number) => {
    mutateDelete({ memberId })
  }

  const headers = [
    { label: t('common.fields.mandate.perimeter') },
    { label: t('common.fields.mandate.startDate') },
    { label: t('common.fields.mandate.endDate') },
    { label: t('common.fields.mandate.function') },
    { label: t('common.fields.mandate.status') },
  ]

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                {headers.map(({ label }, index) => (
                  <th key={`${label}${index}`}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {userMembersWithManagerRights && userMembersWithManagerRights.length > 0 ? (
                userMembersWithManagerRights.map((member, index) => (
                  <RenderMember
                    current={!!member.is_current}
                    canEdit={!!member.has_manager_right}
                    member={member}
                    key={member.id}
                    index={index}
                    errors={errors}
                    register={register}
                    submitUpdate={submitUpdate}
                    submitDelete={submitDelete}
                    relation={MemberRelationEnum.USER}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length}>
                    <p className='text-center mb-0'>{t('mandates.noMandatesFound')} </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {canAddMandate && (
        <Button
          className='mt-3 mx-auto d-block'
          color='primary'
          label={t('mandates.addMandate.member.modalButton')}
          onClick={openModal}
        />
      )}
      {modalOpen && <AddMemberModal user={user} toggle={closeModal} isOpen={modalOpen} refetchMembers={refetch} />}
    </>
  )
}

export default UserMembersTable
