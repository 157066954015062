import { isSameDay } from 'date-fns'
import parse from 'html-react-parser'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { notifyError } from '../../../../utils/alertUtils'
import { convertDateToStringFromFormat, TIME_FORMAT, VERBAL_DATE_FORMAT } from '../../../../utils/dateUtils'
import { useQuery } from '../../../../utils/hooks/useQuery'

import { InvitationHistory } from '../../../../types/InvitationHistory'

import { invitationHistoryService } from '../../../../services/invitationHistoryService'
import Button from '../../../atoms/button/Button'
import Modal from '../../../atoms/modal/Modal'
import Spinner from '../../../atoms/spinner/Spinner'

interface ModalInvitationHistoryProps {
  isOpen: boolean
  toggle: () => void
  invitationId?: number
  queryKey: (string | number | undefined)[]
}

const ModalInvitationHistory: FC<ModalInvitationHistoryProps> = (props) => {
  const { isOpen, toggle, invitationId, queryKey } = props
  const { t } = useTranslation()

  const [days, setDays] = useState<Date[]>([])

  const { isLoading: isHistoryLoading, data: invitationHistories = [] } = useQuery<InvitationHistory[]>({
    queryKey,
    queryFn: () => (invitationId ? invitationHistoryService.getAllByInvitationId(invitationId) : []),
    onError: () => notifyError(t('toastify.errors.get.invitation')),
    cacheTime: 0,
  })

  useEffect(() => {
    setDays(
      invitationHistories.reduce<Date[]>((acc: Date[], invitationHistory: InvitationHistory) => {
        const date = new Date(invitationHistory.created_at)
        const found = acc.find((d: Date) => {
          return isSameDay(date, d)
        })
        return found ? acc : [...acc, date]
      }, []),
    )
  }, [invitationHistories])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size='lg'>
      <ModalHeader>{t('modal.invitationHistory')}</ModalHeader>
      <ModalBody>
        {isHistoryLoading && <Spinner />}
        {!isHistoryLoading && (
          <>
            {days.length ? (
              days.map((day: Date, index: number) => (
                <div key={day.toString()} className={index !== days.length - 1 ? 'pb-5' : undefined}>
                  <h3>{convertDateToStringFromFormat(day, VERBAL_DATE_FORMAT)}</h3>
                  {invitationHistories
                    .filter((invitationHistory: InvitationHistory) =>
                      isSameDay(new Date(invitationHistory.created_at), day),
                    )
                    .map((invitationHistory: InvitationHistory) => (
                      <div key={invitationHistory.id} className='my-2 d-flex'>
                        <label className='mt-1 mr-3'>
                          {convertDateToStringFromFormat(new Date(invitationHistory.created_at), TIME_FORMAT)}
                        </label>
                        <p>
                          {parse(
                            t(`meeting.invitations.history.${invitationHistory.type}`, {
                              author: invitationHistory.author,
                              recipient: invitationHistory.recipient,
                              substitute: invitationHistory.substitute,
                            }),
                          )}
                        </p>
                      </div>
                    ))}
                </div>
              ))
            ) : (
              <p>{t('modal.noInvitationHistory')}</p>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={toggle}>
          {t('common.close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalInvitationHistory
