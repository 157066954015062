import { FileTypeEnum } from '../utils/constants/File'

export type DocumentType = {
  id: number
  name: string
  mime: string
  extension: FileTypeEnum
  url: string
  size: string
  date: string
  ext?: FileTypeEnum
}

export type DocumentListType = {
  label: string
  documents: DocumentType[]
  loadMore?: boolean
  entityId?: number
}

export enum DocumentTypeEnum {
  MEETING = 'MEETING',
  CONVOCATION = 'CONVOCATION',
  ABSENCE_JUSTIFICATIF = 'ABSENCE_JUSTIFICATIF',
  ARTICLE = 'ARTICLE',
  KIOSK_ACTIVITY_REPORTS = 'KIOSK_ACTIVITY_REPORTS',
  KIOSK_ACTIVITY = 'KIOSK_ACTIVITY',
  KIOSK_FORMATIONS = 'KIOSK_FORMATIONS',
  KIOSK_COMMUNICATION_TOOLS = 'KIOSK_COMMUNICATION_TOOLS',
  KIOSK_EXPENSES_PROCEDURES = 'KIOSK_EXPENSES_PROCEDURES',
  KIOSK_FINANCIAL_DATA = 'KIOSK_FINANCIAL_DATA',
  KIOSK_FOUNDING_TEXTS = 'KIOSK_FOUNDING_TEXTS',
  KIOSK_OBJECTIVES_CONVENTION = 'KIOSK_OBJECTIVES_CONVENTION',
  KIOSK_DECISIONS_RECORD = 'KIOSK_DECISIONS_RECORD',
  KIOSK_SUMMARY_BOARD_DIRECTORS = 'KIOSK_SUMMARY_BOARD_DIRECTORS',
  KIOSK_INSTANCE_LETTER = 'KIOSK_INSTANCE_LETTER',
  STRAPI = 'STRAPI',
  USER = 'USER',
}
