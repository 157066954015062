import { yupResolver } from '@hookform/resolvers/yup'
import { DataNode } from 'antd/lib/tree'
import Button from 'components/atoms/button/Button'
import AutoCompleteInput from 'components/atoms/input/AutoCompleteInput'
import CustomInput from 'components/atoms/input/CustomInput'
import InputWithLabel from 'components/atoms/input/InputWithLabel'
import Row from 'components/atoms/layout/Row'
import Spinner from 'components/atoms/spinner/Spinner'
import { buildPerimeterData as buildCollaboratorPerimeter } from 'components/mandate/add-mandate/CollaboratorForm'
import { buildPerimeterData as buildDelegatePerimeter } from 'components/mandate/add-mandate/DelegateForm'
import InstancesTreeInput from 'components/mandate/add-mandate/InstancesTreeInput'
import { buildPerimeterData as buildMemberPerimeter } from 'components/mandate/add-mandate/MemberForm'
import React, { FC, Key, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { Col, Form, Label } from 'reactstrap'
import collaboratorService from 'services/collaboratorService'
import delegateService from 'services/delegateService'
import { instanceService } from 'services/instanceService'
import memberService from 'services/memberService'
import { organisationService } from 'services/organisationService'
import { userService } from 'services/userService'
import { CollaboratorSubmit, PerimeterTypeEnum } from 'types/Collaborator'
import { DelegateSubmit } from 'types/Delegate'
import { MemberCollegeEnum, MemberFormValues } from 'types/Member'
import { User, UserCreate, UserCreateWithPerimeter, UserManagerRights, UserProfileEnum } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'
import { MANDATE_TYPES } from 'utils/constants/MandateTypes'
import { userCreateValidationSchema } from 'utils/constants/User'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { getAllInstancesAsDataNodeTypeForManager, getInstanceMeetingAsDataNodeTypeForManager } from 'utils/instances'

import { USER_COLLEGE } from '../../../utils/constants/College'
import { usePermissions } from '../../../utils/hooks/usePermissions'
import { formatFirstname } from '../../../utils/userUtils'

import Heading from '../heading/Heading'
import SameEmailUserModal from './SameEmailUserModal'
import SimilarUserListModal from './SimilarUserListModal'
import './UserCreateForm.scss'

const fetchInstances = async (managerRights: UserManagerRights) =>
  instanceService.getAll().then((res) => getAllInstancesAsDataNodeTypeForManager({ allInstances: res, managerRights }))

const fetchInstanceMeetingsForDelegate = async (managerRights: UserManagerRights) =>
  instanceService
    .getInstanceMeetingsForDelegate(1)
    .then((meetings) => getInstanceMeetingAsDataNodeTypeForManager(meetings, managerRights))

const UserCreateForm: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { register, errors, watch, setValue, handleSubmit } = useForm<UserCreateWithPerimeter>({
    mode: 'onChange',
    resolver: yupResolver(userCreateValidationSchema),
  })

  const managerRights = useManagerRights()
  const { CONTACTS_CAN_MANAGE, IS_SUPER_ADMIN } = usePermissions()

  const [checkedPerimeterList, setCheckedPerimeterList] = useState<Key[]>([])
  const onChangePerimeterList = (checkedPerimeter: Key[]) => setCheckedPerimeterList(checkedPerimeter)

  const [sameEmailUser, setSameEmailUser] = useState<User | undefined>(undefined)
  const [similarUserList, setSimilarUserList] = useState<User[]>([])

  // getValues doesn't render values on each change
  const formValues = watch()
  const isDelegate = formValues.profile === UserProfileEnum.DELEGATE
  const isCollaborator = formValues.profile === UserProfileEnum.COLLABORATOR
  const isOther = formValues.profile === UserProfileEnum.OTHER
  const selectedCollege = formValues.college

  const { data: instances, isLoading } = useQuery<DataNode[]>({
    queryKey: ['instance', 'getInstancesForManager'],
    queryFn: () => fetchInstances(managerRights),
    onError: () => notifyError(t('toastify.errors.get.instance')),
    cacheTime: 0,
  })

  const { data: meetings, isLoading: meetingsIsLoading } = useQuery<DataNode[]>({
    queryKey: ['instance', 'getInstanceMeetingsForDelegate'],
    queryFn: () => fetchInstanceMeetingsForDelegate(managerRights),
    onError: () => notifyError(t('toastify.errors.get.meeting')),
    cacheTime: 0,
  })

  const { mutateAsync: createUser, data: dataNewUser } = useMutation((data: UserCreate) => userService.create(data), {
    mutationKey: ['user', 'create'],
    onSuccess: () => notifySuccess(t('toastify.success.createUser')),
    onError: () => notifyError(t('toastify.errors.create.user')),
  })

  const redirectToNewUserMandates = () => {
    history.push(generatePath(PATHS.USER_MANAGEMENT.MANDATES, { id: dataNewUser?.id }))
  }

  const onSameEmailUserModalClose = () => {
    setSameEmailUser(undefined)
    setValue('email', '')
  }

  const mandateMutationParams = {
    onSuccess: () => {
      notifySuccess(t('toastify.success.createMandate'))
    },
    onError: () => notifyError(t('toastify.errors.create.mandate')),
    onSettled: () => redirectToNewUserMandates(),
  }

  const { mutate: createMembers } = useMutation((data: Partial<MemberFormValues>[]) => memberService.createMany(data), {
    mutationKey: ['user', 'createManyMember'],
    ...mandateMutationParams,
  })

  const { mutate: createDelegates } = useMutation(
    (data: Partial<DelegateSubmit>[]) => delegateService.createMany(data),
    {
      mutationKey: ['delegate', 'createMany'],
      ...mandateMutationParams,
    },
  )

  const { mutate: createCollaborators } = useMutation(
    (data: Partial<CollaboratorSubmit>[]) => collaboratorService.createMany(data),
    {
      mutationKey: ['collaborator', 'createMany'],
      ...mandateMutationParams,
    },
  )

  const mappingCollege = {
    PATRONAL: {
      enum: MemberCollegeEnum.PATRONAL,
      functionName: 'getOrganisationPatronal',
      function: organisationService.getOrganisationPatronal,
    },
    SALARIE: {
      enum: MemberCollegeEnum.SALARIE,
      functionName: 'getOrganisationSalarie',
      function: organisationService.getOrganisationSalarie,
    },
  }

  const { data: organisation } = useQuery<{ value: string }[]>({
    queryKey: ['organisation', selectedCollege],
    queryFn: async (): Promise<{ value: string }[]> => {
      const res = await mappingCollege[selectedCollege || 'PATRONAL'].function()
      return res.map((organisation, index) => {
        return {
          value: organisation.label,
          key: index,
        }
      })
    },
    onError: () => notifyError(t('toastify.errors.organisation.list')),
  })

  const { data: organisationGroup } = useQuery<{ value: string }[]>({
    queryKey: ['organisation', `getGroupOrganisationWithFilter(${selectedCollege})`],
    queryFn: async (): Promise<{ value: string }[]> => {
      const res = await organisationService.getGroupOrganisationWithFilter(
        mappingCollege[selectedCollege || 'PATRONAL'].enum,
      )
      return res.map((organisationGroup, index) => {
        return {
          value: organisationGroup.label,
          key: index,
        }
      })
    },
    onError: () => notifyError(t('toastify.errors.organisation.group')),
  })

  const getFilteredMandate = () => {
    if (!IS_SUPER_ADMIN() && CONTACTS_CAN_MANAGE()) {
      return MANDATE_TYPES.filter(
        (mandat) => mandat.key !== UserProfileEnum.OTHER && mandat.key !== UserProfileEnum.COLLABORATOR,
      )
    }
    return MANDATE_TYPES
  }

  useEffect(() => {
    if (instances?.length && !isDelegate) {
      register('perimeter_list')
      const perimeterData = isCollaborator
        ? buildCollaboratorPerimeter(checkedPerimeterList, instances)
        : buildMemberPerimeter(checkedPerimeterList)
      setValue('perimeter_list', perimeterData)
    } else if (meetings?.length) {
      register('perimeter_list')
      const perimeterData = buildDelegatePerimeter(checkedPerimeterList)
      setValue('perimeter_list', perimeterData)
    }
  }, [register, setValue, checkedPerimeterList, formValues.profile])

  const instancesTreeData =
    isCollaborator || isOther
      ? [
          {
            title: t('common.fields.mandate.allPerimeter'),
            key: PerimeterTypeEnum.ALL,
            children: instances,
          },
        ]
      : instances

  useEffect(() => {
    setCheckedPerimeterList([])
  }, [isDelegate])

  const submit = async (values: UserCreateWithPerimeter) => {
    const is_permission = values.profile === UserProfileEnum.OTHER
    if (values.profile === UserProfileEnum.OTHER) {
      values.profile = UserProfileEnum.COLLABORATOR
    }
    const randomString = Math.random() // Generate random number
      .toString(36) // Convert to base-36
      .slice(-8) // Cut off last 8 characters

    const user = {
      firstname: formatFirstname(values.firstname),
      lastname: values.lastname.toUpperCase(),
      email: values.email,
      college: isCollaborator || isOther ? null : values.college,
      organization: isCollaborator || isOther ? undefined : values.organization,
      organization_group: isCollaborator || isOther ? undefined : values.organization_group,
      username: values.email,
      password: randomString,
      forceDuplicate: values.forceDuplicate,
    }

    const perimeterList = values.perimeter_list

    // TODO Nico: popup validation nom + prénom
    if (perimeterList?.length) {
      await createUser(user)
        .then((res) => {
          const baseMandate = {
            users_permissions_user: res.id,
          }

          if (values.profile === UserProfileEnum.COLLABORATOR) {
            const baseCollaborator = {
              ...baseMandate,
              is_manager: !!values.is_manager,
              is_permission: is_permission,
            }

            const collaborators = (perimeterList as string[]).map((perimeter) => {
              if (perimeter === PerimeterTypeEnum.ALL) {
                return {
                  ...baseCollaborator,
                  perimeter_type: perimeter,
                }
              } else if (!Number.isInteger(perimeter) && perimeter.startsWith('asso_')) {
                return {
                  ...baseCollaborator,
                  perimeter_type: PerimeterTypeEnum.ASSOCIATION,
                  id_perimeter: parseInt(perimeter.replace('asso_', '')),
                }
              } else {
                return {
                  ...baseCollaborator,
                  perimeter_type: PerimeterTypeEnum.INSTANCE,
                  id_perimeter: parseInt(perimeter),
                }
              }
            })

            return createCollaborators(collaborators)
          } else if (values.profile === UserProfileEnum.MEMBER) {
            const members: Partial<MemberFormValues>[] = perimeterList.map((instanceId) => ({
              ...baseMandate,
              instance: instanceId as number,
            }))
            return createMembers(members)
          } else {
            const date = new Date()
            const delegates: Partial<DelegateSubmit>[] = perimeterList.map((meetingId) => ({
              ...baseMandate,
              meeting: meetingId as number,
              start_date: date.toISOString(),
            }))
            return createDelegates(delegates)
          }
        })
        .catch((error) => {
          const errorDetails = error.data?.data?.[0]?.messages?.[0]

          if (errorDetails?.sameEmailUser) {
            setSameEmailUser(errorDetails?.sameEmailUser)
          } else if (errorDetails?.similarUserList?.length) {
            setSimilarUserList(errorDetails?.similarUserList)
          } else {
            notifyError(errorDetails?.message || t('toastify.errors.create.user'))
          }
        })
    } else {
      return notifyError(t('toastify.errors.noPerimeter'))
    }
  }

  const handleSubmitWithForceDuplicate = handleSubmit((values) => submit({ ...values, forceDuplicate: true }))

  if (isLoading || meetingsIsLoading) {
    return <Spinner />
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        <Row grid>
          <Col sm={12} md={6}>
            <InputWithLabel
              type='text'
              id='lastname'
              name='lastname'
              innerRef={register}
              placeholder={t('forms.lastname')}
              labelText={t('forms.lastname')}
              error={errors?.lastname?.message && t(errors.lastname.message)}
              required={true}
              silentRequired={true}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputWithLabel
              type='text'
              id='firstname'
              name='firstname'
              innerRef={register}
              placeholder={t('forms.firstname')}
              labelText={t('forms.firstname')}
              error={errors?.firstname?.message && t(errors.firstname.message)}
              required={true}
              silentRequired={true}
            />
          </Col>
          <Col sm={12} md={12}>
            <InputWithLabel
              type='email'
              id='email'
              name='email'
              innerRef={register}
              placeholder={t('forms.email')}
              labelText={t('forms.email')}
              error={errors?.email?.message && t(errors.email.message)}
            />
          </Col>
          <Col sm={12} md={6}>
            <InputWithLabel
              type='select'
              id='profile'
              name='profile'
              innerRef={register}
              labelText={t('forms.profile')}
              error={errors?.profile?.message && t(errors.profile.message)}
            >
              {getFilteredMandate().map(({ key, label }) => (
                <option key={key} value={key}>
                  {t(label)}
                </option>
              ))}
            </InputWithLabel>
          </Col>
          {!(isCollaborator || isOther) && (
            <Col sm={12} md={6}>
              <InputWithLabel
                type='select'
                id='college'
                name='college'
                innerRef={register}
                labelText={t('forms.college')}
                error={errors?.college?.message && t(errors.college.message)}
              >
                {USER_COLLEGE.map(([key, college]) => (
                  <option key={key} value={key}>
                    {t(college.label)}
                  </option>
                ))}
              </InputWithLabel>
            </Col>
          )}
          {!(isCollaborator || isOther) && (
            <>
              <Col sm={12} md={6}>
                <Label className='font-weight-bold' for='organization'>
                  {t('forms.organization')}
                </Label>
                <AutoCompleteInput
                  className='mb-3'
                  searchOptions={organisation}
                  name='organization'
                  innerRef={register}
                  placeholder={t('forms.organization')}
                />
              </Col>
              <Col sm={12} md={6}>
                <Label className='font-weight-bold' for='organization_group'>
                  {t('forms.organizationGroup')}
                </Label>
                <AutoCompleteInput
                  className='mb-3'
                  searchOptions={organisationGroup}
                  name='organization_group'
                  innerRef={register}
                  placeholder={t('forms.organizationGroup')}
                />
              </Col>
            </>
          )}
          {isCollaborator && (
            <Col sm={12} md={6}>
              <CustomInput
                type='checkbox'
                id='is_manager'
                name='is_manager'
                innerRef={register}
                label={t('common.fields.mandate.manager')}
              />
            </Col>
          )}
          {instances?.length && !isDelegate && (
            <Col sm={12} md={12} className='InstanceCol'>
              <Heading title={t('common.fields.mandate.associatedPerimeter')} />
              <div className='InstancePerimeter'>
                <InstancesTreeInput
                  checkedPerimeterList={checkedPerimeterList}
                  onChangePerimeterList={onChangePerimeterList}
                  instancesTreeData={instancesTreeData}
                />
              </div>
            </Col>
          )}
          {!!meetings?.length && isDelegate && (
            <Col sm={12} md={12} className='InstanceCol'>
              <Heading title={t('common.fields.mandate.associatedPerimeter')} />
              <div className='InstancePerimeter'>
                <InstancesTreeInput
                  checkedPerimeterList={checkedPerimeterList}
                  onChangePerimeterList={onChangePerimeterList}
                  instancesTreeData={meetings}
                />
              </div>
            </Col>
          )}
          {!meetings?.length && isDelegate && (
            <Col sm={12} md={12} className='InstanceCol'>
              <Heading title={t('common.fields.mandate.associatedPerimeter')} />
              <div className='InstancePerimeter'>{t('common.fields.mandate.noMeetings')}</div>
            </Col>
          )}
          <Col>
            <Button type='submit' label={t('userCreate.addUserButton')} className='mx-auto' />
          </Col>
        </Row>
      </Form>
      {sameEmailUser && <SameEmailUserModal user={sameEmailUser} onModalClose={onSameEmailUserModalClose} />}
      {!!similarUserList?.length && (
        <SimilarUserListModal
          userList={similarUserList}
          onModalClose={() => setSimilarUserList([])}
          onConfirmCreate={() => {
            setSimilarUserList([])
            handleSubmitWithForceDuplicate()
          }}
        />
      )}
    </>
  )
}

export default UserCreateForm
