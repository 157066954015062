export type MemberStatusType = MemberStatusEnum | null

export enum MemberStatusEnum {
  TITULAIRE = 'TITULAIRE',
  SUPPLEANT = 'SUPPLEANT',
}

export const MemberStatusValues = {
  [MemberStatusEnum.TITULAIRE]: {
    label: 'common.enums.memberStatutes.holder',
  },
  [MemberStatusEnum.SUPPLEANT]: {
    label: 'common.enums.memberStatutes.substitute',
  },
  EXTERNE: {
    label: 'common.enums.memberStatutes.external',
  },
  UNSPECIFIED: {
    label: 'common.enums.memberStatutes.unspecified',
  },
}

export const getMandateStatusLabel = (status?: MemberStatusType): string => {
  switch (status) {
    case null:
      return MemberStatusValues.UNSPECIFIED.label

    case MemberStatusEnum.TITULAIRE:
      return MemberStatusValues.TITULAIRE.label

    case MemberStatusEnum.SUPPLEANT:
      return MemberStatusValues.SUPPLEANT.label

    case undefined:
      return MemberStatusValues.EXTERNE.label

    default:
      return MemberStatusValues.EXTERNE.label
  }
}
