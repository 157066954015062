import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FILE_TYPES } from '../../../utils/constants/File'

import { DocumentType } from '../../../types/Document'

import { BASE_PATH } from '../../../api/api'
import Icon from '../../atoms/icon/Icon'
import Table from '../../atoms/table/Table'

interface SimplerDocumentTableProps {
  fileList: DocumentType[]
  onFileChange: (file?: DocumentType) => void
}

const SimplerDocumentTable: FC<SimplerDocumentTableProps> = ({
  fileList,
  onFileChange,
}: SimplerDocumentTableProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Table className={'table-hover'}>
      <thead>
        <tr>
          <th scope='col'>{t('common.enums.documentTable.name')}</th>
          <th scope='col'>{t('common.enums.documentTable.date')}</th>
          <th scope='col'>{t('common.enums.documentTable.size')}</th>
          <th scope='col'>{t('common.enums.documentTable.download')}</th>
        </tr>
      </thead>
      <tbody>
        {fileList.map((file: DocumentType) => (
          <tr key={file.id}>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.name}
            </td>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.date}
            </td>
            <td onClick={() => onFileChange(file)} className={`${FILE_TYPES[file.extension] ? 'cursor-pointer' : ''}`}>
              {file.size}
            </td>
            <td>
              <a
                href={BASE_PATH + file.url}
                download
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
              >
                <Icon name='download' />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SimplerDocumentTable
