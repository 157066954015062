import { InvitationStatusValueType } from '../../types/InvitationStatus'

export enum InvitationStatusEnum {
  PRESENT_IN_PERSON = 'PRESENT_IN_PERSON',
  PRESENT_REMOTE = 'PRESENT_REMOTE',
  PRESENT = 'PRESENT',
  ABSENT = 'ABSENT',
  PENDING = 'PENDING',
  NOT_SENT = 'NOT_SENT',
}

export const INVITATION_STATUS = {
  PRESENT_IN_PERSON: {
    label: 'meeting.status.present_in_person',
    color: 'meeting-present',
  },
  PRESENT: {
    label: 'meeting.status.present',
    color: 'meeting-present',
  },
  PRESENT_REMOTE: {
    label: 'meeting.status.present_remote',
    color: 'meeting-present',
  },
  ABSENT: {
    label: 'meeting.status.absent',
    color: 'meeting-absent',
  },
  PENDING: {
    label: 'meeting.status.pending',
    color: 'meeting-pending',
  },
  NOT_SENT: {
    label: 'meeting.status.not_sent',
    color: 'meeting-not-sent',
  },
}

export type AbsenceStatusKey = keyof typeof ABSENCE_STATUS
export const ABSENCE_STATUS = {
  ACCEPTED: {
    label: 'meeting.invitations.acceptedValidation',
    color: 'accepted',
    icon: 'Accepted',
  },
  REFUSED: {
    label: 'meeting.invitations.refusedValidation',
    color: 'refused',
    icon: 'Refused',
  },
  PENDING: {
    label: 'meeting.invitations.pendingValidation',
    color: 'pending',
    icon: 'Pending',
  },
}

/**
  États des invitations pour la canaux non mixtes
 */
export type InvitationStatusEntriesType = [
  'PRESENT_REMOTE' | 'PRESENT_IN_PERSON' | 'ABSENT' | 'PENDING' | 'NOT_SENT',
  InvitationStatusValueType,
][]

export const MANAGER_STATUTES = Object.entries(INVITATION_STATUS).filter(([, obj]) =>
  [
    INVITATION_STATUS.PRESENT_REMOTE,
    INVITATION_STATUS.PRESENT_IN_PERSON,
    INVITATION_STATUS.ABSENT,
    INVITATION_STATUS.PENDING,
    INVITATION_STATUS.NOT_SENT,
  ].includes(obj),
) as InvitationStatusEntriesType

export const UNRESPONDED_USER_STATUTES = MANAGER_STATUTES.filter(([, obj]) =>
  [INVITATION_STATUS.ABSENT, INVITATION_STATUS.PENDING].includes(obj),
) as InvitationStatusEntriesType

export const RESPONDED_USER_STATUTES = MANAGER_STATUTES.filter(([, obj]) =>
  [INVITATION_STATUS.ABSENT].includes(obj),
) as InvitationStatusEntriesType

/**
  États des invitations pour la canaux mixtes
 */
export type InvitationStatusEntriesTypeMixedChannel = [
  'PRESENT_IN_PERSON' | 'PRESENT_REMOTE' | 'ABSENT' | 'PENDING' | 'NOT_SENT',
  InvitationStatusValueType,
][]

export const MANAGER_STATUTES_MIXED_CHANNEL = Object.entries(INVITATION_STATUS).filter(([, obj]) =>
  [
    INVITATION_STATUS.PRESENT_IN_PERSON,
    INVITATION_STATUS.PRESENT_REMOTE,
    INVITATION_STATUS.ABSENT,
    INVITATION_STATUS.PENDING,
    INVITATION_STATUS.NOT_SENT,
  ].includes(obj),
) as InvitationStatusEntriesTypeMixedChannel

export const UNRESPONDED_USER_STATUTES_MIXED_CHANNEL = MANAGER_STATUTES_MIXED_CHANNEL.filter(([, obj]) =>
  [
    INVITATION_STATUS.PRESENT_IN_PERSON,
    INVITATION_STATUS.PRESENT_REMOTE,
    INVITATION_STATUS.ABSENT,
    INVITATION_STATUS.PENDING,
  ].includes(obj),
) as InvitationStatusEntriesTypeMixedChannel

export const RESPONDED_USER_STATUTES_MIXED_CHANNEL = MANAGER_STATUTES_MIXED_CHANNEL.filter(([, obj]) =>
  [INVITATION_STATUS.PRESENT_IN_PERSON, INVITATION_STATUS.PRESENT_REMOTE, INVITATION_STATUS.ABSENT].includes(obj),
) as InvitationStatusEntriesTypeMixedChannel

/**
  États des invitations pour la recherche de réunions
 */
export type InvitationStatusEntriesTypeStatusFilter = [
  'PRESENT_IN_PERSON' | 'PRESENT_REMOTE' | 'PRESENT' | 'ABSENT' | 'PENDING',
  InvitationStatusValueType,
][]

export const UNRESPONDED_USER_STATUTES_STATUS_FILTER = Object.entries(INVITATION_STATUS).filter(([, obj]) =>
  [
    INVITATION_STATUS.PRESENT_IN_PERSON,
    INVITATION_STATUS.PRESENT_REMOTE,
    INVITATION_STATUS.ABSENT,
    INVITATION_STATUS.PENDING,
  ].includes(obj),
) as InvitationStatusEntriesTypeStatusFilter
