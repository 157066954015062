import { useStore } from 'effector-react'
import { capitalize } from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { Col } from 'reactstrap'
import { isMixedChannelInstance } from 'utils/instances'

import { notifyError, notifySuccess } from '../../../../../utils/alertUtils'
import { ABSENCE_OPTIONS } from '../../../../../utils/constants/AbsenceOption'
import {
  ABSENCE_STATUS,
  AbsenceStatusKey,
  INVITATION_STATUS,
  MANAGER_STATUTES,
  MANAGER_STATUTES_MIXED_CHANNEL,
} from '../../../../../utils/constants/Status'
import { useQuery } from '../../../../../utils/hooks/useQuery'

import { AbsenceOption } from '../../../../../types/AbsenceOption'
import { Invitation } from '../../../../../types/Invitation'
import { Member } from '../../../../../types/Member'
import { MemberStatusEnum } from '../../../../../types/MemberStatus'
import { User } from '../../../../../types/User'

import { invitationService } from '../../../../../services/invitationService'
import { userService } from '../../../../../services/userService'
import { UserStore } from '../../../../../store/UserStore'
import Button from '../../../../atoms/button/Button'
import IconSvg from '../../../../atoms/icon/IconSvg'
import { IconSvgMapKey } from '../../../../atoms/icon/IconSvgMap'
import TextInput from '../../../../atoms/input/TextInput'
import SelectSearchInput from '../../../../atoms/input/select/search/SelectSearchInput'
import Option from '../../../../atoms/input/select/search/options/Option'
import Row from '../../../../atoms/layout/Row'
import Spinner from '../../../../atoms/spinner/Spinner'
import TooltipWithLabel from '../../../../atoms/tooltip/TooltipWithLabel'
import UpdateInvitationAbsenceChildrenModal from './UpdateInvitationAbsenceChildrenModal'

interface ParticipationCellProps {
  id: string
  meetingId: string
  userMandate?: Member
  invitation: Invitation
  instanceId: number
  absence_option: string | null
  invitationsQueryKey: (string | number)[]
  historyQueryKey: (string | number | undefined)[]
  disabled: boolean
}

const ParticipationCell: FC<ParticipationCellProps> = (props) => {
  const {
    id,
    meetingId,
    userMandate,
    invitation,
    instanceId,
    absence_option,
    invitationsQueryKey,
    historyQueryKey,
    disabled,
  } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const user = useStore(UserStore)
  const queryCache = useQueryClient()

  // Si pas d'invitation qui remplace, alors valeur à null et on veut la set à
  // undefined pour l'option vide.

  const [substituteUser, setSubstituteUser] = useState<User | undefined>(invitation?.absence_children?.user)
  const { isLoading, data: substituteUsers = [] } = useQuery({
    queryKey: [
      'user',
      'getSubstituteUsers',
      instanceId,
      invitation.absence_option,
      invitation.user.id,
      invitation.user.college,
    ],
    queryFn: () => {
      // La liste des utilisateurs pouvant remplacer un membre dépend du mode :
      // si c'est pour donner son pouvoir, alors seuls ceux de son instance peuvent
      // être choisi, sinon pour un remplacement des membres de n'importe quelle
      // instance peuvent l'être.
      return userService.getSubstituteUsers(
        invitation.user.id,
        invitation.user.college,
        invitation.absence_option === AbsenceOption.POWER ? [instanceId] : undefined,
      )
    },
    onSuccess: (data) => {
      if (invitation.absence_children && invitation.absence_children.user && invitation.absence_children.user.id) {
        setSubstituteUser(data.find((user) => user.id.toString() === invitation.absence_children?.user.id.toString()))
      }
    },
    onError: () => notifyError(t('toastify.errors.get.user')),
    enabled: !!invitation.absence_option,
  })

  const { mutate } = useMutation(
    async ({ id, value }: { id: string; value: Record<string, unknown> }) =>
      invitationService.updateInvitation(id, value),
    {
      mutationKey: ['invitation', 'updateInvitation', invitation.id],
      onMutate: ({ id, value }) => {
        const prevInvitations: Invitation[] = queryCache.getQueryData(invitationsQueryKey) || []
        // Lorsqu'on modifie le statut d'une invitation, on reset les données
        // liées à la passation de pouvoir.
        const newInvitationValues = {
          ...value,
          absence_option: null,
          absence_children: null,
          absence_justificatif: null,
        }

        // On update la valeur dans le cache de ReactQuery pour qu'elle soit affichée sur l'interface
        queryCache.setQueryData(
          invitationsQueryKey,
          prevInvitations.map((invitation) =>
            invitation.id.toString() === id ? { ...invitation, ...newInvitationValues } : invitation,
          ),
        )

        return prevInvitations
      },
      onSuccess: () => {
        notifySuccess(t('toastify.success.meetings.updateInvitationStatus'))
        void queryCache.refetchQueries(['invitation', 'getAllInvitationsByMeeting', meetingId])
        void queryCache.refetchQueries(historyQueryKey)
      },
      onError: (err, mutationParams, prevData) => {
        // Si la query échoue, alors on reset le select à son ancienne valeur. Sinon, il y aura la valeur
        // sélectionnée par l'utilisateur qui sera affichée mais en BDD ce sera toujours l'ancienne
        queryCache.setQueryData(invitationsQueryKey, prevData)
        notifyError(t('toastify.errors.update.invitation'))
      },
    },
  )

  const updateQueryCache = (name: string, value?: string | number) => {
    const prevInvitations: Invitation[] = queryCache.getQueryData(invitationsQueryKey) || []

    queryCache.setQueryData(
      invitationsQueryKey,
      prevInvitations.map((invitation) =>
        invitation.id.toString() === id ? { ...invitation, [name]: value } : invitation,
      ),
    )
  }

  const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (invitation.user.id === user?.id) {
      void queryCache.resetQueries(['user', 'getUserMeetings'])
    }
    if (event.target.name === 'status') {
      mutate({
        id,
        value: {
          status: event.target.value,
        },
      })
    } else if (event.target.name === 'absence_option' && event.target.value === '') {
      mutate({ id, value: { absence_option: null } })
    } else {
      updateQueryCache(event.target.name, event.target.value)
    }
  }

  const onSelectAbsenceChildrenChange = (selectedUserId: string | number) => {
    setSubstituteUser(substituteUsers.find((user) => user.id.toString() === selectedUserId.toString()))
  }

  const onValidateButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen(true)
  }
  const onToggleModal = () => setOpen(false)

  // On filtre les options transmission_pouvoir/remplacement en fonction des droits
  // proposés par l'instance
  const options = Object.entries(ABSENCE_OPTIONS).filter(([key]) => absence_option?.includes(key))

  const showAbsenceOptions =
    INVITATION_STATUS[invitation.status] === INVITATION_STATUS.ABSENT &&
    (userMandate?.status === MemberStatusEnum.TITULAIRE || userMandate?.status === null) &&
    options.length > 0

  return (
    <Row className='mr-0 w-100' gutters='sm'>
      <Col xl={showAbsenceOptions ? 3 : 6} lg={showAbsenceOptions ? 6 : 12} xs={12} className='my-1 my-xl-0'>
        <TextInput
          name='status'
          type='select'
          className='cursor-pointer'
          value={invitation.status}
          onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
          onChange={onSelectChange}
          disabled={disabled}
        >
          {isMixedChannelInstance(instanceId) &&
            MANAGER_STATUTES_MIXED_CHANNEL.map(([key, value]) => (
              <option key={key} value={key} className='cursor-pointer'>
                {t(value.label)}
              </option>
            ))}
          {!isMixedChannelInstance(instanceId) &&
            MANAGER_STATUTES.map(([key, value]) => (
              <option key={key} value={key} className='cursor-pointer'>
                {t(value.label)}
              </option>
            ))}
        </TextInput>
      </Col>
      {showAbsenceOptions && (
        <>
          <Col xl={invitation.absence_option ? 2 : 3} lg={6} xs={12} className='my-1 my-xl-0'>
            <TextInput
              type='select'
              name='absence_option'
              className='cursor-pointer'
              value={invitation.absence_option || ''}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
              onChange={onSelectChange}
            >
              <option value=''>{t('meeting.invitations.table.none')}</option>
              {options.map(([key, { label }]) => (
                <option key={key} value={key} className='cursor-pointer'>
                  {t(label)}
                </option>
              ))}
            </TextInput>
          </Col>
          {invitation.absence_option && (
            <>
              <Col xl={4} lg={12} xs={12} className='my-1 my-xl-0'>
                <div className='d-flex'>
                  <span className='mr-3 my-auto'>à</span>
                  {isLoading && <Spinner />}
                  {!isLoading && (
                    <SelectSearchInput
                      className='cursor-pointer overflow-hidden w-100'
                      value={substituteUser?.id || ''}
                      placeholder={t('forms.selectUser')}
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}
                      onChange={onSelectAbsenceChildrenChange}
                    >
                      {substituteUsers.map((user) => {
                        const value = `${capitalize(user.firstname)} ${user.lastname?.toUpperCase()} ${
                          user.organization ? `- ${user.organization}` : ''
                        }`
                        return (
                          user.id !== invitation.user.id && (
                            <Option key={user.id} value={user.id} className='cursor-pointer'>
                              {value}
                            </Option>
                          )
                        )
                      })}
                    </SelectSearchInput>
                  )}
                  {open && (
                    <UpdateInvitationAbsenceChildrenModal
                      isOpen={open}
                      toggle={onToggleModal}
                      meetingId={meetingId}
                      absence_option={invitation.absence_option}
                      invitation={invitation}
                      absenceChildrenUserId={substituteUser?.id.toString()}
                      historyQueryKey={historyQueryKey}
                    />
                  )}
                </div>
              </Col>
              <Col xl={2} xs={11} className='my-1 my-xl-0'>
                <Button
                  disabled={!invitation.absence_option || !substituteUser}
                  onClick={onValidateButtonClick}
                  className='w-100 flex-nowrap d-flex justify-content-center'
                >
                  {t('meeting.invitations.table.confirm')}
                </Button>
              </Col>
            </>
          )}
        </>
      )}
      {invitation.absence_validation && (
        <Col xs={1} className='my-1 my-xl-0 my-xl-0'>
          <TooltipWithLabel
            className='align-items-center ml-3'
            target={`Tooltip${invitation.id}`}
            placement='top'
            label={
              <IconSvg name={ABSENCE_STATUS[invitation.absence_validation as AbsenceStatusKey].icon as IconSvgMapKey} />
            }
            autohide={false}
          >
            <p>{t(ABSENCE_STATUS[invitation.absence_validation as AbsenceStatusKey].label)}</p>
          </TooltipWithLabel>
        </Col>
      )}
    </Row>
  )
}

export default ParticipationCell
