import React, { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Redirect } from 'react-router-dom'

import { PATHS } from '../utils/constants/routes/RoutePaths'

const LogoutView: FC = () => {
  const queryClient = useQueryClient()
  queryClient.removeQueries()
  return <Redirect to={PATHS.LOGIN} />
}

export default LogoutView
