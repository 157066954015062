import React, { FC, HTMLAttributes, ReactNode, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import ViewFoot from './ViewFoot'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  className?: string
  scrollViewBody?: boolean
  hasFoot?: boolean
}

const ViewBody: FC<Props> = ({ children, className = '', scrollViewBody, hasFoot = true, ...rest }) => {
  const history = useHistory()
  const ref = useRef<HTMLDivElement | null>(null)

  history.listen(() => {
    ref.current?.scrollTo({
      top: 0,
      left: 0,
    })
  })
  const ViewBodyClassName = (className ? className : '') + (scrollViewBody ? ' ScrollViewBody' : '')

  return (
    <div ref={ref} className={`ViewBody ${ViewBodyClassName}`} {...rest}>
      {children}
      {hasFoot && <ViewFoot />}
    </div>
  )
}

export default ViewBody
