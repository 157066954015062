import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Language } from '../../../utils/constants/Languages'

const LanguageSwitch: FC = () => {
  const { i18n } = useTranslation()
  const [lang, setLang] = useState<Language>(i18n.language as Language)

  const changeLanguageHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value
    i18n.changeLanguage(language).then(() => {
      setLang(language as Language)
    })
  }

  return (
    <span className='mr-5'>
      <select className='form-control' value={lang} name='language' onChange={changeLanguageHandler}>
        <option value={Language.FR}>FR</option>
        <option value={Language.EN}>EN</option>
      </select>
    </span>
  )
}

export default LanguageSwitch
