import { useStore } from 'effector-react'
import { capitalize } from 'lodash'
import { FC, ReactText, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../../utils/alertUtils'
import { InvitationStatusEnum } from '../../../../utils/constants/Status'
import { useQuery } from '../../../../utils/hooks/useQuery'

import { AbsenceOption } from '../../../../types/AbsenceOption'
import { AbsenceOptionType, Invitation } from '../../../../types/Invitation'
import { User } from '../../../../types/User'

import { invitationService } from '../../../../services/invitationService'
import { userService } from '../../../../services/userService'
import { UserStore } from '../../../../store/UserStore'
import Modal from '../../../atoms/modal/Modal'
import { ModalAbsenceStep0, ModalAbsenceStep1, ModalAbsenceStep2, ModalAbsenceStep3 } from './ModalAbsenceSteps'

interface ModalAbsenceProps {
  open: boolean
  meetingId: number
  instanceId: number
  invitation: Invitation
  hasGivePower: boolean
  hasReplacement: boolean
  onModalToggle: () => void
}

const ModalAbsence: FC<ModalAbsenceProps> = (props) => {
  const { open, meetingId, instanceId, invitation, hasGivePower, hasReplacement, onModalToggle } = props
  const { t } = useTranslation()

  // Si l'utilisateur a déjà donné son pouvoir, alors on lui affiche la step 0
  // (proposition : "Donner mon pouvoir" / "Annuler mon pouvoir")
  // sinon, on affiche la step 1

  const defaultStep = invitation.absence_option ? 0 : 1
  const [step, setStep] = useState(defaultStep)
  const [absenceOption, setAbsenceOption] = useState(invitation.absence_option)

  const [selectedUser, setSelectedUser] = useState<User>()
  const [uploadedFile, setUploadedFile] = useState<File>()

  const userStore = useStore(UserStore)
  const college = userStore?.college
  const userId = userStore?.id || 0

  const { isLoading, data: users = [] } = useQuery({
    queryKey: ['user', 'getSubstituteUsers', absenceOption, instanceId, userId, college],
    queryFn: () => {
      // La liste des utilisateurs pouvant remplacer un membre dépend du mode :
      // si c'est pour donner son pouvoir, alors seuls ceux de son instance peuvent
      // être choisi, sinon pour un remplacement des membres de n'importe quelle
      // instance peuvent l'être.
      return userService.getSubstituteUsers(
        userId,
        college,
        absenceOption === AbsenceOption.POWER ? [instanceId] : undefined,
      )
    },
    onError: () => notifyError(t('toastify.errors.get.user')),
    enabled: open && step === 2,
  })

  const onModalClose = () => {
    // On reset l'étape car sinon, à la réouverture de la modale, on se retrouvera à l'étape
    // à la fermeture de la modale précédente
    onModalToggle()
    setStep(defaultStep)
  }

  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    ({ selectedUserId, file }: { selectedUserId?: number; file?: File }) => {
      // Si on n'envoie aucunes de ces données, alors on souhaite cancel notre
      // pouvoir.
      return selectedUserId || file
        ? invitationService.postAbsenceDelegation(invitation.id, selectedUserId?.toString(), file, absenceOption)
        : invitationService.updateInvitation(invitation.id, { status: InvitationStatusEnum.ABSENT })
    },
    {
      mutationKey: ['invitation', 'postAbsenceDelegation', invitation.id, userId, absenceOption],
      onSuccess: () => {
        notifySuccess(t('toastify.success.meetings.power'))
        queryClient.refetchQueries(`meeting getOne ${meetingId}`)
        onModalClose()
      },
      onError: () => notifyError(t('toastify.errors.update.invitation')),
    },
  )

  const onNextStepClick = () => setStep(1)
  const onStep0CancelPowerButton = () => {
    mutate({})
  }

  const onStep1ButtonClick = (mode: AbsenceOptionType) => {
    setAbsenceOption(mode)
    setStep(2)
  }

  const onValidateStep2ButtonClick = () => {
    if (selectedUser) {
      setStep(3)
    }
  }

  const onSelectChange = (selectedUserId: ReactText) => {
    setSelectedUser(users.find((user) => user.id.toString() === selectedUserId.toString()))
  }

  const onFileUpload = (file: File) => setUploadedFile(file)

  const onDeleteFilePreview = () => setUploadedFile(undefined)

  const onValidateStep3ButtonClick = () => {
    if (uploadedFile && selectedUser) {
      mutate({ selectedUserId: selectedUser.id, file: uploadedFile })
    }
  }

  const goBack = () => setStep((prevState) => prevState - 1)
  const header =
    step === 1
      ? `meeting.modal.absence.step${step}.header`
      : `meeting.modal.absence.step${step}.${absenceOption?.toLowerCase()}Header`
  const absenceUser = invitation.absence_children?.user
  const displayAbsenceChildrenName = absenceUser
    ? `${capitalize(absenceUser.firstname)} ${absenceUser.lastname?.toUpperCase()}`
    : ''
  return (
    <Modal isOpen={open} toggle={onModalClose} size='md'>
      <ModalHeader toggle={onModalClose}>
        {step > defaultStep && <i className='icon-left-open mr-3 cursor-pointer' onClick={goBack} />}
        {t(header, { user: displayAbsenceChildrenName })}
      </ModalHeader>
      <ModalBody>
        {step === 0 && (
          <ModalAbsenceStep0
            absenceOption={absenceOption}
            onNextStepButtonClick={onNextStepClick}
            onCancelPowerButtonClick={onStep0CancelPowerButton}
          />
        )}
        {step === 1 && (
          <ModalAbsenceStep1 hasGivePower={hasGivePower} hasReplacement={hasReplacement} onClick={onStep1ButtonClick} />
        )}
        {step === 2 && (
          <ModalAbsenceStep2
            isLoading={isLoading}
            absenceOption={absenceOption}
            selectedUser={selectedUser}
            users={users}
            onSelectChange={onSelectChange}
            onClick={onValidateStep2ButtonClick}
          />
        )}
        {step === 3 && absenceOption && (
          <ModalAbsenceStep3
            invitationId={invitation.id}
            absenceOption={absenceOption}
            uploadedFile={uploadedFile}
            onFileUpload={onFileUpload}
            onDeleteFilePreview={onDeleteFilePreview}
            onClick={onValidateStep3ButtonClick}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalAbsence
