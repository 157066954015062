import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationInterface } from '../../../types/Notification'

import { NotificationStore } from '../../../store/NotificationStore'
import Badge from '../../atoms/badge/Badge'
import Button from '../../atoms/button/Button'
import Icon from '../../atoms/icon/Icon'
import Popover from '../../atoms/popover/Popover'
import PopoverBody from '../../atoms/popover/popover-body/PopoverBody'
import PopoverHeader from '../../atoms/popover/popover-header/PopoverHeader'
import './NotificationContainer.scss'
import NotificationItem from './NotificationItem'

const NotificationContainer: FC = () => {
  const notifications = NotificationStore.getState()
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => setOpen((state: boolean) => !state)

  const notViewedNotifications = notifications.filter((notification: NotificationInterface) => !notification.view)
  const notViewedCount = notViewedNotifications.length
  const count = (notifications || []).length
  return (
    <>
      <Button id='NotificationButton'>
        <Icon name='bell' />
        {notViewedCount > 0 && (
          <Badge pill color='red'>
            {notViewedCount}
          </Badge>
        )}
      </Button>
      <Popover
        className='NotificationPopover'
        placement='bottom'
        trigger='focus'
        toggle={toggle}
        isOpen={open}
        target='NotificationButton'
      >
        <PopoverHeader className='pt-3'>{t('notifications.title')}</PopoverHeader>
        <PopoverBody>
          {count === 0 && (
            <p className='text-center my-3'>
              <i>{t('notifications.empty')}</i>
            </p>
          )}
          {count !== 0 &&
            notifications?.map((notification: NotificationInterface, index: number) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                separator={index < notifications.length - 1}
              />
            ))}
        </PopoverBody>
      </Popover>
    </>
  )
}

export default NotificationContainer
