import enUS from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'

export enum Language {
  FR = 'fr-FR',
  EN = 'en-US',
  fr = 'fr',
  en = 'en',
}

type LocaleListType = {
  [key: string]: {
    localeData: Locale
  }
}

export const LOCALES: LocaleListType = {
  [Language.EN]: {
    localeData: enUS,
  },
  [Language.en]: {
    localeData: enUS,
  },
  [Language.FR]: {
    localeData: fr,
  },
  [Language.fr]: {
    localeData: fr,
  },
}
