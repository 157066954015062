import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import ReactQuill from 'react-quill'
import { Form, Input, ModalBody, ModalHeader } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { Instance } from 'types/Instance'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'

import { EmailInstance } from '../../../types/Email'

import Button from '../../atoms/button/Button'
import IconSvg from '../../atoms/icon/IconSvg'
import TextInput from '../../atoms/input/TextInput'
import Modal from '../../atoms/modal/Modal'

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['blockquote'],

  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  ['link', 'image'],
  ['clean'], // remove formatting button
]

interface SendEmailModalProps {
  className?: string
  instance: Instance
  queryKey?: (string | number)[]
}

const InstanceEmailModal: FC<SendEmailModalProps> = (props) => {
  const { className = '', instance, queryKey } = props

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm<EmailInstance>({
    mode: 'onChange',
  })

  useEffect(() => {
    const defaultValues = {
      subject: instance.email_template?.subject || '',
      content: instance.email_template?.content || '',
    }

    reset(defaultValues)
  }, [instance])

  // Mutation du on submit
  const queryClient = useQueryClient()
  const { mutate } = useMutation(
    (emailData: EmailInstance): Promise<Instance | void> => {
      return instanceService.update(instance.id, { ...instance, email_template: emailData })
    },
    {
      mutationKey: ['instance', 'update'],
      onSuccess: async () => {
        await queryClient.refetchQueries(queryKey)
        notifySuccess(t('toastify.success.templateSuccessfullyEdited'))
        setIsOpen(false)
      },
      onError: () => notifyError(t('toastify.errors.unknown')),
    },
  )

  // ----------------------------- ON CHANGE ACTIONS ---------------------------------
  const onReactQuillChange = (value: string) => setValue('content', value)

  const onSubmit = (emailData: EmailInstance) => {
    mutate({ ...emailData })
  }

  const { t } = useTranslation()

  const onModalOpen = (isOpen: boolean) => setIsOpen(isOpen)
  const [isOpen, setIsOpen] = useState(false)

  const watchValues = watch()

  return (
    <div className={`${className}`}>
      <Button onClick={() => onModalOpen(true)} color='primary'>
        <IconSvg name='ConvocationEdit' className='mr-3' />
        {t('email.editTemplate')}
      </Button>
      <Modal isOpen={isOpen} toggle={() => onModalOpen(false)} size='full'>
        <ModalHeader toggle={() => onModalOpen(false)}>{t('email.editTemplate')}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column flex-grow-1'>
            <TextInput
              type='text'
              value={watchValues.subject}
              name='subject'
              error={errors?.subject?.message && t(errors?.subject?.message)}
              innerRef={register}
            />
            <Input name='content' hidden innerRef={register} />
            <ReactQuill
              className='Quill'
              value={watchValues.content}
              onChange={onReactQuillChange}
              modules={{
                toolbar: toolbarOptions,
              }}
            />
            <Button type='submit' className='mx-auto mt-3'>
              {t('common.save')}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default InstanceEmailModal
