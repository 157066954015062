import React, { FC } from 'react'
import { DropdownItem as RSDropdownItem } from 'reactstrap'

import Icon from '../icon/Icon'

interface DropdownItemProps {
  text: string
  iconName: string
  onClick?: () => Promise<unknown>
  className: string
}

const DropdownItem: FC<DropdownItemProps> = (props) => {
  const { text, iconName, className, onClick } = props
  return (
    <RSDropdownItem className={className} onClick={onClick}>
      <Icon name={iconName} className='mr-1' />
      <span>{text}</span>
    </RSDropdownItem>
  )
}

export default DropdownItem
