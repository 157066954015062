import * as Yup from 'yup'

export const updateMemberValidationSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.string().test('compare1', 'mandates.errors.startDateAfterEndDate', function (start_date) {
        return start_date && this.parent.end_date ? start_date.localeCompare(this.parent.end_date) < 0 : true
      }),
      end_date: Yup.string().test('compare2', 'mandates.errors.endDateBeforeStartDate', function (end_date) {
        return end_date && this.parent.start_date ? this.parent.start_date.localeCompare(end_date) < 0 : true
      }),
    }),
  ),
})

export const createMemberValidationSchema = Yup.object().shape({
  users_permissions_user: Yup.string().trim().required('common.validation.required'),
  start_date: Yup.string().test('compare1', 'mandates.errors.startDateAfterEndDate', function (start_date) {
    return start_date && this.parent.end_date ? start_date.localeCompare(this.parent.end_date) < 0 : true
  }),
  end_date: Yup.string().test('compare2', 'mandates.errors.endDateBeforeStartDate', function (end_date) {
    return end_date && this.parent.start_date ? this.parent.start_date.localeCompare(end_date) < 0 : true
  }),
})
