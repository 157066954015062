import { yupResolver } from '@hookform/resolvers/yup'
import TextInput from 'components/atoms/input/TextInput'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Link, useHistory } from 'react-router-dom'
import { CardBody, CardHeader, Col, Form } from 'reactstrap'

import { notifyError } from '../../../../utils/alertUtils'
import { loginValidationSchema } from '../../../../utils/constants/Auth'
import { PATHS } from '../../../../utils/constants/routes/RoutePaths'

import { CredentialsType } from '../../../../types/Auth'
import { User } from '../../../../types/User'

import { loginService } from '../../../../services/loginService'
import Button from '../../../atoms/button/Button'
import Card from '../../../atoms/card/Card'
import IconSvg from '../../../atoms/icon/IconSvg'

const LoginFormSection: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const { register, handleSubmit, errors } = useForm<CredentialsType>({
    mode: 'onChange',
    resolver: yupResolver(loginValidationSchema),
  })
  const { mutate } = useMutation(
    ({ identifier, password }: CredentialsType) => loginService.login(identifier, password),
    {
      mutationKey: ['auth', 'login'],
      onSuccess: (user: User | undefined) => {
        if (user === undefined) {
          notifyError(t('toastify.errors.noMandate'))
        } else {
          history.push(PATHS.DASHBOARD)
        }
      },
      onError: () => notifyError(t('toastify.errors.login')),
    },
  )

  const passwordInputType = showPassword ? 'text' : 'password'
  const passwordIconName = showPassword ? 'PasswordHide' : 'PasswordShow'
  const onPasswordIconClick = () => setShowPassword((prevState) => !prevState)
  const onSubmitLogin = (values: CredentialsType) => mutate(values)

  return (
    <Card className='LoginCard' boxShadow='none' banner bannerColor='orange' borderRadius='xl'>
      <CardHeader>
        <h2 className='BannerContent'> {t('auth.mySpace')}</h2>
      </CardHeader>
      <CardBody>
        <Col sm={12} className='mt-4 h-100'>
          <Form className='w-100 h-100 d-flex flex-column' onSubmit={handleSubmit(onSubmitLogin)}>
            <div>
              <h2>{t('auth.login.identifier')}</h2>
              <TextInput
                autoFocus
                name='identifier'
                innerRef={register}
                type='email'
                className='mb-5'
                placeholder={t('auth.login.identifierPlaceholder')}
                error={errors.identifier?.message && t(errors.identifier.message)}
              />

              <h2>{t('auth.login.password')}</h2>
              <div className='position-relative d-flex mb-5'>
                <TextInput
                  name='password'
                  innerRef={register}
                  type={passwordInputType}
                  placeholder={t('auth.login.passwordPlaceholder')}
                  error={errors.password?.message && t(errors.password.message)}
                />
                <IconSvg className='PasswordShowIcon' name={passwordIconName} onClick={onPasswordIconClick} />
              </div>
              <Link to={PATHS.FORGOT_PASSWORD} className='text-underline font-weight-medium'>
                {t('auth.login.forgotPassword')}
              </Link>
            </div>
            <div className='flex-grow-1 d-flex align-items-center justify-content-end'>
              <Button type='submit' label={t('auth.login.submit')} />
            </div>
          </Form>
        </Col>
      </CardBody>
    </Card>
  )
}

export default LoginFormSection
