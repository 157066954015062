import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { INVITATION_STATUS } from '../../../../utils/constants/Status'

import { InvitationStatusType } from '../../../../types/InvitationStatus'

import './MeetingStatutes.scss'

type MeetingStatutesType = {
  activeStatus: InvitationStatusType
}

const MeetingStatutes: FC<MeetingStatutesType> = (props) => {
  const { activeStatus } = props
  const { t } = useTranslation()

  const status = INVITATION_STATUS[activeStatus]
  return (
    <div className={`MeetingStatutes bg-${status.color}`}>
      <span className={`meetingStatusText text-white font-weight-bold`}>{t(status.label)}</span>
    </div>
  )
}

export default MeetingStatutes
