import Icon from 'components/atoms/icon/Icon'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { NavItem } from 'reactstrap'

import './NavbarItem.scss'

export interface NavbarPropsInterface {
  key: string
  i18nKey: string
  icon: string
  path?: string
  onClick?: () => void
  type?: 'externalLink' | 'internalLink' | 'action'
  children?: ReactElement
}

const NavbarItem: FC<NavbarPropsInterface> = (props) => {
  const { i18nKey, icon, path, onClick, type = 'internalLink', children } = props
  const { t } = useTranslation()

  const navbarItemContent = (
    <>
      <Icon name={icon} />
      <span>{t(`nav.${i18nKey}`)}</span>
      {children}
    </>
  )
  return (
    <NavItem>
      {(type === 'internalLink' && (
        <NavLink to={path || ''} className='NavbarItem' activeClassName='NavbarItemSelected'>
          {navbarItemContent}
        </NavLink>
      )) ||
        (type === 'externalLink' && (
          <a className='NavbarItem' href={path || ''} target={'_blank'} rel={'noreferrer'}>
            {navbarItemContent}
          </a>
        )) ||
        (type === 'action' && (
          <div className='NavbarItem' onClick={onClick}>
            {navbarItemContent}
          </div>
        ))}
    </NavItem>
  )
}

export default NavbarItem
