import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface StringsListProps {
  strs?: string[]
  className?: string
}

const MeetingPointsOverview: FC<StringsListProps> = ({ strs, className = '' }) => {
  const { t } = useTranslation()

  if (!strs?.length) {
    return <p className='MeetingPointsOverview '>{t('meeting.noInfosAvailable')}</p>
  }

  return (
    <ul className={`MeetingPointsOverview ${className}`}>
      {strs.slice(0, 3).map((t, i) => (
        <li className='mt-2' key={t + i}>
          {t}
        </li>
      ))}
      {strs.length > 3 && <p>...</p>}
    </ul>
  )
}

export default MeetingPointsOverview
