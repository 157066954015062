import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ViewHead: FC<Props> = ({ children }) => {
  return <div className={`ViewHead`}>{children}</div>
}

export default ViewHead
