import axios, { AxiosError, AxiosResponse } from 'axios'
import i18next from 'i18next'

import { notifyError } from '../utils/alertUtils'

import { loginService } from '../services/loginService'

const MAX_TIMEOUT = 30_000 // 30s
export const BASE_PATH = process.env.REACT_APP_BASE_PATH

const request = async <T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  responseType?: 'json' | 'blob',
  body?: Record<string, unknown>,
  headers?: Record<string, string>,
  params?: Record<string, unknown>,
): Promise<T> => {
  return axios
    .request<T>({
      url: `${BASE_PATH}${path}`,
      method: method || (body ? 'POST' : 'GET'),
      headers,
      data: body && JSON.stringify(body),
      params,
      timeout: MAX_TIMEOUT,
      withCredentials: true,
      responseType: responseType,
    })
    .then((res: AxiosResponse<T>) => res.data)
    .catch((res: AxiosError) => {
      const reqStatus = res.response?.status

      if (reqStatus && [401, 403].includes(reqStatus)) {
        if (process.env.REACT_APP_LOGOUT_WHEN_QUERY_FAIL !== 'false') {
          loginService.getConnectedUser() &&
            loginService.logout().then(() => {
              notifyError(i18next.t('toastify.errors.sessionExpired'))
            })
        }
      }

      throw res.response
    })
}

export const execute = async <T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: Record<string, unknown>,
  headers: Record<string, string> = {},
  params?: Record<string, unknown>,
  responseType?: 'json' | 'blob',
): Promise<T> => {
  const apiHeaders = { 'content-type': 'application/json', ...headers }
  return request<T>(path, method, responseType, body, apiHeaders, params)
}

export const executeFormData = async <T>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body: FormData,
  headers: Record<string, string> = {},
): Promise<T> => {
  const requestOptions: RequestInit = {
    method: method,
    body: body,
    headers: headers,
  }

  // On se base sur fetch et non sur axios car ce dernier a des soucis (connus)
  // avec l'envoi de formData (vu par Jean C.)
  return fetch(`${BASE_PATH}${path}`, requestOptions).then((res) => {
    // TODO : Factoriser avec le traitement dans le execute
    const reqStatus = res.status

    if ([401, 403].includes(reqStatus)) {
      loginService.logout()
      throw new Error(i18next.t('auth.expiredSession'))
    } else if (![200, 201, 204].includes(reqStatus)) {
      // FIXME Max : translation
      throw new Error(`Unable to query ${path} : ${reqStatus} / ${res.statusText}`)
    }

    return res.json()
  })
}
