import { Select } from 'antd'

/**
 * Un warning est rejeté par AntDesign si on passe un composant autre que son composant Option en children.
 * Donc on ne peut pas faire de wrapper.
 * Sauf que pour utiliser l'option de AntDesign, on est obligé d'importer le Select initial. Donc, si
 * on veut utiliser le wrapper de leur Select, il faut quand même importer leur Select à eux pour avoir
 * accès au Option natif.
 * Pour contourner ça, on peut l'exporter de cette façon et l'utiliser en le nommant Option.
 */

const Option = Select.Option

export default Option
