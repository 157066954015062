import { DataNode } from 'antd/lib/tree'
import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import Spinner from 'components/atoms/spinner/Spinner'
import Tree from 'components/atoms/tree/Tree'
import React, { FC, Key } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { UserManagerRights } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { getAllInstancesAsDataNodeTypeForManager } from 'utils/instances'

import { PATHS } from '../utils/constants/routes/RoutePaths'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'

const fetchInstances = async (managerRights: UserManagerRights) =>
  instanceService.getAll().then((res) => getAllInstancesAsDataNodeTypeForManager({ allInstances: res, managerRights }))

const InstancesListView: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const managerRights = useManagerRights()

  const { data: instances, isLoading } = useQuery<DataNode[]>({
    queryKey: ['instance', 'getInstancesForManager'],
    queryFn: () => fetchInstances(managerRights),
    onError: () => notifyError(t('toastify.errors.get.instance')),
    cacheTime: 0,
  })

  const onSelect = (selectedKeys: Key[]) => {
    const selectedKey = selectedKeys[0]

    if (selectedKey && !selectedKey.toString().startsWith('asso_')) {
      history.push(`${history.location.pathname}/${selectedKey}`)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.CONTACTS.HOME}>{t('contacts.header')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{t('instances.list')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {instances && (
            <Row>
              <Tree
                treeData={instances}
                defaultExpandAll
                checkable={false}
                selectable={true}
                multiple={false}
                autoExpandParent={true}
                onSelect={onSelect}
                className='bg-transparent'
              />
            </Row>
          )}
          {!instances && <p>{t('instances.noInstance')}</p>}
        </Section>
      </ViewBody>
    </View>
  )
}

export default InstancesListView
