import { ChonkyActions, ChonkyFileActionData, FileArray, FileData, FileHelper, FullFileBrowser } from 'chonky'
import { isInteger } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 } from 'uuid'

import { frenchI18n } from '../../../utils/constants/ChonkyFileBrowser'
import { triggerMultipleDownloadFromObjectUrls } from '../../../utils/fileUtils'
import { FileBrowserComponentProps, FileBrowserDataNode } from '../../../utils/treeUtils'

import { DownloadFileObject } from '../../../types/File'
import { GenericFileActionHandler } from 'chonky/src/types/action-handler.types'
import { ChonkyActionUnion } from 'chonky/src/types/file-browser.types'

import { BASE_PATH } from '../../../api/api'
import { meetingService } from '../../../services/meetingService'
import './FileBrowser.scss'

// We define a custom interface for file data because we want to add some custom fields
// to Chonky's built-in `FileData` interface.
interface CustomFileData extends FileData {
  parentId?: string
  childrenIds?: string[]
}
interface CustomFileMap {
  [fileId: string]: CustomFileData
}

// Helper method to attach our custom TypeScript types to the imported JSON file map.
const prepareCustomFileMap = (fsMap: FileBrowserDataNode) => {
  const baseFileMap = (fsMap.fileMap as unknown) as CustomFileMap
  const rootFolderId = fsMap.rootFolderId
  return { baseFileMap, rootFolderId }
}

// Hook that sets up our file map and defines functions used to mutate - `deleteFiles`,
// `moveFiles`, and so on.
const useCustomFileMap = (fsMap: FileBrowserDataNode, props: VFSProps) => {
  const { baseFileMap, rootFolderId } = useMemo(() => prepareCustomFileMap(fsMap), [fsMap])

  // Setup the React state for our file map and the current folder.
  const [fileMap, setFileMap] = useState(baseFileMap)
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId)
  useEffect(() => {
    setFileMap(baseFileMap)
  }, [baseFileMap])

  // Setup the function used to reset our file map to its initial value. Note that
  // here and below we will always use `useCallback` hook for our functions - this is
  // a crucial React performance optimization, read more about it here:
  // https://reactjs.org/docs/hooks-reference.html#usecallback
  const resetFileMap = useCallback(() => {
    setFileMap(baseFileMap)
    setCurrentFolderId(rootFolderId)
  }, [baseFileMap, rootFolderId])

  // Setup logic to listen to changes in current folder ID without having to update
  // `useCallback` hooks. Read more about it here:
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const currentFolderIdRef = useRef(currentFolderId)
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId
  }, [currentFolderId])

  // Function that will be called when user deletes files either using the toolbar
  // button or `Delete` key.
  const deleteFiles = useCallback(
    (files: CustomFileData[]) => {
      // We use the so-called "functional update" to set the new file map. This
      // lets us access the current file map value without having to track it
      // explicitly. Read more about it here:
      // https://reactjs.org/docs/hooks-reference.html#functional-updates
      setFileMap((currentFileMap) => {
        // Create a copy of the file map to make sure we don't mutate it.

        files.forEach((file) => {
          // Delete file from the file map.
          if (file.id.startsWith('folder') || isInteger(file.id) || !isNaN(parseInt(file.id))) {
            delete currentFileMap[file.id]
          } else {
            return currentFileMap
          }

          const orderOfTheDay = props.updatedMeeting?.order_of_the_day
          if (orderOfTheDay && orderOfTheDay.length > 0) {
            const order = orderOfTheDay[0]
            const fileMap = order.fileBrowserData?.fileMap
            if (fileMap) {
              //TODO delete recurse fileMap[file.id]
              delete fileMap[file.id]
              if (file.parentId && fileMap[file.parentId]) {
                const parent = order.fileBrowserData?.fileMap?.[file.parentId]
                fileMap[file.parentId] = {
                  childrenIds: parent?.childrenIds?.filter((child) => child != file.id),
                  childrenCount: parent?.childrenIds?.length,
                }
              }
            }
          }

          // Update the parent folder to make sure it doesn't try to load the
          // file we just deleted.
          if (file.parentId) {
            const parent = currentFileMap[file.parentId]
            const newChildrenIds = parent?.childrenIds?.filter((id) => id !== file.id)
            currentFileMap[file.parentId] = {
              ...parent,
              childrenIds: newChildrenIds,
              childrenCount: newChildrenIds?.length,
            }
          }

          if (props.meetingId) {
            meetingService.update(props.meetingId, props.updatedMeeting ? props.updatedMeeting : {})
            if (file.id.startsWith('folder')) {
              /*
               TODO check folder <> pt du jour
                    si oui > fail
                    sinon
                     > recurse > delete childrens
                     > delete folder   
               */
            } else if (!isNaN(Number(file.id))) {
              meetingService.deleteDocument(props.meetingId.toString(), parseInt(file.id))
              meetingService.update(props.meetingId, props.updatedMeeting ? props.updatedMeeting : {})
            }
          }
        })

        props.newFileMap && props.newFileMap(currentFileMap, props.rootFolderId)

        return currentFileMap
      })
    },
    [props],
  )

  // Function that will be called when files are moved from one folder to another
  // using drag & drop.
  const moveFiles = useCallback(
    (files: CustomFileData[], source: CustomFileData, destination: CustomFileData) => {
      setFileMap((currentFileMap) => {
        const newFileMap = { ...currentFileMap }
        const moveFileIds = new Set(files.map((f) => f.id))

        // Delete files from their source folder.
        const newSourceChildrenIds = source.childrenIds?.filter((id) => !moveFileIds.has(id))
        newFileMap[source.id] = {
          ...source,
          childrenIds: newSourceChildrenIds,
          childrenCount: newSourceChildrenIds?.length,
        }

        // Add the files to their destination folder.
        const newDestinationChildrenIds = [...(destination.childrenIds ?? []), ...files.map((f) => f.id)]
        newFileMap[destination.id] = {
          ...destination,
          childrenIds: newDestinationChildrenIds,
          childrenCount: newDestinationChildrenIds.length,
        }

        // Finally, update the parent folder ID on the files from source folder
        // ID to the destination folder ID.
        files.forEach((file) => {
          newFileMap[file.id] = {
            ...file,
            parentId: destination.id,
          }
        })

        props.newFileMap && props.newFileMap(newFileMap, props.rootFolderId)

        return newFileMap
      })
    },
    [props],
  )

  // Function that will be called when user creates a new folder using the toolbar
  // button. That that we use incremental integer IDs for new folder, but this is
  // not a good practice in production! Instead, you should use something like UUIDs
  // or MD5 hashes for file paths.
  const createFolder = useCallback(
    (folderName: string) => {
      setFileMap((currentFileMap) => {
        const newFileMap = { ...currentFileMap }

        // Create the new folder
        const newFolderId = `folder-${v4()}`
        newFileMap[newFolderId] = {
          id: newFolderId,
          name: folderName,
          isDir: true,
          modDate: new Date(),
          parentId: currentFolderIdRef.current,
          childrenIds: [],
          childrenCount: 0,
        }

        // Update parent folder to reference the new folder.
        const parent = newFileMap[currentFolderIdRef.current]
        newFileMap[currentFolderIdRef.current] = {
          ...parent,
          childrenIds: [...(parent?.childrenIds ?? []), newFolderId],
        }

        if (currentFolderIdRef.current === props.instanceId) {
          props.newFileMap && props.newFileMap(newFileMap, 'attached-document')
        } else {
          props.newFileMap && props.newFileMap(newFileMap, props.rootFolderId)
        }
        return newFileMap
      })
    },
    [props],
  )

  return {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    deleteFiles,
    moveFiles,
    createFolder,
  }
}

export const useFiles = (fileMap: CustomFileMap, currentFolderId: string): FileArray => {
  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId]
    const childrenIds = currentFolder?.childrenIds ?? []
    return childrenIds.map((fileId: string) => fileMap[fileId])
  }, [currentFolderId, fileMap])
}

export const useFolderChain = (fileMap: CustomFileMap, currentFolderId: string): FileArray => {
  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId]

    const folderChain = [currentFolder]

    let parentId = currentFolder?.parentId
    while (parentId) {
      const parentFile = fileMap[parentId]
      if (parentFile) {
        folderChain.unshift(parentFile)
        parentId = parentFile.parentId
      } else {
        break
      }
    }

    return folderChain
  }, [currentFolderId, fileMap])
}

export const useFileActionHandler = (
  props: VFSProps,
  setCurrentFolderId: (folderId: string) => void,
  deleteFiles: (files: CustomFileData[]) => void,
  moveFiles: (files: FileData[], source: FileData, destination: FileData) => void,
  createFolder: (folderName: string) => void,
): GenericFileActionHandler<ChonkyActionUnion> => {
  return useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload
        const fileToOpen = targetFile ?? files[0]
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          setCurrentFolderId(fileToOpen.id)
          return
        } else if (!FileHelper.isDirectory(fileToOpen)) {
          const downloadFile: DownloadFileObject[] = data.payload.files.map((file) => ({
            objectURL: BASE_PATH + file.url,
            fileName: file.name,
          }))
          triggerMultipleDownloadFromObjectUrls(downloadFile)
        }
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        deleteFiles(data.state.selectedFilesForAction)
      } else if (data.id === ChonkyActions.MoveFiles.id && data.payload.source) {
        // TODO le move marche pas a cause de react dnd
        moveFiles(data.payload.files, data.payload.source, data.payload.destination)
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt('Provide the name for your new folder:')
        if (folderName) {
          createFolder(folderName)
        }
      }
    },
    [props, createFolder, deleteFiles, moveFiles, setCurrentFolderId],
  )
}

export type VFSProps = Partial<FileBrowserComponentProps>

export const FileBrowser: React.FC<VFSProps> = (props) => {
  const { instanceId, dataFiles, readOnly, rootFolderId } = props
  if (!dataFiles) {
    return null
  }

  const { fileMap, currentFolderId, setCurrentFolderId, deleteFiles, moveFiles, createFolder } = useCustomFileMap(
    dataFiles,
    props,
  )

  const files = useFiles(fileMap, currentFolderId)
  const folderChain = useFolderChain(fileMap, currentFolderId)
  const handleFileAction = useFileActionHandler(props, setCurrentFolderId, deleteFiles, moveFiles, createFolder)
  const fileActions = useMemo(
    () => (readOnly ? [ChonkyActions.OpenSelection] : [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles]),
    [readOnly],
  )

  const i18n = useMemo(() => frenchI18n, [])

  useEffect(() => {
    props?.getCurrentFolderId && props.getCurrentFolderId(currentFolderId, rootFolderId)
  }, [currentFolderId])

  return (
    <>
      <div className='w-100 file-browser'>
        <FullFileBrowser
          instanceId={instanceId}
          disableDragAndDrop={readOnly}
          files={files}
          folderChain={folderChain}
          fileActions={fileActions}
          onFileAction={handleFileAction}
          i18n={i18n}
          defaultFileViewActionId={'enable_list_view'}
          {...props}
        />
      </div>
    </>
  )
}
