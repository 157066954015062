import { useQuery as useReactQuery } from 'react-query'

import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types'

export const useQuery = <TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, TError, TData>,
): UseQueryResult<TData, TError> => {
  return useReactQuery<TQueryFnData, TError, TData>({
    retry: 0,
    cacheTime: 60000, // 60s
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  })
}
