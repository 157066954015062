import Card from 'components/atoms/card/Card'
import React, { FC, useState } from 'react'
import { CardBody, Col } from 'reactstrap'

import { FILE_TYPES, FileTypeEnum } from '../../../utils/constants/File'
import { truncateString } from '../../../utils/truncate'

import { DocumentType } from '../../../types/Document'

import { BASE_PATH } from '../../../api/api'
import Checkbox from '../../atoms/checkbox/Checkbox'
import Icon from '../../atoms/icon/Icon'
import IconSvg from '../../atoms/icon/IconSvg'
import Row from '../../atoms/layout/Row'
import './DocumentCard.scss'

interface DocumentCardProps {
  file: DocumentType
  grid?: boolean
  openPreviewDocument?: (file: DocumentType) => void
  showChecked?: boolean
  isChecked?: boolean
  onCheckDocument?: (file: DocumentType) => void
  downloadable?: boolean
  showFavorite?: boolean
  favoriteIds?: number[]
  changeFavorite?: (isFavorite: boolean, id: number) => void
}

const DocumentCard: FC<DocumentCardProps> = (props): JSX.Element => {
  const {
    file,
    grid = false,
    openPreviewDocument,
    isChecked = false,
    onCheckDocument,
    showChecked = true,
    downloadable = true,
    showFavorite = false,
    favoriteIds,
    changeFavorite,
  } = props
  const { name, date, extension, url } = file
  const [checked, setChecked] = useState(isChecked)

  const onFileClicked = () => openPreviewDocument && FILE_TYPES[extension] && openPreviewDocument(file)
  const handleCheck = () => {
    onCheckDocument && onCheckDocument(file)
    setChecked(!checked)
  }

  return (
    <Card className='DocumentCard'>
      <CardBody>
        <Row className='DocumentCardGrid' grid={grid}>
          <Col xs='auto' className='DocumentCardCheck card-checkbox'>
            {showChecked && <Checkbox checked={checked} onClick={handleCheck} />}
          </Col>
          <Col xs='auto' className='DocumentCardIcon'>
            {extension && (
              <Col
                xs={12}
                className={`text-center btn-flip m-auto ${FILE_TYPES[extension] ? 'cursor-pointer' : ''}`}
                onClick={onFileClicked}
                data-back='Back'
                data-front='Front'
              >
                {FILE_TYPES[extension] ? (
                  <div className='flip-card'>
                    <div className='flip-card-inner'>
                      <div className='flip-card-front'>
                        <IconSvg
                          name={FILE_TYPES[extension]?.iconName || FILE_TYPES[FileTypeEnum.UNKNOWN].iconName}
                          size='6'
                        />
                      </div>
                      <div className='flip-card-back'>
                        <IconSvg name='IconEye' size='6' />
                      </div>
                    </div>
                  </div>
                ) : (
                  <IconSvg
                    name={FILE_TYPES[extension]?.iconName || FILE_TYPES[FileTypeEnum.UNKNOWN].iconName}
                    size='6'
                  />
                )}
              </Col>
            )}
          </Col>
          <Col xs='auto' className='m-auto DocumentCardName'>
            {grid ? truncateString(name, 70) : name}
          </Col>
          <Col xs='auto' className='DocumentCardDate'>
            <strong>{date}</strong>
          </Col>
          <Col xs='auto' className='DocumentCardWeight'>
            {/*<strong>{size}</strong>*/}
          </Col>
          <Col xs='auto' className='DocumentCardWeight'>
            {favoriteIds && changeFavorite && showFavorite && (
              <div onClick={() => changeFavorite(favoriteIds.includes(file.id, 0), file.id)}>
                <Icon name={favoriteIds.includes(file.id, 0) ? 'star' : 'star-empty'} />{' '}
              </div>
            )}
          </Col>
          <Col xs='auto' className='DocumentCardActions'>
            {/* <Icon name='star' /> */}
            {downloadable && (
              <a
                href={BASE_PATH + url}
                download
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
              >
                <Icon name='download' />
              </a>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default DocumentCard
