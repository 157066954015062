import MandateInstanceAssociationName from 'components/molecules/mandate/MandateInstanceAssociationName'
import ConfirmationModal from 'components/molecules/modals/ConfirmationModal'
import { capitalize } from 'lodash'
import React, { ChangeEvent, FC, Ref, useState } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { CollaboratorForManagerType, CollaboratorRelationEnum, PerimeterTypeEnum } from 'types/Collaborator'
import { Instance } from 'types/Instance'
import { Member } from 'types/Member'
import { USER_COLLEGE_ENUM } from 'utils/constants/College'
import { usePermissions } from 'utils/hooks/usePermissions'

import { PATHS } from '../../../utils/constants/routes/RoutePaths'

import Button from '../../atoms/button/Button'
import CollaboratorStateCell from '../tables-cells/CollaboratorStateCell'
import MandateDateCell from '../tables-cells/MandateDateCell'
import './RenderCollaborator.scss'

interface RenderCollaboratorProps {
  collaborator: CollaboratorForManagerType
  index: number
  errors: DeepMap<
    {
      data: Member[]
    },
    FieldError
  >
  register: Ref<HTMLInputElement>
  canEdit: boolean
  submit: (collaboratorId: number, data: Partial<CollaboratorForManagerType>) => void
  submitDelete: (collaboratorId: number) => void
  current: boolean
  relation: CollaboratorRelationEnum
  isOther?: boolean
  userId?: number
}

const RenderCollaborator: FC<RenderCollaboratorProps> = ({
  collaborator: {
    users_permissions_user: { firstname, lastname, email, organization, college },
    ...collaborator
  },
  index,
  errors,
  register,
  canEdit,
  submit,
  submitDelete,
  current,
  relation,
  isOther = false,
  userId,
}) => {
  const { IS_SUPER_ADMIN } = usePermissions()
  const { t } = useTranslation()
  const submitDate = (e: ChangeEvent<HTMLInputElement>, dateType: 'start_date' | 'end_date') => {
    submit(collaborator.id, { [dateType]: e.target.value })
  }
  const perimeterName =
    relation === CollaboratorRelationEnum.USER &&
    (collaborator.perimeter_type === 'ALL' ? (
      <span>{t('common.fields.mandate.allPerimeter')}</span>
    ) : collaborator.perimeter_type === PerimeterTypeEnum.ASSOCIATION ? (
      <span>{collaborator.perimeter?.name || ''}</span>
    ) : (
      <MandateInstanceAssociationName instance={collaborator.perimeter as Instance} nameType='name' />
    ))
  const history = useHistory()

  const onButtonClick = () => {
    history.push(generatePath(PATHS.USER_MANAGEMENT.MANDATES_RIGHTS, { id: userId, idMandate: collaborator.id }))
  }

  const [isOpenModal, setOpenModal] = useState(false)

  function deleteCollaboratorMandate(id: number) {
    submitDelete(id)
  }

  return (
    <tr key={collaborator.id} className={current ? '' : 'collaborator-row-archived'}>
      {relation === CollaboratorRelationEnum.USER && <td>{perimeterName}</td>}
      {relation === CollaboratorRelationEnum.INSTANCE && (
        <>
          <td>{lastname?.toUpperCase()}</td>
          <td>{capitalize(firstname)}</td>
          <td>{email}</td>
          <td>{`${college && USER_COLLEGE_ENUM[college] ? t(USER_COLLEGE_ENUM[college].shortLabel) + ' ' : ''}${
            organization || ''
          }`}</td>
        </>
      )}
      <MandateDateCell
        error={errors.data?.[index]?.start_date?.message}
        id={`data[${index}].start_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={collaborator.id}
        dateType='start_date'
      />
      <MandateDateCell
        error={errors.data?.[index]?.end_date?.message}
        id={`data[${index}].end_date`}
        register={register}
        disabled={!canEdit}
        submit={submitDate}
        entityId={collaborator.id}
        dateType='end_date'
      />
      {!isOther && (
        <CollaboratorStateCell
          id={`data[${index}].is_manager`}
          register={register}
          disabled={!canEdit}
          submit={submit}
          collaboratorId={collaborator.id}
        />
      )}
      {isOther && (
        <Button onClick={onButtonClick} className='my-1'>
          {t('permissions.button')}
        </Button>
      )}
      {IS_SUPER_ADMIN() && (
        <td>
          <button type='button' onClick={() => setOpenModal(true)} className='btn btn-default btn-sm my-1'>
            &#10060;
          </button>
          <ConfirmationModal
            show={isOpenModal}
            toggle={() => setOpenModal(false)}
            onChange={() => {
              deleteCollaboratorMandate(collaborator.id)
              setOpenModal(false)
            }}
          />
        </td>
      )}
    </tr>
  )
}
export default RenderCollaborator
