import Spinner from 'components/atoms/spinner/Spinner'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import React, { FC, ReactText } from 'react'
import { useTranslation } from 'react-i18next'

import { AbsenceOption } from '../../../../types/AbsenceOption'
import { AbsenceOptionType } from '../../../../types/Invitation'
import { User } from '../../../../types/User'

import { invitationService } from '../../../../services/invitationService'
import Button from '../../../atoms/button/Button'
import Dropzone from '../../../atoms/dropzone/Dropzone'
import SelectSearchInput from '../../../atoms/input/select/search/SelectSearchInput'
import Option from '../../../atoms/input/select/search/options/Option'
import Section from '../../../atoms/layout/Section'
import ButtonDownloadFile from '../../buttons/files/ButtonDownloadFile'
import AttachmentCard from '../../documents/AttachmentCard'

interface ModalAbsenceStep0Props {
  onNextStepButtonClick: () => void
  onCancelPowerButtonClick: () => void
  absenceOption?: string
}

export const ModalAbsenceStep0: FC<ModalAbsenceStep0Props> = (props) => {
  const { onNextStepButtonClick, onCancelPowerButtonClick, absenceOption } = props
  const { t } = useTranslation()

  if (!absenceOption) return null
  return (
    <div className='d-flex justify-content-around'>
      <Button color='primary' onClick={onNextStepButtonClick}>
        {t(`meeting.modal.absence.step0.${absenceOption.toLowerCase()}ModifyButton`)}
      </Button>
      <Button color='secondary' onClick={onCancelPowerButtonClick}>
        {t(`meeting.modal.absence.step0.${absenceOption.toLowerCase()}CancelButton`)}
      </Button>
    </div>
  )
}

interface ModalAbsenceStep1Props {
  hasGivePower: boolean
  hasReplacement: boolean
  onClick: (mode: AbsenceOptionType) => void
}

export const ModalAbsenceStep1: FC<ModalAbsenceStep1Props> = (props) => {
  const { hasGivePower, hasReplacement, onClick } = props
  const { t } = useTranslation()

  const onGivePowerButtonClick = () => onClick(AbsenceOption.POWER)
  const onReplacementButtonClick = () => onClick(AbsenceOption.REPLACEMENT)

  return (
    <div className='d-flex justify-content-around'>
      {hasGivePower && (
        <Button color='primary' onClick={onGivePowerButtonClick}>
          {t('meeting.modal.absence.step1.givePowerButton')}
        </Button>
      )}
      {hasReplacement && (
        <Button color='primary' onClick={onReplacementButtonClick}>
          {t('meeting.modal.absence.step1.replacementButton')}
        </Button>
      )}
    </div>
  )
}

interface ModalAbsenceStep2Props {
  isLoading: boolean
  absenceOption?: AbsenceOptionType
  selectedUser?: User
  users: User[]
  onSelectChange: (value: ReactText) => void
  onClick: () => void
}

export const ModalAbsenceStep2: FC<ModalAbsenceStep2Props> = (props) => {
  const { isLoading, absenceOption, selectedUser, users, onSelectChange, onClick } = props
  const { t } = useTranslation()

  if (isLoading) return <Spinner />

  const buttonLabel =
    absenceOption === AbsenceOption.POWER
      ? t('meeting.modal.absence.step2.powerButton')
      : t('meeting.modal.absence.step2.replacementButton')

  return (
    <>
      {!isLoading && users.length === 0 && <p className='text-center'>{t('meeting.modal.absence.step2.noUser')}</p>}
      {!isLoading && users.length !== 0 && (
        <>
          <SelectSearchInput
            className='mx-auto'
            value={selectedUser && `${selectedUser.firstname} ${selectedUser.lastname}`}
            placeholder={t('forms.selectUser')}
            onChange={onSelectChange}
          >
            {users.map((user) => (
              <Option key={user.id} value={user.id.toString()}>
                {`${capitalize(user.firstname)} ${user.lastname?.toUpperCase()} ${
                  user.organization ? `- ${user.organization}` : ''
                }`}
              </Option>
            ))}
          </SelectSearchInput>
          <Button color='primary' disabled={!selectedUser} onClick={onClick} className='mt-4 mx-auto'>
            {buttonLabel}
          </Button>
        </>
      )}
    </>
  )
}

interface ModalAbsenceStep3Props {
  invitationId: number
  absenceOption: AbsenceOptionType
  uploadedFile?: File
  onFileUpload: (event: File) => void
  onDeleteFilePreview: () => void
  onClick: () => void
}

export const ModalAbsenceStep3: FC<ModalAbsenceStep3Props> = (props) => {
  const { invitationId, absenceOption, uploadedFile, onFileUpload, onDeleteFilePreview, onClick } = props
  const { t } = useTranslation()

  const downloadQueryKey = ['invitation', 'getDelegationTemplate', invitationId]
  const downloadQueryFn = () => invitationService.getDelegationTemplate(invitationId, absenceOption)

  const onDropzoneAddFile = (fileList: File[]) => {
    const file = fileList[0]
    onFileUpload(file)
  }

  const validateButtonLabel =
    absenceOption === AbsenceOption.POWER
      ? t('meeting.modal.absence.step3.powerButton')
      : t('meeting.modal.absence.step3.replacementButton')
  const filename =
    absenceOption === AbsenceOption.POWER
      ? t('meeting.convocation.formPowerFileName', { date: format(new Date(), 'yyyy_MM_dd_HH_mm') })
      : t('meeting.convocation.formReplacementFileName', { date: format(new Date(), 'yyyy_MM_dd_HH_mm') })

  return (
    <>
      <Section fluid>
        {uploadedFile && <AttachmentCard file={uploadedFile} onDeleteFile={onDeleteFilePreview} />}
        <Dropzone label={t('meeting.modal.absence.step3.dropzone')} addNewFiles={onDropzoneAddFile} />
      </Section>
      <div className='my-3 d-flex'>
        <ButtonDownloadFile
          queryKey={downloadQueryKey}
          queryFn={downloadQueryFn}
          buttonLabel={t('meeting.modal.absence.step3.templateButton')}
          templateName={filename}
        />
        <Button disabled={!uploadedFile} color='primary' onClick={onClick} className='ml-0 ml-md-4'>
          {validateButtonLabel}
        </Button>
      </div>
    </>
  )
}
