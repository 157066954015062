import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import IconSvg from '../../../atoms/icon/IconSvg'

const LoginHeader: FC = () => {
  const { t } = useTranslation()

  return (
    <div className='LoginTopBar'>
      <div className='Logo'>
        <IconSvg name='PortailInstanceLogo' size='6' filled={false} />
      </div>
      <div className='Banner d-flex align-items-center'>
        <h1 className='m-0 ml-3 text-white text-lowercase'>{t('common.instances')}</h1>
        <IconSvg name='Logo2i' className='ml-3 ' size='4' filled={false} />
      </div>
    </div>
  )
}

export default LoginHeader
