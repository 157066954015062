import Card from 'components/atoms/card/Card'
import Figure from 'components/atoms/figure/Figure'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { CardBody, CardTitle } from 'reactstrap'

import { UnreadKioskNotification } from '../../../../types/Notification'

import Badge from '../../../atoms/badge/Badge'
import { KioskArticleType } from './KioskArticles'
import './KioskArticles.scss'

interface KioskArticleCardProps extends KioskArticleType {
  previewMode?: boolean
  className?: string
  unreadNotifNumber?: UnreadKioskNotification
  title?: string
}

const KioskArticleCard: FC<KioskArticleCardProps> = ({
  title = '',
  //text = '',
  icon,
  href,
  unreadNotifNumber,
  previewMode,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const onCardClicked = () => {
    href && history.push(href)
  }

  return (
    <Card
      className={`KioskArticleCard${previewMode ? '-previewMode' : ''}`}
      boxShadow='none'
      borderRadius='none'
      clickable
      onClick={onCardClicked}
    >
      <CardBody>
        <Figure ratio={previewMode ? '4-3' : '16-9'}>{icon}</Figure>

        <div className={'d-flex justify-content-center position-relative'}>
          <CardTitle tag={previewMode ? 'h3' : 'h2'} className={previewMode ? 'text-secondary mt-4' : 'text-orange'}>
            {t(title)}
          </CardTitle>
          {unreadNotifNumber && unreadNotifNumber.unreadNumber > 0 ? (
            <Badge pill color='red' className={'pillBadge'}>
              {unreadNotifNumber.unreadNumber}
            </Badge>
          ) : undefined}
        </div>
      </CardBody>
    </Card>
  )
}

export default KioskArticleCard
