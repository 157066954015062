import Spinner from 'components/atoms/spinner/Spinner'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { instanceService } from 'services/instanceService'
import { userService } from 'services/userService'
import { InstancesWithPerimeterAndRights } from 'types/Instance'
import { User } from 'types/User'
import { notifyError } from 'utils/alertUtils'
import useManagerRights from 'utils/hooks/useManagerRights'
import { useQuery } from 'utils/hooks/useQuery'
import { computeFullName } from 'utils/userUtils'

import Modal from '../../atoms/modal/Modal'
import MemberForm from './MemberForm'

interface AddMemberModalProps {
  user: User
  isOpen: boolean
  toggle: () => void
  refetchMembers: () => void
}

const AddMemberModal: FC<AddMemberModalProps> = ({ user, refetchMembers, isOpen, toggle }) => {
  const { t } = useTranslation()

  const managerRights = useManagerRights()

  const { data, isLoading } = useQuery<InstancesWithPerimeterAndRights>({
    queryKey: ['instance', 'getAllWithUserPerimeterAndManagerRights', user.id],
    queryFn: async () => {
      const allInstances = await instanceService.getAll()
      const userPerimeter = await userService.getPerimeter(user.id)
      const userRights = await userService.getUserRights(user.id)

      return {
        allInstances,
        userPerimeter,
        managerRights,
        userRights,
      }
    },
    onError: () => notifyError(t('toastify.errors.get.instance')),
    cacheTime: 0,
  })

  if (isLoading) {
    return <Spinner />
  }
  if (!data) {
    return null
  } else {
    data.userPerimeter.instancesIds = data.userRights.member.instance
    data.userPerimeter.associationsIds = []
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className='text-capitalize' toggle={toggle}>{`${t(
        'mandates.addMandate.member.modalTitle',
      )} ${computeFullName(user)}`}</ModalHeader>
      <ModalBody>
        <MemberForm data={data} userId={user.id} refetchMembers={refetchMembers} toggle={toggle} />
      </ModalBody>
    </Modal>
  )
}

export default AddMemberModal
