import React, { FC } from 'react'
import { TooltipProps as RSTooltipProps } from 'reactstrap'

import Tooltip from './Tooltip'
import './Tooltip.scss'

interface TooltipProps extends RSTooltipProps {
  className?: string
  label?: React.ReactNode
  labelClassName?: string
  target: string
  forceClose?: boolean
  children: React.ReactNode
}

const TooltipWithLabel: FC<TooltipProps> = (props) => {
  const { className = '', labelClassName = '', label, children, target = '', forceClose = false, ...rest } = props
  return (
    <div className={`d-flex cursor-pointer ${className}`}>
      {label && (
        <span id={target} className={labelClassName}>
          {label}
        </span>
      )}
      <Tooltip forceClose={forceClose} target={target} {...rest}>
        {children}
      </Tooltip>
    </div>
  )
}

export default TooltipWithLabel
