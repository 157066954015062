import { DependencyList, useEffect } from 'react'

export const useInfiniteScroll = (elementId: string, deps: DependencyList, handleScroll: () => void): void => {
  const wrappedElement = document.getElementById(elementId)

  const onScrollBottom = () => {
    if (wrappedElement && wrappedElement.scrollHeight - wrappedElement.scrollTop === wrappedElement.clientHeight) {
      handleScroll()
    }
  }

  useEffect(() => {
    function watchScroll() {
      wrappedElement?.addEventListener('scroll', onScrollBottom)
    }
    watchScroll()

    return () => {
      wrappedElement?.removeEventListener('scroll', onScrollBottom)
    }
  }, [wrappedElement, ...deps])
}
