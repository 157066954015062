import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { CardBody, Col } from 'reactstrap'

import Card from '../../atoms/card/Card'
import Row from '../../atoms/layout/Row'
import './Search.scss'

const SearchLoader: FC = () => {
  return (
    <div className='search'>
      <Row>
        <Col xs={12} sm={6} className={'pt-3 pl-md-5'}>
          <Card className='search-card'>
            <CardBody>
              <ContentLoader style={{ width: '100%', height: '100%' }}>
                <rect x='10' y='7' rx='3' ry='3' width='80' height='10' />
                <rect x='10' y='30' rx='3' ry='3' width='15' height='15' />
                <rect x='40' y='33' rx='3' ry='3' width='120' height='8' />
                <rect x='10' y='70' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='70' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='70' rx='20' ry='20' width='230' height='230' />
                <rect x='10' y='330' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='330' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='330' rx='20' ry='20' width='230' height='230' />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pr-md-5'}>
          <Card className='search-card'>
            <CardBody>
              <ContentLoader style={{ width: '100%', height: '100%' }}>
                <rect x='10' y='7' rx='3' ry='3' width='80' height='10' />
                <rect x='10' y='40' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='40' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='40' rx='20' ry='20' width='230' height='230' />
                <rect x='10' y='300' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='300' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='300' rx='20' ry='20' width='230' height='230' />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pl-md-5'}>
          <Card className='search-card'>
            <CardBody>
              <ContentLoader style={{ width: '100%', height: '100%' }}>
                <rect x='10' y='7' rx='3' ry='3' width='80' height='10' />
                <rect x='10' y='30' rx='3' ry='3' width='1000' height='2' />

                <rect x='10' y='40' rx='3' ry='3' width='70' height='10' />
                <rect x='130' y='40' rx='3' ry='3' width='70' height='10' />
                <rect x='250' y='40' rx='3' ry='3' width='70' height='10' />
                <rect x='430' y='40' rx='3' ry='3' width='70' height='10' />
                <rect x='540' y='40' rx='3' ry='3' width='70' height='10' />
                <rect x='650' y='40' rx='3' ry='3' width='70' height='10' />

                <rect x='10' y='60' rx='3' ry='3' width='1000' height='2' />

                {[70, 100, 130, 160, 190, 220, 250, 280, 310, 340, 370, 400, 430].map((item) => (
                  <>
                    <rect x='10' y={item} rx='3' ry='3' width='70' height='8' />
                    <rect x='130' y={item} rx='3' ry='3' width='70' height='8' />
                    <rect x='250' y={item} rx='3' ry='3' width='110' height='8' />
                    <rect x='430' y={item} rx='3' ry='3' width='70' height='8' />
                    <rect x='540' y={item} rx='3' ry='3' width='70' height='8' />
                    <rect x='650' y={item} rx='3' ry='3' width='70' height='8' />

                    <rect x='10' y={item + 20} rx='3' ry='3' width='1000' height='1' />
                  </>
                ))}
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} sm={6} className={'pt-3 pr-md-5'}>
          <Card className='search-card'>
            <CardBody>
              <ContentLoader style={{ width: '100%', height: '100%' }}>
                <rect x='10' y='7' rx='3' ry='3' width='80' height='10' />
                <rect x='10' y='44' rx='3' ry='3' width='60' height='2' />
                <rect x='80' y='40' rx='3' ry='3' width='150' height='10' />
                <rect x='240' y='44' rx='3' ry='3' width='1000' height='2' />
                <rect x='10' y='70' rx='3' ry='3' width='15' height='15' />
                <rect x='40' y='73' rx='3' ry='3' width='120' height='8' />
                <rect x='10' y='110' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='110' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='110' rx='20' ry='20' width='230' height='230' />
                <rect x='10' y='370' rx='20' ry='20' width='230' height='230' />
                <rect x='260' y='370' rx='20' ry='20' width='230' height='230' />
                <rect x='510' y='370' rx='20' ry='20' width='230' height='230' />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SearchLoader
