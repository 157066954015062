import Row from 'components/atoms/layout/Row'
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isSaturday,
  isSunday,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns'
import { FC } from 'react'
import { Col } from 'reactstrap'

import { MeetingDashboard } from '../../../../types/Meeting'

import { locale } from './Calendar'
import './CalendarCells.scss'
import CalendarDateCell from './CalendarDateCell'

interface CalendarCellsProps {
  meetings: MeetingDashboard[]
  selectedDate: Date
  onSelectDate: (day: Date) => void
}

const CalendarCells: FC<CalendarCellsProps> = (props) => {
  const { meetings, selectedDate, onSelectDate } = props
  const monthStart = startOfMonth(selectedDate)
  const monthEnd = endOfMonth(monthStart)
  const startDate = startOfWeek(monthStart)
  let endDate = endOfWeek(monthEnd)

  const dateFormat = 'd'
  const rows = []

  let days = []
  let day = startDate
  let formattedDate = ''

  // DateFNS considère que le début d'une semaine est le dimanche et sa fin le samedi.
  // Puisque ces deux jours sont masqués du calendrier, on ajoute 7 jours au mois s'il commence par un dimanche et inversement s'il finit par un samedi pour éviter d'avoir une ligne entièrement grisée.
  if (isSaturday(monthStart)) {
    day = addDays(day, 7)
  }
  if (isSunday(monthEnd)) {
    endDate = subDays(endDate, 7)
  }

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat, { locale })
      const cloneDay = day

      const sortedMeetingList = meetings
        .filter((meeting) => isSameDay(cloneDay, meeting.start_date))
        .sort((prev) => (prev.invitations?.length ? -1 : 1))

      const dayId = `Popover-${formattedDate}-${day.getMonth()}`
      days.push(
        <CalendarDateCell
          key={dayId}
          id={dayId}
          label={formattedDate}
          isHidden={isWeekend(day)}
          isSameMonth={isSameMonth(day, monthStart)}
          isSelected={isSameDay(day, selectedDate)}
          meetingList={sortedMeetingList}
          day={day}
          onClick={() => onSelectDate(new Date(cloneDay))}
        />,
      )

      day = addDays(day, 1)
    }
    rows.push(
      <Col key={day.getTime()}>
        <Row grid gutters='xs' flexFill flexWrap='nowrap'>
          {days}
        </Row>
      </Col>,
    )
    days = []
  }

  return (
    <div className='CalendarCells'>
      <Row grid flexDirection='column' gutters='xs' flexFill flexWrap='nowrap'>
        {rows}
      </Row>
    </div>
  )
}

export default CalendarCells
