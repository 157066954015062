import { BASE_PATH } from 'api/api'
import React, { FC } from 'react'
import LibFileWiewer from 'react-file-viewer'
import { FileTypeEnum } from 'utils/constants/File'

import { DocumentType } from '../../../types/Document'

import ErrorComponent from './error/ErrorComponent'

interface FileViewerProps {
  file: DocumentType
  extension?: FileTypeEnum
  downloadUrl?: string
}

const FileViewer: FC<FileViewerProps> = (props) => {
  // On laisse la possibilité de surcharger l'extension et l'url de téléchargement en attendant d'homogénéiser la gestion des fichiers
  const { file, extension = file.extension, downloadUrl = BASE_PATH + file.url } = props

  return (
    <LibFileWiewer
      fileType={extension}
      filePath={downloadUrl}
      unsupportedComponent={() => <ErrorComponent extension={extension} />}
    />
  )
}

export default FileViewer
