import { setChonkyDefaults } from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'
import 'services/traductionService'

import history from './utils/history'

import './styles/app.scss'
import './styles/fontello/css/fontello.css'

import App from './App'

const queryClient = new QueryClient()
setChonkyDefaults({ iconComponent: ChonkyIconFA })

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
