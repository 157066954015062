import React, { FC, useState } from 'react'
import { Tooltip as RSTooltip, TooltipProps as RSTooltipProps } from 'reactstrap'

import './Tooltip.scss'

interface TooltipProps extends RSTooltipProps {
  className?: string
  forceClose?: boolean
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { className = '', forceClose = false, ...rest } = props
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(forceClose ? false : !tooltipOpen)

  return <RSTooltip className={`Tooltip ${className}`} isOpen={tooltipOpen} toggle={toggle} {...rest} />
}

export default Tooltip
