import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface ErrorComponentProps {
  extension: string
}
const ErrorComponent: FC<ErrorComponentProps> = (props) => {
  const { extension } = props
  const { t } = useTranslation()
  return <p className='font-weight-bold text-danger'>{t('documents.isNotSupported', { extension })}</p>
}

export default ErrorComponent
