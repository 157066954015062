export type UserCollegeType = keyof typeof USER_COLLEGE_ENUM

export const USER_COLLEGE_ENUM = {
  PATRONAL: {
    label: 'common.enums.college.patronal',
    shortLabel: 'common.enums.college.patronalShort',
  },
  SALARIE: {
    label: 'common.enums.college.salarie',
    shortLabel: 'common.enums.college.salarieShort',
  },
}

export const USER_COLLEGE = Object.entries(USER_COLLEGE_ENUM)
