import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Label } from 'reactstrap'

import { Association } from '../../../types/Association'

import TextInput from '../../atoms/input/TextInput'

interface AssociationSelectProps {
  onAssociationSelect: (associationId: number) => void
  associationList: Association[]
  className?: string
  associationId?: string
}
const AssociationSelectFilter: FC<AssociationSelectProps> = (props) => {
  const { associationList, onAssociationSelect, className = '', associationId } = props
  const { t } = useTranslation()

  const onSelectedValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const associationId = parseInt(event.target.value)
    onAssociationSelect(associationId)
  }

  return (
    <FormGroup className={className}>
      <Label>{t('common.associations')}</Label>
      <TextInput type='select' id='statusSelect' onChange={onSelectedValueChange} defaultValue={associationId || ''}>
        <option value=''>{t('forms.selectAssociation')}</option>
        {associationList.map((association) => (
          <option key={association.id} value={association.id}>
            {`${association.name}`}
          </option>
        ))}
      </TextInput>
    </FormGroup>
  )
}

export default AssociationSelectFilter
