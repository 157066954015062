import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { BreadcrumbItem, Col, FormGroup, Label } from 'reactstrap'

import { useQuery } from '../utils/hooks/useQuery'

import { Article } from '../types/Article'
import { NotificationInterface, NotificationType } from '../types/Notification'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import TextInput from '../components/atoms/input/TextInput'
import Row from '../components/atoms/layout/Row'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import ArticleLoader from '../components/molecules/cards/article/ArticleLoader'
import CardArticle from '../components/molecules/cards/article/CardArticle'
import Header from '../components/molecules/heading/Header'

import { articleService } from '../services/articlesService'
import { notificationService } from '../services/notificationService'
import { NotificationStore } from '../store/NotificationStore'

const ArticlesView: FC = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { isLoading, data: articles = [] } = useQuery<Article[]>({
    queryKey: ['article', 'getFilteredArticles', 1, search],
    queryFn: () => articleService.getFilteredArticles(search),
    keepPreviousData: true,
  })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const queryClient = useQueryClient()
  const getNotificationsKey = ['user', 'getUserNotifications']

  const deleteNotifications = async () => {
    const notifications = NotificationStore.getState()
    const boardNotifications = notifications.filter(
      (notification) => [NotificationType.ARTICLE_ADDED].includes(notification.type) && !notification.view,
    )

    const now = new Date()
    if (boardNotifications.length > 0) {
      boardNotifications.forEach((notification) => {
        notificationService.update(notification.id, { view: now }).then(() => {
          queryClient.setQueryData(
            getNotificationsKey,
            notifications.map((item: NotificationInterface) => {
              if (item.type === NotificationType.ARTICLE_ADDED) {
                return {
                  ...item,
                  view: now,
                }
              }
              return item
            }),
          )
        })
      })
    }
  }

  useEffect(() => {
    deleteNotifications()
  }, [])

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>{t('nav.news')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
          <Row>
            <Col sm={12} lg={{ size: 6, offset: 3 }} className='mx-auto justify-items-end'>
              <FormGroup className='mb-0 py-2 py-lg-0'>
                <Label>{t('kiosk.article.filter.label')}</Label>
                <TextInput
                  name='search'
                  placeholder={t('kiosk.article.filter.placeholder')}
                  className='shadow-none'
                  value={search}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {isLoading && <ArticleLoader />}
          <Row grid xs={1} md={2} lg={3}>
            {articles.map((article) => (
              <Col key={article.id}>
                <CardArticle {...article} />
              </Col>
            ))}
          </Row>
        </Section>
      </ViewBody>
    </View>
  )
}

export default ArticlesView
