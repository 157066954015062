import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PATHS } from '../../../utils/constants/routes/RoutePaths'

import Heading from '../heading/Heading'
import KioskArticles, { kioskArticlesList } from '../kiosk/kiosk-articles/KioskArticles'

interface KioskSectionProps {
  className?: string
}

const KioskSection: FC<KioskSectionProps> = () => {
  const { t } = useTranslation()

  const link = {
    href: PATHS.KIOSK.HOME,
    label: t('dashboard.seeAllRubrics'),
  }
  return (
    <>
      <Heading className='my-5' title={t('nav.kiosk')} />
      {kioskArticlesList.length > 0 && <KioskArticles kioskArticles={kioskArticlesList} previewMode />}
      <Heading title={t(' ')} link={link} arrowAsLinkBreakpoint='sm' />
    </>
  )
}

export default KioskSection
