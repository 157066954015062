import Button from 'components/atoms/button/Button'
import React, { FC, Key, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import memberService, { MemberPerimeterType } from 'services/memberService'
import { InstancesWithPerimeterAndRights } from 'types/Instance'
import { MemberFormValues } from 'types/Member'
import { notify, notifyError, notifySuccess } from 'utils/alertUtils'
import { getAllInstancesAsDataNodeTypeForManager } from 'utils/instances'

import InstancesTreeInput from './InstancesTreeInput'

// On filtre les associations du périmètre sélectionné
export const buildPerimeterData = (perimeterKeys: Key[]): Key[] => {
  return perimeterKeys.filter((perimeter) => !perimeter.toString().startsWith('asso'))
}
interface MandateFormProps {
  data: InstancesWithPerimeterAndRights
  userId: number
  refetchMembers: () => void
  toggle: () => void
}

const MemberForm: FC<MandateFormProps> = ({ data, userId, refetchMembers, toggle }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, setValue } = useForm<MemberPerimeterType>({
    mode: 'onSubmit',
  })

  const { mutate } = useMutation((data: Partial<MemberFormValues>[]) => memberService.createMany(data), {
    mutationKey: ['member', 'createMany'],
    onSuccess: (newMemberList) => {
      if (newMemberList.length) {
        notifySuccess(t('toastify.success.createMandate'))
      } else {
        notify(t('toastify.info.noNewMandateCreated'))
      }
      toggle()
      refetchMembers()
    },
    onError: () => notifyError(t('toastify.errors.create.member')),
  })

  const submit = (values: MemberPerimeterType) => {
    const members: Partial<MemberFormValues>[] = values.perimeter_list.map((instanceId) => ({
      instance: instanceId,
      users_permissions_user: userId,
    }))
    mutate(members)
  }

  const [checkedPerimeterList, setCheckedPerimeterList] = useState<Key[]>([])

  const instancesTreeData = getAllInstancesAsDataNodeTypeForManager(
    {
      allInstances: data.allInstances,
      managerRights: data.managerRights,
    },
    { ...data.userPerimeter },
  )

  useEffect(() => {
    // TODO Nico: Voir input pouvant stocker les valeurs dans un array
    register('perimeter_list')
    const perimeterData = buildPerimeterData(checkedPerimeterList)
    setValue('perimeter_list', perimeterData)
  }, [register, setValue, checkedPerimeterList])

  const onChangePerimeterList = (checkedPerimeter: Key[]) => setCheckedPerimeterList(checkedPerimeter)

  return (
    <div>
      <InstancesTreeInput
        checkedPerimeterList={checkedPerimeterList}
        onChangePerimeterList={onChangePerimeterList}
        instancesTreeData={instancesTreeData}
      />
      <Button label={t('mandates.addMandate.addButton')} onClick={handleSubmit(submit)} />
    </div>
  )
}

export default MemberForm
