import { ChonkyActions, FileData, FileHelper, I18nConfig } from 'chonky'
import { IntlShape } from 'react-intl'
import { Nullable } from 'tsdef'

export const frenchI18n: I18nConfig = {
  locale: 'fr',
  formatters: {
    formatFileModDate: (intl: IntlShape, file: Nullable<FileData>): string | null => {
      const safeModDate = FileHelper.getModDate(file)
      if (safeModDate) {
        return `${safeModDate.getDay()}/${safeModDate.getMonth()}/${safeModDate.getFullYear()}`
      } else {
        return null
      }
    },
    formatFileSize: (intl: IntlShape, file: Nullable<FileData>): string | null => {
      if (!file || typeof file.size !== 'number') return null
      return `Taille: ${file.size}`
    },
  },
  messages: {
    'chonky.toolbar.searchPlaceholder': 'Rechercher',
    'chonky.toolbar.visibleFileCount': `{fileCount, plural,
            =0 {# élément}
            one {# élément}
            other {# éléments}
        }`,
    'chonky.toolbar.selectedFileCount': `{fileCount, plural,
            =0 {}
            one {# sélectionné}
            other {# sélectionnés}
        }`,
    'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
            =0 {}
            one {# caché}
            other {# cachés}
        }`,
    'chonky.fileList.nothingToShow': 'Aucun dossiers ou fichiers',
    'chonky.contextMenu.browserMenuShortcut': 'Raccourci menu: {shortcut}',

    // File action translation strings. These depend on which actions you have
    // enabled in Chonky.
    [`chonky.actionGroups.Actions`]: 'Actions',
    [`chonky.actionGroups.Options`]: 'Options',
    [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: 'Revenir au parent',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: 'Créer un dossier',
    [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: 'Créer un dossier',
    [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: 'Supprimer le/les fichier(s) sélectionné(s)',
    [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: 'Télécharger le/les fichier(s) sélectionné(s)',
    [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: 'Sélectionner tous les fichiers',
    [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: 'Désélectionner tout',
    [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: 'Vue liste',
    [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: 'Vue block',
    [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: 'Tri par nom',
    [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: 'Tri par taille',
    [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: 'Tri par date',
    [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: 'Afficher les fichiers cachés',
    [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: 'Afficher les dossiers en premier',
  },
}

export const ATTACHED_DOCUMENT_KEY = 'attached-document'
export const ATTACHED_DOCUMENT_ROOT_INDEX_KEY = 'attached-document-root-index'
