import { useStore } from 'effector-react'

import { Instance } from '../../types/Instance'
import { User } from '../../types/User'

import { instanceService } from '../../services/instanceService'
import { loginService } from '../../services/loginService'
import { userService } from '../../services/userService'
import { setInstanceList } from '../../store/InstanceListStore'
import { UserStore } from '../../store/UserStore'
import { useQuery } from './useQuery'

interface useUpdateUserReturnValue {
  isLoading: boolean
  isLoggedIn: boolean
}

export const useUpdateStore = (): useUpdateUserReturnValue => {
  const userStore = useStore(UserStore)

  // GET User me
  const { isLoading: isUserLoading } = useQuery<User>({
    queryKey: ['user', 'getMe'],
    queryFn: userService.getMe,
    onSuccess: async (user) => {
      loginService.setConnectedUser(user)
    },
    cacheTime: 0,
  })

  const { isLoading: isInstanceListLoading } = useQuery<Instance[]>({
    queryKey: ['instance', 'getAll'],
    queryFn: instanceService.getAll,
    onSuccess: (instanceList) => {
      setInstanceList(instanceList)
    },
    cacheTime: 0,
    enabled: !!userStore, //On initialise les instances seulement si l'utilisateur est connecté
  })

  return { isLoading: isUserLoading || isInstanceListLoading, isLoggedIn: !!userStore }
}
