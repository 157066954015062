import { isBefore, isValid } from 'date-fns'
import { useStore } from 'effector-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { generatePath, useHistory } from 'react-router'
import { CardBody, CardHeader, Col } from 'reactstrap'
import { isMixedChannelInstance } from 'utils/instances'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'
import { ABSENCE_STATUS, AbsenceStatusKey, INVITATION_STATUS, MANAGER_STATUTES } from '../../../utils/constants/Status'
import { PATHS } from '../../../utils/constants/routes/RoutePaths'
import { parseFullDateAsDate } from '../../../utils/dateUtils'
import { usePermissions } from '../../../utils/hooks/usePermissions'
import { isMandateCurrent } from '../../../utils/mandateUtils'

import { AbsenceOption } from '../../../types/AbsenceOption'
import { Invitation } from '../../../types/Invitation'
import { InvitationStatusType } from '../../../types/InvitationStatus'
import { Meeting, MeetingStatus } from '../../../types/Meeting'
import { getMandateStatusLabel, MemberStatusValues } from '../../../types/MemberStatus'

import { invitationService } from '../../../services/invitationService'
import { UserStore } from '../../../store/UserStore'
import Button from '../../atoms/button/Button'
import Card from '../../atoms/card/Card'
import UserName from '../../atoms/custom/UserName'
import Row from '../../atoms/layout/Row'
import Popover from '../../atoms/popover/Popover'
import PopoverBody from '../../atoms/popover/popover-body/PopoverBody'
import TooltipWithLabel from '../../atoms/tooltip/TooltipWithLabel'
import DropdownStatus from '../dropdown/dropdown-status/DropdownStatus'
import ModalAbsence from '../modals/meetings/ModalAbsence'
import './ParticipantCard.scss'

export interface ParticipantCardProps {
  title?: string
  meeting: Meeting
  instanceId: number
  absence_options?: string | null
  invitations: Invitation[]
  canManageParticipants?: boolean
}
const ParticipantCard: FC<ParticipantCardProps> = (props) => {
  const { title, meeting, instanceId, absence_options, invitations, canManageParticipants = false } = props
  const { t } = useTranslation()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [hoveredStatus, setHoveredStatus] = useState<InvitationStatusType>()
  const { MEETINGS_INVITATIONS_CAN_VIEW_NOT_SENT } = usePermissions()
  const canViewNotSent = MEETINGS_INVITATIONS_CAN_VIEW_NOT_SENT(meeting)

  const userStore = useStore(UserStore)
  const userId = userStore?.id || 0
  const userInvitation = invitations.find((invitation) => invitation.user.id === userId)
  const userMandate = userInvitation?.user.members.find(
    (mandate) => isMandateCurrent(mandate.start_date, mandate.end_date) && (mandate.instance as unknown) === instanceId,
  )

  const selectedStatus = userInvitation && INVITATION_STATUS[userInvitation.status]
  const filteredInvitations = invitations.filter((invitation) => invitation.status === hoveredStatus)
  const meetingDate = parseFullDateAsDate(`${meeting.start_date} ${meeting.start_time}`)
  const hasGivePower = !!absence_options?.includes(AbsenceOption.POWER)
  const hasReplacement = !!absence_options?.includes(AbsenceOption.REPLACEMENT)
  const postponed = meeting.status === MeetingStatus.CANCELED
  const queryCache = useQueryClient()
  const isOpco2iAGMeeting =
    meeting.instance.short_name === 'AG' && meeting.instance.association?.short_name === 'OPCO 2i'

  // Conditions pour que soit proposé à l'utilisateur tout le workflow pour donner
  // son absence. Il doit avoir un mandat et être TITULAIRE ou ne pas avoir de statut spécifié.
  // Comme le statut peut être null (EXTERNE) ou undefined (non spécifié), et
  // qu'on doit se servir de l'enum et qu'on ne peut pas utiliser undefined pour
  // indexer, on se sert du label pour comparer).
  const isUserStatusTitulaireOrUnspecified =
    userMandate &&
    (getMandateStatusLabel(userMandate.status) === MemberStatusValues.TITULAIRE.label ||
      getMandateStatusLabel(userMandate.status) === MemberStatusValues.UNSPECIFIED.label)
  const isMeetingExpired = !meetingDate || (isValid(meetingDate) && isBefore(meetingDate, new Date()))

  const { mutate } = useMutation(
    ({ invitationId, status }: { invitationId: number; status: InvitationStatusType }) =>
      invitationService.updateInvitation(invitationId, { status }),
    {
      onSuccess: async (res, values) => {
        if (INVITATION_STATUS[values.status] !== INVITATION_STATUS.ABSENT || (!hasGivePower && !hasReplacement)) {
          notifySuccess(t('toastify.success.meetings.updateInvitationStatus'))
        }

        return await queryCache.refetchQueries([`meeting getOne ${meeting.id}`])
      },
      onError: () => notifyError(t('toastify.errors.update.invitation')),
    },
  )

  const results = invitations.reduce(
    (acc, participant) => {
      const status = participant.status
      const value = acc[status] || 0
      return {
        ...acc,
        [status]: value + 1,
      }
    },
    { PRESENT_REMOTE: 0, PRESENT_IN_PERSON: 0, ABSENT: 0, PENDING: 0, NOT_SENT: 0, PRESENT: 0 },
  )

  const onDropdownItemClick = (status: InvitationStatusType) => {
    // On ne lance l'appel ws que si l'utilisateur est bien invité et que le
    // statut change
    if (userInvitation && status !== userInvitation?.status) {
      mutate({ invitationId: userInvitation.id, status })
      void queryCache.resetQueries(['user', 'getUserMeetings'])

      // S'il se déclare absent et qu'il est titulaire, alors on affiche le
      // workflow pour donner son absence
      if (
        isUserStatusTitulaireOrUnspecified &&
        INVITATION_STATUS[status] === INVITATION_STATUS.ABSENT &&
        !isMeetingExpired
      ) {
        setOpen(true)
      }
    }
  }

  const onButtonClick = () => history.push(generatePath(PATHS.MEETINGS.MEETINGS_PARTICIPANTS, { id: meeting.id }))
  const onPopoverHide = () => setHoveredStatus(undefined)
  const onModalToggle = () => setOpen(false)

  const isStatusHidden =
    userInvitation?.status && selectedStatus && selectedStatus === INVITATION_STATUS.NOT_SENT && !canViewNotSent
  const userAbsenceInfos = {
    parent: {
      POWER: 'meeting.invitations.parent.power',
      REPLACEMENT: 'meeting.invitations.parent.replacement',
    },
    absence_children: {
      POWER: 'meeting.invitations.absenceChildren.power',
      REPLACEMENT: 'meeting.invitations.absenceChildren.replacement',
    },
  }
  const computedUserInvitation = {
    ...userInvitation,
    // on peut avoir plusieurs parents, si par exemple je remplace plusieurs personnes
    parent: invitations.filter((invit) => invit.absence_children?.id === userInvitation?.id),
    // on ne peut avoir qu'un seul children, je ne me fait remplacer que par une seule personne (idem pour la passation de pouvoir)
    absence_children: invitations.find((invit) => invit?.id === userInvitation?.absence_children?.id),
  }

  return (
    <Card className='ParticipantCard' banner bannerColor='primary'>
      <CardHeader>
        <div className='d-flex justify-content-between align-items-center'>
          <h2 className='BannerContent'>{title}</h2>
          {canManageParticipants && <Button onClick={onButtonClick}>{t('meeting.invitations.header')}</Button>}
        </div>
      </CardHeader>
      <CardBody>
        <Row className='pt-3'>
          <Col xs={6} id='participants'>
            <ul>
              {MANAGER_STATUTES.map((status) => {
                const [statusKey, statusObject] = status

                return (
                  (canManageParticipants || statusObject !== INVITATION_STATUS.NOT_SENT) && (
                    <li
                      key={statusKey}
                      className='mb-1'
                      id={statusKey}
                      onMouseEnter={() => setHoveredStatus(statusKey)}
                      onMouseLeave={onPopoverHide}
                    >
                      {`${results[statusKey]} ${t(isOpco2iAGMeeting ? 'common.delegate' : 'common.participant', {
                        count: results[statusKey],
                      }).toLowerCase()}`}
                      <span className={`text-${statusObject.color}`}>
                        {` ${t(statusObject.label, { count: results[statusKey] }).toLowerCase()}`}
                      </span>
                    </li>
                  )
                )
              })}
            </ul>
          </Col>
          {filteredInvitations.length > 0 && (
            <Popover
              key={hoveredStatus}
              isOpen={!!hoveredStatus}
              target={hoveredStatus || 'participants'}
              placement='right'
            >
              <PopoverBody>
                <Col xs={12}>
                  {filteredInvitations
                    .sort((invitation1, invitation2) =>
                      invitation1.user.lastname > invitation2.user.lastname ? 0 : -1,
                    )
                    .map((invitation) => {
                      return (
                        <Row key={`popover_${invitation.id}`} className='align-items-center my-2'>
                          <UserName user={invitation.user} />
                        </Row>
                      )
                    })}
                </Col>
              </PopoverBody>
            </Popover>
          )}
        </Row>
        <Row className='pt-3'>
          {!userInvitation?.status && <p className='mx-auto'>{t('meeting.notInvited')}</p>}
          {!isStatusHidden && selectedStatus && (
            <>
              <div className='w-100 d-flex justify-content-center'>
                <DropdownStatus
                  label={t(selectedStatus.label)}
                  color={selectedStatus.color}
                  onClick={onDropdownItemClick}
                  mixedChannel={isMixedChannelInstance(instanceId)}
                  disabled={isMeetingExpired || postponed}
                />
              </div>
              <div className='d-block mx-auto'>
                {userInvitation &&
                  Object.entries(userAbsenceInfos).map(([field, value]) => {
                    // si je reçois le pouvoir, c'est l'invitation parent qui m'intéresse, sinon
                    // si c'est moi qui donne le pouvoir alors l'invitation qui m'intéresse est la mienne
                    const mainInvitation: unknown[] =
                      field === 'parent' ? computedUserInvitation.parent : [computedUserInvitation]
                    const childInvitation = computedUserInvitation.absence_children as Invitation

                    return mainInvitation?.map((unknownInvitation: unknown) => {
                      const invitation = unknownInvitation as Invitation
                      const invit = field === 'parent' ? invitation : childInvitation
                      return (
                        invitation.absence_option &&
                        value[invitation.absence_option] && (
                          <TooltipWithLabel
                            className='align-items-center ml-3'
                            target={`Tooltip${invitation.id}`}
                            placement='top'
                            label={
                              <p
                                className={`text-${
                                  ABSENCE_STATUS[invitation.absence_validation as AbsenceStatusKey].color
                                }`}
                              >
                                {t(value[invitation.absence_option], {
                                  firstname: invit?.user?.firstname,
                                  lastname: invit?.user?.lastname,
                                })}
                              </p>
                            }
                            autohide={false}
                          >
                            <p>{t(ABSENCE_STATUS[invitation.absence_validation as AbsenceStatusKey].label)}</p>
                          </TooltipWithLabel>
                        )
                      )
                    })
                  })}
              </div>
            </>
          )}
        </Row>
        {userInvitation && open && (
          <ModalAbsence
            open={open}
            meetingId={meeting.id}
            instanceId={instanceId}
            invitation={userInvitation}
            hasGivePower={hasGivePower}
            hasReplacement={hasReplacement}
            onModalToggle={onModalToggle}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default ParticipantCard
