import _ from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { BreadcrumbItem, Col } from 'reactstrap'

import { notify, notifySuccess } from '../utils/alertUtils'
import { mailHistoryFilter } from '../utils/constants/Contacts'
import { SEARCH_DEBOUNCE_DELAY } from '../utils/constants/Filters'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'

import { ForgotPasswordValuesType } from '../types/Auth'
import { MailFilterType } from '../types/Filters'
import { EmailHistory, MailHistory } from '../types/MailHistory'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import InputWithLabel from '../components/atoms/input/InputWithLabel'
import Row from '../components/atoms/layout/Row'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Spinner from '../components/atoms/spinner/Spinner'
import Table from '../components/atoms/table/Table'
import Header from '../components/molecules/heading/Header'
import ModalMailDisplay from '../components/molecules/modals/mail/ModalMailDisplay'
import Reducer from '../components/molecules/reducer/Reducer'

import { mailService } from '../services/MailService'
import { loginService } from '../services/loginService'

const MailHistoryView: FC = () => {
  const { t } = useTranslation()
  const { CONTACTS_CAN_MANAGE } = usePermissions()
  const [loadMore, setLoadMore] = useState(true)
  const [mailFilters, setMailFilters] = useState<MailFilterType>(mailHistoryFilter)
  const [selectedMail, setSelectedMail] = useState<MailHistory>()

  const { data: meetingList = [], isLoading: areMeetingsLoading } = useQuery<EmailHistory[]>({
    queryKey: ['user', 'getUserMeetings', mailFilters],
    queryFn: async (): Promise<EmailHistory[]> => {
      const freshMeetingList = await mailService.getMailHistoryForgottenPasswordWithFilters(mailFilters)

      setLoadMore(
        !(freshMeetingList.mails.length && mailFilters.page + freshMeetingList.mails.length === freshMeetingList.count),
      )
      if (mailFilters.page == 0) return freshMeetingList.mails
      else return meetingList.concat(freshMeetingList.mails)
    },
    onError: () => notify(t('toastify.errors.get.meeting'), 'error'),
    cacheTime: 0,
    keepPreviousData: loadMore,
  })

  const { mutate } = useMutation(({ email }: ForgotPasswordValuesType) => loginService.forgotPassword(email), {
    mutationKey: ['auth', 'forgotPassword'],
    onSettled: () => {
      notifySuccess(t('toastify.success.forgotPassword'), {
        autoClose: 6000,
      })
    },
  })

  const onSearchInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onSearchChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )
  const onSearchChange = (value: string) => {
    setLoadMore(true)
    setMailFilters((prevState) => ({
      ...prevState,
      recipient: value,
      page: 0,
    }))
  }

  const history = useHistory()

  if (!CONTACTS_CAN_MANAGE()) {
    history.push('/')
  }
  const loadMoreHandle = () => {
    !areMeetingsLoading &&
      loadMore &&
      setMailFilters((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 10,
        }
      })
  }

  const onMailClicked = (mail: EmailHistory) => {
    mutate({ email: mail.destination_email })
  }
  const onModalClose = () => {
    setSelectedMail(undefined)
  }

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Row className='align-items-center' grid>
            <Col>
              <Header className='flex-column flex-sm-row'>
                <Breadcrumb>
                  <BreadcrumbItem>{t('nav.forgotPassword')}</BreadcrumbItem>
                </Breadcrumb>
              </Header>
            </Col>
          </Row>
          <Reducer label={t('meeting.filters.filter')}>
            <Row>
              <Col xs={12} lg={3}>
                <InputWithLabel
                  name={'contact-filter-fullSearch'}
                  type='text'
                  placeholder={t('email.filters.recipient')}
                  labelText={t('email.filters.recipient').toUpperCase()}
                  onChange={onSearchInputChange}
                  labelClassName='font-weight-normal'
                />
              </Col>
            </Row>
          </Reducer>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {meetingList && meetingList.length > 0 ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th scope='col'>{t('common.emailHistory.recipient')}</th>
                    <th scope='col'>{t('common.emailHistory.sendDate')}</th>
                    <th scope='col'>{t('common.emailHistory.etat')}</th>
                    <th scope='col'>{t('common.emailHistory.actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {meetingList.map((meeting) => (
                    <tr key={meeting.created_at}>
                      <td>{meeting.destination_email}</td>
                      <td>{new Date(meeting.created_at).toLocaleString()}</td>
                      <td>{meeting.success}</td>
                      <td className={'d-flex'}>
                        <Button className={'mr-3'} onClick={() => onMailClicked(meeting)}>
                          {t('common.emailHistory.resend')}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {loadMore && (
                <Button
                  className='d-block mx-auto mt-3'
                  onClick={loadMoreHandle}
                  label={t('documents.filters.seeMoreButton.label')}
                />
              )}
            </>
          ) : (
            <>{areMeetingsLoading ? <Spinner /> : <h3 className='mt-3 text-center'>{t('common.noResult')}</h3>}</>
          )}
          {selectedMail && (
            <ModalMailDisplay
              isOpen={!!selectedMail}
              toggle={onModalClose}
              mail={selectedMail}
              isEditable={false}
              answerMode={false}
            />
          )}
        </Section>
      </ViewBody>
    </View>
  )
}

export default MailHistoryView
