import { DataNode } from 'antd/lib/tree'
import React, { FC, Key } from 'react'

import Tree from '../../atoms/tree/Tree'

interface InstancesTreeInputProps {
  checkedPerimeterList: Key[]
  onChangePerimeterList: (checkedPerimeter: Key[]) => void
  instancesTreeData?: DataNode[]
  className?: string
}

const InstancesTreeInput: FC<InstancesTreeInputProps> = ({
  checkedPerimeterList,
  onChangePerimeterList,
  instancesTreeData = [],
  className = '',
}) => {
  const onCheck = (checked: Key[]) => onChangePerimeterList(checked)

  if (!instancesTreeData?.length) {
    return <></>
  }

  return (
    <Tree
      className={`bg-transparent ${className}`}
      treeData={instancesTreeData}
      defaultExpandAll
      checkable={true}
      onCheck={onCheck}
      selectable={false}
      autoExpandParent={true}
      checkedKeys={checkedPerimeterList}
    />
  )
}

export default InstancesTreeInput
