import Icon from 'components/atoms/icon/Icon'
import { FC, useState } from 'react'

import './Navbar.scss'
import NavbarHeader from './navbar-header/NavbarHeader'
import SideNavbar from './side-navbar/SideNavbar'

const Navbar: FC = () => {
  const [open, setOpen] = useState(false)

  const onBurgerMenuClick = () => setOpen((prevState) => !prevState)
  const closeSideMenu = () => setOpen(false)

  return (
    <nav className='Navbar'>
      <div className='NavbarHead'>
        <NavbarHeader />
        <div className='NavbarToggler px-4 d-block d-lg-none text-center cursor-pointer' onClick={onBurgerMenuClick}>
          <Icon name='menu' className='text-xl' />
        </div>
      </div>
      <div className='NavbarBody'>
        <SideNavbar open={open} closeSideMenu={closeSideMenu} />
      </div>
    </nav>
  )
}

export default Navbar
