import React, { FunctionComponent } from 'react'

import './FigureAcronyme.scss'

type FigureAcronymeProps = {
  children: string
}

const FigureAcronyme: FunctionComponent<FigureAcronymeProps> = ({ children }) => {
  return <span className={`FigureAcronyme`}>{children}</span>
}

export default FigureAcronyme
