import Icon from 'components/atoms/icon/Icon'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import './Heading.scss'

interface Props {
  className?: string
  title?: string
  link?: {
    href: string
    label: string
  }
  arrowAsLinkBreakpoint?: 'sm' | 'md' | 'lg' | 'xl'
}

const Heading: FC<Props> = ({ className, title, link, arrowAsLinkBreakpoint }) => {
  if (!title && (!link || !arrowAsLinkBreakpoint)) {
    return null
  }

  const containerClassName = `Heading${className ? ` ${className}` : ''}`

  const additionalClassNames = {
    icon: arrowAsLinkBreakpoint ? ` d-block d-${arrowAsLinkBreakpoint}-none` : ' d-none',
    span: arrowAsLinkBreakpoint ? `d-none d-${arrowAsLinkBreakpoint}-block` : '',
  }

  return (
    <div className={containerClassName}>
      {title && <h2 className='title'>{title}</h2>}
      {link?.href && (link.label || arrowAsLinkBreakpoint) && (
        <Link to={link.href} title={link.label}>
          <Icon name='right-open' className={`${additionalClassNames['icon']}`} />
          <span className={additionalClassNames['span']}>{link.label}</span>
        </Link>
      )}
    </div>
  )
}

export default Heading
