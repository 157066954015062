import Dropzone from 'components/atoms/dropzone/Dropzone'
import Section from 'components/atoms/layout/Section'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

import { fetchFileFromUrl } from '../../../utils/fileUtils'

import { DocumentType } from '../../../types/Document'

import AttachmentCard from '../documents/AttachmentCard'
import './ConvocationFileInput.scss'

interface ConvocationFileInputProps {
  convocationFilePreview?: File
  convocationRecord?: DocumentType
  onAddPreview: (file: File) => void
  onDeletePreview: () => void
}

const ConvocationFileInput: FC<ConvocationFileInputProps> = ({
  convocationFilePreview,
  convocationRecord,
  onAddPreview,
  onDeletePreview,
}) => {
  const onDropzoneAddFile = (filesList: File[]) => {
    const file = filesList[0]
    onAddPreview(file)
  }

  const { t } = useTranslation()
  const [convocationFile, setConvocationFile] = useState<File>()

  useEffect(() => {
    if (convocationRecord) {
      fetchFileFromUrl(convocationRecord.url, convocationRecord.name).then((file) => setConvocationFile(file))
    }
  }, [convocationRecord])

  return (
    <Section fluid className='ConvocationFileInput'>
      <Col xs={12}>
        {convocationFilePreview && <AttachmentCard file={convocationFilePreview} onDeleteFile={onDeletePreview} />}
        {!convocationFilePreview && convocationFile && <AttachmentCard file={convocationFile} />}
      </Col>
      <Dropzone
        label={t('meeting.convocation.dropzoneLabel')}
        addNewFiles={onDropzoneAddFile}
        // .doc, .docx, .pdf cf https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        accept={[
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
        ]}
        maxFiles={1}
      />
    </Section>
  )
}

export default ConvocationFileInput
