import View from 'components/atoms/layout/View'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { loginService } from 'services/loginService'
import { userService } from 'services/userService'
import { StrapiError } from 'types/Errors'
import { tokenEmailUserUpdate } from 'types/User'
import { notifyError, notifySuccess } from 'utils/alertUtils'

import { UserStore } from '../store/UserStore'

interface EmailUpdateParamsProps {
  slug: string
}

const ValidateEmailView: FC = () => {
  const { slug }: EmailUpdateParamsProps = useParams()
  const { t } = useTranslation()

  const { mutate } = useMutation((validationData: tokenEmailUserUpdate) => userService.validateEmail(validationData), {
    mutationKey: ['me', 'validateEmail'],
    onSuccess: () => {
      notifySuccess(t('toastify.success.activateNewEmail'))
    },
    onError: (error: StrapiError) => {
      notifyError(error.data?.message ? t(error.data.message) : t('toastify.errors.activate.email'))
    },
  })

  const [canMutate, setCanMutate] = useState(false)

  //request is sent only if the user is not connected, to avoid double-requesting during disconnection
  useEffect(() => {
    if (UserStore.getState() == null && canMutate) {
      mutate({
        validateEmailToken: slug,
      })
    } else {
      loginService.logout()
      setCanMutate(true)
    }
  }, [canMutate])

  return (
    <View>
      <div style={{ width: '100px', height: '1OOpx' }} className='text-center'>
        <div className='spinner-border text-primary' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </View>
  )
}

export default ValidateEmailView
