import _ from 'lodash'
import React, { ChangeEvent, FC, ReactText, SyntheticEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { MeetingUpdate } from 'types/Meeting'

import { SEARCH_DEBOUNCE_DELAY } from '../../../../utils/constants/Filters'
import { InputDataNode } from '../../../../utils/treeUtils'

import { DocumentType } from '../../../../types/Document'

import Button from '../../../atoms/button/Button'
import Icon from '../../../atoms/icon/Icon'
import IconSvg from '../../../atoms/icon/IconSvg'
import TextInput from '../../../atoms/input/TextInput'
import Row from '../../../atoms/layout/Row'
import { FileBrowser } from '../../file-browser/FileBrowser'
import './OrderOfTheDay.scss'
import OrderOfTheDayTitle from './OrderOfTheDayTitle'

interface OrderOfTheDayManagerTreeItem {
  updatedMeeting: MeetingUpdate
  meetingId: number
  inputDataNode: InputDataNode
  onDelete?: (key: ReactText) => void
  onAdd?: (parent: InputDataNode) => void
  rootFileKeyList: DocumentType[]
  toggleModal: (treeItem: InputDataNode) => void
  openPreviewDocument?: (file: DocumentType) => void
  onItemChange?: (item: InputDataNode) => void
  onKeyExpand?: (key: ReactText) => void
  setCurrentDirectoryByTree?: (currentDirectory: { [key: string]: string }) => void
  currentDirectoryByTree?: { [key: string]: string }
}

const OrderOfTheDayManagerTreeItem: FC<OrderOfTheDayManagerTreeItem> = (props) => {
  const {
    updatedMeeting,
    inputDataNode: item,
    onDelete,
    onAdd,
    toggleModal,
    onItemChange,
    onKeyExpand,
    setCurrentDirectoryByTree,
    currentDirectoryByTree,
    meetingId,
  } = props

  const { register } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: item.title,
    },
  })
  const { t } = useTranslation()
  const onTitleClick = (event: SyntheticEvent) => {
    event.preventDefault()
    onKeyExpand && onKeyExpand(item.key)
  }

  // On debounce pour éviter de rerender trop souvent quand l'input change
  const onTitleChange = _.debounce((e: ChangeEvent<HTMLInputElement>) => {
    onItemChange &&
      onItemChange({
        ...item,
        title: e.target.value,
      })
  }, SEARCH_DEBOUNCE_DELAY)

  const { level = 0, key } = item

  return (
    <Col>
      <Row className='d-flex'>
        <Col auto className='d-flex'>
          <OrderOfTheDayTitle item={item} showTitle={false} onClick={onTitleClick} />
          <TextInput
            innerRef={register}
            name='title'
            onChange={onTitleChange}
            className='text-secondary align-self-center ml-3'
          />
          <Button
            className='add-file-button'
            title={t('meeting.documents.manage')}
            outline
            color={'primary'}
            onClick={() => toggleModal(item)}
          >
            <Icon name='documents' />
          </Button>
          {onAdd && level < 2 && (
            <p onClick={() => onAdd(item)} className='Picto'>
              <IconSvg name='Plus' size={'2'} />
            </p>
          )}
          {onDelete && (
            <p onClick={() => onDelete(key)} className='Picto'>
              <IconSvg name='Minus' size={'2'} />
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <FileBrowser
            meetingId={meetingId}
            updatedMeeting={updatedMeeting}
            newFileMap={(fileMap) => {
              if (onItemChange && item?.fileBrowserData) {
                onItemChange({
                  ...item,
                  fileBrowserData: {
                    ...item.fileBrowserData,
                    fileMap,
                  },
                })
              }
            }}
            dataFiles={item?.fileBrowserData}
            instanceId={item.key?.toString()}
            getCurrentFolderId={(currentFolder) => {
              if (currentDirectoryByTree && setCurrentDirectoryByTree && item.key) {
                setCurrentDirectoryByTree({
                  ...currentDirectoryByTree,
                  [item.key]: currentFolder,
                })
              }
            }}
          />
        </Col>
      </Row>
    </Col>
  )
}

export default OrderOfTheDayManagerTreeItem
