import * as Yup from 'yup'

import { passwordRegex } from './RegexConstants'

export const loginValidationSchema = Yup.object().shape({
  identifier: Yup.string().email('common.validation.email').required('common.validation.required'),
  password: Yup.string().required('common.validation.required'),
})

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('common.validation.email').required('common.validation.required'),
})

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().matches(passwordRegex, 'common.validation.password').required('common.validation.required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'common.validation.passwordConfirmation')
    .required('common.validation.required'),
})
