import TextInput from 'components/atoms/input/TextInput'
import React, { ChangeEvent, FC, Ref } from 'react'
import { useTranslation } from 'react-i18next'
import { Member } from 'types/Member'
import { MEMBER_STATUS } from 'utils/constants/MemberStatus'

import { MemberStatusEnum } from '../../../types/MemberStatus'

interface MemberStatusCellProps {
  id: string
  register: Ref<HTMLInputElement>
  disabled: boolean
  submit: (memberId: number, data: Partial<Member>) => void
  memberId: number
}

const MemberStatusCell: FC<MemberStatusCellProps> = ({ id, register, disabled, submit, memberId }) => {
  const { t } = useTranslation()

  const onStatusSelect = (e: ChangeEvent<HTMLInputElement>) =>
    submit(memberId, { ['status']: (e.target.value as MemberStatusEnum) || null })

  return (
    <td>
      <TextInput type='select' id={id} innerRef={register} name={id} onChange={onStatusSelect} disabled={disabled}>
        <option value={''}>{t('common.enums.memberStatutes.unspecified')}</option>
        {MEMBER_STATUS.map(({ key, label }) => (
          <option key={key} value={key}>
            {label ? t(label) : t('common.enums.memberStatutes.unspecified')}
          </option>
        ))}
      </TextInput>
    </td>
  )
}

export default MemberStatusCell
