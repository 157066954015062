import { Association } from './Association'
import { Instance } from './Instance'
import { User, UserManagerRights } from './User'

export enum PerimeterTypeEnum {
  ALL = 'ALL',
  INSTANCE = 'INSTANCE',
  ASSOCIATION = 'ASSOCIATION',
}

export enum CollaboratorRelationEnum {
  USER = 'USER',
  INSTANCE = 'INSTANCE',
}

export type CollaboratorBaseType = {
  id: number
  function?: string
  start_date?: string
  end_date?: string
  is_manager: boolean
  perimeter_type: PerimeterTypeEnum
  id_perimeter?: number
  is_permission?: boolean
  permission?: string[]
}

export type CollaboratorSubmit = CollaboratorBaseType & {
  users_permissions_user: number
  perimeter_id: number
}

export type Collaborator = CollaboratorBaseType & {
  users_permissions_user: User
}

export type CollaboratorForManagerType = Collaborator & {
  perimeter?: Instance | Association
  has_manager_right?: boolean
  is_current?: boolean
}

export type UserCollaboratorsForManagerQuery = {
  userAllCollaboratorsList: CollaboratorForManagerType[]
  managerRights: UserManagerRights
}

export type InstanceCollaboratorsForManagerQuery = {
  instanceAllCollaboratorsList: CollaboratorForManagerType[]
  managerRights: UserManagerRights
}
