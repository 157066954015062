import Breadcrumb from 'components/atoms/breadcrumb/Breadcrumb'
import Section from 'components/atoms/layout/Section'
import View from 'components/atoms/layout/View'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import Header from 'components/molecules/heading/Header'
import KioskDecisionsRecords from 'components/molecules/kiosk/kiosk-rubrics/KioskDecisionsRecords'
import _ from 'lodash'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { ParamsType } from 'types/Params'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { useQuery } from 'utils/hooks/useQuery'

import { usePermissions } from '../utils/hooks/usePermissions'
import { useToggle } from '../utils/hooks/useToggle'

import Button from '../components/atoms/button/Button'
import ModalAddDecisionRecord from '../components/molecules/modals/ModalAddDecisionRecord'

import { instanceService } from '../services/instanceService'
import { meetingService } from '../services/meetingService'

export const getMyMeetingsKey = ['instance', 'getMeetings']
const KioskDecisionsRecordsInstanceView: FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()
  const { state: isAddDecisionRecordOpen, toggle: toggleModalAddDecisionRecord } = useToggle()

  const { CAN_MANAGE_DECISION_RECORDS_IN_INSTANCE } = usePermissions()
  const canManageDecisionRecordsInInstance = CAN_MANAGE_DECISION_RECORDS_IN_INSTANCE(parseInt(id))

  const { isLoading: isInstanceLoading, data: instance } = useQuery({
    queryKey: ['instance', 'getOne', id],
    queryFn: async () => await instanceService.getOne(parseInt(id)),
  })

  const { isLoading: areMyMeetingsLoading, data: meetingList } = useQuery({
    queryKey: getMyMeetingsKey,
    queryFn: async () => {
      const meetingList = await instanceService.getInstanceMeetings(parseInt(id))
      return meetingList.filter((meeting) => meeting.decisions_record && meeting.decisions_record.documents?.length > 0)
    },
    cacheTime: 0,
  })

  const yearList = _.sortedUniq(meetingList?.map((meeting) => meetingService.getMeetingYear(meeting)))
  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.HOME}>{t('nav.kiosk')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={PATHS.KIOSK.DECISIONS_RECORDS.ALL_INSTANCES}>{t('kiosk.rubrics.decisionsRecords')}</Link>
              </BreadcrumbItem>
              {instance && (
                <BreadcrumbItem>{`${instance.long_name}${
                  instance.association?.name ? ` - ${instance.association?.name}` : ''
                }`}</BreadcrumbItem>
              )}
            </Breadcrumb>
            {canManageDecisionRecordsInInstance && (
              <Button onClick={toggleModalAddDecisionRecord}>{t('kiosk.addDecisionRecords')}</Button>
            )}
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {(areMyMeetingsLoading || isInstanceLoading) && <Spinner />}
          {meetingList && (
            <KioskDecisionsRecords
              meetingList={meetingList}
              yearList={yearList}
              canDeleteDecisionRecords={canManageDecisionRecordsInInstance}
            />
          )}
          {!meetingList && (
            <span>
              {t('kiosk.rubrics.noDocumentOnRubric', {
                rubricLabel: t('kiosk.rubrics.decisionsRecords').toLowerCase(),
              })}
            </span>
          )}
        </Section>
        <ModalAddDecisionRecord
          toggle={toggleModalAddDecisionRecord}
          isOpen={isAddDecisionRecordOpen}
          instanceId={id}
        />
      </ViewBody>
    </View>
  )
}

export default KioskDecisionsRecordsInstanceView
