import React, { FunctionComponent } from 'react'
import { Modal as LibModal, ModalProps as LibModalProps } from 'reactstrap'

import './Modal.scss'

type BasicModalProps = LibModalProps & {
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full' | 'auto'
}

const Modal: FunctionComponent<BasicModalProps> = ({ size = 'md', children, className = '', ...rest }) => {
  return (
    <LibModal className={`Modal ${className}`} size={size} {...rest}>
      {children}
    </LibModal>
  )
}

export default Modal
