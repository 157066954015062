import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../../../utils/alertUtils'

import { AbsenceOption } from '../../../../../types/AbsenceOption'
import { AbsenceOptionType, Invitation } from '../../../../../types/Invitation'

import { invitationService } from '../../../../../services/invitationService'
import Button from '../../../../atoms/button/Button'
import Dropzone from '../../../../atoms/dropzone/Dropzone'
import Section from '../../../../atoms/layout/Section'
import Modal from '../../../../atoms/modal/Modal'
import ButtonDownloadFile from '../../../buttons/files/ButtonDownloadFile'
import AttachmentCard from '../../../documents/AttachmentCard'

interface UpdateInvitationAbsenceChildrenModalProps {
  isOpen: boolean
  toggle: () => void
  meetingId: string
  invitation: Invitation
  absenceChildrenUserId?: string
  absence_option: AbsenceOptionType
  historyQueryKey: (string | number | undefined)[]
}

const UpdateInvitationAbsenceChildrenModal: FC<UpdateInvitationAbsenceChildrenModalProps> = (props) => {
  const { isOpen, toggle, meetingId, invitation, absenceChildrenUserId, absence_option, historyQueryKey } = props
  const { t } = useTranslation()
  const [file, setFile] = useState<File>()

  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    ({ file, userId }: { file?: File; userId: string }) =>
      invitationService.postAbsenceDelegation(invitation.id, userId, file, absence_option, 'ACCEPTED'),
    {
      mutationKey: ['invitation', 'postAbsenceDelegation', absence_option],
      onSuccess: () => {
        notifySuccess(t('toastify.success.meetings.updateInvitationStatus'))
        void queryClient.refetchQueries(['invitation', 'getAllInvitationsByMeeting', meetingId])
        void queryClient.refetchQueries(historyQueryKey)
        toggle()
      },
      onError: () => notifyError(t('toastify.errors.update.invitation')),
    },
  )

  const downloadQueryKey = ['invitation', 'getDelegationTemplate', invitation.id]
  const downloadQueryFn = () =>
    invitationService.getDelegationTemplate(invitation.id, invitation?.absence_option || 'POWER')

  const onFiledrop = (files: File[]) => {
    setFile(files[0])
  }

  const onDeleteFilePreview = () => setFile(undefined)

  const onValidate = () => {
    if (absenceChildrenUserId) {
      mutate({ file, userId: absenceChildrenUserId })
    } else {
      notifyError(t('meeting.invitations.noChildrenSpecified'))
    }
  }

  const modalHeader =
    absence_option !== AbsenceOption.REPLACEMENT
      ? t('meeting.modal.absence.step3.powerHeader')
      : t('meeting.modal.absence.step3.replacementHeader')

  const hasUploadedFile = !!invitation.absence_justificatif
  const dropzoneLabel = hasUploadedFile
    ? t('meeting.modal.absence.step3.newDropzoneFile')
    : t('meeting.modal.absence.step3.dropzone')

  const filename =
    absence_option === AbsenceOption.POWER
      ? t('meeting.convocation.formPowerFileName', { date: format(new Date(), 'yyyy_MM_dd_HH_mm') })
      : t('meeting.convocation.formReplacementFileName', { date: format(new Date(), 'yyyy_MM_dd_HH_mm') })

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{modalHeader}</ModalHeader>
      <ModalBody>
        <Section>
          {file && <AttachmentCard file={file} onDeleteFile={onDeleteFilePreview} />}
          {!file && !!invitation.absence_justificatif && <AttachmentCard file={invitation.absence_justificatif} />}
          <Dropzone label={dropzoneLabel} addNewFiles={onFiledrop} maxFiles={1} />
        </Section>
      </ModalBody>
      <ModalFooter className='d-flex justify-content-between'>
        {invitation.absence_option && (
          <ButtonDownloadFile
            queryKey={downloadQueryKey}
            queryFn={downloadQueryFn}
            buttonLabel={t('meeting.modal.absence.step3.templateButton')}
            templateName={filename}
          />
        )}
        <Button disabled={!file && !hasUploadedFile} onClick={onValidate}>
          {t('meeting.invitations.table.validate')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default UpdateInvitationAbsenceChildrenModal
