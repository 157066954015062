import _ from 'lodash'
import { Collaborator, CollaboratorForManagerType, PerimeterTypeEnum } from 'types/Collaborator'
import { Delegate, DelegateForManagerType } from 'types/Delegate'
import { Instance } from 'types/Instance'
import { Member, MemberForManagerType } from 'types/Member'
import { UserManagerRights } from 'types/User'

import { parseAsDate } from './dateUtils'
import { _getCollaboratorInstanceList, _getMemberInstanceList } from './hooks/usePermissions'

/**
 * Le mandat n'est pas en cours si sa date de début est définie et après aujourd'hui et/ou si sa date de fin est définie et avant aujourd'hui
 * @param startDate
 * @param endDate
 * @returns boolean
 */
export const isMandateCurrent = (startDate?: string | null, endDate?: string | null): boolean => {
  const today = new Date()
  return (!startDate || parseAsDate(startDate) < today) && (!endDate || parseAsDate(endDate) > today)
}

/**
 * On ajoute is_current aux mandats membres pour définir si le mandat est en cours ou non
 * @param memberList
 * @returns liste de membres avec l'attribut is_current à true ou false sur chacun
 */
const addIsCurrentToMembers = (memberList: Member[]): MemberForManagerType[] =>
  memberList.map((member: Member) => {
    const is_current = isMandateCurrent(member.start_date, member.end_date)

    return { ...member, is_current }
  })

/**
 * On ajoute is_current aux mandats membres pour définir si le mandat est en cours ou non
 * @param delegateList
 * @returns liste de membres avec l'attribut is_current à true ou false sur chacun
 */
const addIsCurrentToDelegates = (delegateList: Delegate[]): DelegateForManagerType[] =>
  delegateList.map((delegate: Delegate) => {
    const is_current = isMandateCurrent(delegate.start_date, delegate.end_date)

    return { ...delegate, is_current }
  })

/**
 * On ajoute is_current aux mandats collaborateurs pour définir si le mandat est en cours ou non
 * @param collaboratorList
 * @returns liste de collaborateurs avec l'attribut is_current à true ou false sur chacun
 */
const addIsCurrentToCollaborators = (collaboratorList: Collaborator[]): CollaboratorForManagerType[] =>
  collaboratorList.map((collaborator: Collaborator) => {
    const is_current = isMandateCurrent(collaborator.start_date, collaborator.end_date)

    return { ...collaborator, is_current }
  })

/**
 * On ajoute has_manager_right aux mandats membres pour définir si l'utilisateur connecté a un droit de modification sur ceux-ci
 * @param memberList
 * @param managerRights
 * @returns liste de mandats membres avec has_manager_rights à true ou false sur chacun
 */
const addManagerRightsToMembers = (
  memberList: MemberForManagerType[],
  managerRights: UserManagerRights,
): MemberForManagerType[] =>
  memberList.map((member) => {
    let has_manager_right = false

    if (managerRights.hasManagerRightsOnAll) {
      has_manager_right = true
    } else {
      has_manager_right = !!managerRights.instancesIds?.includes(member.instance.id)
    }

    return { ...member, has_manager_right }
  })

/**
 * On ajoute has_manager_right aux mandats délégués pour définir si l'utilisateur connecté a un droit de modification sur ceux-ci
 * @param delegateList
 * @param managerRights
 * @returns liste de mandats délégués avec has_manager_rights à true ou false sur chacun
 */
const addManagerRightsToDelegates = (
  delegateList: DelegateForManagerType[],
  managerRights: UserManagerRights,
): DelegateForManagerType[] =>
  delegateList.map((delegate) => {
    let has_manager_right = false

    if (managerRights.hasManagerRightsOnAll) {
      has_manager_right = true
    } else {
      has_manager_right = !!managerRights.instancesIds?.includes(delegate.meeting.instance.id)
    }

    return { ...delegate, has_manager_right }
  })

/**
 * On ajoute has_manager_right aux mandats collaborateurs avec le périmètre (collaborator.perimeter pointant vers une instance ou une association) pour définir si l'utilisateur connecté a un droit de modification sur ceux-ci
 * @param collaboratorListWithPerimeter
 * @param managerRights
 * @returns liste de mandats collaborateurs avec has_manager_rights à true ou false sur chacun
 */
const addManagerRightsToCollaboratorsWithPerimeter = (
  collaboratorListWithPerimeter: CollaboratorForManagerType[],
  managerRights: UserManagerRights,
): CollaboratorForManagerType[] =>
  collaboratorListWithPerimeter.map((collaborator) => {
    let has_manager_right = false

    if (managerRights.hasManagerRightsOnAll) {
      has_manager_right = true
    } else {
      if (collaborator.perimeter && !collaborator.is_manager) {
        if (collaborator.perimeter_type === PerimeterTypeEnum.INSTANCE) {
          has_manager_right = !!managerRights.instancesIds?.includes(collaborator.perimeter.id)
        }
        if (collaborator.perimeter_type === PerimeterTypeEnum.ASSOCIATION) {
          has_manager_right = !!managerRights.associationsIds?.includes(collaborator.perimeter.id)
        }
      }
    }

    return { ...collaborator, has_manager_right }
  })

/**
 * On trie les mandats membres par leur attribut is_current
 * @param memberList
 * @returns liste ordonnée de mandats membres avec ceux en cours en premier
 */
const sortMembersByCurrent = (memberList: MemberForManagerType[]): MemberForManagerType[] =>
  memberList.sort((member1: MemberForManagerType, member2: MemberForManagerType) =>
    member1.is_current === member2.is_current ? 0 : member1.is_current ? -1 : 1,
  )

/**
 * On trie les mandats délégués par leur attribut is_current
 * @param delegateList
 * @returns liste ordonnée de mandats délégués avec ceux en cours en premier
 */
const sortDelegatesByCurrent = (delegateList: DelegateForManagerType[]): DelegateForManagerType[] =>
  delegateList.sort((delegate1: DelegateForManagerType, delegate2: DelegateForManagerType) =>
    delegate1.is_current === delegate2.is_current ? 0 : delegate1.is_current ? -1 : 1,
  )

/**
 * On trie les mandats collaborateurs par leur attribut is_current
 * @param collaboratorList
 * @returns liste ordonnée de mandats collaborateurs avec ceux en cours en premier
 */
const sortCollaboratorsByCurrent = (collaboratorList: CollaboratorForManagerType[]): CollaboratorForManagerType[] =>
  collaboratorList.sort((collaborator1: CollaboratorForManagerType, collaborator2: CollaboratorForManagerType) =>
    collaborator1.is_current === collaborator2.is_current ? 0 : collaborator1.is_current ? -1 : 1,
  )

/**
 * On calcule sur chaque mandat membre de la liste s'il est en cours ou non et si l'utilisateur connecté a un droit d'édition sur celui-ci puis on les trie par ceux en cours en premier
 * @param memberList
 * @param managerRights
 * @returns Liste de membres avec sur chaque membre les attributs is_current et has_manager_right, ordonnée par is_current = true en premier
 */
export const buildMembersForManager = (
  memberList: Member[],
  connectedUserManagerRights: UserManagerRights,
): MemberForManagerType[] => {
  const memberListWithIsCurrent = addIsCurrentToMembers(memberList)
  const memberListWithManagerRights = addManagerRightsToMembers(memberListWithIsCurrent, connectedUserManagerRights)
  return sortMembersByCurrent(memberListWithManagerRights)
}

/**
 * On calcule sur chaque mandat délégué de la liste s'il est en cours ou non et si l'utilisateur connecté a un droit d'édition sur celui-ci puis on les trie par ceux en cours en premier
 * @param delegateList
 * @param managerRights
 * @returns Liste de délégués avec sur chaque délégué les attributs is_current et has_manager_right, ordonnée par is_current = true en premier
 */
export const buildDelegatesForManager = (
  delegateList: Delegate[],
  managerRights: UserManagerRights,
): DelegateForManagerType[] => {
  const delegateListWithIsCurrent = addIsCurrentToDelegates(delegateList)
  const delegateListWithManagerRights = addManagerRightsToDelegates(delegateListWithIsCurrent, managerRights)
  return sortDelegatesByCurrent(delegateListWithManagerRights)
}

/**
 * On calcule sur chaque mandat collaborateur de la liste s'il est en cours ou non et si l'utilisateur connecté a un droit d'édition sur celui-ci puis on les trie par ceux en cours en premier
 * @param collaboratorList
 * @param managerRights
 * @returns Liste de collaborateurs avec sur chaque membre les attributs is_current et has_manager_right, ordonnée par is_current = true en premier
 */
export const buildCollaboratorsForManager = (
  collaboratorList: Collaborator[],
  managerRights: UserManagerRights,
): CollaboratorForManagerType[] => {
  const collaboratorListWithIsCurrent = addIsCurrentToCollaborators(collaboratorList)
  const collaboratorListWithManagerRights = addManagerRightsToCollaboratorsWithPerimeter(
    collaboratorListWithIsCurrent,
    managerRights,
  )
  return sortCollaboratorsByCurrent(collaboratorListWithManagerRights)
}

/**
 * Retourne la liste d'instances (uniques) du périmètre ciblé par des membres et collaborateurs
 * @param memberList
 * @param collaboratorList
 * @param allInstanceList
 * @returns {[Instance]}
 */
export const getUniqueInstancesFromMembersAndCollaborators = (
  memberList: Member[],
  collaboratorList: Collaborator[],
  allInstanceList: Instance[],
): Instance[] => {
  const memberInstanceList = _getMemberInstanceList(allInstanceList, memberList)
  const collaboratorInstanceList = _getCollaboratorInstanceList(allInstanceList, collaboratorList)

  return _.uniqBy(memberInstanceList.concat(collaboratorInstanceList), (e) => e.id).sort((prevInst) =>
    prevInst.association?.name?.startsWith('OPCO 2i') ? -1 : 1,
  )
}
