import React, { FC, ReactText, SyntheticEvent } from 'react'
import { Col } from 'reactstrap'

import { InputDataNode } from '../../../../utils/treeUtils'

import { DocumentType } from '../../../../types/Document'

import { FileBrowser } from '../../file-browser/FileBrowser'
import './OrderOfTheDay.scss'
import OrderOfTheDayTitle from './OrderOfTheDayTitle'

interface OrderOfTheDayTreeItemProps {
  item: InputDataNode
  openPreviewDocument?: (file: DocumentType) => void
  showDocuments?: boolean
  onKeyExpand?: (key: ReactText) => void
}

const OrderOfTheDayMemberTreeItem: FC<OrderOfTheDayTreeItemProps> = (props) => {
  const { item, showDocuments = true, onKeyExpand } = props

  const onTitleClick = (event: SyntheticEvent) => {
    event.preventDefault()
    onKeyExpand && onKeyExpand(item.key)
  }

  return (
    <Col>
      <OrderOfTheDayTitle item={item} onClick={onTitleClick} />
      {showDocuments && (
        <FileBrowser readOnly={true} dataFiles={item?.fileBrowserData} instanceId={item.key?.toString()} />
      )}
    </Col>
  )
}

export default OrderOfTheDayMemberTreeItem
