import { FC } from 'react'
import { PopoverBody as RSPopoverBody, PopoverBodyProps as RSPopoverBodyProps } from 'reactstrap'

import './PopoverBody.scss'

interface PopoverBodyProps extends RSPopoverBodyProps {
  className?: string
}

const PopoverBody: FC<PopoverBodyProps> = (props) => {
  const { className = '', children, ...rest } = props

  return (
    <RSPopoverBody className={`PopoverBody ${className}`} {...rest}>
      {children}
    </RSPopoverBody>
  )
}

export default PopoverBody
