import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Input, Label, ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'

import { DocumentType, DocumentTypeEnum } from '../../../types/Document'
import { ObjectivesConventionComponentType } from '../../../types/KioskObjectivesConvention'

import { documentService } from '../../../services/documentService'
import { kioskService } from '../../../services/kioskService'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import Modal from '../../atoms/modal/Modal'
import DocumentCarrousel from '../documents/DocumentCarrousel'
import './Modals.scss'

interface ModalAddFoundingTextProps {
  toggle: () => void
  isOpen: boolean
  oldDocuments: ObjectivesConventionComponentType[]
}

const ModalAddConventionObjectif: FC<ModalAddFoundingTextProps> = ({ toggle, isOpen, oldDocuments }) => {
  const initialState = {
    associationId: undefined,
    files: [],
  }

  const { t } = useTranslation()
  const [filterValues, setFilterValues] = useState<{
    year?: number
    files: File[]
  }>(initialState)

  const { mutate: uploadFoundingText, isLoading: isUploadLoading } = useMutation(
    async () => {
      if (!filterValues.year || filterValues.files.length === 0) {
        throw t('toastify.errors.kioskUpload')
      }

      const documents = await documentService.uploadDocuments(
        filterValues.files,
        'kiosk.objectives-convention',
        Math.floor(Math.random() * 10000000),
        'documents',
        DocumentTypeEnum.KIOSK_OBJECTIVES_CONVENTION,
      )

      await kioskService.updateObjectifConvention({
        document: [
          ...oldDocuments.map((oldDocument) => ({
            id: oldDocument.id,
            year: oldDocument.year,
            media: oldDocument.media.id,
          })),
          ...documents.map((doc) => ({
            year: filterValues.year,
            media: doc.id,
          })),
        ],
      })
    },
    {
      mutationKey: ['update', 'updateYearAndDocument'],
      onError: () => {
        notifyError(t('toastify.errors.foundingTextUpload'))
      },
      onSuccess: () => {
        setFilterValues(initialState)
        toggle()
        notifySuccess(t('toastify.success.decisionRecordUploaded'))
      },
    },
  )

  const changeFilterValues = (field: string, value: string | number | File[] | undefined) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onSubmit = () => {
    return !isUploadLoading && uploadFoundingText()
  }

  return (
    <Modal toggle={toggle} isOpen={isOpen} size='xl'>
      <ModalHeader toggle={toggle}>{t('kiosk.addObjectivesConvention')}</ModalHeader>
      <ModalBody>
        <div className='col-12 mb-4'>
          <Label>{t('common.year')}</Label>
          <Input
            type='number'
            className='my-0'
            value={filterValues.year}
            onChange={(val) => changeFilterValues('year', parseInt(val.target.value))}
          />
        </div>
        <DocumentCarrousel
          fileList={(filterValues.files as unknown) as DocumentType[]}
          closable={false}
          checkable={false}
          downloadable={false}
        />
        <Dropzone
          className='d-block my-3'
          label={t('meeting.dropzonePlaceholder')}
          addNewFiles={(newFileList) => {
            changeFilterValues('files', newFileList)
          }}
        />
        <Button className='mx-auto px-5' isLoading={isUploadLoading} onClick={onSubmit}>
          {!isUploadLoading && t('common.add')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddConventionObjectif
