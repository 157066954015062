import { yupResolver } from '@hookform/resolvers/yup'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import ReactQuill from 'react-quill'
import { Col, Form, Input, ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'
import { EmailValidationSchema } from '../../../utils/constants/Email'
import { EMAIL_INTERPOLATION, ibk, iek } from '../../../utils/constants/EmailInterpolation'
import { fetchFileFromUrl } from '../../../utils/fileUtils'

import { DocumentType } from '../../../types/Document'
import { Email } from '../../../types/Email'
import { Meeting, MeetingStatus } from '../../../types/Meeting'

import { instanceService } from '../../../services/instanceService'
import { meetingService } from '../../../services/meetingService'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import IconSvg from '../../atoms/icon/IconSvg'
import TextInput from '../../atoms/input/TextInput'
import Row from '../../atoms/layout/Row'
import Modal from '../../atoms/modal/Modal'
import TooltipWithLabel from '../../atoms/tooltip/TooltipWithLabel'
import AttachmentCard from '../documents/AttachmentCard'
import './SendEmailModal.scss'

const toolbarOptions = [
  [{ font: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['blockquote'],

  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: [] }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  ['link', 'image'],
  ['clean'], // remove formatting button
]

interface SendEmailModalProps {
  className?: string
  meeting: Meeting
  isEditingTemplate?: boolean
  queryKey?: (string | number)[]
  convocation?: DocumentType
  selectedInvitations: string[]
  refetchInvitations?: () => void
  historyQueryKey: (string | number | undefined)[]
}

const SendEmailModal: FC<SendEmailModalProps> = (props) => {
  const {
    className = '',
    meeting,
    isEditingTemplate = false,
    queryKey,
    selectedInvitations,
    refetchInvitations,
    convocation,
    historyQueryKey,
  } = props

  // ----------------------------- HOOKS ---------------------------------
  const { t } = useTranslation()

  const [files, setFiles] = useState<File[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const { register, handleSubmit, setValue, watch, reset, errors } = useForm<Email>({
    mode: 'onChange',
    resolver: yupResolver(EmailValidationSchema(isEditingTemplate)),
  })

  const interpolationEntries = Object.entries(EMAIL_INTERPOLATION(meeting))
  const postponed = meeting.status === MeetingStatus.CANCELED

  useEffect(() => {
    const defaultValues = {
      subject: meeting.email_template?.subject || '',
      content: meeting.email_template?.content || '',
      cc: '',
    }

    // Replace all interpolation values
    if (!isEditingTemplate) {
      for (const [key, value] of interpolationEntries) {
        defaultValues.subject = defaultValues.subject.replaceAll(`${ibk}${key}${iek}`, t(value.interpolation))
        defaultValues.content = defaultValues.content.replaceAll(`${ibk}${key}${iek}`, t(value.interpolation))
      }
    }

    reset(defaultValues)

    //if meeting has convocation then download it
    convocation &&
      fetchFileFromUrl(convocation.url, convocation.name).then((file) => {
        if (!files.find((stateFile) => stateFile.name === file.name)) {
          setFiles([file])
        }
      })
  }, [meeting])

  // Mutation du on submit
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    (emailData: Email): Promise<Meeting | void> => {
      return isEditingTemplate
        ? meetingService.update(meeting.id, { ...meeting, email_template: emailData })
        : instanceService.sendEmailToParticipants(meeting.id, {
            email: emailData,
            invitationList: selectedInvitations,
          })
    },
    {
      mutationKey: isEditingTemplate ? ['meeting', 'update'] : ['instance', 'sendEmail'],
      onSuccess: async () => {
        if (isEditingTemplate) {
          await queryClient.refetchQueries(queryKey)
        } else {
          refetchInvitations && refetchInvitations()
          await queryClient.refetchQueries(historyQueryKey)
        }
        notifySuccess(t(stateValue.notifySuccessMessage))
        setIsOpen(false)
      },
      onError: () => notifyError(t('toastify.errors.unknown')),
    },
  )

  // ----------------------------- ON CHANGE ACTIONS ---------------------------------
  const onReactQuillChange = (value: string) => setValue('content', value)

  const onSubmit = (emailData: Email) => {
    if (!isLoading) mutate({ ...emailData, attachments: files })
  }

  const onAddFiles = (newFiles: File[]) => {
    setFiles((prevState) => {
      const filesToAdd = newFiles.filter((file) => !prevState.some((prevFile) => prevFile.name === file.name))
      return [...prevState, ...filesToAdd]
    })
  }

  const onDeleteFile = (fileToDelete: File) => {
    setFiles((prevState) => prevState.filter((file) => fileToDelete.name !== file.name))
  }

  const onModalOpen = (isOpen: boolean) => setIsOpen(isOpen)

  const stateValue = {
    modalHeader: isEditingTemplate ? 'email.editTemplate' : 'email.sendEmail',
    submitButton: isEditingTemplate ? 'common.save' : 'common.send',
    submitButtonIsLoading: isEditingTemplate ? 'email.templateIsSaving' : 'email.emailIsSending',
    modalButton: isEditingTemplate ? 'email.editTemplate' : 'email.sendEmail',
    notifySuccessMessage: isEditingTemplate
      ? 'toastify.success.templateSuccessfullyEdited'
      : 'toastify.success.emailSuccessfullySent',
  }
  // ----------------------------- JSX LOGIC ---------------------------------
  const watchValues = watch()
  const isSendDisabled =
    (!isEditingTemplate && selectedInvitations.length <= 0 && watchValues.cc?.length <= 0) || postponed
  return (
    <div className={`${className}`}>
      <Button onClick={() => onModalOpen(true)} color='primary'>
        <IconSvg name='ConvocationEdit' className='mr-3' />
        {t(stateValue.modalButton)}
      </Button>
      <Modal isOpen={isOpen} toggle={() => onModalOpen(false)} size='full'>
        <ModalHeader toggle={() => onModalOpen(false)}>{t(stateValue.modalHeader)}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)} className='d-flex flex-column flex-grow-1'>
            <TextInput
              type='text'
              placeholder={t('email.subject')}
              innerRef={register}
              name='subject'
              error={errors?.subject?.message && t(errors?.subject?.message)}
            />
            {!isEditingTemplate && (
              <TextInput
                className='mt-3'
                type='text'
                placeholder={t('email.cc')}
                innerRef={register}
                name='cc'
                error={errors?.cc?.message && t(errors?.cc?.message)}
              />
            )}

            {!isEditingTemplate && (
              <div className='my-4'>
                {t('email.attachments')}
                <Dropzone className='flex-shrink' label={t('meeting.dropzonePlaceholder')} addNewFiles={onAddFiles} />
                <Row grid className='overflow-auto' flexWrap='nowrap'>
                  {files.map((file) => (
                    <Col xs={2} key={file.name}>
                      <AttachmentCard file={file} onDeleteFile={onDeleteFile} />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            <Input name='content' innerRef={register} hidden />
            <ReactQuill
              className='Quill'
              value={watchValues.content}
              onChange={onReactQuillChange}
              modules={{
                toolbar: toolbarOptions,
              }}
            />
            {isEditingTemplate && (
              <TooltipWithLabel
                className='mr-0 ml-auto'
                target='interpolationTooltip'
                placement='bottom'
                label={t('nav.help')}
                forceClose={!isOpen}
                autohide={false}
              >
                <>
                  {interpolationEntries.map(([key, value]) => (
                    <div className='text-left' key={key}>
                      <p className='mb-0'>
                        <strong>{`${ibk}${key}${iek}`}</strong>
                      </p>
                      <p>{t(value.explanation)}</p>
                    </div>
                  ))}
                </>
              </TooltipWithLabel>
            )}
            {postponed && <p className='text-center'>{t('meeting.cannotUpdateAPostponedMeeting')}</p>}
            {!postponed && (
              <Button type='submit' disabled={isSendDisabled} className='mx-auto mt-3' isLoading={isLoading}>
                {t(isLoading ? stateValue.submitButtonIsLoading : stateValue.submitButton)}
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default SendEmailModal
