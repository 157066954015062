import { useStore } from 'effector-react'
import _ from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { ModalBody, ModalHeader } from 'reactstrap'

import { notifyError, notifySuccess } from '../../../utils/alertUtils'
import { usePermissions } from '../../../utils/hooks/usePermissions'
import { useQuery } from '../../../utils/hooks/useQuery'

import { DocumentTypeEnum } from '../../../types/Document'
import { MeetingListType, MeetingPreview } from '../../../types/Meeting'

import { getMyMeetingsKey } from '../../../views/KioskDecisionsRecordsInstanceView'

import { documentService } from '../../../services/documentService'
import { meetingService } from '../../../services/meetingService'
import { userService } from '../../../services/userService'
import { InstanceListStore } from '../../../store/InstanceListStore'
import Button from '../../atoms/button/Button'
import Dropzone from '../../atoms/dropzone/Dropzone'
import Modal from '../../atoms/modal/Modal'
import Spinner from '../../atoms/spinner/Spinner'
import YearPicker from '../datepicker/YearPicker'
import DocumentCarrousel from '../documents/DocumentCarrousel'
import InstanceSelectFilter from '../filters/InstanceSelectFilter'
import MeetingDatePicker from '../meeting/MeetingDatePicker'
import './Modals.scss'

interface ModalAddDecisionRecordProps {
  toggle: () => void
  isOpen: boolean
  instanceId?: string
}

const ModalAddDecisionRecord: FC<ModalAddDecisionRecordProps> = (props) => {
  const { toggle, isOpen, instanceId } = props
  const initialState = {
    instanceId: instanceId,
    year: undefined,
    meetingId: undefined,
    files: [],
  }

  const { t } = useTranslation()
  const { USER_IS_COLLABORATOR_MANAGER_IN_INSTANCE } = usePermissions()
  const [initialMeetingList, setInitialMeetingList] = useState<MeetingPreview[]>([])
  const [myMeetingList, setMyMeetingList] = useState<MeetingPreview[]>([])
  const [filterValues, setFilterValues] = useState(initialState)

  /**
   * Je récupère les meetings selon mon périmètre
   */
  const myInstances = useStore(InstanceListStore).filter((instance) =>
    USER_IS_COLLABORATOR_MANAGER_IN_INSTANCE(instance.id),
  )
  const queryClient = useQueryClient()
  const { isLoading: areMyMeetingsLoading } = useQuery({
    queryKey: ['user', 'getMyMeeting', myInstances], //on met les instances en keys pour refetch si elles changent
    queryFn: () =>
      userService.getUserMeetingsWithFilters({
        limit: -1,
        page: 1,
        instanceList: instanceId ? [instanceId] : (myInstances || []).map((instance) => instance.id.toString()),
      }),
    onSuccess: async (data: MeetingListType) => {
      setInitialMeetingList(data?.meetings)
    },
    cacheTime: 0,
  })

  const { mutate: uploadDecisionRecord, isLoading: isUploadLoading } = useMutation(
    async () => {
      const selectedMeeting = initialMeetingList.find((meeting) => meeting.id.toString() === filterValues.meetingId)
      //On throw pour pas upload les documents et pour tomber dans le onError
      if (!selectedMeeting?.decisions_record?.id) {
        throw t('toastify.errors.decisionRecordUpload')
      }
      return await documentService.uploadDocuments(
        filterValues.files,
        'kiosk.decisions-record',
        selectedMeeting.decisions_record.id,
        'documents',
        DocumentTypeEnum.KIOSK_DECISIONS_RECORD,
      )
    },
    {
      mutationKey: ['meeting', 'update', 'decisionRecords', filterValues.meetingId],
      onError: () => {
        notifyError(t('toastify.errors.decisionRecordUpload'))
      },
      onSuccess: () => {
        setFilterValues(initialState)
        toggle()
        notifySuccess(t('toastify.success.decisionRecordUploaded'))
        queryClient.refetchQueries(getMyMeetingsKey)
      },
    },
  )

  const changeFilterValues = (field: string, value: string | number | File[] | undefined) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const onSubmit = () => !isUploadLoading && uploadDecisionRecord()

  /**
   * Si une valeur change alors on update la liste des réunions localement
   */
  useEffect(() => setMyMeetingList(initialMeetingList), [initialMeetingList])
  useEffect(() => {
    changeFilterValues('meetingId', undefined)
    const filteredMeeting = initialMeetingList.filter((meeting) => {
      let predicate = true
      if (filterValues.instanceId)
        predicate = predicate && meeting.instance.id.toString() === filterValues.instanceId.toString()
      if (filterValues.year) predicate = predicate && meetingService.getMeetingYear(meeting) === filterValues.year

      return predicate
    })

    setMyMeetingList(filteredMeeting)
  }, [filterValues.instanceId, filterValues.year])
  const yearList = _.uniq(initialMeetingList.map((meeting) => meetingService.getMeetingYear(meeting)))
  return (
    <Modal toggle={toggle} isOpen={isOpen} size='xl'>
      <ModalHeader toggle={toggle}>{t('kiosk.addDecisionRecords')}</ModalHeader>
      <ModalBody>
        <div className='ModalAddDecisionRecord col-8 mb-4'>
          <InstanceSelectFilter
            className='my-0'
            instanceId={instanceId}
            instanceList={myInstances}
            onInstanceSelect={(instanceId) => {
              changeFilterValues('instanceId', isNaN(instanceId) ? undefined : instanceId)
            }}
          />
          {areMyMeetingsLoading && <Spinner />}

          {!areMyMeetingsLoading && (
            <YearPicker
              onSelectYear={(year) => {
                changeFilterValues('year', year)
              }}
              yearList={yearList}
            />
          )}
          {areMyMeetingsLoading && <Spinner />}
          {!areMyMeetingsLoading && (
            <MeetingDatePicker
              meetingList={myMeetingList}
              onSelectMeeting={(meetingId) => {
                changeFilterValues('meetingId', meetingId)
              }}
            />
          )}
        </div>
        {filterValues.files?.length > 0 && (
          <DocumentCarrousel fileList={filterValues.files} closable={false} checkable={false} />
        )}
        <Dropzone
          className='d-block my-3'
          label={t('meeting.dropzonePlaceholder')}
          addNewFiles={(newFileList) => {
            changeFilterValues('files', newFileList)
          }}
        />
        <Button className='mx-auto px-5' isLoading={isUploadLoading} onClick={onSubmit}>
          {!isUploadLoading && t('common.add')}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default ModalAddDecisionRecord
