import React, { FunctionComponent } from 'react'

import './IconSvg.scss'
// Data
import { ICON_SVG_MAP } from './IconSvgMap'

type IconProps = {
  name: keyof typeof ICON_SVG_MAP
  className?: string
  size?: '2' | '3' | '4' | '5' | '6'
  onClick?: () => void
  filled?: boolean
}

const IconSvg: FunctionComponent<IconProps> = (props) => {
  const { name, className = '', size, onClick, filled = true } = props

  if (!ICON_SVG_MAP[name]) {
    return null
  }

  const IconClassName = (size ? ' IconSize--' + size : '') + ` ${className}`

  return (
    <span className={`IconSvg ${filled ? 'filled' : ''} ${IconClassName}`} onClick={onClick}>
      {ICON_SVG_MAP[name]}
    </span>
  )
}

export default IconSvg
