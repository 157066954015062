import Button from 'components/atoms/button/Button'
import Icon from 'components/atoms/icon/Icon'
import { FC } from 'react'

import NavbarItemGroup from '../navbar-item-group/NavbarItemGroup'
import './SideNavbar.scss'

interface SideNavbarProps {
  open: boolean
  closeSideMenu: () => void
}

const SideNavbar: FC<SideNavbarProps> = (props) => {
  const { open, closeSideMenu } = props
  const backdropClassName = `Backdrop ${open ? '' : 'BackdropClosed'}`
  const sidebarClassName = `SideNavbar ${open ? 'SideNavbarOpen' : ''}`

  return (
    <>
      <div className={backdropClassName} onClick={closeSideMenu} />
      <nav className={sidebarClassName}>
        <Button
          color='secondary'
          boxShadow='none'
          outline
          className='SideNavbarClose'
          border='none'
          onClick={closeSideMenu}
        >
          <Icon name='cancel' />
        </Button>
        <NavbarItemGroup />
      </nav>
    </>
  )
}

export default SideNavbar
