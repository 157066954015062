import Button from 'components/atoms/button/Button'
import Dropzone from 'components/atoms/dropzone/Dropzone'
import Figure from 'components/atoms/figure/Figure'
import Row from 'components/atoms/layout/Row'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardImg, Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getUrlFromFile, KILO_BYTE } from 'utils/fileUtils'

import Modal from '../../atoms/modal/Modal'

interface Props {
  toggle: () => void
  isOpen: boolean
  uploadProfilePicture: (file: File) => void
}

const EditProfilePictureModal: FC<Props> = ({ toggle, isOpen, uploadProfilePicture }) => {
  const { t } = useTranslation()

  const [selectedPicture, setSelectedPicture] = useState<File | undefined>(undefined)

  const onDropzoneAddFile = (filesList: File[]) => {
    const file = filesList[0]
    setSelectedPicture(file)
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size='md'>
      <ModalHeader toggle={toggle}>{`${t('profilePicture.modalTitle')}`}</ModalHeader>
      <ModalBody>
        <Row grid gutters='xl' className='justify-content-center'>
          <Col xs='9'>
            {selectedPicture && (
              <Figure ratio='1-1' circle>
                <CardImg src={getUrlFromFile(selectedPicture)} alt={t('profilePicture.previewAlt')} />
              </Figure>
            )}
          </Col>
          <Col xs='12'>
            <Dropzone
              label={t('profilePicture.dropzoneLabel')}
              addNewFiles={onDropzoneAddFile}
              accept={['image/*']}
              maxFiles={1}
              // 1 Mo
              maxSize={KILO_BYTE ** 2}
              multiple={false}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!selectedPicture}
          label={t('common.confirm')}
          onClick={() => selectedPicture && uploadProfilePicture(selectedPicture)}
          className='m-auto'
        />
      </ModalFooter>
    </Modal>
  )
}

export default EditProfilePictureModal
