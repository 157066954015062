import React, { FC } from 'react'

import { getGlobalDataFiles, InputDataNode } from '../../../../utils/treeUtils'

import { DocumentType } from '../../../../types/Document'
import { Meeting } from '../../../../types/Meeting'

import { FileBrowser } from '../../file-browser/FileBrowser'

interface OrderOfTheDayProps {
  treeData: InputDataNode[]
  openPreviewDocument?: (file: DocumentType) => void
  showDocuments?: boolean
  meeting: Meeting
}

const OrderOfTheDayMember: FC<OrderOfTheDayProps> = (props) => {
  const { treeData, meeting } = props

  return (
    <>
      <FileBrowser
        readOnly={true}
        dataFiles={getGlobalDataFiles(treeData, `meeting-${meeting.id}`)}
        instanceId={`meeting-${meeting.id}`}
      />
    </>
  )
}

export default OrderOfTheDayMember
