import { BASE_PATH } from 'api/api'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CardImg } from 'reactstrap'

import { User } from '../../../types/User'

import Figure from '../../atoms/figure/Figure'
import FigureAcronyme from '../../atoms/figure/FigureAcronyme'

interface ProfilePictureProps {
  user: User
  className?: string
  hideProfilePicture?: boolean
}
const ProfilePicture: FC<ProfilePictureProps> = (props) => {
  const { user, className = '', hideProfilePicture } = props
  const { t } = useTranslation()

  return (
    <Figure ratio='1-1' circle className={className}>
      {user.profilePicture && !hideProfilePicture ? (
        <CardImg
          top
          src={`${BASE_PATH}${user.profilePicture.url}`}
          alt={t('contacts.imgAlt', { name: `${user.firstname} ${user.lastname}` })}
        />
      ) : (
        <FigureAcronyme>{`${user.firstname[0]}${user.lastname[0]}`}</FigureAcronyme>
      )}
    </Figure>
  )
}

export default ProfilePicture
