import Card from 'components/atoms/card/Card'
import { FC } from 'react'
import { CardBody, CardHeader } from 'reactstrap'

import './DateCard.scss'

interface DateCardProps {
  date: Date
  className?: string
}

const truncatedMonths = ['jan', 'fév', 'mars', 'avril', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc']

// TODO: UTC ?
const DateCard: FC<DateCardProps> = ({ date, className = '' }) => {
  return (
    <Card padding='sm' borderRadius='md' className={`DateCard text-center ${className ? ` ${className}` : ''}`}>
      <CardHeader className='bg-primary day'>
        <span>{date.getDate()}</span>
      </CardHeader>
      <CardBody>
        <div className={'month'}>{truncatedMonths[date.getMonth()]}</div>
        <div className={'year'}>{date.getFullYear()}</div>
      </CardBody>
    </Card>
  )
}

export default DateCard
