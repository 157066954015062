import { FC, useState } from 'react'
import { Dropdown as RSDropdown, DropdownMenu, DropdownProps as RSDropdownProps, DropdownToggle } from 'reactstrap'

import './Dropdown.scss'

export type DropdownProps = RSDropdownProps & {
  label: string
  color?: Color
  className?: string
}

const Dropdown: FC<DropdownProps> = (props) => {
  const { label, color = 'white', children, className = '', ...rest } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <RSDropdown isOpen={dropdownOpen} toggle={toggle} className={className} {...rest}>
      {/* caret=fleche */}
      <DropdownToggle caret color={color} className={`DropdownToggle bg-${color}`}>
        {label}
      </DropdownToggle>
      <DropdownMenu className='DropdownMenu'>{children}</DropdownMenu>
    </RSDropdown>
  )
}

export default Dropdown
