import LanguageSwitch from 'components/molecules/language-switch/LanguageSwitch'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ModalContactInfo from '../../molecules/modals/ModalContactInfo'
import ModalLegalNotice from '../../molecules/modals/ModalLegalNotice'

interface ViewFootProps {
  className?: string
}

const ViewFoot: FC<ViewFootProps> = (props) => {
  const { className = '' } = props
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
  const [isLegalNoticeModalOpen, setIsLegalNoticeModalOpen] = useState(false)
  const toggleHelpModal = () => setIsHelpModalOpen((state: boolean) => !state)
  const toggleLegalNoticeModal = () => setIsLegalNoticeModalOpen((state: boolean) => !state)
  const { t } = useTranslation()

  return (
    <div className={`ViewFoot ${className}`}>
      <LanguageSwitch />
      <ModalContactInfo isOpen={isHelpModalOpen} toggle={toggleHelpModal} />
      <ModalLegalNotice isOpen={isLegalNoticeModalOpen} toggle={toggleLegalNoticeModal} />
      <a onClick={toggleLegalNoticeModal} className=' text-right mr-5 cursor-pointer FixedLinkLineHeight'>
        {t('common.legalNotice')}
      </a>
      <a onClick={toggleHelpModal} className=' text-right mr-5 cursor-pointer FixedLinkLineHeight'>
        {t('common.needHelp')}
      </a>
      <a target='_blank' rel='noopener noreferrer' href={'https://www.opco2i.fr/'}>
        <img alt='OPCO 2i' src='/news_logo-512x512.png' title={t('nav.home')} className='NavbarProfileImage' />
      </a>
    </div>
  )
}

export default ViewFoot
