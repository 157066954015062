import { ReactNode } from 'react'
import { Badge as RSBadge, BadgeProps as RSBadgeProps } from 'reactstrap'

import './Badge.scss'

interface BadgeProps extends RSBadgeProps {
  children: ReactNode
  color?: Color
  className?: string
  outline?: boolean
  filled?: boolean
  size?: 'sm' | 'md' | 'lg'
}

const Badge = ({
  children,
  className = '',
  outline,
  filled,
  size,
  color = 'medium',
  ...rest
}: BadgeProps): JSX.Element => {
  const badgeClassName = `Badge-${filled ? 'filled-' : ''}${outline ? 'outline-' : ''}${color}${
    size ? ` Badge-${size}` : ''
  } ${className}`

  return (
    <RSBadge className={`Badge ${badgeClassName}`} {...rest}>
      {children}
    </RSBadge>
  )
}

export default Badge
