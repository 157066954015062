import { ReactNode } from 'react'
import { Nav as RSNav, NavProps as RSNavProps } from 'reactstrap'

import './Nav.scss'

interface NavProps extends RSNavProps {
  children: ReactNode
  className?: string
}

const Nav = ({ children, className, ...rest }: NavProps): JSX.Element => {
  return (
    <RSNav className={`Nav${className ? ` ${className}` : ''}`} {...rest}>
      {children}
    </RSNav>
  )
}

export default Nav
