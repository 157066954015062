import Card from 'components/atoms/card/Card'
import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'
import { CardBody, Col } from 'reactstrap'

import './MeetingCard.scss'

const MeetingLoader: FC = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
        <Col key={item} lg={6} className='mb-4'>
          <Card className='MeetingCard'>
            <CardBody className='d-flex justify-content-between'>
              <ContentLoader style={{ width: '70%', height: '100%' }}>
                <rect x='10' y='5' rx='3' ry='3' width='200' height='15' />

                <rect x='10' y='40' rx='3' ry='3' width='70' height='13' />

                <rect x='10' y='75' rx='3' ry='3' width='450' height='11' />
                <rect x='10' y='105' rx='3' ry='3' width='350' height='11' />
                <rect x='10' y='135' rx='3' ry='3' width='400' height='11' />
              </ContentLoader>
              <ContentLoader style={{ width: '100px', height: '100%' }}>
                <rect x='0' y='5' rx='10' ry='10' width='100' height='110' />
              </ContentLoader>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  )
}

export default MeetingLoader
