import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'

import Button from '../../atoms/button/Button'
import Row from '../../atoms/layout/Row'
import Modal from '../../atoms/modal/Modal'

interface ConfirmationModalProps {
  show: boolean
  toggle: () => void
  onChange: () => void
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ show, toggle, onChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal className='FileSelectionModal' isOpen={show} toggle={toggle} centered size='lg'>
        <ModalHeader toggle={toggle}>{t('modal.areYouSure')}</ModalHeader>
        <ModalBody>
          <Row grid fluid className='p-3 d-flex justify-content-around'>
            <Button onClick={onChange}>{t('common.yes')}</Button>
            <Button onClick={toggle}>{t('common.no')}</Button>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ConfirmationModal
