import { generatePath } from 'react-router'

import { ApiRoutes } from '../utils/constants/ApiRoutes'

import { InvitationHistory } from '../types/InvitationHistory'

import { execute } from '../api/api'

const getAllByInvitationId = (id: number): Promise<InvitationHistory[]> => {
  return execute<InvitationHistory[]>(generatePath(ApiRoutes.INVITATIONS.HISTORY, { id }), 'GET')
}

export const invitationHistoryService = {
  getAllByInvitationId,
}
