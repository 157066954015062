import { BASE_PATH } from 'api/api'
import FileViewer from 'components/atoms/file-viewer/FileViewer'
import Icon from 'components/atoms/icon/Icon'
import React, { FC } from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'

import { DocumentType } from '../../../types/Document'

import Modal from '../../atoms/modal/Modal'
import './DocumentViewer.scss'

interface DocumentViewerProps {
  file: DocumentType
  isOpen: boolean
  onClose: () => void
}
const DocumentViewer: FC<DocumentViewerProps> = (props) => {
  const { file, isOpen, onClose } = props

  return (
    <Modal isOpen={isOpen} toggle={() => onClose()} size='full'>
      <ModalHeader toggle={() => onClose()}>
        <div className='d-flex'>
          <h4>{file.name}</h4>
          <a className={'text-right'} href={`${BASE_PATH}${file.url}`} download>
            <Icon name='download' />
          </a>
        </div>
      </ModalHeader>
      <ModalBody className='FileViewer'>
        <FileViewer file={file} />
      </ModalBody>
    </Modal>
  )
}

export default DocumentViewer
