import ProfilePicture from 'components/molecules/profile/ProfilePicture'
import React, { FC } from 'react'
import { DropdownToggle as RSDropdownToggle } from 'reactstrap'
import { User } from 'types/User'

import UserName from '../custom/UserName'

interface DropdownToggleProps {
  compact: boolean
  user: User
  color: string
  size: string
  className: string
}

const DropdownToggle: FC<DropdownToggleProps> = (props) => {
  const { compact, user, color, size, className } = props
  return (
    <RSDropdownToggle color={color} size={size} className={className}>
      {!compact ? (
        <>
          <ProfilePicture user={user} className='mx-auto picture' />
          <UserName className='text-center font-weight-bold name mt-1 mb-0' user={user} />
        </>
      ) : (
        <ProfilePicture user={user} className='mx-auto picture' />
      )}
    </RSDropdownToggle>
  )
}

export default DropdownToggle
