import { Tree as AntdTree, TreeProps as AntsTreeProps } from 'antd'
import React, { FC, Key } from 'react'

interface TreeProps extends Omit<AntsTreeProps, 'onCheck'> {
  onCheck?: (checked: Key[]) => void
}

const Tree: FC<TreeProps> = (props) => {
  const { onCheck, ...rest } = props

  return <AntdTree onCheck={onCheck as AntsTreeProps['onCheck']} {...rest} />
}

export default Tree
