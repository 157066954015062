import React, { FC } from 'react'
import { FormGroup, Label } from 'reactstrap'
import { InputType } from 'reactstrap/lib/Input'

import './InputWithLabel.scss'
import TextInput from './TextInput'

interface InputWithLabelProps {
  type: InputType
  name: string
  labelText: string
  placeholder?: string
  required?: boolean
  silentRequired?: boolean
  className?: string
  labelClassName?: string
  [key: string]: unknown
}

const InputWithLabel: FC<InputWithLabelProps> = ({
  labelText,
  placeholder,
  name,
  required = false,
  silentRequired = false,
  className = '',
  labelClassName = 'font-weight-bold',
  ...rest
}) => {
  return (
    <FormGroup className={`InputWithLabel ${className}`}>
      <Label for={name} className={labelClassName}>
        {`${labelText} ${required && !silentRequired ? '*' : ''}`}
      </Label>
      <TextInput id={name} name={name} placeholder={placeholder} required={required} {...rest} />
    </FormGroup>
  )
}

export default InputWithLabel
