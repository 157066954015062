import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

import { useToggle } from '../../../utils/hooks/useToggle'

import { DocumentType } from '../../../types/Document'

import Button from '../../atoms/button/Button'
import Checkbox from '../../atoms/checkbox/Checkbox'
import Row from '../../atoms/layout/Row'
import ModalAreYouSure from '../modals/ModalAreYouSure'
import DocumentCard from './DocumentCard'
import './DocumentCarrousel.scss'
import DownloadMultipleDocumentLink from './DownloadMultipleDocumentLink'

interface DocumentCarrouselProps {
  onDeleteDocuments?: (documentList: DocumentType[]) => void
  fileList: DocumentType[]
  openPreviewDocument?: (file: DocumentType) => void
  prefixKey?: string
  closable?: boolean
  checkable?: boolean
  deletable?: boolean
  downloadable?: boolean
  xlSize?: number
  flexWrap?: 'wrap' | 'nowrap'
}

const DocumentCarrousel: FC<DocumentCarrouselProps> = (props) => {
  const {
    fileList = [],
    openPreviewDocument,
    onDeleteDocuments,
    prefixKey = '',
    closable = true,
    checkable = true,
    deletable = false,
    downloadable = true,
    xlSize = 2,
    flexWrap = 'nowrap',
  } = props
  const [isOpen, setIsOpen] = useState(true)
  const [checkedFileList, setCheckedFileList] = useState<DocumentType[]>([])
  const [isAllChecked, setIsAllChecked] = useState(checkedFileList.length === fileList.length && fileList.length > 0)

  const { t } = useTranslation()
  const { state: isDeleteModalOpen, toggle: toggleDeleteModal } = useToggle()

  const onOpen = (value: boolean) => {
    setIsOpen(value)
  }
  // il faut synchroniser le state isAllChecked avec le state checkedFileList
  // le setState étant asynchrone, le changement de state de isAllChecked doit se faire en même temps que le checkedFileList
  const onCheckDocument = (file: DocumentType) => {
    setCheckedFileList((prevState) => {
      const checked = !checkedFileList.includes(file)
      const newCheckFileList = checked
        ? [...prevState, file]
        : prevState.filter((prevFile) => prevFile.name !== file.name)
      setIsAllChecked(newCheckFileList.length === fileList.length)
      return newCheckFileList
    })
  }

  const onDeleteConfirm = async () => {
    if (onDeleteDocuments) {
      await onDeleteDocuments(checkedFileList)
      setCheckedFileList([])
    }
  }

  const onAllHandleCheck = () => {
    isAllChecked ? setCheckedFileList([]) : setCheckedFileList(fileList)
    setIsAllChecked((prevState) => !prevState)
  }
  return (
    <div className='DocumentCarrousel mb-3'>
      {fileList.length > 0 && (
        <>
          {checkable && (
            <div onClick={onAllHandleCheck} className='cursor-pointer'>
              <Checkbox checked={isAllChecked} className='cursor-pointer' />
              <p className='ml-5'>{isAllChecked ? t('common.unselectAll') : t('common.selectAll')}</p>
            </div>
          )}
          {checkedFileList && checkedFileList.length > 0 && (
            <div className='d-flex justify-content-end '>
              {downloadable && <DownloadMultipleDocumentLink documentList={checkedFileList} className='mr-3 ' />}
              {deletable && (
                <Button
                  color='secondary'
                  onClick={toggleDeleteModal}
                  label={t('meeting.documents.deleteDocuments', { size: checkedFileList.length })}
                />
              )}
            </div>
          )}
          <div onClick={() => onOpen(!isOpen)} className='cursor-pointer'>
            {closable && (
              <a className='d-block text-right mt-3'>
                {isOpen ? t('documents.closeDocuments') : t('documents.openDocuments', { count: fileList.length })}
              </a>
            )}
          </div>

          {isOpen && (
            <Row grid flexWrap={flexWrap} className='overflow-auto'>
              {fileList?.length > 0 &&
                fileList.map((document) => (
                  <Col xs={12} sm={6} md={4} lg={3} xl={xlSize} key={`${prefixKey}_${document.name}_${document.id}`}>
                    <DocumentCard
                      file={document}
                      grid
                      isChecked={checkedFileList.includes(document)}
                      openPreviewDocument={openPreviewDocument}
                      onCheckDocument={onCheckDocument}
                      showChecked={checkable}
                      downloadable={downloadable}
                    />
                  </Col>
                ))}
            </Row>
          )}
        </>
      )}
      <ModalAreYouSure
        toggle={toggleDeleteModal}
        isOpen={isDeleteModalOpen}
        onConfirmClick={onDeleteConfirm}
        modalHeaderText={t('modal.areYouSureToDeleteDocuments')}
        modalBody={
          <>
            <p>
              {t('modal.actionCanNotBeUndone')}
              <br />
              {t('modal.documentsWillBeDeleted')}
              <br />
            </p>
            {checkedFileList.map((file) => (
              <p key={file.id}>{file.name}</p>
            ))}
          </>
        }
      />
    </div>
  )
}

export default DocumentCarrousel
