import Button from 'components/atoms/button/Button'
import Modal from 'components/atoms/modal/Modal'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalHeader } from 'reactstrap'
import { User } from 'types/User'

import ModalContact from '../modals/contacts/ModalContact'

interface Props {
  user: User
  onModalClose: () => void
}

const SameEmailUserModal: FC<Props> = ({ user, onModalClose }) => {
  const { t } = useTranslation()
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false)

  const openContact = () => setUserModalOpen(true)

  return (
    <>
      <Modal isOpen={true} toggle={onModalClose} size='md'>
        <ModalHeader toggle={onModalClose}>
          <b>{t('userCreate.sameEmailModal.head')}</b>
        </ModalHeader>
        <ModalBody>
          <a className='cursor-pointer text-center mb-5' onClick={openContact}>
            {t('userCreate.sameEmailModal.body', { firstname: user.firstname, lastname: user.lastname })}
          </a>
          <Button label={'Reprendre la création'} onClick={onModalClose} />
        </ModalBody>
      </Modal>
      {userModalOpen && (
        <ModalContact isOpen={userModalOpen} toggle={() => setUserModalOpen(false)} contact={user} editRight={true} />
      )}
    </>
  )
}

export default SameEmailUserModal
