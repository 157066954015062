import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { generatePath } from 'react-router'
import { Link, useParams } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

import { notifyError, notifySuccess } from '../utils/alertUtils'
import { PERMISSIONS } from '../utils/constants/Permissions'
import { PATHS } from '../utils/constants/routes/RoutePaths'
import { useQuery } from '../utils/hooks/useQuery'

import { Collaborator } from '../types/Collaborator'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'
import MandateRight, { MandateRightType } from '../components/molecules/mandate/mandatePermission'

import collaboratorService from '../services/collaboratorService'

const MandatesRightView: FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { idMandate, id } = useParams<{ idMandate: string; id: string }>()

  const setCurrentPermissions = (permissions: string[]) => {
    return Object.keys(PERMISSIONS).map((permission) => {
      return {
        title: t('permissions.' + permission),
        permission: permission,
        checked: permissions.includes(permission),
      }
    })
  }

  const { data: collaborator, refetch } = useQuery<Collaborator>({
    queryKey: ['collaborator', 'getOne'],
    queryFn: () => collaboratorService.getOne(parseInt(idMandate, 10)),
    onError: () => notifyError(t('toastify.errors.get.user')),
    cacheTime: 0,
  })

  const [rights, setRights] = useState<MandateRightType[]>(setCurrentPermissions([]))

  useEffect(() => {
    if (collaborator?.permission) {
      setRights(setCurrentPermissions(collaborator.permission))
    }
  }, [collaborator])

  const onChecked = (checked: boolean, permission: string) => {
    if (collaborator) {
      const permissionCollab = collaborator.permission ?? []
      const newCollab = {
        ...collaborator,
        permission: checked
          ? [...permissionCollab, permission]
          : permissionCollab.filter((perm) => perm !== permission),
        is_manager: permission === PERMISSIONS.MANAGER_RIGHT ? checked : collaborator.is_manager,
      }
      mutate({ collaboratorId: collaborator.id, data: newCollab })
      setRights(
        rights.map((right) => {
          if (right.permission === permission) {
            return {
              ...right,
              checked,
            }
          }
          return right
        }),
      )
    }
  }

  const { mutate } = useMutation(
    ({ collaboratorId, data }: { collaboratorId: number; data: Partial<Collaborator> }) =>
      collaboratorService.updateOne(collaboratorId, data),
    {
      mutationKey: ['collaborator', 'updateMe'],
      onSuccess: () => {
        refetch()
        notifySuccess(t('toastify.success.updateMe'))
      },
      onError: () => notifyError(t('toastify.errors.update.collaborator')),
    },
  )

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.USER_MANAGEMENT.HOME}>{t('nav.userManagement')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <span className='d-flex align-items-center'>{t('permissions.header.permissionManagement')}</span>
              </BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
        <Link
          to={generatePath(PATHS.USER_MANAGEMENT.MANDATES, { id })}
          className='btn btn-primary font-weight-bold ml-4'
        >
          {t('common.back')}
        </Link>
      </ViewHead>
      <ViewBody>
        <MandateRight rights={rights} onChange={onChecked} />
      </ViewBody>
    </View>
  )
}

export default MandatesRightView
