import HelpSection from 'components/molecules/help/HelpSection'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BreadcrumbItem, Spinner } from 'reactstrap'
import { helpService } from 'services/helpService'
import { PATHS } from 'utils/constants/routes/RoutePaths'
import { usePermissions } from 'utils/hooks/usePermissions'
import { useQuery } from 'utils/hooks/useQuery'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import Header from '../components/molecules/heading/Header'

const HelpForManagerView: FC = () => {
  const { t } = useTranslation()
  const { USER_CAN_ACCESS_MANAGER_HELP } = usePermissions()

  const { isLoading, data: managerHelpTutorials } = useQuery({
    queryKey: ['help', 'fetchHelpTutorialsAndGroupByType', 'managers_documents'],
    queryFn: () => helpService.fetchHelpTutorialsAndGroupByType('managers_documents'),
  })

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Header>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={PATHS.HELP.HOME}>{t('nav.help')}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{t('help.managerHelp')}</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Section>
      </ViewHead>
      <ViewBody>
        {isLoading && <Spinner />}
        {!isLoading && USER_CAN_ACCESS_MANAGER_HELP() && (
          <HelpSection videoList={managerHelpTutorials?.videoList} slidesList={managerHelpTutorials?.slidesList} />
        )}
      </ViewBody>
    </View>
  )
}

export default HelpForManagerView
