import Spinner from 'components/atoms/spinner/Spinner'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { BreadcrumbItem } from 'reactstrap'

import { PATHS } from '../utils/constants/routes/RoutePaths'
import { useQuery } from '../utils/hooks/useQuery'

import { Article } from '../types/Article'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Section from '../components/atoms/layout/Section'
import View from '../components/atoms/layout/View'
import ViewBody from '../components/atoms/layout/ViewBody'
import ViewHead from '../components/atoms/layout/ViewHead'
import ArticleContent from '../components/molecules/articles/ArticleContent'
import Header from '../components/molecules/heading/Header'

import { articleService } from '../services/articlesService'

interface ArticleRouteParams {
  slug: string
}
const ArticlePageView: FC = () => {
  const { slug } = useParams<ArticleRouteParams>()
  const { t } = useTranslation()
  const { isLoading, data: article } = useQuery<Article>({
    queryKey: ['article', 'findOneBySlug', slug],
    queryFn: () => articleService.findBySlug(slug),
  })

  if (isLoading) return <Spinner />

  return (
    <View>
      <ViewHead>
        <Header>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={PATHS.NEWS.HOME}>{t('nav.news')}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>{article?.title || ''}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </ViewHead>
      <ViewBody>
        <Section fluid>
          {article && <ArticleContent article={article} />}
          {!article && <p>{t('kiosk.article.noArticle')}</p>}
        </Section>
        {/*<Section fluid>*/}
        {/*  <LastArticles currentArticleSlug={slug} />*/}
        {/*</Section>*/}
      </ViewBody>
    </View>
  )
}

export default ArticlePageView
