import Row from 'components/atoms/layout/Row'
import * as dateFns from 'date-fns'
import { FC } from 'react'
import { Col } from 'reactstrap'

import { locale } from './Calendar'
import './CalendarDays.scss'

interface CalendarDaysProps {
  selectedDate: Date
}

const dateFormat = 'EEEE'
const CalendarDays: FC<CalendarDaysProps> = (props) => {
  const { selectedDate } = props
  const days = []

  const startDate = dateFns.startOfWeek(selectedDate, { weekStartsOn: 1 })

  const buildDay = (i: number) => dateFns.format(dateFns.addDays(startDate, i), dateFormat, { locale })

  for (let i = 0; i < 5; i++) {
    days.push(
      <Col className='col col-center' key={i}>
        <span>{buildDay(i)}</span>
      </Col>,
    )
  }

  return (
    <Row grid gutters='xs' className='CalendarDays' flexFill flexWrap='nowrap'>
      {days}
    </Row>
  )
}

export default CalendarDays
