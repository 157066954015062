import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { CardBody, CardTitle, Col } from 'reactstrap'

import { Article } from '../../../types/Article'

import Card from '../../atoms/card/Card'
import Row from '../../atoms/layout/Row'
import CardArticle from '../cards/article/CardArticle'
import './Search.scss'

interface SearchNewsProps {
  news: Article[]
}

const SearchNews: FC<SearchNewsProps> = ({ news }) => {
  const { t } = useTranslation()

  return (
    <Card className='search-card'>
      <CardBody>
        <CardTitle>{t('search.news.title')}</CardTitle>
        {news.length > 0 ? (
          <Row>
            {news.map((n) => (
              <Col sm={4} key={n.id} className='my-3'>
                <CardArticle {...n} className='article-card' />
              </Col>
            ))}
          </Row>
        ) : (
          <div className='no-result d-flex flex-column justify-content-center'>
            <p>{t('search.noResult')}</p>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default SearchNews
