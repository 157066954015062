import { ReactNode } from 'react'
import { Card as RSCard, CardProps as RSCardProps } from 'reactstrap'

import './Card.scss'

interface CardProps extends RSCardProps {
  children: ReactNode
  color?: Color
  className?: string
  padding?: 'none' | 'sm' | 'md' | 'lg'
  borderRadius?: 'none' | 'sm' | 'md' | 'lg' | 'xl'
  clickable?: boolean
  banner?: boolean
  bannerColor?: Color
  boxShadow?:
    | 'none'
    | 'primary-light-sm'
    | 'primary-light-md'
    | 'primary-light-lg'
    | 'primary-medium-sm'
    | 'primary-medium-md'
    | 'primary-medium-lg'
    | 'primary-strong-sm'
    | 'primary-strong-md'
    | 'primary-strong-lg'
    | 'dark-light-sm'
    | 'dark-light-md'
    | 'dark-light-lg'
    | 'dark-medium-sm'
    | 'dark-medium-md'
    | 'dark-medium-lg'
    | 'dark-strong-sm'
    | 'dark-strong-md'
    | 'dark-strong-lg'
}

const Card = (props: CardProps): JSX.Element => {
  const {
    children,
    className = '',
    borderRadius = 'lg',
    boxShadow = 'dark-medium-lg',
    padding,
    clickable,
    banner,
    bannerColor,
    ...rest
  } = props
  const cardClassName = `Card border-radius-${borderRadius} box-shadow-${boxShadow} ${className}
  ${padding ? ` padding-${padding}` : ''} ${clickable ? 'CardClickable' : ''} ${
    banner ? ` CardBanner Banner-${bannerColor}` : ''
  }`

  return (
    <RSCard className={`Card ${cardClassName}`} {...rest}>
      {children}
    </RSCard>
  )
}

export default Card
