import Card from 'components/atoms/card/Card'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap'
import { InstanceListStore } from 'store/InstanceListStore'
import { getUniqueInstancesFromMembersAndCollaborators, isMandateCurrent } from 'utils/mandateUtils'

import { PATHS } from '../../../utils/constants/routes/RoutePaths'
import { isCollaboratorOnAllPerimeter, isDelegate, usePermissions } from '../../../utils/hooks/usePermissions'

import { Instance } from '../../../types/Instance'
import { User } from '../../../types/User'

import Badge from '../../atoms/badge/Badge'
import Button from '../../atoms/button/Button'
import TooltipWithLabel from '../../atoms/tooltip/TooltipWithLabel'
import MeetingInstanceAssociationName from '../meeting/MeetingInstanceAssociationName'
import MandateInstanceAssociationName from './MandateInstanceAssociationName'
import './Mandates.scss'

interface MandateProps {
  user: User
  title: string
  editRight: boolean
}

const Mandates: FC<MandateProps> = ({ user, title, editRight }: MandateProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const onEditMandatesClicked = (tab: string) => {
    history.push(`${generatePath(PATHS.USER_MANAGEMENT.MANDATES, { id: user.id })}?tab=${tab}`)
  }
  const { IS_SUPER_ADMIN, CONTACTS_CAN_MANAGE } = usePermissions()

  let instances: Instance[] = []
  const userHasAllPerimeter = isCollaboratorOnAllPerimeter(user.collaborators)

  const currentMembers = user.members.filter((member) => isMandateCurrent(member.start_date, member.end_date))
  const currentCollaborators = user.collaborators.filter((collab) =>
    isMandateCurrent(collab.start_date, collab.end_date),
  )
  const currentDelegates = user.delegates?.filter((delegate) =>
    isMandateCurrent(delegate.start_date, delegate.end_date),
  )

  if (!userHasAllPerimeter) {
    const allInstanceList = InstanceListStore.getState() || []
    instances = getUniqueInstancesFromMembersAndCollaborators(currentMembers, currentCollaborators, allInstanceList)
  }

  return (
    <Card className='Mandates' boxShadow='none' color='mediumgrey'>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      {!isDelegate(user.delegates) && (
        <CardBody className='MandatesBody'>
          {!userHasAllPerimeter && instances.length === 0 && <p>{t('mandates.empty')}</p>}
          {userHasAllPerimeter ? (
            <Badge outline filled color='primary' size='md' className='MandateBadge'>
              <span>{t('common.fields.mandate.allPerimeter')}</span>
            </Badge>
          ) : (
            instances.map((instance) => (
              <TooltipWithLabel
                key={instance.id}
                target={`badge_${instance.id}`}
                labelClassName='w-100'
                label={
                  <Badge outline filled color='primary' size='md' className='MandateBadge'>
                    <MandateInstanceAssociationName instance={instance} nameType='short_name' />
                  </Badge>
                }
              >
                <MandateInstanceAssociationName instance={instance} nameType='name' />
              </TooltipWithLabel>
            ))
          )}
        </CardBody>
      )}
      {isDelegate(user.delegates) && (
        <CardBody className='MandatesBody'>
          {currentDelegates.length === 0 ? (
            <p>{t('meeting.empty')}</p>
          ) : (
            currentDelegates.map((delegate) => (
              <TooltipWithLabel
                key={delegate.id}
                target={`badge_${delegate.id}`}
                labelClassName='w-100'
                label={
                  <Badge outline filled color='primary' size='md' className='MandateBadge'>
                    <MeetingInstanceAssociationName meeting={delegate.meeting} nameType='short_name' />
                  </Badge>
                }
              >
                <MeetingInstanceAssociationName meeting={delegate.meeting} nameType='name' />
              </TooltipWithLabel>
            ))
          )}
        </CardBody>
      )}
      <CardFooter>
        {editRight && (CONTACTS_CAN_MANAGE() || IS_SUPER_ADMIN()) && (
          <Button
            block
            outline
            label={t('mandates.editRights')}
            color={'primary'}
            onClick={() => onEditMandatesClicked('member')}
          />
        )}
      </CardFooter>
    </Card>
  )
}

export default Mandates
