import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { Language } from '../utils/constants/Languages'

import translationEN from '../i18n/en.json'
import translationFR from '../i18n/fr.json'

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    fallbackLng: Language.FR,
    keySeparator: '.',
    detection: LanguageDetector,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18next
