import { FC, ReactNode } from 'react'
import { Button as RSButton, ButtonProps as RSButtonProps } from 'reactstrap'

import Spinner from '../spinner/Spinner'
import './Button.scss'

type ButtonProps = RSButtonProps & {
  label?: string
  className?: string
  color?: Color
  size?: 'sm' | 'lg'
  children?: ReactNode
  borderRadius?: 'none' | 'sm' | 'md' | 'lg'
  isLoading?: boolean
  boxShadow?:
    | 'none'
    | 'primary-light-sm'
    | 'primary-light-md'
    | 'primary-light-lg'
    | 'primary-medium-sm'
    | 'primary-medium-md'
    | 'primary-medium-lg'
    | 'primary-strong-sm'
    | 'primary-strong-md'
    | 'primary-strong-lg'
    | 'dark-light-sm'
    | 'dark-light-md'
    | 'dark-light-lg'
    | 'dark-medium-sm'
    | 'dark-medium-md'
    | 'dark-medium-lg'
    | 'dark-strong-sm'
    | 'dark-strong-md'
    | 'dark-strong-lg'
}

const Button: FC<ButtonProps> = ({
  color = 'primary',
  label,
  className = '',
  size,
  borderRadius = 'md',
  boxShadow = 'primary-light-sm',
  children,
  isLoading,
  disabled,
  ...rest
}): JSX.Element => {
  const buttonClassName = `Button${borderRadius ? ` border-radius-${borderRadius}` : ''}${
    boxShadow ? ` box-shadow-${boxShadow}` : ''
  } ${className}`

  return (
    <RSButton
      className={`Button ${buttonClassName}`}
      color={color}
      size={size}
      disabled={isLoading || disabled}
      {...rest}
    >
      {!isLoading && <span>{label}</span>}
      {isLoading && <Spinner className='mx-3' />}
      {children}
    </RSButton>
  )
}

export default Button
