import { TreeSelect as AntdTreeSelect } from 'antd'
import React, { FC, Key, ReactNode } from 'react'

// Styles
import './TreeSelect.scss'

const { SHOW_PARENT, SHOW_CHILD } = AntdTreeSelect

export type TreeDataType = {
  key?: Key
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value?: any
  title?: ReactNode
  label?: ReactNode
  disabled?: boolean
  disableCheckbox?: boolean
  checkable?: boolean
  children?: TreeDataType[]
}

interface TreeSelectorProps {
  defaultExpandAll?: boolean
  className?: string
  data: TreeDataType[]
  placeholder: string
  value: string[]
  onChange: (value: string[]) => void
  defaultValue?: string[]
  filterTreeNode?: (search: string, node: TreeDataType | undefined) => boolean
  style?: Record<string, unknown>
  showParent?: boolean
}

const TreeSelect: FC<TreeSelectorProps> = (props) => {
  const { defaultExpandAll, data = [], className = '', showParent = true, ...rest } = props
  return (
    <AntdTreeSelect
      treeDefaultExpandAll={defaultExpandAll}
      className={`TreeSelect ${className}`}
      treeData={data}
      treeCheckable
      showCheckedStrategy={showParent ? SHOW_PARENT : SHOW_CHILD}
      {...rest}
    />
  )
}

export default TreeSelect
