import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownItem, DropdownProps } from 'reactstrap'

import { RESPONDED_USER_STATUTES, RESPONDED_USER_STATUTES_MIXED_CHANNEL } from '../../../../utils/constants/Status'

import { InvitationStatusType } from '../../../../types/InvitationStatus'

import Dropdown from '../../../atoms/dropdown/Dropdown'
import './DropdownStatus.scss'

interface DropdownStatusProps extends Omit<DropdownProps, 'dropdownItems'> {
  onClick: (status: InvitationStatusType) => void
  mixedChannel: boolean
  disabled?: boolean
}

const DropdownStatus: FC<DropdownStatusProps> = (props) => {
  const { label, color = 'white', onClick, mixedChannel, disabled = false } = props
  const { t } = useTranslation()

  return (
    <Dropdown label={label} color={color} className='StatusDropdown' disabled={disabled}>
      {mixedChannel &&
        RESPONDED_USER_STATUTES_MIXED_CHANNEL.map(([key, value]) => (
          <DropdownItem key={key} onClick={() => onClick(key)} className={`font-weight-bold text-${value.color}`}>
            {t(value.label)}
          </DropdownItem>
        ))}
      {!mixedChannel &&
        RESPONDED_USER_STATUTES.map(([key, value]) => (
          <DropdownItem key={key} onClick={() => onClick(key)} className={`font-weight-bold text-${value.color}`}>
            {t(value.label)}
          </DropdownItem>
        ))}
    </Dropdown>
  )
}

export default DropdownStatus
