import Row from 'components/atoms/layout/Row'
import Section from 'components/atoms/layout/Section'
import ViewBody from 'components/atoms/layout/ViewBody'
import ViewHead from 'components/atoms/layout/ViewHead'
import { useStore } from 'effector-react'
import _ from 'lodash'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Redirect } from 'react-router-dom'
import { BreadcrumbItem, Col, FormGroup, Label } from 'reactstrap'
import { PATHS } from 'utils/constants/routes/RoutePaths'

import { CONTACT_COLLEGE, CONTACT_FUNCTIONS, initialFilter } from '../utils/constants/Contacts'
import { SEARCH_DEBOUNCE_DELAY } from '../utils/constants/Filters'
import { downloadExportDocument } from '../utils/fileUtils'
import { useInfiniteScroll } from '../utils/hooks/useInfiniteScroll'
import { usePermissions } from '../utils/hooks/usePermissions'
import { useQuery } from '../utils/hooks/useQuery'
import { filterInstances, getInstancesAsTreeDataType } from '../utils/instances'

import { ContactFilterType } from '../types/Filters'
import { User } from '../types/User'

import Breadcrumb from '../components/atoms/breadcrumb/Breadcrumb'
import Button from '../components/atoms/button/Button'
import InputWithLabel from '../components/atoms/input/InputWithLabel'
import TextInput from '../components/atoms/input/TextInput'
import View from '../components/atoms/layout/View'
import ButtonDisplayType from '../components/molecules/buttons/ButtonDisplayType'
import ContactCardList from '../components/molecules/contacts/ContactCardList'
import ContactLoader from '../components/molecules/contacts/ContactLoader'
import ContactTable from '../components/molecules/contacts/ContactTable'
import InstanceFilter from '../components/molecules/filters/InstanceFilter'
import Header from '../components/molecules/heading/Header'
import ModalContact from '../components/molecules/modals/contacts/ModalContact'
import Reducer from '../components/molecules/reducer/Reducer'

import { userService } from '../services/userService'
import { InstanceListStore } from '../store/InstanceListStore'

const viewBodyId = 'contactslist'

const UserManagementView: FC = () => {
  const [contactFilters, setContactFilters] = useState<ContactFilterType>(initialFilter)
  const [loadMore, setLoadMore] = useState(false)
  const [typeDisplay, setTypeDisplay] = useState(true)

  const [selectedUser, setSelectedUser] = useState<User>()
  const { t } = useTranslation()
  const { CONTACTS_CAN_MANAGE, IS_SUPER_ADMIN } = usePermissions()
  const instances = getInstancesAsTreeDataType(useStore(InstanceListStore))

  const { data: contactList = [], isLoading: areContactsLoading } = useQuery<User[]>({
    queryKey: ['users', 'getContactsWithFilters', contactFilters],
    queryFn: async (): Promise<User[]> => {
      const freshContactList = await userService.getUsersWithFilters(contactFilters)
      setLoadMore(freshContactList.loadMore)
      return contactFilters.page === 1 ? freshContactList.users : contactList.concat(freshContactList.users)
    },

    keepPreviousData: loadMore,
    cacheTime: 0,
  })

  const onSearchInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onSearchChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )
  const onSearchLastNameInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onSearchLastNameChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )
  const onSearchFirstNameInputChange = _.debounce(
    (event: ChangeEvent<HTMLInputElement>) => onSearchFirstNameChange(event.target.value),
    SEARCH_DEBOUNCE_DELAY,
  )
  const list = [
    {
      name: 'contact-filter-fullSearch',
      placeholder: 'contacts.filters.search.fullSearch.label',
      label: 'contacts.filters.search.fullSearch.label',
      func: onSearchInputChange,
    },
    {
      name: 'contact-filter-firstNameSearch',
      placeholder: 'contacts.filters.search.firstName.placeholder',
      label: 'contacts.filters.search.firstName.label',
      func: onSearchFirstNameInputChange,
    },
    {
      name: 'contact-filter-lastNameSearch',
      placeholder: 'contacts.filters.search.lastName.placeholder',
      label: 'contacts.filters.search.lastName.label',
      func: onSearchLastNameInputChange,
    },
  ]

  const onInstanceChange = (selectedInstances: string[]) => {
    setContactFilters((prevState) => ({
      ...prevState,
      instanceList: selectedInstances,
      page: 1,
    }))
  }

  const onFunctionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setContactFilters((prevState) => ({
      ...prevState,
      role: event.target.value,
      page: 1,
    }))
  }
  const onCollegeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setContactFilters((prevState) => ({
      ...prevState,
      college: event.target.value,
      page: 1,
    }))
  }

  const onSearchChange = (value: string) => {
    setContactFilters((prevState) => ({
      ...prevState,
      search: value,
      page: 1,
    }))
  }
  const onSearchLastNameChange = (value: string) => {
    setContactFilters((prevState) => ({
      ...prevState,
      lastname: value,
      page: 1,
    }))
  }
  const onSearchFirstNameChange = (value: string) => {
    setContactFilters((prevState) => ({
      ...prevState,
      firstname: value,
      page: 1,
    }))
  }
  const loadMoreHandle = () => {
    !areContactsLoading &&
      loadMore &&
      setContactFilters((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        }
      })
  }
  useInfiniteScroll(viewBodyId, [contactList], () => loadMoreHandle())

  const onContactClicked = (user: User) => {
    setSelectedUser(user)
  }

  const onModalClose = () => {
    setSelectedUser(undefined)
  }

  if (!(IS_SUPER_ADMIN() || CONTACTS_CAN_MANAGE())) return <Redirect to='/' />

  return (
    <View>
      <ViewHead>
        <Section fluid>
          <Row className='align-items-center' grid>
            <Col>
              <Header className='flex-column flex-sm-row'>
                <Breadcrumb>
                  <BreadcrumbItem>{t('userManagement.header')}</BreadcrumbItem>
                </Breadcrumb>
              </Header>
            </Col>
            <Col xs='auto' className={'mb-3'}>
              <ButtonDisplayType typeDisplay={typeDisplay} setTypeDisplay={setTypeDisplay} />
            </Col>
            {CONTACTS_CAN_MANAGE() && (
              <>
                <Col xs='auto'>
                  <Link to={PATHS.USER_MANAGEMENT.ALL_INSTANCES}>
                    <Button label={t('contacts.buttons.instancesManagement')} />
                  </Link>
                </Col>
                <Col xs='auto'>
                  <Link to={PATHS.USER_MANAGEMENT.CREATE_USER} className='ml-sm-3 mt-3 mt-sm-0'>
                    <Button label={t('userCreate.linkButton')} />
                  </Link>
                </Col>
                <Col xs='auto'>
                  <Button
                    label={t('contacts.buttons.exportUser')}
                    onClick={() => downloadExportDocument(contactFilters)}
                  />
                </Col>
              </>
            )}
          </Row>

          <Reducer label={t('meeting.filters.filter')}>
            <Row>
              <Col xs={12} lg={5}>
                <InstanceFilter
                  defaultExpandAll
                  label={t('contacts.filters.instance.label')}
                  placeholder={t('contacts.filters.instance.disabled')}
                  filterTreeNode={filterInstances}
                  data={instances || []}
                  value={contactFilters.instanceList}
                  onChange={onInstanceChange}
                />
              </Col>
              <Col xs={12} lg={3}>
                <FormGroup className='mb-3'>
                  <Label for='functionSelect'>{t('contacts.filters.function.label').toUpperCase()}</Label>
                  <TextInput
                    type='select'
                    name='role'
                    id='functionSelect'
                    value={contactFilters.role}
                    onChange={onFunctionChange}
                  >
                    {CONTACT_FUNCTIONS.map((option) => (
                      <option key={option.key} value={option.key}>
                        {t(option.label)}
                      </option>
                    ))}
                  </TextInput>
                </FormGroup>
              </Col>
              <Col xs={12} lg={4}>
                <FormGroup className='mb-3'>
                  <Label for='collegeSelect'>{t('contacts.filters.college.label').toUpperCase()}</Label>
                  <TextInput
                    type='select'
                    name='college'
                    id='collegeSelect'
                    value={contactFilters.college}
                    onChange={onCollegeChange}
                  >
                    {CONTACT_COLLEGE.map((option) => (
                      <option key={option.key} value={option.key}>
                        {t(option.label)}
                      </option>
                    ))}
                  </TextInput>
                </FormGroup>
              </Col>
              {list.map((item) => {
                return (
                  <Col xs={12} lg={4} key={item.name}>
                    <InputWithLabel
                      name={item.name}
                      type='text'
                      placeholder={t(item.placeholder)}
                      labelText={t(item.label).toUpperCase()}
                      onChange={item.func}
                      labelClassName='font-weight-normal'
                    />
                  </Col>
                )
              })}
            </Row>
          </Reducer>
        </Section>
      </ViewHead>
      <ViewBody id={viewBodyId}>
        <Section fluid className={areContactsLoading ? 'h-100' : ''}>
          {contactList && contactList.length > 0 ? (
            <>
              {typeDisplay ? (
                <ContactCardList
                  contactList={contactList}
                  onContactClicked={onContactClicked}
                  contactWithoutMandate={true}
                />
              ) : (
                <ContactTable contactList={contactList} onContactClicked={onContactClicked} />
              )}

              {loadMore && (
                <Button
                  className='d-block mx-auto mt-3'
                  onClick={loadMoreHandle}
                  label={t('documents.filters.seeMoreButton.label')}
                />
              )}
            </>
          ) : (
            <>{areContactsLoading ? <ContactLoader /> : <h3 className='mt-3 text-center'>{t('common.noResult')}</h3>}</>
          )}
          <ModalContact
            isOpen={!!selectedUser}
            toggle={onModalClose}
            contact={selectedUser}
            editRight={true}
            forceEditRight={true}
          />
        </Section>
      </ViewBody>
    </View>
  )
}

export default UserManagementView
