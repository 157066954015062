import React, { FC, useState } from 'react'

import { DocumentType } from '../../../types/Document'

import DocumentCard from '../documents/DocumentCard'
import DocumentViewer from '../documents/DocumentViewer'
import './CguCard.scss'

interface CguCardProps {
  cgu?: DocumentType
}

const CguCard: FC<CguCardProps> = (props) => {
  const { cgu } = props

  const [selectedFile, setSelectedFile] = useState<DocumentType>()

  return (
    <div className='CguCard'>
      {selectedFile && (
        <DocumentViewer file={selectedFile} isOpen={Boolean(selectedFile)} onClose={() => setSelectedFile(undefined)} />
      )}
      {cgu && <DocumentCard file={cgu} grid openPreviewDocument={setSelectedFile} showChecked={false} />}
    </div>
  )
}

export default CguCard
