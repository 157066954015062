import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DocumentType } from 'types/Document'
import { triggerMultipleDownloadFromObjectUrls } from 'utils/fileUtils'

import { BASE_PATH } from '../../../api/api'
import Button from '../../atoms/button/Button'

interface DownloadMultipleDocumentLinkProps {
  documentList: DocumentType[]
  className?: string
}

const DownloadMultipleDocumentLink: FC<DownloadMultipleDocumentLinkProps> = ({ documentList, className = '' }) => {
  const { t } = useTranslation()

  const onDownloadMultipleDocuments = (documentList: DocumentType[]) => {
    const fileArray = documentList.map((file) => ({ objectURL: BASE_PATH + file.url, fileName: file.name }))
    triggerMultipleDownloadFromObjectUrls(fileArray)
  }

  return (
    <Button
      className={className}
      onClick={() => onDownloadMultipleDocuments(documentList)}
      label={t('documents.downloadDocuments', { count: documentList.length })}
    />
  )
}

export default DownloadMultipleDocumentLink
